<template>
  <main>
    <div class="bank">
      <div class="container-db bread-container">
        <div class="bread-crumbs">
          <router-link to="/">Home</router-link>
          <span class="bread-separator">/</span>
          <span class="bread-current">NFT market and Swapping </span>
        </div>
      </div>
      <div class="container-db">
        <h1 class="bank-title">NFT market and Swapping</h1>
      </div>
    </div>
    <div class="descrip-result-wrapper">
      <div class="descrip-result-text">
        <div class="container-db">
          <div class="decription-container">
            <div class="description portfolio-container">
              <div class="portfolio-container-top">
                <div>
                  <h2>Description</h2>
                  <p>
                    FlexiSwap is a decentralized NFT swap platform built on
                    Ethereum. It allows users to easily exchange NFTs without
                    intermediaries, using a unique pricing algorithm based on market
                    demand and historical sales data. FlexiSwap aims to address the
                    liquidity issue in the NFT market by providing a user-friendly
                    and secure platform for NFT trading.
                  </p>

                  <div class="tech-features-section">
                    <div class="container-db bank-tech">
                      <div class="tech-features-wrapper">
                        <div class="features">
                          <h2 class="tech-features-title">Features</h2>
                          <ul>
                            <li class="marked-item">
                              Opportunity to create the Wish List of NFT and NFT
                              collections.
                            </li>
                            <li class="marked-item">
                              Opportunity to swap NFT collections (Multichain)
                            </li>
                            <li class="marked-item">
                              Opportunity to create counteroffers for the current
                              propositions
                            </li>
                            <li class="marked-item">
                              Swapping opportunities for searching and filtering
                            </li>
                          </ul>
                        </div>
                        <ul class="features second">
                          <li class="marked-item">
                            Opportunity to add tokens to the swapping deals
                          </li>
                          <li class="marked-item">
                            Opportunity for the automatic matching with other NFTs
                            on NFT marketplaces
                          </li>
                          <li class="marked-item">
                            Support of other standards (ERC20, ERC1155)
                          </li>
                          <li class="marked-item">
                            A notification system based on EPNS
                          </li>
                        </ul>
                      </div>
                      <div class="tech-featuchers-wrapper">
                        <div class="technologies-wrapper">
                          <h2 class="tech-features-title">Technologies</h2>
                          <ul class="features">
                            <li class="marked-item">
                              Typescript, Solidity, AssemblyScript, React, Node.js,
                              Hardhat
                            </li>
                            <li class="marked-item">
                              Nest.js, GraphQL, Apollo Federations, AWS, Alchemy,
                              Docker
                            </li>
                            <li class="marked-item">
                              MetaMask, OpenZeppelin, ethers.js, ERC165, ERC721
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <img
                  class="nft-img"
                  v-lazyload
                  data-url="/img/images-db/case-studies/nft-swap.webp"
                  alt="nft-swap"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

		<div class="relevant-case-studies">
      <div class="container-db">
        <h2>portfolio</h2>
        <div class="slider-container">
          <div class="relevant-case-studies-slider">
            <slick ref="slick" :options="slickOptions">
              <div class="case-slider-item">
								<router-link to="currency-research-analasis-news-platform">
									<div class="case-studies-item">
                    <h3>Currency Analysis and News</h3>
                  </div>
                </router-link>
              </div>
							<div class="case-slider-item">
								<router-link to="cannabis-platform">
									<div class="case-studies-item">
										<h3>Cannabis Platform</h3>
									</div>
								</router-link>
							</div>
              <div class="case-slider-item">
                <router-link to="doctormed">
                  <div class="case-studies-item">
                    <h3>DoctorMed</h3>
                  </div>
                </router-link>
              </div>
              <div class="case-slider-item">
                <router-link to="social-network-for-dog-owners">
                  <div class="case-studies-item">
                    <h3>Social Network for Dog Owners</h3>
                  </div>
                </router-link>
              </div>
              <div class="case-slider-item">
                <router-link to="real-estate-platform">
                  <div class="case-studies-item">
                    <h3>Real Estate</h3>
                  </div>
                </router-link>
              </div>
              <div class="case-slider-item">
                <router-link to="art-galleries-development">
                  <div class="case-studies-item">
                    <h3>ArtGallery</h3>
                  </div>
                </router-link>
              </div>
							<div class="case-slider-item">
                <router-link to="banking-app">
                  <div class="case-studies-item">
                    <h3>Banking Application</h3>
                  </div>
                </router-link>
              </div>
              <div class="case-slider-item">
                <router-link to="markets-exchanges">
                  <div class="case-studies-item">
                    <h3>Markets and Exchanges</h3>
                  </div>
                </router-link>
              </div>
            </slick>
          </div>
        </div>
      </div>
    </div>
    <AcquaintedSection />
    <div
      v-lazyload
      data-src="/img/images-db/parallax/stone2.webp"
      class="lazy-bg bottom-stone"
    ></div>
    <script v-html="jsonld" type="application/ld+json"></script>
  </main>
</template>
<script>
import Slick from "vue-slick";
import AcquaintedSection from "../AcquaintedSection";
let vm = {};
export default {
  name: "NFTMarketSwapping",
  metaInfo: {
    title: "NFT Market and Swapping",
    meta: [
      {
        vmid: "description",
        name: "description",
        content:
          "We at DevBrother have created a comfortable and modern art gallery site from Canada. A small dedicated team was involved to create it.",
      },
      {
        vmid: "og:image:type",
        name: "og:image:type",
        content: "image/webp",
      },
      {
        vmid: "og:title",
        name: "og:title",
        content:
          "Case: Creating a CMS for an art gallery in Toronto VitavieGallery",
      },
      {
        vmid: "og:description",
        name: "og:description",
        content:
          "We at DevBrother have created a comfortable and modern art gallery site from Canada. A small dedicated team was involved to create it.",
      },
      {
        vmid: "og:image",
        name: "og:image",
        content:
          "https://devbrother.com/img/images-db/case-studies/art-solutions.webp",
      },
      {
        vmid: "og:type",
        name: "og:type",
        content: "website",
      },
      {
        vmid: "og:url",
        name: "og:url",
        content: "https://devbrother.com/cases/flexiswap-nft-swap-platform",
      },
      // {
      //   vmid: "twitter:title",
      //   name: "twitter:title",
      //   content:
      //     "Case: Creating a CMS for an art gallery in Toronto VitavieGallery",
      // },
      {
        vmid: "twitter:description",
        name: "twitter:description",
        content:
          "We at DevBrother have created a comfortable and modern art gallery site from Canada. A small dedicated team was involved to create it.",
      },
      {
        vmid: "twitter:image",
        name: "twitter:image",
        content:
          "https://devbrother.com/img/images-db/case-studies/art-solutions.webp",
      },
    ],
  },
  components: { Slick, AcquaintedSection },
  mounted() {
			this.addCanonicalLink();
  },
	methods: {
			addCanonicalLink: () => {
				const link = document.createElement('link');
				link.setAttribute('rel', 'canonical');
				link.setAttribute('href', 'https://devbrother.com/cases/flexiswap-nft-swap-platform');

				const oldLink = document.querySelector('link[rel="canonical"]');
				if (oldLink) {
					document.head.removeChild(oldLink);
				}

				document.head.appendChild(link);
			},
	},
  data() {
    const jsonld = {
      "@context": "https://schema.org",
      "@type": "IndividualProduct",
      name: "NFT market and Swapping",
      description: "NFT market and Swapping",
    };
    return {
      jsonld,
      slickOptions: {
        prevArrow:
          '<div class="prev-arrow"><img src="/img/images-db/Arrow.svg" alt="arrow" class="arrow" width="26" height="11"/><img src="/img/images-db/Arrow-hover.svg" alt="arrow" class="arrow-hover" width="26" height="11"/></div>',
        nextArrow:
          '<div class="next-arrow"><img src="/img/images-db/Arrow.svg" alt="arrow" class="arrow" width="26" height="11"/><img src="/img/images-db/Arrow-hover.svg" alt="arrow" class="arrow-hover" width="26" height="11"/></div>',
        slidesToShow: 4,
        slidesToScroll: 1,
        // centerMode: true,
        responsive: [
          {
            breakpoint: 769,
            settings: {
              slidesToShow: 1,
              centerMode: false,
              prevArrow: "",
              nextArrow: "",
              dots: true,
              customPaging: function (slider, i) {
                return '<div class="slick-mobile-dot"></div>';
              },
            },
          },
        ],
        // Any other options that can be got from plugin documentation
      },
    };
  },
};
</script>
<!-- <template>
	<main>
		<section class="top-section">
			<div class="container-db bread-container">
				<div class="bread-crumbs">
					<router-link to="/">Home</router-link>
					<span class="bread-separator">/</span>
					<span class="bread-current">NFT Swap Platform</span>
				</div>
			</div>
			<div class="container-db">

				<div class="slogan">
					<div class="slogan__logo">
						<img v-lazyload data-url="/img/images-db/case-studies/FlexiSwap-logo.svg" width="200"
							height="127" alt="FlexiSwap logo">
						<h1>FlexiSwap - NFT Swap Platform</h1>
						<h2>FlexiSwap is a universal Swapping Tool, which combines already presented features and
							introduces new ones.</h2>
					</div>
				</div>
				<div class="img-block">
					<img class="details-img" v-lazyload data-url="/img/images-db/case-studies/NFT-details.webp"
						width="350" height="175" alt="NFT details">
					<img class="details-king-img" v-lazyload data-url="/img/images-db/case-studies/NFT-king.webp"
						width="150" height="150" alt="NFT king">
					<img class="main-img" v-lazyload data-url="/img/images-db/case-studies/FlexiSwap-Main.webp"
						width="650" height="365" alt="FlexiSwap Main">
				</div>
			</div>
		</section>

		<section class="navigation">
			<div class="container-db service-description">
				<nav>
					<ul>
						<li>
							<p><a href="#researches" @click.prevent="scrollTo('researches')">Researches</a></p>
							<span class="point-wrap">
								<span class="point"></span>
							</span>
						</li>
						<li>
							<p><a href="#brainstorm" @click.prevent="scrollTo('brainstorm')">Brainstorm</a></p>
							<span class="point-wrap">
								<span class="point"></span>
							</span>
						</li>
						<li>
							<p><a href="#development" @click.prevent="scrollTo('development')">Development</a></p>
							<span class="point-wrap">
								<span class="point"></span>
							</span>
						</li>
						<li>
							<p><a href="#technologies" @click.prevent="scrollTo('technologies')">Technologies</a></p>
							<span class="point-wrap">
								<span class="point"></span>
							</span>
						</li>
						<li>
							<p><a href="#result" @click.prevent="scrollTo('result')">Result</a></p>
							<span class="point-wrap">
								<span class="point"></span>
							</span>
						</li>
					</ul>
				</nav>
			</div>
		</section>

		<section class="video-section">
			<div class="container-db">
				<div class="video-wrap">
					<video src="/video/FlexiSwap.mp4" rel="nofollow" preload="auto" controls
						poster="/img/images-db/case-studies/NFT-video-overlay.webp">
					</video>
				</div>
			</div>
		</section>

		<section class="researches">
			<div class="container-db service-description">
				<h2 id="researches">1.Researches</h2>
				<p>The popularity of digital assets is growing rapidly and has already occupied a significant niche in
					the industry of decentralized systems. According to analysts' predictions, the NFT market
					capitalization will grow by <span>269.6%</span> by 2030, which is approximately <span>33.7%</span>
					annually. A large number of
					trading platforms have already been established where interested users can purchase NFTs using
					cryptocurrency or fiat currency. Industry analysis has shown that collectors often use such systems
					to acquire desirable assets.
				</p>
				<p>
					After NFT market research, our team assessed the current possibilities of exchanging assets for
					cryptocurrency or other NFT. The method of exchanging NFT for NFT turned out to be the most
					interesting for us, and we began to study this direction in depth. Surprisingly, we did not find
					convenient and popular systems that would meet such requirements, so we decided to develop our own
					system.
				</p>
				<img class="details-king-img" v-lazyload data-url="/img/images-db/case-studies/NFT-researches.webp"
					width="1170" height="446" alt="NFT researches">
			</div>
		</section>

		<section class="brainstorm">
			<div class="container-db service-description">
				<h2 id="brainstorm">2.Brainstorm</h2>
				<p>Our team was inspired by the quite successful Web2 projects that allow users to exchange cosmetic
					items in the video game Counter-Strike. The concept of "many-to-many" exchange seemed successful to
					us. So, the main idea of ​​this project is to create a convenient tool within the Web3 that would
					allow the exchange of NFT sets.</p>
				<p class="list-title">Before development, we discussed the main requirements for the product, namely:
				</p>
				<ul>
					<li class="marked-item">
						Wish Lists. This will put the process of finding interested NFT on the new level
					</li>
					<li class="marked-item">
						Opportunity to swap several NFTs on several
					</li>
					<li class="marked-item">
						Opportunity to swap for ANY NFT from the NFT COLLECTION Enables users to list and swap
					</li>
					<li class="marked-item">
						NFT assets in one-for-one, many-for-one, and many-for-many fashion
					</li>
					<li class="marked-item">
						All trades can be accomplished by wish list or any user can create his/her counteroffer to an
						already existing trade
					</li>
				</ul>
			</div>
		</section>

		<section class="development">
			<div class="container-db service-description">
				<h2 id="development">3.Development</h2>
				<h3>3.1. UI/UX Design</h3>
				<p>The main requirement for the design was the creation of a flexible and comfortable user interface
					that would allow you to easily create your own trades and view already created ones.</p>
				<p class="with-bg logo-bg">The first association with the name FlexiSwap was the emoji 🔁, so it was
					taken as a basis. In our opinion, the use of arrows as guides for the text turned out to be a good
					option. The final version of the logo is the name of the project, which visually resembles the
					aforementioned emoji.
					<img v-lazyload data-url="/img/images-db/case-studies/LOGO-word.svg" width="264" height="75"
						alt="LOGO">
				</p>
				<img class="logo-transition" v-lazyload
					data-url="/img/images-db/case-studies/FlexiSwap-logo-transition.webp" width="1170" height="440"
					alt="logo transition">
				<p class="with-bg trades-bg">We decided to display already existing trades in the form of blocks that
					reflect the
					most important information about this trade. If desired, the user viewing this trade can expand it
					and view detailed information.
					<img v-lazyload data-url="/img/images-db/case-studies/TRADES-word.svg" width="405" height="75"
						alt="TRADES">
				</p>
				<img class="trades-img-1" v-lazyload data-url="/img/images-db/case-studies/NFT-trades-img.webp"
					width="800" height="836" alt="NFT trades">


				<p class="list-title">The process of creating a trade was divided into several stages:</p>
				<ul>
					<li class="marked-item">
						Selection of a set of NFTs that the user gives in exchange for others
					</li>
					<li class="marked-item">
						Selection of a set of NFTs that the user wants to receive as a result of the exchange
					</li>
				</ul>
				<p>It was important to provide the user with a convenient interface for editing the content of the
					trade, so it was decided to divide the screen into two logical parts - a list of already selected
					NFTs and a list of NFTs available for exchange.
				</p>
				<img class="trades-img-2" v-lazyload data-url="/img/images-db/case-studies/NFT-trades-img-2.webp"
					width="850" height="607" alt="NFT trades 2">

				<p class="with-bg meta-bg">An important condition for the design was the display of information about
					individual NFTs or their collections, while not overloading the user interface.
					<img v-lazyload data-url="/img/images-db/case-studies/NFT-META-word.svg" width="502" height="75"
						alt="NFT META">
				</p>

				<img class="nft-meta-img" v-lazyload data-url="/img/images-db/case-studies/NFT-META-POPUP.webp"
					width="850" height="539" alt="NFT META POPUP">

				<h3>3.2. Smart Contracts</h3>
				<p>
					A smart contract is a set of rules that govern the sequence and content of transactions that will be
					executed in the blockchain network.
				</p>
				<p>
					The main requirements for a smart contract, which controls the logic of trades in the system, are
					the correct execution of transactions and the safety of users.
				</p>
				<p>
					The first problem we discussed was the implementation of a mechanism that would allow NFTs that
					participate in active trade to be stored on the balance of the smart contract itself. This approach
					turned out to be not entirely safe, because if the contract is compromised, the NFT can very easily
					be transferred to third-party balances. Therefore, it was decided to keep NFTs on the balance sheets
					of their owners until the trade is completed. An important condition before creating or accepting a
					trade is to grant the rights to the smart contract to manage individual NFTs, which will allow
					automated sending of assets upon completion of the trade.
				</p>
				<p>
					Below is a code snippet that checks if the smart contract has been granted permissions to manage
					individual user NFTs.
				</p>

				<img class="NFT-code" v-lazyload data-url="/img/images-db/case-studies/NFT-code.webp" width="800"
					height="340" alt="code">


				<p>
					Also, one of the topics of discussion was the method of storing information about trades
					(centralized - on the blockchain itself or decentralized - in the database). In order to preserve
					transparency and the concept of decentralization, it was decided to store all information in the
					blockchain.
				</p>
			</div>
		</section>

		<section class="tech-stack">
			<div class="container-db service-description">
				<h2 id="technologies">Technologies & Tools That We Use</h2>
				<div class="tech-wrap">
					<div class="row-1">

						<div class="languages">
							<h3>Programming languages</h3>
							<ul>
								<li>
									<img v-lazyload data-url="/img/images-db/case-studies/icons/typescript.svg"
										width="60" height="60" alt="typescript">
								</li>
								<li>
									<img v-lazyload data-url="/img/images-db/case-studies/icons/assemblyscript.svg"
										width="60" height="60" alt="assemblyscript">
								</li>
								<li>
									<img class="solidity" v-lazyload
										data-url="/img/images-db/case-studies/icons/solidity.svg" width="35" height="60"
										alt="solidity">
								</li>
							</ul>
						</div>
						<div class="infrastructure">
							<h3>Infrastructure</h3>
							<ul>
								<li>
									<img v-lazyload data-url="/img/images-db/case-studies/icons/docker.svg" width="60"
										height="60" alt="docker">
								</li>
								<li>
									<img v-lazyload data-url="/img/images-db/case-studies/icons/aws.svg" width="60"
										height="60" alt="aws">
								</li>
								<li>
									<img class="alchemy" v-lazyload
										data-url="/img/images-db/case-studies/icons/alchemy.svg" width="150" height="60"
										alt="alchemy">
								</li>
							</ul>
						</div>
					</div>
					<div class="libraries">
						<h3>Frameworks and libraries</h3>
						<ul class="list-top">
							<li>
								<img v-lazyload data-url="/img/images-db/case-studies/icons/apollo.svg" width="60"
									height="60" alt="apollo">
							</li>
							<li>
								<img class="hardhat" v-lazyload data-url="/img/images-db/case-studies/icons/hardhat.svg"
									width="200" height="60" alt="hardhat">
							</li>
							<li>
								<img v-lazyload data-url="/img/images-db/case-studies/icons/react.svg" width="60"
									height="60" alt="react">
							</li>
							<li>
								<img class="openzeppelin" v-lazyload
									data-url="/img/images-db/case-studies/icons/openzeppelin.svg" width="200"
									height="60" alt="openzeppelin">
							</li>
							<li>
								<img class="nodejs" v-lazyload data-url="/img/images-db/case-studies/icons/nodejs.svg"
									width="180" height="52" alt="nodejs">
							</li>
						</ul>
						<ul class="list-bottom">
							<li>
								<img v-lazyload data-url="/img/images-db/case-studies/icons/ethers.svg" width="95"
									height="60" alt="ethers">
							</li>
							<li>
								<img v-lazyload data-url="/img/images-db/case-studies/icons/graphql.svg" width="60"
									height="60" alt="graphql">
							</li>
							<li>
								<img v-lazyload data-url="/img/images-db/case-studies/icons/nestjs.svg" width="60"
									height="60" alt="nestjs">
							</li>
							<li>
								<img v-lazyload data-url="/img/images-db/case-studies/icons/MetaMask.svg" width="60"
									height="60" alt="MetaMask">
							</li>

						</ul>

					</div>
				</div>
			</div>
		</section>

		<section class="result">
			<div class="container-db service-description">
				<h2 id="result">Result</h2>
				<div class="reward">
					<strong>WINNER OF:</strong>
					<div class="reward-wrap flex items-center">
						<img v-lazyload data-url="/img/images-db/case-studies/icons/NFTPort.webp" width="46" height="46"
							alt="trophy">
						<p class="poll-prize">🏊‍♂️ NFTPort — Pool Prize</p>
					</div>
				</div>
				<p class="list-title">The main features we’ve implemented:</p>
				<ul>
					<li class="marked-item">
						Opportunity to create the Wish List of NFT and NFT collections. It will allow users to create
						ANY interesting NFT sets, collections, that he is interestined in for swapping. This will make
						the process of searching and matching between NFT traders much easier
					</li>
					<li class="marked-item">
						Opportunity to swap NFT collections
					</li>
					<li class="marked-item">
						Swap ANY NFT from the specific NFT collection
					</li>
					<li class="marked-item">
						Opportunity to create counteroffers for the current propositions
					</li>
					<li class="marked-item">
						Multichain swapping
					</li>
					<li class="marked-item">
						Swapping opportunities for searching and filtering (f.e. searching by specific attributes, like
						apes with cigars)
					</li>
					<li class="marked-item">
						Opportunity to add tokens to the swapping deals
					</li>
					<li class="marked-item">
						Opportunity for the automatic matching with other NFTs on NFT marketplaces
					</li>
					<li class="marked-item">
						Support of other standards (ERC20, ERC1155)
					</li>
					<li class="marked-item">
						A notification system based on EPNS
					</li>
				</ul>
			</div>
		</section>

		<AcquaintedSection />
		<div v-lazyload data-src="/img/images-db/parallax/stone2.webp" class="lazy-bg bottom-stone"></div>
		<script v-html="jsonld" type="application/ld+json"></script>
	</main>
</template>
<script>

import AcquaintedSection from '../AcquaintedSection'

export default {
	name: 'NFTSwapPlatform',
	metaInfo: {
		link: [
			{
				rel: 'canonical',
				href: 'https://devbrother.com/cases/flexiswap-nft-swap-platform'
			}
		],
		title: 'Case: FlexiSwap - How We Creating NFT Swap Platform',
		meta: [
			{
				vmid: "description",
				name: 'description',
				content: 'Case of NFT Swap Platform creation - from idea to implementation. Detailed Guide. Creating an NFT exchange service like to Sudoswap.'
			},
			{
				vmid: "og:image:type",
				property: "og:image:type",
				content: 'image/webp'
			},
			{
				vmid: "og:title",
				property: "og:title",
				content: 'Case: FlexiSwap - How We Creating NFT Swap Platform'
			},
			{
				vmid: "og:description",
				property: "og:description",
				content: 'Case of NFT Swap Platform creation - from idea to implementation. Detailed Guide. Creating an NFT exchange service like to Sudoswap.'
			},
			{
				vmid: "og:image",
				name: "og:image",
				content: 'https://devbrother.com/img/images-db/case-studies/FlexiSwap-logo.svg'
			},
			{
				vmid: "og:type",
				property: "og:type",
				content: "website"
			},
			{
				vmid: "og:url",
				property: "og:url",
				content: 'https://devbrother.com/cases/flexiswap-nft-swap-platform'
			},
			{
				vmid: "twitter:title",
				name: "twitter:title",
				content: 'Case: FlexiSwap - How We Creating NFT Swap Platform'
			},
			{
				vmid: "twitter:description",
				name: "twitter:description",
				content: 'Case of NFT Swap Platform creation - from idea to implementation. Detailed Guide. Creating an NFT exchange service like to Sudoswap.'
			},
			{
				vmid: "twitter:image",
				name: "twitter:image",
				content: 'https://devbrother.com/img/images-db/case-studies/FlexiSwap-logo.svg'
			}
		],
	},
	components: {
		AcquaintedSection
	},
	data() {
		const jsonld = {
			"@context": "https://schema.org",
			"@type": "IndividualProduct",
			"name": "NFTSwapPlatform",
			"description": "NFTSwapPlatform"
		}
		return {
			jsonld
		}
	},
	methods: {
		scrollTo: (el) => {
			document.getElementById(el).scrollIntoView({
				behavior: "smooth",
				block: "start"
			});
		}
	}
}
</script>

<style lang="scss" scoped>
h2 {
	padding-top: 80px;
	margin-top: -70px;
}

img {
	width: 100%;
	height: auto;
}

.list-title {
	margin-bottom: 15px;
}

li.marked-item::after,
li.marked-item::before {
	background-color: #F39C12;
}

.top-section {
	position: relative;
	margin-top: 80px;
	margin-bottom: 100px;
	padding-bottom: 150px;
	overflow: hidden;
	background: linear-gradient(342.18deg, #7b581a 0%, #131517 55%, #000000 100%);

	.slogan__logo {
		width: 50%;
		width: 500px;

		img {
			width: 200px;
			margin-bottom: 20px;
		}

		h1 {
			font-size: 24px;
			line-height: 28px;
		}

		h2 {
			font-size: 18px;
			line-height: 26px;
		}

		a {
			display: inline-block;
			margin-bottom: 15px;
		}
	}

	.img-block {
		position: absolute;
		right: 0;
		bottom: 50px;
		max-width: 700px;

		img {
			max-width: 100%;
			vertical-align: middle;
			object-fit: contain;
		}

		.main-img {
			position: relative;
		}

		.details-img {
			position: absolute;
			width: 350px;
			height: auto;
			left: -250px;
			top: -30px;
		}

		.details-king-img {
			position: absolute;
			width: 100px;
			height: auto;
			left: -55px;
			top: 200px;
			z-index: 1;
		}
	}
}

.navigation {
	ul {
		display: flex;
		flex-wrap: nowrap;
	}

	li {
		border-top: 1px solid #bcbcbc;
		flex: 1 1;
		margin-top: 1px;
		padding: 14px 5px 10px 7px;
		position: relative;

		&:last-child {
			max-width: fit-content;
			border-top: 1px solid transparent;
		}

		a {
			&:hover {
				color: #F5841F;

			}
		}

		p{
			display: inline-block;
		}

		.point-wrap {
			background-color: #1f2427;
			display: block;
			padding: 0 10px;
			position: absolute;
			right: calc(100% - 23px);
			top: -2px;
		}

		.point {
			background-color: #F5841F;
			display: block;
			height: 3px;
			width: 3px;
			border-radius: 50%;
			transition: 0.1s;
		}
	}
}

.navigation li:has(a:hover):not(:last-child) {
	border-top-color: #F5841F;
}

@media(min-width: 768px) {
	.navigation li .point-wrap {
		width: 23px;
		height: 13px;
		display: flex;
		align-items: center;
		justify-content: center;
		top: -7px;
	}

	.navigation li p:hover+.point-wrap .point {
		height: 5px;
		width: 5px;
		flex: none;
	}
}

.video-section {
	margin-bottom: 100px;

	.video-wrap {
		max-width: 800px;
		padding: 15px;
		margin-left: auto;
		margin-right: auto;
		border-radius: 20px;
		overflow: hidden;
		background: linear-gradient(342.18deg, #473324 0%, #F39C12 100%);
	}

	video {
		width: 100%;
		height: 100%;
		border-radius: 15px;
	}
}

.researches {
	margin-bottom: 50px;

	span {
		color: #F5841F;
	}
}

.brainstorm {
	margin-bottom: 50px;
}

.development {
	margin-bottom: 50px;

	.with-bg {
		position: relative;

		img {
			position: absolute;
			height: 75px;
			width: auto;
			pointer-events: none;
		}

		&.logo-bg {
			margin-bottom: 60px;

			img {
				right: 30px;
				top: -10px;
			}
		}

		&.trades-bg {
			margin-top: 60px;

			img {
				left: 10px;
				top: -10px;
			}
		}

		&.meta-bg {
			max-width: 1000px;
			margin-top: 60px;

			img {
				left: 10px;
				top: -10px;
			}
		}
	}

	.trades-img-1 {
		display: block;
		max-width: 800px;
		margin-right: auto;
		margin-top: 40px;
		margin-bottom: 25px;
	}

	.trades-img-2 {
		display: block;
		max-width: 850px;
		margin-left: auto;
	}

	.nft-meta-img {
		display: block;
		max-width: 850px;
		margin-bottom: 60px;
		margin-right: auto;
	}

	.NFT-code {
		display: block;
		max-width: 800px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 40px;
		border-radius: 15px;
	}
}

.result {
	margin-bottom: 120px;

	ul {
		max-width: 900px;
	}

	.reward {
		strong {
			display: inline-block;
			margin-bottom: 15px;
			color: #F39C12;
		}

		img {
			width: 50px;
			height: auto;
			margin-right: 15px;
			border-radius: 50%;
			border: 2px solid #F39C12;
		}

		.reward-wrap {
			margin-bottom: 30px;
			display: flex;
			align-items: center;
		}

		.poll-prize {
			font-size: 30px;
		}
	}
}

.tech-stack {
	margin-bottom: 50px;

	h2 {
		text-align: center;
		margin-bottom: 60px;
	}

	img {
		object-fit: contain;
	}

	li {
		display: flex;
	}

	.row-1 {
		display: flex;
		justify-content: space-between;

		img {
			width: 60px;
		}
	}

	.languages {
		width: 50%;
		margin-bottom: 70px;
		display: flex;
		flex-direction: column;
		align-items: center;

		ul {
			max-width: 250px;
			width: 100%;
			display: flex;
			justify-content: space-between;
		}

		.solidity {
			width: 35px;
			object-fit: cover;
		}
	}

	.infrastructure {
		width: 50%;
		margin-bottom: 70px;
		display: flex;
		flex-direction: column;
		align-items: center;

		ul {
			max-width: 320px;
			width: 100%;
			display: flex;
			justify-content: space-between;
		}

		.alchemy {
			width: 150px;
		}
	}

	.libraries {
		h3 {
			text-align: center;
		}

		img {
			height: 60px;
			width: auto;
		}

		ul {
			width: 100%;
			display: flex;
			justify-content: space-between;
		}

		.list-top {
			max-width: 850px;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 40px;
		}

		.hardhat {
			width: 200px;
		}

		.openzeppelin {
			width: 200px;
			object-fit: cover;
		}

		.nodejs {
			width: 180px;
			height: 52px;
			object-fit: cover;
		}

		.list-bottom {
			max-width: 600px;
			margin-left: auto;
			margin-right: auto;
		}
	}
}

@media(max-width: 1600px){
	.top-section {
		margin-bottom: 60px;
	}
}

@media(max-width: 1439px) {
	.top-section {
		.details-king-img {
			display: none;
		}
	}
}

@media(max-width: 1220px) {
	.top-section {
		padding-bottom: 30px;

		.slogan__logo {
			h2 {
				max-width: 500px;
			}
		}

		.img-block {
			max-width: 480px;
			bottom: 60px;
		}
	}

	.video-section {
		margin-bottom: 60px;
	}

	.researches {
		margin-bottom: 0;
	}

	.brainstorm {
		margin-bottom: 0;
	}

	.development {
		margin-bottom: 0;

		.nft-meta-img {
			margin-bottom: 30px;
		}
	}

	.result {
		margin-bottom: 20px;
	}
}

@media(max-width: 990px) {
	.top-section {
		padding-bottom: 50px;

		.slogan__logo {
			width: auto;
		}

		.img-block {
			position: relative;
			bottom: 0;
			margin-left: auto;
		}

		.details-img {
			display: none;
		}
	}
}

@media(max-width: 768px) {
	.video-section {
		.video-wrap {
			padding: 7px;
		}
	}
}

@media(max-width: 767px) {

	.navigation {
		ul {
			flex-direction: column;
			margin-left: 15px;
		}

		li {
			border-left: 1px solid #bcbcbc;
			border-top: 0 solid transparent;
			margin-left: 1px;
			margin-top: 0;
			padding-top: 7px;

			&:first-child {
				border-left: 1px solid transparent;

				&::before {
					background-color: #bcbcbc;
					bottom: 0;
					content: "";
					display: block;
					height: calc(50% - 3.5px);
					left: -1px;
					position: absolute;
					width: 1px;
				}
			}

			&:last-child {
				border-left: 1px solid transparent;
				border-top: 0 solid transparent;

				&:before {
					content: "";
					display: block;
					position: absolute;
					background-color: #bcbcbc;
					height: calc(50% - 3.5px);
					left: -1px;
					right: unset;
					top: 0;
					width: 1px;
				}
			}

			p {
				margin-bottom: 0;
				padding-left: 15px;
				letter-spacing: 2px;
			}

			.point-wrap {
				left: -2px;
				padding: 5px 0;
				right: unset;
				top: calc(50% - 7.5px);
			}
		}
	}

	.development {
		.with-bg {
			img {
				height: 45px;
			}
		}
	}

	.tech-stack {
		.row-1 {
			flex-wrap: wrap;
		}

		.languages,
		.infrastructure {
			width: 100%;
		}

		.libraries {
			.list-top {
				flex-wrap: wrap;
				max-width: 315px;
				justify-content: space-around;

				li:not(:last-child) {
					margin-bottom: 12px;
				}
			}
		}
	}
}

@media(max-width: 576px) {
	.result {
		.reward {
			.poll-prize {
				font-size: 24px;
			}
		}
	}

}
</style> -->
