<template>

  <main class="privacy">
    <section class="service-description">
      <div class="container-db">
        <h1>Privacy policy</h1>
        <p class="top-message">We respect your personal data. That’s why we’ve created a straightforward Privacy Policy
          explaining what information we collect when you visit devbrother.com or communicate with us by any means
          (including email, phone, messengers, and more). The Privacy Policy reveals how you control the collected
          information, how we use this data, and how to contact us. It also describes what measures we take to protect
          your data like the rules requested by General Data Protection Regulation (<a href="https://gdpr.eu/"
            class="">GDPR</a>).</p>

        <h2>Contact details</h2>
        <p>If you have any questions about the privacy policy, feel free to contact us.</p>
        <p>We’ll get back to you in 24 hours.</p>
        <h3>Offices</h3>
        <div class="offices">
          <p>+(48) 572-364-123</p>
          <p>80-309, Poland, Gdańsk</p>
          <p>aleja Grunwaldzka, 472D Olivia Six</p>
        </div>
        <div class="offices">
          <p>+(380) 938-922-544</p>
          <p>02000, Ukraine, Kyiv</p>
          <p>Peremohy Ave.26</p>
        </div>
        <div class="offices">
          <p>+(380) 938-922-544</p>
          <p>61000, Ukraine, Kharkiv</p>
          <p>Bakulina 4A</p>
        </div>
        <h3>Email</h3>
        <a href="mailto:contact@devbrother.com" target="_blank">contact@devbrother.com</a>

        <h2>Who are we?</h2>
        <p>The administrator of your data is (company name - suggested LEADINTELLIGENCE SP. Z O.O. due to the ongoing audit).</p>
        <p>Here’s how you can contact us:</p>
        <ul>
          <li class="marked-item">Using the &nbsp;<a href="/about#contact-form">contact form</a>&nbsp;on our website</li>
          <li class="marked-item">Calling the contact number published on our website from 9 AM to 9 PM</li>
          <li class="marked-item">Writing to the email address published on our website</li>
        </ul>

        <h2>Why do we collect personal information?</h2>
        <p>DevBrother collects information to offer better services to clients.</p>
        <p>Generally, we collect information for marketing purposes.</p>
        <ul class="">
          <li class="marked-item">If you leave a contact request or send us an email, we’ll collect and process your
            data based on your requests. In this case, we’ve a legitimate interest in building client relationships with
            you.</li>
          <li class="marked-item">If you download an eBook from our website, we collect your data to analyze who has
            downloaded our marketing material. We can also contact you to find out if there’s a business opportunity for
            our cooperation. It’s done based on the legitimate interest to follow marketing practices and build client
            relationships with you.</li>
          <li class="marked-item">If you subscribe to our blog, we’ll send you emails with our new articles,
            achievements, and relevant tech trends. We tend to personalize our emails based on your personal information
            to make them more useful.</li>
          <li class="marked-item">We add your personal data and information about your company that we receive from you
            through a website or by email to our CRM system. We analyze it for marketing purposes. We have a legitimate
            interest in knowing our client’s profile.</li>
        </ul>
        <p>If you no longer want us to process your personal data, you should contact us in any convenient way and
          inform us about your intention.</p>
        <p>Also, you can unsubscribe from our communications by clicking the Unsubscribe button under any email you
          receive from us.</p>
        <p>Mind that after clicking the Unsubscribe button, we don’t delete your data automatically. You need to request
          us to do so by sending an email to the address above.</p>

        <h2>What personal information do we collect?</h2>
        <p>We may collect, store, and use the following personal information:</p>
        <ul class="">
          <li class="marked-item">Data you provide filling in personal information on our website like your name, email
            address, company name, title, and country.</li>
          <li class="marked-item">Information we receive when you use devbrother.com, such as IP address, geographical
            location, browser type and version, operating system, referral source, length of visit, page views, and
            website navigation paths.</li>
          <li class="marked-item">Information you give us to subscribe to our email notifications and/or newsletters
            (including name and email address).</li>
          <li class="marked-item">Data you give us applying for the job with your CV and any additional information by
            oral or written means.</li>
        </ul>
        
        <h2>How do we use the collected information?</h2>
        <p>We may use your personal information to:</p>
        <ul class="">
          <li class="marked-item">Personalize our website for you</li>
          <li class="marked-item">Administer our website and business</li>
          <li class="marked-item">Send statements, invoices or payment reminders to you, and collect payments from you
          </li>
          <li class="marked-item">Send you non-marketing commercial communications</li>
          <li class="marked-item">Send you emails that you requested</li>
          <li class="marked-item">Send you blog newsletters if you subscribe</li>
          <li class="marked-item">Send you our newsletters that you requested (you can unsubscribe at any time)</li>
          <li class="marked-item">Send you marketing communications related to our business or the businesses of
            selected third parties which we think may be interesting for you (you can inform us at any time if you no
            longer want to receive marketing communications)</li>
        </ul>
        <p>We proceed only with the information received from you through a website or by email. We’ll use that
          information in accordance with the permission you grant to us.</p>

        <h2>Who has access to your personal information?</h2>
        <p>We may share your personal data with our employees if it’s necessary for the purposes described in this
          policy.</p>
        <p>We may share your personal information with any member of our group of companies (this means our
          subsidiaries, our ultimate holding company, and its subsidiaries) if it’s necessary for the purposes described
          in this policy.</p>
        <p>We also share data with the service providers and third-party vendors who perform functions on our behalf
          (including accountants, lawyers, website management, hosting, tech support, and marketing analysis).</p>
        <p>There are some other cases when we can share your data:</p>
        <ul class="">
          <li class="marked-item">To the extent that’s required by law</li>
          <li class="marked-item">In connection with any ongoing or prospective legal proceeding</li>
          <li class="marked-item">To establish, exercise, or defend our legal rights (including providing information to
            others for fraud prevention)</li>
        </ul>

        <h2>How do we store personal information?</h2>
        <p>We want to share our data retention policies and procedures. We’ve designed them to comply with our legal
          obligations associated with the retention and deletion of personal information, required by <a
            href="https://gdpr.eu/">GDPR</a>.</p>
        <p>We do not store collected information longer than it’s necessary for our purposes or if we no longer have a
          legal basis to keep it.</p>
        <ul class="">
          <li class="marked-item">We delete your data as soon as we receive a request from you to do so. You can ask us
            to delete information by sending an email.</li>
          <li class="marked-item">We store information as long as we have a business relationship. If we don’t start a
            business relationship, we won’t store it longer than required for marketing analysis. We won’t keep your
            data for more than a year.</li>
        </ul>
        <p>However, we’ll retain documents (including electronic documents) containing personal information:</p>
        <ul class="">
          <li class="marked-item">To the extent that we are allowed by law</li>
          <li class="marked-item">If we believe the documents may be relevant to any ongoing or prospective legal
            proceedings</li>
          <li class="marked-item">To establish, exercise, or defend our legal rights (including providing information to
            others for fraud prevention)</li>
        </ul>

        <h2>Is your personal information secured?</h2>
        <p>We take reasonable technical and organizational precautions to prevent the loss, misuse, or alteration of
          your personal information, as required by <a href="https://gdpr.eu/">GDPR</a>.</p>
        <p>We store all the personal information you provide on our secure (password- and firewall-protected) servers.
        </p>
        <ul class="">
          <li class="marked-item">All our employees, consultants, and any recipients of your information are bound by
            confidentiality. They cannot process your data for purposes other than described in this Privacy Policy</li>
          <li class="marked-item">We follow the Information Security Policy, which is obligatory for all our
            consultants, contractors, and other people who have authorized access to information.</li>
          <li class="marked-item">We apply Information Security Policy to all the data stored on physical or electronic
            carriers, along with software and hardware that we possess or use temporarily.</li>
          <li class="marked-item">We have obligatory training for our employees and consultants on privacy and security.
          </li>
          <li class="marked-item">We constantly gather and analyze data about new and existing threats and
            vulnerabilities, attacks on other organizations, along with the information about actual security controls.
          </li>
          <li class="marked-item">We update our servers, workstations, and internet gateway devices with the latest
            antivirus software.</li>
        </ul>
        <p>We want to safeguard the privacy of our website visitors.</p>
        <ul class="">
          <li class="marked-item">We will ask you to agree to our use of cookies under the terms of this policy when you
            first visit our website. <a href="/cookies-policy">Read more about cookies</a>.</li>
          <li class="marked-item">Personal information that you publish on our website or submit for publication may be
            available via the Internet worldwide. We can't prevent the use or misuse of such information by others.</li>
          <li class="marked-item">You expressly agree to the transfers of personal information described in ‘What
            personal information do we collect?</li>
        </ul>

        <h2>What are your rights?</h2>
        <p>We follow the rules offered by GDPR and treat our visitors equally.</p>
        <p>You have the following rights:</p>
        <ul class="">
          <li class="marked-item"><b>Right of access.</b> You may ask us to provide you with any personal information we
            hold about you.</li>
          <li class="marked-item"><b>Right to be informed.</b> You have the right to know how and why we process your
            personal data. </li>
          <li class="marked-item"><b>Right to erasure.</b> You have the right to request for deleting your data. </li>
          <li class="marked-item"><b>Right to restrict processing.</b> You may command us at any time to stop processing
            your data for marketing purposes. </li>
          <li class="marked-item"><b>Right to rectification.</b> You may ask us to correct your personal data if the
            information we have is inaccurate or outdated. </li>
        </ul>
        <p>We may withhold personal information that you request to the extent permitted by law. In practice, you either
          agree to the use of your personal information for marketing purposes or opt out of this option.</p>

        <h2>Do we update this Privacy Policy?</h2>
        <ul class="">
          <li class="marked-item">We may update this policy from time to time by publishing a new version on our
            website.</li>
          <li class="marked-item">You should check this page occasionally to ensure you’re happy with any changes to
            this policy.</li>
          <li class="marked-item">We may notify you of changes to this policy by email.</li>
        </ul>

        <h2>What about cookies?</h2>
        <p>Our website uses cookies.</p>
        <p>A cookie is a text file with data — like the website's name and unique ID tags — that we use to identify your
          computer next time.</p>
        <p><a href="/cookies-policy">Read more</a></p>
        <p><a href="/about#contact-form">Contact us</a></p>
      </div>
    </section>

    <script v-html="jsonld" type="application/ld+json"></script>
  </main>
</template>
<script>

let vm = {};
export default {
  name: 'PrivacyPolicy',
  metaInfo: {
    title: 'DevBrother privacy policy: an explanation of what data we collect and use',
    meta: [
      {
        vmid: "description",
        name: 'description',
        content: 'A privacy policy that tells you what data we collect and process.'
      },
      // {
      //     vmid: "og:image:type",
      //     name: "og:image:type",
      //     content: 'image/webp'
      // },
      {
        vmid: "og:title",
        name: "og:title",
        content: 'DevBrother privacy policy: an explanation of what data we collect and use'
      },
      {
        vmid: "og:description",
        name: "og:description",
        content: 'A privacy policy that tells you what data we collect and process.'
      },
      // {
      //     vmid: "og:image",
      //     name: "og:image",
      //     content: 'https://devbrother.com/img/images-db/...'
      // },
      {
        vmid: "og:type",
        name: "og:type",
        content: "website"
      },
      {
        vmid: "og:url",
        name: "og:url",
        content: 'https://devbrother.com/privacy-policy'
      },
      {
        vmid: "twitter:title",
        name: "twitter:title",
        content: 'DevBrother privacy policy: an explanation of what data we collect and use'
      },
      {
        vmid: "twitter:description",
        name: "twitter:description",
        content: 'A privacy policy that tells you what data we collect and process.'
      },
      // {
      //     vmid: "twitter:image",
      //     name: "twitter:image",
      //     content: 'https://devbrother.com/img/images-db/...'
      // }
    ]
  },
  beforeCreate() {
    vm = this;
  },
  mounted: () => {
    vm.addCanonicalLink();
  },
  methods: {
    addCanonicalLink: () => {
      const link = document.createElement('link');
      link.setAttribute('rel', 'canonical');
      link.setAttribute('href', 'https://devbrother.com/privacy-policy');

      const oldLink = document.querySelector('link[rel="canonical"]');
      if (oldLink) {
        document.head.removeChild(oldLink);
      }

      document.head.appendChild(link);
    },
  },
  data() {
    const jsonld = {
      "@context": "https://schema.org",
      "@type": "Privacy",
      // ?
      // "name": "Web Development Agency - Custom Outsourced Services ➜ DevBrother",
      "name": "Privacy Policy ➜ DevBrother",
      "description": "A privacy policy that tells you what data we collect and process."
    }

    return {
      jsonld,
    }
  },
}
</script>
<style lang="scss" scoped>
.privacy {
  .container-db {
    max-width: 960px;
    margin-top: 150px;
    line-height: 1.5 !important;
  }

  h1 {
    font-size: 26px;
    line-height: 30px;
  }

  h2 {
    margin-top: 30px;
    font-size: 20px;
    line-height: 24px;
  }

  h3 {
    margin-top: 30px;
    margin-bottom: 15px;
    font-size: 18px;
  }

  .top-message {
    margin-bottom: 50px;
  }

  p {
    margin-bottom: 7px;
    letter-spacing: 0.36px;
  }

  a:not(.router-link-active) {
    color: #06c6ff;

    &:hover {
      opacity: 0.8;
      transition: opacity 0.15s;
    }
  }

  .short-block {
    max-width: 790px;
  }

  .offices:not(:last-child) {
    margin-bottom: 25px;
  }

  @media (max-width: 1220px) {
    .container-db {
      line-height: 1.4 !important;
    }

    .service-description p {
      line-height: 1.4 !important;
    }
  }
}
</style>
