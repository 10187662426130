import Vue from 'vue';
import Vuex from 'vuex';
import VueMeta from 'vue-meta'

Vue.use(Vuex);
Vue.use(VueMeta);

export const store = new Vuex.Store({
  state: {
    mail: {},
    imageFormat: 'png'
  },
  mutations: {
    setImageFormat: (state, format) => {
      state.imageFormat = format;
    }
  },
  actions: {
  },
});