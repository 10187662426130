<template>    <!--SECTION Team-->
	<section id="team" class="team">
      <div class="inner-container">
        <div v-lazyload data-src="/img/images-db/images/bg_img/img v8-3_0000.webp" class="lazy-bg team-bg"></div>
        <h2 :class="[activeLeaderClass]">Leadership</h2>
        <div class="team-wrapper">
          <div class="team-member" data-aos="fade-right"
          data-aos-offset="300"
          data-aos-easing="ease-in-sine" data-aos-duration="800">
          <div class="member-info">
              <div class="member-name">Vlad Vilihura</div>
              <div class="prof">CTO</div>
              <div class="team-photo-wrapper">
                <img v-lazyload height="325" width="250" :data-url="'/img/images-db/photo/vlad-flat.' + this.$store.state.imageFormat" alt="Vlad Vilihura" >
                <ul class="list-social-founder row">
                  <li>
                    <a target="_blank" href="https://www.linkedin.com/in/vlad-vilihura/" rel="nofollow">
                      <svg width="24px" height="24px" viewBox="0 0 30 29" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><title>LinkedIn</title> <desc>Created with Sketch.</desc> <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="footer" transform="translate(-810.000000, -204.000000)" fill="#2b506e" fill-rule="nonzero"><g id="Group"><g id="icons" transform="translate(531.000000, 204.000000)"><g id="Filled/16x16/LinkedIn" transform="translate(279.000000, 0.000000)"><path id="linkedin" d="M30,17.779877 L30,29 L23.5700286,29 L23.5700286,18.5313332 C23.5700286,15.9015187 22.6400026,14.1067732 20.3125661,14.1067732 C18.5358635,14.1067732 17.4785465,15.3163002 17.013394,16.4863139 C16.8437658,16.9045893 16.8001032,17.4867738 16.8001032,18.0722746 L16.8001032,29 L10.3679696,29 C10.3679696,29 10.4545972,11.2695145 10.3679696,9.43250398 L16.7994755,9.43250398 L16.7994755,12.2061182 C16.7864325,12.2268626 16.7694837,12.2492299 16.7572079,12.2693393 L16.7994755,12.2693393 L16.7994755,12.2061182 C17.6539639,10.8747354 19.1799906,8.97281035 22.5957123,8.97281035 C26.8275609,8.97273979 30,11.7694975 30,17.779877 Z M3.63968409,0 C1.43925955,0 0,1.46008292 0,3.3801417 C0,5.25835884 1.39761972,6.76275298 3.55431197,6.76275298 L3.59727702,6.76275298 C5.84031787,6.76275298 7.23528714,5.25864108 7.23528714,3.3801417 C7.19301957,1.46008292 5.84031787,0 3.63968409,0 Z M0.382012336,29 L6.81170472,29 L6.81170472,9.43250398 L0.382012336,9.43250398 L0.382012336,29 Z"></path></g></g></g></g></g></svg>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="mailto:vladv@devbrother.com">
                      <svg width="24px" height="24px" viewBox="0 0 30 25" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><title>Filled/24x24/mail</title> <desc>Created with Sketch.</desc> <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="footer" transform="translate(-531.000000, -207.000000)" fill="#2b506e"><g id="Group"><g id="icons" transform="translate(531.000000, 204.000000)"><g id="Filled/24x24/mail"><path d="M4.43201031e-13,12.0401786 L15,20.4553571 L30,12.0401786 L30,24.83125 C30,26.1325709 28.8807119,27.1875 27.5,27.1875 L2.5,27.1875 C1.11928813,27.1875 4.43201031e-13,26.1325709 4.43201031e-13,24.83125 L4.43201031e-13,12.0401786 Z M1,3.625 L29,3.625 C29.5522847,3.625 30,4.07271525 30,4.625 C30,6.01785714 30,7.41071429 30,8.80357143 L15,17.21875 L4.43201031e-13,8.80357143 L4.43201031e-13,4.625 C4.43133396e-13,4.07271525 0.44771525,3.625 1,3.625 Z"></path></g></g></g></g></g></svg>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
              <div class="characteristic">
                <p>
                  Our technical leader. Vlad got Ph.D. in cybersecurity and has built tens of websites from little web stores and visit cards to big start-ups and enterprise projects.
                </p>
                <p>
                  He leads, controls, and supports the main technical part of our projects, and he is the real leader for our technicians.
                </p>
              </div>
          </div>
          <div class="team-member" data-aos="zoom-in" data-aos-duration="800">
            <div class="member-info">
              <div class="member-name">Yuriy Golikov</div>
              <div class="prof">CEO | Founder</div>
              <div class="team-photo-wrapper">
                <img v-lazyload height="325" width="250" :data-url="'/img/images-db/photo/yura.' + this.$store.state.imageFormat" alt="Yuriy Golikov" class="ceo-img">
                <ul class="list-social-founder row">
                  <li>
                    <a target="_blank" href="https://www.linkedin.com/in/yuriy-golikov/" rel="nofollow">
                      <svg width="24px" height="24px" viewBox="0 0 30 29" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><title>LinkedIn</title> <desc>Created with Sketch.</desc> <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="footer" transform="translate(-810.000000, -204.000000)" fill="#2b506e" fill-rule="nonzero"><g id="Group"><g id="icons" transform="translate(531.000000, 204.000000)"><g id="Filled/16x16/LinkedIn" transform="translate(279.000000, 0.000000)"><path id="linkedin" d="M30,17.779877 L30,29 L23.5700286,29 L23.5700286,18.5313332 C23.5700286,15.9015187 22.6400026,14.1067732 20.3125661,14.1067732 C18.5358635,14.1067732 17.4785465,15.3163002 17.013394,16.4863139 C16.8437658,16.9045893 16.8001032,17.4867738 16.8001032,18.0722746 L16.8001032,29 L10.3679696,29 C10.3679696,29 10.4545972,11.2695145 10.3679696,9.43250398 L16.7994755,9.43250398 L16.7994755,12.2061182 C16.7864325,12.2268626 16.7694837,12.2492299 16.7572079,12.2693393 L16.7994755,12.2693393 L16.7994755,12.2061182 C17.6539639,10.8747354 19.1799906,8.97281035 22.5957123,8.97281035 C26.8275609,8.97273979 30,11.7694975 30,17.779877 Z M3.63968409,0 C1.43925955,0 0,1.46008292 0,3.3801417 C0,5.25835884 1.39761972,6.76275298 3.55431197,6.76275298 L3.59727702,6.76275298 C5.84031787,6.76275298 7.23528714,5.25864108 7.23528714,3.3801417 C7.19301957,1.46008292 5.84031787,0 3.63968409,0 Z M0.382012336,29 L6.81170472,29 L6.81170472,9.43250398 L0.382012336,9.43250398 L0.382012336,29 Z"></path></g></g></g></g></g></svg>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="mailto:yuriy@devbrother.com">
                      <svg width="24px" height="24px" viewBox="0 0 30 25" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><title>Filled/24x24/mail</title> <desc>Created with Sketch.</desc> <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="footer" transform="translate(-531.000000, -207.000000)" fill="#2b506e"><g id="Group"><g id="icons" transform="translate(531.000000, 204.000000)"><g id="Filled/24x24/mail"><path d="M4.43201031e-13,12.0401786 L15,20.4553571 L30,12.0401786 L30,24.83125 C30,26.1325709 28.8807119,27.1875 27.5,27.1875 L2.5,27.1875 C1.11928813,27.1875 4.43201031e-13,26.1325709 4.43201031e-13,24.83125 L4.43201031e-13,12.0401786 Z M1,3.625 L29,3.625 C29.5522847,3.625 30,4.07271525 30,4.625 C30,6.01785714 30,7.41071429 30,8.80357143 L15,17.21875 L4.43201031e-13,8.80357143 L4.43201031e-13,4.625 C4.43133396e-13,4.07271525 0.44771525,3.625 1,3.625 Z"></path></g></g></g></g></g></svg>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="characteristic">
              <p>After getting technical degree, Yuriy spent more than 7 years as a software engineer. Meanwhile, he was interested in business development strategies, team work organizations and other related to Software Engineering company internal and external processes.</p>
              <p>Strong technical, communication and business skills made it possible to launch his Software Engineering company in 2017 - DevBrother.</p>
            </div>
          </div>
          <div class="team-member" data-aos="fade-left"
          data-aos-offset="300"
          data-aos-easing="ease-in-sine" data-aos-duration="800">
          <div class="member-info">
            <div class="member-name">Aleksandr Pazynin</div>
            <div class="prof">COO</div>
            <div class="team-photo-wrapper">
              <img v-lazyload height="325" width="250" :data-url="'/img/images-db/photo/alex.' + this.$store.state.imageFormat" alt="Alex">
              <ul class="list-social-founder row">
                  <li>
                    <a target="_blank" href="https://www.linkedin.com/in/alex-pazynin/" rel="nofollow">
                      <svg width="24px" height="24px" viewBox="0 0 30 29" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><title>LinkedIn</title> <desc>Created with Sketch.</desc> <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="footer" transform="translate(-810.000000, -204.000000)" fill="#2b506e" fill-rule="nonzero"><g id="Group"><g id="icons" transform="translate(531.000000, 204.000000)"><g id="Filled/16x16/LinkedIn" transform="translate(279.000000, 0.000000)"><path id="linkedin" d="M30,17.779877 L30,29 L23.5700286,29 L23.5700286,18.5313332 C23.5700286,15.9015187 22.6400026,14.1067732 20.3125661,14.1067732 C18.5358635,14.1067732 17.4785465,15.3163002 17.013394,16.4863139 C16.8437658,16.9045893 16.8001032,17.4867738 16.8001032,18.0722746 L16.8001032,29 L10.3679696,29 C10.3679696,29 10.4545972,11.2695145 10.3679696,9.43250398 L16.7994755,9.43250398 L16.7994755,12.2061182 C16.7864325,12.2268626 16.7694837,12.2492299 16.7572079,12.2693393 L16.7994755,12.2693393 L16.7994755,12.2061182 C17.6539639,10.8747354 19.1799906,8.97281035 22.5957123,8.97281035 C26.8275609,8.97273979 30,11.7694975 30,17.779877 Z M3.63968409,0 C1.43925955,0 0,1.46008292 0,3.3801417 C0,5.25835884 1.39761972,6.76275298 3.55431197,6.76275298 L3.59727702,6.76275298 C5.84031787,6.76275298 7.23528714,5.25864108 7.23528714,3.3801417 C7.19301957,1.46008292 5.84031787,0 3.63968409,0 Z M0.382012336,29 L6.81170472,29 L6.81170472,9.43250398 L0.382012336,9.43250398 L0.382012336,29 Z"></path></g></g></g></g></g></svg>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="mailto:alex@devbrother.com">
                      <svg width="24px" height="24px" viewBox="0 0 30 25" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><title>Filled/24x24/mail</title> <desc>Created with Sketch.</desc> <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="footer" transform="translate(-531.000000, -207.000000)" fill="#2b506e"><g id="Group"><g id="icons" transform="translate(531.000000, 204.000000)"><g id="Filled/24x24/mail"><path d="M4.43201031e-13,12.0401786 L15,20.4553571 L30,12.0401786 L30,24.83125 C30,26.1325709 28.8807119,27.1875 27.5,27.1875 L2.5,27.1875 C1.11928813,27.1875 4.43201031e-13,26.1325709 4.43201031e-13,24.83125 L4.43201031e-13,12.0401786 Z M1,3.625 L29,3.625 C29.5522847,3.625 30,4.07271525 30,4.625 C30,6.01785714 30,7.41071429 30,8.80357143 L15,17.21875 L4.43201031e-13,8.80357143 L4.43201031e-13,4.625 C4.43133396e-13,4.07271525 0.44771525,3.625 1,3.625 Z"></path></g></g></g></g></g></svg>
                    </a>
                  </li>
              </ul>
            </div>
          </div>
            <div class="characteristic">
              <p>A successful C-level executive focused on driving revenues and profits with a passion for building companies, both from the ground up and by growing and streamlining established organizations.
              </p>
              <p>Develops exceptionally productive and motivated teams and encompasses a background in revitalizing and turning around companies as operations, sales and marketing leader.
              </p>
            </div>
          </div>
        </div>
        <div class="team-wrapper mobile">
          <div class="team-member" data-aos="fade-right" data-aos-duration="800">
            <div class="member-info">
              <div class="member-name">Yuriy Golikov</div>
              <div class="prof">CEO | Founder</div>
              <div class="team-photo-wrapper">
                <img v-lazyload height="325" width="250" data-url="/img/images-db/photo/yura.webp" alt="Yuriy Golikov" class="ceo-img">
                <ul class="list-social-founder row">
                  <li>
                    <a target="_blank" href="https://www.linkedin.com/in/yuriy-golikov/" rel="nofollow">
                      <svg width="24px" height="24px" viewBox="0 0 30 29" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><title>LinkedIn</title> <desc>Created with Sketch.</desc> <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="footer" transform="translate(-810.000000, -204.000000)" fill="#2b506e" fill-rule="nonzero"><g id="Group"><g id="icons" transform="translate(531.000000, 204.000000)"><g id="Filled/16x16/LinkedIn" transform="translate(279.000000, 0.000000)"><path id="linkedin" d="M30,17.779877 L30,29 L23.5700286,29 L23.5700286,18.5313332 C23.5700286,15.9015187 22.6400026,14.1067732 20.3125661,14.1067732 C18.5358635,14.1067732 17.4785465,15.3163002 17.013394,16.4863139 C16.8437658,16.9045893 16.8001032,17.4867738 16.8001032,18.0722746 L16.8001032,29 L10.3679696,29 C10.3679696,29 10.4545972,11.2695145 10.3679696,9.43250398 L16.7994755,9.43250398 L16.7994755,12.2061182 C16.7864325,12.2268626 16.7694837,12.2492299 16.7572079,12.2693393 L16.7994755,12.2693393 L16.7994755,12.2061182 C17.6539639,10.8747354 19.1799906,8.97281035 22.5957123,8.97281035 C26.8275609,8.97273979 30,11.7694975 30,17.779877 Z M3.63968409,0 C1.43925955,0 0,1.46008292 0,3.3801417 C0,5.25835884 1.39761972,6.76275298 3.55431197,6.76275298 L3.59727702,6.76275298 C5.84031787,6.76275298 7.23528714,5.25864108 7.23528714,3.3801417 C7.19301957,1.46008292 5.84031787,0 3.63968409,0 Z M0.382012336,29 L6.81170472,29 L6.81170472,9.43250398 L0.382012336,9.43250398 L0.382012336,29 Z"></path></g></g></g></g></g></svg>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="mailto:yuriy@devbrother.com">
                      <svg width="24px" height="24px" viewBox="0 0 30 25" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><title>Filled/24x24/mail</title> <desc>Created with Sketch.</desc> <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="footer" transform="translate(-531.000000, -207.000000)" fill="#2b506e"><g id="Group"><g id="icons" transform="translate(531.000000, 204.000000)"><g id="Filled/24x24/mail"><path d="M4.43201031e-13,12.0401786 L15,20.4553571 L30,12.0401786 L30,24.83125 C30,26.1325709 28.8807119,27.1875 27.5,27.1875 L2.5,27.1875 C1.11928813,27.1875 4.43201031e-13,26.1325709 4.43201031e-13,24.83125 L4.43201031e-13,12.0401786 Z M1,3.625 L29,3.625 C29.5522847,3.625 30,4.07271525 30,4.625 C30,6.01785714 30,7.41071429 30,8.80357143 L15,17.21875 L4.43201031e-13,8.80357143 L4.43201031e-13,4.625 C4.43133396e-13,4.07271525 0.44771525,3.625 1,3.625 Z"></path></g></g></g></g></g></svg>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="characteristic">
              <p>After getting technical degree, Yuriy spent more than 7 years as a software engineer. Meanwhile, he was interested in business development strategies, team work organizations and other related to Software Engineering company internal and external processes.</p>
              <p>Strong technical, communication and business skills made it possible to launch his Software Engineering company in 2017 - DevBrother.</p>
            </div>
          </div>
            <div class="team-member" data-aos=" zoom-in"
            data-aos-offset="300"
            data-aos-easing="ease-in-sine" data-aos-duration="800">
            <div class="member-info">
              <div class="member-name">Vlad Vilihura</div>
              <div class="prof">CTO</div>
              <div class="team-photo-wrapper">
                <img v-lazyload height="325" width="250" :data-url="'/img/images-db/photo/vlad-flat.' + this.$store.state.imageFormat" alt="Vlad Vilihura" >
                <ul class="list-social-founder row">
                  <li>
                    <a target="_blank" href="https://www.linkedin.com/in/vlad-vilihura/" rel="nofollow">
                      <svg width="24px" height="24px" viewBox="0 0 30 29" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><title>LinkedIn</title> <desc>Created with Sketch.</desc> <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="footer" transform="translate(-810.000000, -204.000000)" fill="#2b506e" fill-rule="nonzero"><g id="Group"><g id="icons" transform="translate(531.000000, 204.000000)"><g id="Filled/16x16/LinkedIn" transform="translate(279.000000, 0.000000)"><path id="linkedin" d="M30,17.779877 L30,29 L23.5700286,29 L23.5700286,18.5313332 C23.5700286,15.9015187 22.6400026,14.1067732 20.3125661,14.1067732 C18.5358635,14.1067732 17.4785465,15.3163002 17.013394,16.4863139 C16.8437658,16.9045893 16.8001032,17.4867738 16.8001032,18.0722746 L16.8001032,29 L10.3679696,29 C10.3679696,29 10.4545972,11.2695145 10.3679696,9.43250398 L16.7994755,9.43250398 L16.7994755,12.2061182 C16.7864325,12.2268626 16.7694837,12.2492299 16.7572079,12.2693393 L16.7994755,12.2693393 L16.7994755,12.2061182 C17.6539639,10.8747354 19.1799906,8.97281035 22.5957123,8.97281035 C26.8275609,8.97273979 30,11.7694975 30,17.779877 Z M3.63968409,0 C1.43925955,0 0,1.46008292 0,3.3801417 C0,5.25835884 1.39761972,6.76275298 3.55431197,6.76275298 L3.59727702,6.76275298 C5.84031787,6.76275298 7.23528714,5.25864108 7.23528714,3.3801417 C7.19301957,1.46008292 5.84031787,0 3.63968409,0 Z M0.382012336,29 L6.81170472,29 L6.81170472,9.43250398 L0.382012336,9.43250398 L0.382012336,29 Z"></path></g></g></g></g></g></svg>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="mailto:vladv@devbrother.com">
                      <svg width="24px" height="24px" viewBox="0 0 30 25" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><title>Filled/24x24/mail</title> <desc>Created with Sketch.</desc> <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="footer" transform="translate(-531.000000, -207.000000)" fill="#2b506e"><g id="Group"><g id="icons" transform="translate(531.000000, 204.000000)"><g id="Filled/24x24/mail"><path d="M4.43201031e-13,12.0401786 L15,20.4553571 L30,12.0401786 L30,24.83125 C30,26.1325709 28.8807119,27.1875 27.5,27.1875 L2.5,27.1875 C1.11928813,27.1875 4.43201031e-13,26.1325709 4.43201031e-13,24.83125 L4.43201031e-13,12.0401786 Z M1,3.625 L29,3.625 C29.5522847,3.625 30,4.07271525 30,4.625 C30,6.01785714 30,7.41071429 30,8.80357143 L15,17.21875 L4.43201031e-13,8.80357143 L4.43201031e-13,4.625 C4.43133396e-13,4.07271525 0.44771525,3.625 1,3.625 Z"></path></g></g></g></g></g></svg>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
              <div class="characteristic">
                <p>
                  Our technical leader. Vlad got Ph.D. in cybersecurity and has built tens of websites from little web stores and visit cards to big start-ups and enterprise projects.
                </p>
                <p>
                  He leads, controls, and supports the main technical part of our projects, and he is the real leader for our technicians.
                </p>
              </div>
            </div>
          <div class="team-member" data-aos="fade-left"
          data-aos-offset="300"
          data-aos-easing="ease-in-sine" data-aos-duration="500">
          <div class="member-info">
            <div class="member-name">Aleksandr Pazynin</div>
            <div class="prof">COO</div>
            <div class="team-photo-wrapper">
              <img v-lazyload height="325" width="250" :data-url="'/img/images-db/photo/alex.' + this.$store.state.imageFormat" alt="Alex">
              <ul class="list-social-founder row">
                  <li>
                    <a target="_blank" href="https://www.linkedin.com/in/alex-pazynin/" rel="nofollow">
                      <svg width="24px" height="24px" viewBox="0 0 30 29" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><title>LinkedIn</title> <desc>Created with Sketch.</desc> <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="footer" transform="translate(-810.000000, -204.000000)" fill="#2b506e" fill-rule="nonzero"><g id="Group"><g id="icons" transform="translate(531.000000, 204.000000)"><g id="Filled/16x16/LinkedIn" transform="translate(279.000000, 0.000000)"><path id="linkedin" d="M30,17.779877 L30,29 L23.5700286,29 L23.5700286,18.5313332 C23.5700286,15.9015187 22.6400026,14.1067732 20.3125661,14.1067732 C18.5358635,14.1067732 17.4785465,15.3163002 17.013394,16.4863139 C16.8437658,16.9045893 16.8001032,17.4867738 16.8001032,18.0722746 L16.8001032,29 L10.3679696,29 C10.3679696,29 10.4545972,11.2695145 10.3679696,9.43250398 L16.7994755,9.43250398 L16.7994755,12.2061182 C16.7864325,12.2268626 16.7694837,12.2492299 16.7572079,12.2693393 L16.7994755,12.2693393 L16.7994755,12.2061182 C17.6539639,10.8747354 19.1799906,8.97281035 22.5957123,8.97281035 C26.8275609,8.97273979 30,11.7694975 30,17.779877 Z M3.63968409,0 C1.43925955,0 0,1.46008292 0,3.3801417 C0,5.25835884 1.39761972,6.76275298 3.55431197,6.76275298 L3.59727702,6.76275298 C5.84031787,6.76275298 7.23528714,5.25864108 7.23528714,3.3801417 C7.19301957,1.46008292 5.84031787,0 3.63968409,0 Z M0.382012336,29 L6.81170472,29 L6.81170472,9.43250398 L0.382012336,9.43250398 L0.382012336,29 Z"></path></g></g></g></g></g></svg>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="mailto:alex@devbrother.com">
                      <svg width="24px" height="24px" viewBox="0 0 30 25" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><title>Filled/24x24/mail</title> <desc>Created with Sketch.</desc> <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="footer" transform="translate(-531.000000, -207.000000)" fill="#2b506e"><g id="Group"><g id="icons" transform="translate(531.000000, 204.000000)"><g id="Filled/24x24/mail"><path d="M4.43201031e-13,12.0401786 L15,20.4553571 L30,12.0401786 L30,24.83125 C30,26.1325709 28.8807119,27.1875 27.5,27.1875 L2.5,27.1875 C1.11928813,27.1875 4.43201031e-13,26.1325709 4.43201031e-13,24.83125 L4.43201031e-13,12.0401786 Z M1,3.625 L29,3.625 C29.5522847,3.625 30,4.07271525 30,4.625 C30,6.01785714 30,7.41071429 30,8.80357143 L15,17.21875 L4.43201031e-13,8.80357143 L4.43201031e-13,4.625 C4.43133396e-13,4.07271525 0.44771525,3.625 1,3.625 Z"></path></g></g></g></g></g></svg>
                    </a>
                  </li>
              </ul>
            </div>
          </div>
            <div class="characteristic">
              <p>A successful C-level executive focused on driving revenues and profits with a passion for building companies, both from the ground up and by growing and streamlining established organizations.
              </p>
              <p>Develops exceptionally productive and motivated teams and encompasses a background in revitalizing and turning around companies as operations, sales and marketing leader.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
</template>
<script>
  import VueAos from 'vue-aos';
  let vm = {	};

	export default {
      name: 'Team',
      components: { VueAos },
      data(){ return {
        activeLeaderClass: 'leadership'
      } },
      mounted: function () {
        this.setActiveLeaderClass();
      },
      methods: {
        setActiveLeaderClass() {
          if (window.location.pathname === '/about') {
            this.activeLeaderClass = 'leadership';
          } else {
            this.activeLeaderClass = 'null';
          }
        }
      }
  }
</script>

<style scoped>
  .leadership {
      text-align: center !important;
  }
</style>