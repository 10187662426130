<template>
  <section class="contact-section">
    <div class="inner-container">

      <h2 class="contact-title">Contact us</h2>

      <p class="contact-us-text contact-info">If you want to reach our sales or development team, have any questions,
        concerns, or just want to chat about your project, feel free to get in touch.</p>

    </div>
    <form v-on:submit.prevent="sendMail()" action="#" class="acquainted-form" id="contact-form" novalidate>
      <div>        
          <div class="main-data-area">
            <div class="name-email-area">
              <p class="name-label">
                <input v-model="mailData.name" type="text" required name="name" id="name" placeholder="Name"
                  @focus="raiseLabelUp('name')" @blur="checkForEmptiness('name')" />
                <span class="error-message" :style="styles.name.span">Please enter the name</span>
              </p>
              <p class="email-label">
                <input v-model="mailData.email" type="email" required name="email" id="email" placeholder="Email"
                  @click="raiseLabelUp('email')" @blur="checkForEmptiness('email')" />
                <span class="error-message" :style="styles.email.span">Please enter the email in valid format</span>
              </p>
            </div>
            <p class="comments">
              <textarea v-model="mailData.comment" name="" id="comment" placeholder="Comments" required
                @focus="raiseLabelUp('comment')" @blur="checkForEmptiness('comment')"></textarea>
              <span :style="styles.comment.span">Please enter your comments</span>
            </p>
          </div>
          <button type="submit" class="btn form-btn">Send</button>
      </div>
      <div v-if="isMailSend" class="success-send-title">
          <button class="close-popup" @click="closePopup()" type="button" aria-label="close popup"></button>
          <div>Your message was successfully sent.<br />We will respond to you as soon as possible.</div>
      </div>
    </form>
  </section>
</template>
<script>

let vm = {};
import { requestGet, requestPost } from "../../services/axios.js";

export default {
  name: "ContactAbout",
  components: {},

  data() {
    return {
      isMailSend: false,
      mailData: {
        name: "",
        email: "",
        comment: ""
      },
      errors: [],
      styles: {
        name: {
          input: "",
          label: "",
          span: "visibility: hidden",
        },
        email: {
          input: "",
          label: "",
          span: "visibility: hidden",
        },
        comment: {
          input: "",
          label: "",
          span: "visibility: hidden;",
        }
      }
    };
  },
  mounted() {
  },
  beforeCreate() {
    vm = this;
  },

  methods: {
    checkForEmptiness: (field) => {
      if (vm.mailData[field] === "") {
        vm.addWarning(field)
      } else {
        vm.styles[field]["span"] = "visibility: hidden";
      }
    },
    addWarning: (field) => {
      vm.styles[field]["input"] = "border: 1px solid red;"
      vm.styles[field]["span"] = "visibility: visible";
    },
    raiseLabelUp: field => {
      vm.styles[field]["input"] = "";
      vm.styles[field]["span"] = "visibility: hidden";
    },
    validEmail: email => {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    hasErrors: () => {
      let gotError = false;
      Object.keys(vm.mailData).forEach(item => {
        if (vm.mailData[item] == "") {
          gotError = true;
          vm.checkForEmptiness(item);
          vm.addWarning('email');
        }
      });
      return gotError;
    },
    sendMail: (event) => {
      if (!vm.hasErrors()) {
        requestPost("/contact-about-us", vm.mailData)
          .then(res => {
            if (res.status) {
              vm.isMailSend = true;
              vm.mailData.name = "";
              vm.mailData.email = "";
              vm.mailData.comment = "";
              setTimeout(() => {
                vm.isMailSend = false;
              }, 5000)
            } else {
              console.log("Email send failed, error", res.error);
              vm.sendMail();
            }
          })
          .catch(error => {
            return { status: false, error };
          });
      }
    },
        closePopup: () => {
            vm.isMailSend = false;
    },
  }
};
</script>
<style lang="scss" scoped>

.contact-section .contact-title {
  margin: 0;
  margin-top: 120px;
  margin-bottom: 30px;
  font-size: 40px;
  font-family: Roboto Condensed, sans-serif;
  font-weight: 700;
  line-height: normal;
  color: #fff;
  text-align: center;
}

.contact-acqua-form {
  background: #1f2427;
  margin-bottom: 100px;
}

.contact-section .contact-info {
  margin-top: 5px;
  font-size: 22px;
  letter-spacing: 1.5px;
  line-height: 1.2;
  font-family: Roboto Condensed, sans-serif;
}

// .contact-location {
//   max-width: 310px;
//   width: 100%;
//   margin-left: auto;
//   margin-right: auto;
// }

// .contact-location:not(:last-child) {
//   margin-bottom: 25px;
// }

// .contact-locations {
//   display: flex;
//   flex-direction: column;
// }

.contact-us-text {
  max-width: 970px;
  margin-left: auto;
  margin-right: auto;
}

.buttons-area {
  margin-bottom: 50px;
}

.main-data-area {
  display: flex;
  max-width: 970px!important;
  width: 100%!important;
  margin: 0 auto;
}

.main-data-area input {
  width: 100%!important;
  border: 1px solid #3f6686;
  box-shadow: 0 2px 15px 0 #3f6686;
}

.acquainted-form .error-message {
  min-width: 50%;
}

.main-data-area textarea {
  width: 50%;
  border: 1px solid #3f6686;
  box-shadow: 0 2px 15px 0 #3f6686;
  width: 100%!important;
}

#contact-form {
  background: none;
  padding-top: 50px;
  padding-bottom: 100px;
}

#contact-form p {
  padding-bottom: 7px;
}

.name-email-area p {
  flex-direction: row;
}

.name-email-area label {
  margin-right: 30px;
}

.main-data-area {
  flex-direction: column;
}

.comments {
  width: 100%;
  align-items: start;
  margin-left: 0px;
}

.comments span {
  width: 50%;
  text-align: center;
}

.comments textarea {
  height: 100%;
}

.main-data-area .name-label,
.main-data-area .email-label {
  flex-direction: column;
  align-items: start;
  width: 100%;
}

.main-data-area .name-email-area {
  width: 100%;
}

.acquainted-form {
    position: relative;
}

.success-send-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 30px 20px;
    max-width: 650px;
    height: auto;
    background-color: black;
    border-radius: 5px;
    background: linear-gradient(342.18deg, #1b222a 0%, #2b506e 100%);
    box-shadow: 0 2px 18px 0 rgba(219, 231, 254, 0.93);
}

.close-popup {
    position: absolute;
    right: 15px;
    top: 15px;
    width: 25px;
    height: 25px;
    border: none;
    background-color: transparent;
    z-index: 1;

    &::before,
    &::after {
        content: "";
        position: absolute;
        width: 25px;
        height: 3px;
        background: #2b506e;
        background: #ffffff;
        top: 10px;
        right: 0;
    }

    &::before {
        transform: rotate(45deg);
    }

    &::after {
        transform: rotate(-45deg);
    }
}

@media(max-width: 767px) {
    .success-send-title {
        max-width: 320px;
    }
}

// @media(min-width: 400px) {
//   .contact-location {
//     max-width: 310px;
//   }
// }

@media(min-width: 768px) {
  .main-data-area {
    flex-direction: row;
    margin-left: auto;
  }

  .comments {
    align-items: center;
    margin-left: 50px;
  }

  .acquainted-form .error-message {
    min-width: 240px;
  }

  .main-data-area textarea {
    width: 400px;
  }
}

// @media(min-width: 900px) {

//   .contact-locations {
//     flex-direction: row;
//     justify-content: space-around;
//   }

//   .contact-location:nth-child(2) {
//     padding: 0 55px;
//     max-width: fit-content;
//   }

//   .contact-location {
//     margin-bottom: 0 !important;
//   }
// }

#name::placeholder,
#email::placeholder,
#comment::placeholder {
  opacity: 0.7;
  font-size: 15px;
  color: #06c6ff;
}

#name::placeholder,
#email::placeholder{
  transform: translateY(-2px);
}

@media(max-width: 450px) {
  .comments span {
    width: 55%;
  }

  .main-data-area input {
    width: 55%;
  }

  .acquainted-form .error-message {
    min-width: 55%;
  }

  .main-data-area textarea {
    width: 55%;
  }

  #contact-form {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media(max-width: 375px) {
  .comments span {
    width: 50%;
  }

  .main-data-area input {
    width: 50%;
  }

  .acquainted-form .error-message {
    min-width: 50%;
  }

  .main-data-area textarea {
    width: 50%;
  }
}

@media(max-width: 320px) {
  .comments span {
    width: 40%;
  }

  .main-data-area input {
    width: 40%;
  }

  .acquainted-form .error-message {
    min-width: 40%;
  }

  .main-data-area textarea {
    width: 40%;
  }
}
</style>
