<template>
  <!-- SECTION Career -->
  <div class="career-page about-page">
    <main class="about-content-page">
      <div class="about-stone-bg"></div>
      <section class="partner" id="partner">
        <div class="pagination">
          <div class="container-db">
            <div class="bread-crumbs">
              <a href="/" class="router-link-active">Home</a>
              <span class="bread-separator">/</span>
              <span class="bread-current">About</span>
            </div>
          </div>
        </div>

        <div class="container-db about-us-text">
          <div id="partner-text" class="partner-text">
            <!-- <div class="partner-title-wrapper-different">
                            <h1 class="about-us">About Us</h1>
                        </div> -->
            <div class="text">
              <p>
                <b>DevBrother (LeadIntelligence)</b> is a unique web development company in USA, Poland and Ukraine, because we are bringing a new generation of Remote Team Extension on the market - we've created one of the biggest European Software Engineers Database and set up all necessary support for remote work with our clients - office spaces, recruiting, hiring, management, administrative, infrastructure, etc., based on partnership with CoWorking Spaces.
              </p>
            </div>
          </div>
        </div>

        <ul class="partner-list">
          <li class="partner-list-item">
            <div class="inner-partner-item">
              <h2>Our mission</h2>
              <p>
                We create a new quality of the connection between people in the
                IT industry based on the principles of pro-activity, humanity,
                and awareness.
              </p>
            </div>
          </li>
          <li class="partner-list-item">
            <div class="inner-partner-item">
              <h2>Our goal</h2>
              <p>
                The company goal is to give client more than they expect,
                focusing on achieving the client's goals and results.
              </p>
            </div>
          </li>
        </ul>
      </section>
      <div class="main-container">
        <div class="main-content-about">
          <section class="db-why-us">
            <h2 class="section-title">Why us?</h2>

            <ul>
              <li class="marked-item">
                <strong>Unique Setup.</strong> We're a company that has one of
                the biggest databases of European developers based on
                partnership with CoWorking. We search and hire faster for better
                prices.
              </li>
              <li class="marked-item">
                <strong>Brilliant Management.</strong> We understand clients
                faster than they explain. We know that Management is one of the
                biggest issues with offshore companies and we solved it on the
                highest level by bringing managers with tech background to our
                team.
              </li>
              <li class="marked-item">
                <strong>Responsibility.</strong> We focused on a long-term
                partnership that's why we bring only scalable reliable solutions
                to help our clients to grow globally. We are interested in our
                Name that's why we don't disappear after the project is
                finished. We are always responsible for what we've done and we
                care about every single detail in the working process to bring
                our clients to long-term success.
              </li>
              <li class="marked-item">
                <strong>Experts.</strong> Our approach to every work is not to
                execute, but first of all - to understand the goals and provide
                our clients with the best solution in every unique situation -
                from big plannings to little development solutions. Pro-active
                approach in every step + quality guarantee.
              </li>
              <li class="marked-item">
                <strong>End Point Service.</strong> From an idea and choosing
                the best solutions/ technologies to implementation, hosting,
                deep testing and launching.
              </li>
            </ul>
          </section>
        </div>
      </div>

      <div class="container-db about-inner-container">
        <Awards />
        <Team />
        <section class="offices" data-aos="zoom-in" data-aos-duration="1000">
          <div class="container-db">
            <h2>Our Offices</h2>
            <ul class="card-wrapper">
              <li class="card-item">
                <img
                  height="200"
                  width="340"
                  v-lazyload
                  data-url="images/New_York_Office_1.webp"
                  alt="Ukraine Kharkiv office photo"
                />

                <section class="card-info">
                  <h3>Headquarters in New York</h3>
                  <p>10010, United States, New York, 43 W 23RD ST, 2nd Fl.</p>
                  <p class="contact-info tel">
                    <a href="tel:+19296298710" target="_blank"
                      >+(1) 929-629-8710</a
                    >
                  </p>
                  <p class="contact-info">
                    <a href="mailto:contact@devbrother.com"
                      >contact@devbrother.com</a
                    >
                  </p>
                  <a
                    href="https://goo.gl/maps/onJS5JHfGDw2HN6P9"
                    target="_blank"
                    rel="nofollow"
                    >See on map</a
                  >
                </section>
              </li>
              <li class="card-item">
                <img
                  height="200"
                  width="340"
                  v-lazyload
                  data-url="images/Olivia_Star.webp"
                  alt="Poland Gdańsk office photo"
                />

                <section class="card-info">
                  <h3>Development office in Gdańsk</h3>
                  <p>
                    80-309, Poland, Gdańsk, aleja Grunwaldzka, 472D Olivia Six
                  </p>
                  <p class="contact-info tel">
                    <a href="tel:+48572364123" target="_blank"
                      >+(48) 572-364-123</a
                    >
                  </p>
                  <p class="contact-info">
                    <a href="mailto:contact@devbrother.com"
                      >contact@devbrother.com</a
                    >
                  </p>
                  <a
                    href="https://goo.gl/maps/gtTnVYCLUxD3vxWk8"
                    target="_blank"
                    rel="nofollow"
                    >See on map</a
                  >
                </section>
              </li>
              <li class="card-item">
                <img
                  height="200"
                  width="340"
                  v-lazyload
                  data-url="images/Kyiv-office.webp"
                  alt="Ukraine Kyiv office photo"
                />

                <section class="card-info">
                  <h3>Development office in Kyiv</h3>
                  <p>02000, Ukraine, Kyiv, Peremohy Ave.26</p>
                  <p class="contact-info tel">
                    <a href="tel:+380938922544" target="_blank"
                      >+(380) 938-922-544</a
                    >
                  </p>
                  <p class="contact-info">
                    <a href="mailto:contact@devbrother.com"
                      >contact@devbrother.com</a
                    >
                  </p>
                  <a
                    href="https://goo.gl/maps/88nJhCyCSRbu6b738"
                    target="_blank"
                    rel="nofollow"
                    >See on map</a
                  >
                </section>
              </li>
              <li class="card-item">
                <img
                  height="200"
                  width="340"
                  v-lazyload
                  data-url="images/Bakulina-4A.webp"
                  alt="Ukraine Kharkiv office photo"
                />

                <section class="card-info">
                  <h3>Development office in Kharkiv</h3>
                  <p>61000, Ukraine, Kharkiv, Bakulina 4A</p>
                  <p class="contact-info tel">
                    <a href="tel:+380938922544" target="_blank"
                      >+(380) 938-922-544</a
                    >
                  </p>
                  <p class="contact-info">
                    <a href="mailto:contact@devbrother.com"
                      >contact@devbrother.com</a
                    >
                  </p>
                  <a
                    href="https://goo.gl/maps/Ltb6f6ZsWESU4TY49"
                    target="_blank"
                    rel="nofollow"
                    >See on map</a
                  >
                </section>
              </li>
            </ul>
          </div>
        </section>
        <OurTeamsHome />
        <OurArticles />
      </div>
      <ContactAbout />
      <script v-html="jsonld" type="application/ld+json"></script>
    </main>
  </div>
</template>
<script>
import Awards from "../partials/Awards";
import Team from "../Home/TeamD";
import OurTeamsHome from "./OurTeamsHome";
import ContactAbout from "./ContactAbout";
import OurArticles from "./OurArticles";

export default {
  components: {
    Awards,
    Team,
    OurTeamsHome,
    ContactAbout,
    OurArticles,
  },
  name: "About",
  metaInfo: {
    title: "DevBrother - Outstaffing Company: Remote Team from Ukraine",
    meta: [
      {
        vmid: "description",
        name: "description",
        content:
          "DevBrother gives you a choice of different outstaffing models that allow you to control the whole process of team selection.",
      },
      {
        vmid: "og:image:type",
        name: "og:image:type",
        content: "image/webp",
      },
      {
        vmid: "og:title",
        name: "og:title",
        content: "DevBrother - Outstaffing Company: Remote Team from Ukraine",
      },
      {
        vmid: "og:description",
        name: "og:description",
        content:
          "DevBrother gives you a choice of different outstaffing models that allow you to control the whole process of team selection.",
      },
      {
        vmid: "og:image",
        name: "og:image",
        content:
          "https://devbrother.com/img/images-db/socials/top-software-developers.webp",
      },
      {
        vmid: "og:type",
        name: "og:type",
        content: "website",
      },
      {
        vmid: "og:url",
        name: "og:url",
        content: "https://devbrother.com/about",
      },
      {
        vmid: "twitter:title",
        name: "twitter:title",
        content: "DevBrother - Outstaffing Company: Remote Team from Ukraine",
      },
      {
        vmid: "twitter:description",
        name: "twitter:description",
        content:
          "DevBrother gives you a choice of different outstaffing models that allow you to control the whole process of team selection.",
      },
      {
        vmid: "twitter:image",
        name: "twitter:image",
        content:
          "https://devbrother.com/img/images-db/socials/top-software-developers.webp",
      },
    ],
  },
  data() {
    const jsonld = {
      "@context": "https://schema.org",
      "@type": "Organization",
      name: "About us",
      description: "about us",
    };
    return {
      jsonld,
    };
  },
  mounted: () => {
        this.addCanonicalLink();
  },
  methods: {
    addCanonicalLink: () => {
      const link = document.createElement('link');
      link.setAttribute('rel', 'canonical');
      link.setAttribute('href', 'https://devbrother.com/about');

      const oldLink = document.querySelector('link[rel="canonical"]');
      if (oldLink) {
        document.head.removeChild(oldLink);
      }

      document.head.appendChild(link);
    },
  }
};
</script>
<style lang="scss" scoped>
.about-us {
  font-size: 40px;
}

.about-inner-container {
  margin-top: -135px;
}

.about-inner-container .team {
  margin-top: -75px;
}

.about-page .about-us-text {
  margin-bottom: 70px;
}

.about-page .partner {
  padding-bottom: 220px;
  margin-bottom: -200px;
  background: radial-gradient(
    at 115% 380px,
    #06c6ff,
    #1a455a,
    #1c3348,
    #1d2836,
    #1f2427 65%
  );
}

.about-page .main-container {
  margin-bottom: 50px;
}

.about-page .our-achievements {
  text-align: center;
  font-size: 40px;
  line-height: 1.16;
  font-weight: bold;
  margin-bottom: 30px;
}

.about-page .partner-title-wrapper-different {
  text-align: center;
}

.about-page .about-content-page .main-container {
  width: 100%;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 50px;
  padding-bottom: 150px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

.about-page .about-content-page .main-content-about {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  margin-top: -50px;
  line-height: 1.5;
  z-index: 1;
}

.about-page .about-content-page .db-why-us {
  padding-top: 70px;
  padding-bottom: 70px;
}

.about-page .about-content-page .db-why-us .section-title {
  margin: 0;
  margin-top: 20px;
  margin-bottom: 30px;
  font-size: 40px;
  font-family: Roboto Condensed, sans-serif;
  font-weight: 700;
  line-height: normal;
  color: #fff;
  text-align: center;
}

.about-page .about-content-page .db-why-us ul {
  letter-spacing: 0.8px;
  color: #d5d4d4;
  padding-left: 15px;
}

.about-page .about-content-page .db-why-us ul strong {
  font-weight: 700;
  font-size: 22px;
  letter-spacing: 1.5px;
  color: #fff;
}

.about-page .about-content-page .db-why-us ul li {
  font-family: Roboto, sans-serif;
  font-size: 22px;
}

.about-page .about-content-page .db-why-us ul li:not(:last-child) {
  margin-bottom: 15px;
}

.about-page .about-stone-bg {
  position: absolute;
  width: 1200px;
  height: 675px;
  right: -345px;
  top: 50px;
  opacity: 1;
  z-index: 0;
  pointer-events: none;
  background: url("/img/images-db/images/bg_img/img v8-1_0000.webp");
  background-size: contain;
  background-repeat: no-repeat;
}

/* offices */

.offices {
  padding-bottom: 40px;
  padding-top: 30px;

  .container-db {
    padding-left: 0;
    padding-right: 0;
  }

  h2 {
    text-align: center;
  }

  .card-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .card-item {
    max-width: 340px;
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    overflow: hidden;
    transition: all 0.3s ease 0s;
    background-color: rgba(43, 80, 110, 0.41);
    outline: 1px solid transparent;
    outline-offset: -1px;
    margin-left: 20px;
    margin-right: 20px;

    &:hover {
      outline-color: #06c6ff;
      box-shadow: 0 1px 3px 0 #06c6ff;
    }
  }

  img {
    width: 100%;
    min-height: 200px;
    object-fit: cover;
  }

  .card-info {
    padding: 15px 15px 20px;
    display: flex;
    flex-direction: column;
    flex-basis: 100%;

    h3 {
      margin-bottom: 20px;
      line-height: 26px;
      font-size: 20px;
      letter-spacing: 0.5px;
      hyphens: auto;
      color: #06c6ff;
    }

    p {
      margin-bottom: 20px;
      line-height: 24px;
      font-size: 18px;
      letter-spacing: 0.5px;
      hyphens: auto;
    }

    a {
      display: inline-flex;
      // margin-top: auto;
      color: #06c6ff;
      text-decoration: underline;

      &:hover {
        opacity: 0.85;
      }
    }

    & > a {
      margin-top: 20px;
      margin-left: auto;
    }

    .contact-info {
      margin-bottom: 5px;

      &.tel {
        margin-top: auto;
      }

      a {
        color: #06c6ff;
        text-decoration: underline;
      }
    }
  }
}

/* end offices */

/* partner-list */
.partner-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  max-width: 1200px;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
}

.partner-list-item {
  width: 470px;
  min-height: 230px;
  border: 1px solid #06c6ff;
  border-radius: 2px;
  padding: 4px 3px;

  &:first-child {
    margin-right: 50px;
  }
}

.inner-partner-item {
  color: #fff;
  font-family: Roboto;
  font-size: 17px;
  letter-spacing: 0.57px;
  line-height: 19px;
  padding: 14px 17px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  border: 1px solid #1e242a;
  background-color: #2b506e;
  border-radius: 2px;

  h2 {
    font-size: 28px;
    color: #06c6ff;
    margin-bottom: 16px;
    text-align: center;
  }

  p {
    font-size: 22px;
    line-height: 1.5;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
  }
}

/* end partner-list */

@media (min-width: 1450px) and (max-width: 1700px) {
  .about-page .about-stone-bg {
    width: 900px !important;
    right: -345px;
    top: 50px;
  }
}

@media (min-width: 1350px) and (max-width: 1450px) {
  .about-page .about-stone-bg {
    width: 700px !important;
    right: -220px;
    top: 300px;
  }
}

@media (max-width: 1350px) {
  .about-page .about-stone-bg {
    display: none;
  }

  .about-page .partner {
    background: radial-gradient(
      at 115% 80px,
      #06c6ff,
      #1a455a,
      #1c3348,
      #1d2836,
      #1f2427 65%
    );
  }
}

@media (max-width: 1220px) {
  .about-page .about-content-page .main-content-about {
    max-width: 1000px;
  }

  .offices {
    .card-item {
      max-width: 31%;
    }
  }

  .partner-list {
    max-width: 990px;
  }

  .about-page .about-us-text .partner-text .text {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 1024px) {
  .about-page .about-stone-bg {
    display: none;
  }

  .about-page .partner {
    background: none;
  }
}

@media (max-width: 990px) {
  .about-page .about-content-page .main-content-about {
    max-width: 760px;
  }

  .offices {
    .card-wrapper {
      flex-direction: column;
    }

    .card-item {
      max-width: 340px;
      margin-left: auto;
      margin-right: auto;

      &:not(:last-child) {
        margin-bottom: 30px;
      }
    }
  }

  .partner-list {
    flex-wrap: wrap;
    justify-content: center;
    max-width: 450px;
  }

  .partner-list-item {
    &:first-child {
      margin-right: 0px;
      margin-bottom: 30px;
    }
  }
}

@media (max-width: 767px) {
  .about-page .about-content-page .db-why-us ul {
    padding-left: 0;
  }
}

@media (min-width: 991px) {
  .offices .card-item:first-child h3 {
    max-width: 150px;
  }
  .offices .card-item:first-child h3 + p {
    max-width: 218px;
  }
}
</style>
