<template>
    <section class="our-articles" data-aos="zoom-in" data-aos-duration="1000">
        <div class="inner-container">
            <h2 class="our-articles-title">Our Articles</h2>
            <ul class="card-wrapper">
                <li class="card-item">
                    <a href="/blog/benefits-of-outsourcing-software-development">
                        <div class="img-wrapper">
                            <img height="200" width="400" v-lazyload data-url="/storage/posts/September2022/JbldSTzUXOi5do3tarXM.jpg" alt="photo">
                        </div>

                        <section class="card-info">
                            <h3>Benefits of Outsourcing Software Development</h3>
                        </section>
                    </a>
                </li>
                <li class="card-item">
                    <a href="/blog/staff-augmentation">
                        <div class="img-wrapper">
                            <img height="200" width="400" v-lazyload data-url="/storage/posts/August2022/3Y5PK791MFYZEra8Hsj5.jpg" alt="photo">
                        </div>

                        <section class="card-info">
                            <h3>Staff augmentation - what it is and how the model works</h3>
                        </section>
                    </a>
                </li>
            </ul>
        </div>
    </section>


</template>
<script>

let vm = {};
export default {
    name: 'OurArticles',
    components: {},
    beforeCreate() {
        vm = this;
    }
};
</script>

<style lang="scss" scoped>
.our-articles-title {
    text-align: center;
}

.card-wrapper {
    display: flex;
    justify-content: space-around;
}

.card-item {
    max-width: 420px;
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    overflow: hidden;
    transition: all 0.3s ease 0s;
    background-color: rgba(43, 80, 110, 0.41);
    border: 1px solid transparent;
    &:hover{
    border: 1px solid #06c6ff;
    box-shadow: 0 1px 3px 0 #06c6ff;
    }
}

.img-wrapper{
    min-height: 200px;
}

img {
    display: block;
    width: 100%;
    object-fit: cover;
    background-color: #FFFFFF;
}

.card-info {
    padding: 15px 15px 25px;
    display: flex;
    flex-direction: column;
    flex-basis: 100%;

    h3 {
        margin-bottom: 0px;
        line-height: 26px;
        font-size: 20px;
        letter-spacing: 0.5px;
        hyphens: auto;
        color: #06c6ff;
    }
}

@media(max-width: 990px){
    .card-wrapper{
        flex-direction: column;
    }
    .card-item{
        margin-left: auto;
        margin-right: auto;
        &:not(:last-child){
            margin-bottom: 35px;
        }
    }
}
</style>