<template>
  <div class="quick-mail">
		<div class="talk" @click="openTalk" :class="{'item-show': !showForm, 'item-hide' : showForm }">
			<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="16" height="16" viewBox="0 0 16 16">
				<path fill="#ffffff" d="M3 11.2c0 0.1 0 0.1 0 0 0 0.1 0 0.1 0 0 0 0 0 0 0 0z"></path>
				<path fill="#ffffff" d="M8.3 1c-4.4 0-8.3 2.6-8.3 5.6 0 2 1.1 3.7 3 4.7 0 0 0 0 0 0s0 0.1 0 0.1c-0.1 1.3-0.9 1.7-0.9 1.7l-1.8 0.9h2c2.5 0 4.3-1.1 5.1-1.9 0.3 0 0.5 0 0.8 0 4.3 0 7.8-2.5 7.8-5.6s-3.4-5.5-7.7-5.5zM8.2 11.1c-0.3 0-0.7 0-0.9 0h-0.3l-0.2 0.2c-0.5 0.5-1.6 1.4-3.3 1.7 0.3-0.5 0.5-1.1 0.5-2v-0.3l-0.3-0.1c-1.8-0.9-2.7-2.3-2.7-4 0-2.4 3.5-4.6 7.3-4.6 3.7 0 6.7 2 6.7 4.6 0 2.4-3.1 4.5-6.8 4.5z"></path>
			</svg>
		</div>
		<div class="close-talk" @click="closeTalk" :class="{'item-show': showForm, 'item-hide' : !showForm }"></div>
		<div class="quick-email-form-wrapper" :class="{'item-show': showForm, 'item-hide' : !showForm, 'mail-send-success': isMailSend }">
			<div v-if="isMailSend" class="success-email">You sent a message successfully!</div>
			<div v-if="!isMailSend" class="quick-email-inner-wrapper">
				<p class="description">Please, write your message below</p>
			<form @submit.prevent="sendMail()" action="post" novalidate>
				<p class="input-wrapper">
					<!-- <label  for="email">Email</label> -->
					<input v-model="mailData.email" type="email" required name="email" id="email" placeholder="Email"
					@blur="checkForEmptiness('email')"
						/>
					<span class="msg-error" :class="{'show-span': showEmailError, 'hide-span': !showEmailError }">Please enter the email in valid format</span>
				</p>
				<p class="input-wrapper">
					<textarea v-model="mailData.message" name="message" id="" cols="" rows="5" required placeholder="Message"
						@blur="checkForEmptiness('message')"></textarea>
					<span class="msg-error" :class="{'show-span': showMessageError, 'hide-span': !showMessageError }">Please enter your message</span>
				</p>
				<button aria-label="submit form" type="submit">Send</button>
			</form>
			</div>
		</div>
	</div>
</template>

<script>

	import { requestPost } from "../../services/axios.js";
    let vm = {};

    export default {
        name: 'QuickMail',

        data: () => {
            return {
					isMailSend: false,
					mailData: {
						email: '',
						message: ''
					},
					showForm: false,
					showEmailError: false,
					showMessageError: false,
                }
        },
        beforeCreate() {
            vm = this;
        },
				methods: {
					openTalk() {
						vm.showForm = true;
					},
					closeTalk() {
						vm.showForm = false;
						vm.showEmailError = false;
						vm.showMessageError = false;
						vm.mailData.message = "";
						vm.mailData.email = "";
						vm.isMailSend = false;
					},
					checkForEmptiness: (field) => {
						if(vm.mailData[field] === "" && field === 'email') {
							vm.showEmailError = true;
						} else if(vm.mailData[field] === "" && field === 'message') {
								vm.showMessageError = true;

						}  else{
							vm.showEmailError = false;
							vm.showMessageError = false;
						}
					},
					validEmail: email => {
						const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
						return re.test(email);
					},
					hasErrors: () => {
						let gotError = false;
						Object.keys(vm.mailData).forEach(item => {
							if (vm.mailData[item] == "") {
									gotError = true;
									vm.checkForEmptiness(item);
							} else if ((item == "email" && !vm.validEmail(vm.mailData[item]))) {
								gotError = true;
								vm.showEmailError = true;
							}
						});
						return gotError;
					},
					sendMail: (event) => {
						if (!vm.hasErrors()) {
							requestPost("/quick-mail", vm.mailData)
								.then(res => {
									if (res.status) {
										vm.isMailSend = true;
										vm.mailData.message = "";
										vm.mailData.email = "";
									} else {
										console.log("Email send failed, error", res.error);
										console.log("resend email");
										vm.sendMail();
									}
									//vm.closeTalk();
								})
								.catch(error => {
									return { status: false, error };
								});
						}

					},
				}
    }
</script>

<style scoped>
.quick-mail {
	display: flex;
    align-items: center;
	justify-content: center;
	position: fixed;
	bottom: 20px;
	right: 20px;
	width: 60px;
	height: 60px;
	background-color: #2b506e;
	border-radius: 50%;
	z-index:1000;
}
.quick-mail svg {
	width: 40px;
	height: 40px;
	cursor: pointer;
}
.close-talk {
	position: relative;
	display: block;
	width: 40px;
	height: 40px;
	cursor: pointer;
}
.close-talk::before,
.close-talk::after{
	position: absolute;
	content: '';
	width: 30px;
	height: 2px;
	background-color: #ffffff;
	top: 50%;
	left: 50%;
	display: block;
}
.close-talk::before {
	transform: translate(-50%) rotate(45deg);
}
.close-talk::after {
	transform: translate(-50%) rotate(-45deg);
}
.quick-mail .item-hide {
	display: none;
}
.quick-mail .item-show {
	display: block;
}
.quick-email-form-wrapper {
	position: absolute;
	top: -262px;
    right: -7px;
	padding: 10px;
	border-radius: 5px;
	background-color: #ffffff;
	color: #000000;
	width: 230px;
	box-shadow: 0 2px 18px 0 rgba(219, 231, 254, 0.93);
}
.quick-mail .description {
	background-color: #2b506e;
	color: #ffffff;
	padding: 5px;
	margin-bottom: 10px;
	border-radius: 5px;
	font-size: 12px;
}
.input-wrapper {
	margin-bottom: 10px;
}
.quick-email-form-wrapper  input {
	box-sizing: border-box;
	font: inherit;
	border: 1px solid #2b506e;
	padding: 5px;
}
.quick-email-form-wrapper textarea {
	width: 100%;
	box-sizing: border-box;
	font: inherit;
	border: 1px solid #2b506e;
	padding: 5px;
}
.quick-email-form-wrapper button {
	display: block;
	border: none;
	padding: 5px 20px;
	color: #ffffff;
	background-color:  #2b506e;
	border-radius: 2px;
	margin: 0 auto;
}
.quick-email-form-wrapper .msg-error {
	display: block;
	color: #fa6400;
	font-family: "Roboto Condensed";
	font-size: 10px;
	letter-spacing: 0.92px;
	line-height: 12px;
	}
	.hide-span {
		visibility: hidden;
	}
	.show-span {
		visibility: visible;
	}
	.mail-send-success {
		top: -72px;
		text-align: center;
	}
</style>