<template>
	<div id="contact">
    <form v-on:submit.prevent="sendMail()" action="#" class="acquainted-form" novalidate>
      <div v-if="isMailSend" class="success-send-title"><div>Your message was successfully sent.<br />We will respond to you as soon as possible.</div></div>
      <div v-else-if="!isMailSend">
        <div class="form-title">Let’s get acquainted</div>
        <div class="name-email-area">
          <p class="name-label">
            <label :style="styles.name.label" for="name">Name</label>
            <input v-model="mailData.name" type="text" required name="name" id="name"
              @focus="raiseLabelUp('name')"
              @blur="checkForEmptiness('name')"/>
            <span class="error-message" :style="styles.name.span">Please enter the name</span>
          </p>
          <p>
            <label :style="styles.email.label" for="email">Email</label>
            <input v-model="mailData.email" type="email" required name="email" id="email"
              @click="raiseLabelUp('email')"
              @blur="checkForEmptiness('email')"/>
            <span class="error-message" :style="styles.email.span">Please enter the email in valid format</span>
          </p>
        </div>
				<!--
        <p>
          <label :style="styles.phone.label" for="phone">Phone (optional)</label>
          <input class="error-message" v-model="mailData.phone" type="phone" name="phone" id="phone"
          @focus="raiseLabelUp('phone')"
          />
        </p>
				-->
<!--        <div class="buttons-area">
          <p>What type of project are you building?</p>
          <div class="type-buttons">
            <button :class="{'btn-popup': true, 'btn-checkbox': checkProjectType('web')}" v-on:click.prevent="setProjectType('web')">Web</button>
            <button :class="{'btn-popup': true, 'btn-checkbox': checkProjectType('mobile')}" v-on:click.prevent="setProjectType('mobile')">Mobile</button>
            <button :class="{'btn-popup': true, 'btn-checkbox': checkProjectType('devops')}" v-on:click.prevent="setProjectType('devops')">DevOps</button>
            <button :class="{'btn-popup': true, 'btn-checkbox': checkProjectType('other')}" v-on:click.prevent="setProjectType('other')">Other</button>
          </div>
        </div>
        <div class="buttons-area">
          <p>Is this a new or existing project?</p>
          <div class="project-buttons">
            <button :class="{'btn-popup': true, 'btn-checkbox': checkProjectNovelty('new')}" v-on:click.prevent="setProjectNovelty('new')">New project</button>
            <button :class="{'btn-popup': true, 'btn-checkbox': checkProjectNovelty('old')}" v-on:click.prevent="setProjectNovelty('old')">Old project</button>
          </div>
        </div>
        <div class="buttons-area">
          <p>Expected budget</p>
          <div class="price-buttons">
            <button :class="{'btn-popup': true, 'btn-checkbox': checkProjectPrice('up-to-20k')}" v-on:click.prevent="setProjectPrice('up-to-20k')">Up to $20K</button>
            <button :class="{'btn-popup': true, 'btn-checkbox': checkProjectPrice('20-50k')}" v-on:click.prevent="setProjectPrice('20-50k')">$20K - $50K</button>
            <button :class="{'btn-popup': true, 'btn-checkbox': checkProjectPrice('50-100k')}" v-on:click.prevent="setProjectPrice('50-100k')">$50K - $100K</button>
            &lt;!&ndash; <button :class="{'btn-popup': true, 'btn-checkbox': checkProjectPrice('100-or-more')}" v-on:click.prevent="setProjectPrice('100-or-more')">$100K or more</button> &ndash;&gt;
          </div>
        </div>-->
        <p>
          <label :style="styles.text.label" for="text" id="text">Message</label>
          <textarea v-model="mailData.text"
            name=""
            id=""
            cols="30"
            rows="3"
            placeholder=""
            required
			aria-label="project description"
            @focus="raiseLabelUp('text')"
            @blur="checkForEmptiness('text')"
          ></textarea>
          <span :style="styles.text.span">Please enter your message</span>
        </p>
        <!-- <p class="range-wrapper">
          <label for="range" class="range-label">Project Budget</label
          ><br />
          <output></output>
          <input
            type="range"
            id="range"
            min="100"
            max="20000"
            step="100"
            class="range"
            value="10000"
            oninput="rangeDetect()"
          />
        </p>
        <div class="range-control">
          <div class="scale">
            <div class="bar"></div>
          </div>
          <div class="range-toggle"></div>
        </div> -->
        <button type="submit" class="btn form-btn" aria-label="submit form">Contact us</button>
      </div>
    </form>
  </div>
</template>

<script>
	import { requestGet, requestPost } from "../../services/axios.js";

		let vm = {

		}
    export default {
				name: 'AcquaintedForm',
				props: {
					formState: Boolean,
					state: Boolean
				},
        data(){
            return {
						isMailSend: false,
						mailData: {
							name: "",
							name: "",
							email: "",
							phone: "",
							text: "",
							newProject: {
								'type': [],
								'novelty': [],
								'price': []
							}
						},
						errors: [],
						styles: {
							name: {
								input: "",
								label: "",
								span: "visibility: hidden",
							},
							email: {
								input: "",
								label: "",
								span: "visibility: hidden",
							},
							phone: {
								input: "",
								label: "",
								span: "visibility: hidden",
							},
							text: {
								input: "",
								label: "",
								span: "visibility: hidden;",
							}
						}
					};
				},
				beforeCreate() {
					vm = this;
				},
				mounted() {
					if(!vm.formState) {
						vm.isMailSend = false;
					}
				},
				// beforeDestroy(){
				// 	clearTimeout(time);
				// },
				methods: {
					checkProjectType: (type) => {
						const typeIndexInArray = vm.mailData.newProject.type.indexOf(type);

						return typeIndexInArray == '-1' ? true : false;
					},
					checkProjectNovelty: (novelty) => {
						const typeIndexInArray = vm.mailData.newProject.novelty.indexOf(novelty);

						return typeIndexInArray == '-1' ? true : false;
					},
					checkProjectPrice: (price) => {
						const typeIndexInArray = vm.mailData.newProject.price.indexOf(price);

						return typeIndexInArray == '-1' ? true : false;
					},
					setProjectType: (type) => {
						const typeIndexInArray = vm.mailData.newProject.type.indexOf(type);

						if (typeIndexInArray == '-1') {
							vm.mailData.newProject.type.push(type);
						} else {
							vm.mailData.newProject.type.splice(typeIndexInArray, 1);
						}
					},
					setProjectNovelty: (novelty) => {
						const typeIndexInArray = vm.mailData.newProject.novelty.indexOf(novelty);

						vm.mailData.newProject.novelty = [];
						vm.mailData.newProject.novelty.push(novelty);
					},
					setProjectPrice: (price) => {
						const typeIndexInArray = vm.mailData.newProject.price.indexOf(price);

						vm.mailData.newProject.price = [];
						vm.mailData.newProject.price.push(price);
					},
					checkForEmptiness: (field) => {
						if (vm.mailData[field] === "") {
							vm.addWarning(field)
						} else {
							vm.styles[field]["span"] = "visibility: hidden";
						}
					},
					addWarning: (field) => {
						//vm.styles[field]["label"] =
						//		"top: -10px; font-size: 14px; padding: 2px; background-color: #253E54; margin-bottom: 0; color: #fa6400;";
						vm.styles[field]["input"] = "border: 1px solid red;"
						// vm.style[field]["input"] = "border: 1px solid #fa6400;";
						vm.styles[field]["span"] = "visibility: visible";
					},
					raiseLabelUp: field => {
						//vm.styles[field]["label"] =	"top: -10px; font-size: 14px; padding: 2px; background-color: #253E54; margin-bottom: 0; color: #7094b1";
						vm.styles[field]["input"] = "";
						vm.styles[field]["span"] = "visibility: hidden";
					},
					validEmail: email => {
						const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
						return re.test(email);
					},
					// validPhone: phone => {
					// 	const re = /^\d{10}$/;
					// 	return re.test(phone);
					// },
					hasErrors: () => {
						let gotError = false;
						Object.keys(vm.mailData).forEach(item => {
							if (vm.mailData[item] == "") {
								if ( item !== "phone") {
									gotError = true;
									vm.checkForEmptiness(item);
									// vm.styles[item]["label"] += "color: #fa6400;";
									// vm.styles[item]["input"] += "border-bottom-color: #fa6400;";
								}
							} else if ((item == "email" && !vm.validEmail(vm.mailData[item]))) {
								gotError = true;
								vm.checkForEmptiness(item);
								vm.addWarning('email');
								// vm.styles[item]["label"] += "color: #fa6400;";
								// vm.styles[item]["input"] += "border-bottom-color: #fa6400;";
							}
						});
						return gotError;
					},
					sendMail: (event) => {
						if (!vm.hasErrors()) {
							requestPost("/contact-us", vm.mailData)
								.then(res => {
									if (res.status) {
										vm.isMailSend = true;
										vm.mailData.name = "";
										vm.mailData.phone = "";
										vm.mailData.email = "";
										vm.mailData.text = "";
										// const time = setTimeout(() => {
										// 	vm.isMailSend =  false;
										// }, 2000)
										//

									} else {
										console.log("Email send failed, error", res.error);
										vm.sendMail();
									}
								})
								.catch(error => {
									return { status: false, error };
								});
						}
					},
				},
				watch: {
					state: (newValue, oldValue) => { console.log('reset ', vm.$refs.form)
						if(!newValue) {
							Object.keys(vm.styles).forEach(key => {
								//vm.styles[key]["label"] = "top: 14px; padding: 2px; background-color: transparent; margin-bottom: 14px;  color: #7094b1;";
								vm.styles[key]["input"] = "border: 1px solid #4b6d89;"
								vm.styles[key]["span"] = "visibility: hidden";
								vm.mailData[key] = "";
							});
						}
					}
				}
    }
</script>
