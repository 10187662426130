<template>    <!--SECTION INDUSTRIES-->
<main>
			<div class="promotaion ecommerce">
				<div class="container-db bread-container">
					<div class="bread-crumbs">
						<router-link to="/">Home</router-link>
						<span class="bread-separator">/</span>
						<span class="bread-current">E-commerce</span>
					</div>
				</div>
				<div class="container-db">
					<h1>E-commerce</h1>
				</div>
			</div>
			<div class="case-container challenge">
				<h2>Overview</h2>
				<p>PineStore asked DevBrother to create an online e-commerce website for selling electronics through web. They wanted to build a convenient tool for selling their products online fast, secure and to reduce the development costs as much as possible.</p>
			</div>
			<div class="descrip-result-wrapper">
				<div class="descrip-result-text">
					<div class="container-db">
						<div class="description">
							<h2>Description</h2>
							<p>
								To reduce development costs and implement e-commerce website for PineStore we used OpenCart CMS system with existing theme. As we didn’t have some special requirements or OpenCart modules customization, the development process were less than one week and one more week for testing and launching on live.
							</p>
						</div>
						<div class="result">
							<h2>Result</h2>
							<p>
								During 2 weeks E-commerce website were successfully delivered to client. We’ve faced all their expectations in terms and quality. As they asked to reduce the cost and development time as much as possible - we’ve used existing OpenCart theme, existing Extensions and didn’t make any customization							</p>
						</div>
					</div>
				</div>
				<img v-lazyload data-url="/img/images-db/case-studies/ec-solution.webp" alt="ec-solution.webp" width="945" height="665">
			</div>
			<div class="tech-features-section">
				<div class="container-db">
					<div class="tech-features-wrapper">
						<div class="technologies-wrapper">
							<h2>Technologies</h2>
							<ul>
								<li class="marked-item">OpenCart</li>
								<li class="marked-item">HTML5, CSS3, Bootstrap</li>
								<li class="marked-item">MySQL</li>
								<li class="marked-item">AWS</li>
							</ul>
						</div>
						<div class="features">
							<h2>Features</h2>
							<ul>
								<li>OpenCart electronics store</li>
								<li>2 weeks for e-commerce website</li>
								<li>Minimum customization</li>
							</ul>
						</div>

					</div>
				</div>
			</div>
			<div class="relevant-case-studies">
				<div class="container-db">
					<h2>portfolio</h2>
					<div class="slider-container">
						<div class="relevant-case-studies-slider">
							<slick ref="slick" :options="slickOptions">
								<div class="case-slider-item">
									<router-link to="art-galleries">
										<div class="case-studies-item">
										<div class="case-studies-img-wrapper">
											<img src="/img/images-db/case-studies/art.webp" alt="Art Galleries" width="300" height="334">
										</div>
										<h3>Art Galleries</h3>
									</div>
									</router-link>
								</div>
								<div class="case-slider-item">
									<router-link to="healthcare">
										<div class="case-studies-item">
											<div class="case-studies-img-wrapper">
												<img src="/img/images-db/case-studies/health.webp" alt="healthcare" width="300" height="334">
											</div>
											<h3>HealthCare <br />DocToc</h3>
										</div>
									</router-link>
								</div>
								<div class="case-slider-item">
									<router-link to="wordpress-dev">
										<div class="case-studies-item">
										<div class="case-studies-img-wrapper">
											<img src="/img/images-db/case-studies/wp3.webp" alt="wordpress development" width="300" height="334">
										</div>
										<h3>WordPress<br>Websites and Plugins Development</h3>
									</div>
									</router-link>
								</div>
								<div class="case-slider-item">
									<router-link to="online-event">
										<div class="case-studies-item">
										<div class="case-studies-img-wrapper">
											<img src="/img/images-db/case-studies/event.webp" alt="Online Tickets  Printing" width="300" height="334">
										</div>
										<h3>Online Tickets  Printing</h3>
									</div>
									</router-link>
								</div>
								<div class="case-slider-item">
									<router-link to="cryptocurrency">
										<div class="case-studies-item">
										<div class="case-studies-img-wrapper">
											<img src="/img/images-db/case-studies/crypto.webp" alt="Cryptocurrency" width="300" height="334">
										</div>
										<h3>Cryptocurrency</h3>
									</div>
									</router-link>
								</div>
							</slick>
						</div>
					</div>
				</div>
			</div>
      <AcquaintedSection />
      <div v-lazyload data-src="/img/images-db/parallax/stone2.webp" class="lazy-bg bottom-stone"></div>
			<script v-html="jsonld" type="application/ld+json"></script>
    </main>
</template>
<script>
	import Slick from 'vue-slick';
	import AcquaintedSection from '../AcquaintedSection';

	export default {
	name: 'Ecommerce',
	metaInfo: {
		title: 'Case: Creating a PineStore online electronics store in 7 days',
		meta: [
			{
				vmid: "description",
				name: 'description',
				content: "Creating an online store does not take much time, it took us at Devbrother only 7 days to bring the client's idea to life."
			},
			{
				vmid: "og:image:type",
				name: "og:image:type",
				content: 'image/webp'
			},
			{
				vmid: "og:title",
				name: "og:title",
				content: 'Case: Creating a PineStore online electronics store in 7 days'
			},
			{
				vmid: "og:description",
				name: "og:description",
				content: "Creating an online store does not take much time, it took us at Devbrother only 7 days to bring the client's idea to life."
			},
			{
				vmid: "og:image",
				name: "og:image",
				content: 'https://devbrother.com/img/images-db/case-studies/ec-solution.webp'
			},
			{
				vmid: "og:type",
				name: "og:type",
				content: "website"
			},
			{
				vmid: "og:url",
				name: "og:url",
				content: 'https://devbrother.com/cases/e-commerce'
			},
			{
				vmid: "twitter:title",
				name: "twitter:title",
				content: 'Case: Creating a PineStore online electronics store in 7 days'
			},
			{
				vmid: "twitter:description",
				name: "twitter:description",
				content: "Creating an online store does not take much time, it took us at Devbrother only 7 days to bring the client's idea to life."
			},
			{
				vmid: "twitter:image",
				name: "twitter:image",
				content: 'https://devbrother.com/img/images-db/case-studies/ec-solution.webp'
			}
		],
      },
		components: { Slick, AcquaintedSection},
		mounted() {
			this.addCanonicalLink();
		},
		methods: {
				addCanonicalLink: () => {
					const link = document.createElement('link');
					link.setAttribute('rel', 'canonical');
					link.setAttribute('href', 'https://devbrother.com/cases/e-commerce');

					const oldLink = document.querySelector('link[rel="canonical"]');
					if (oldLink) {
						document.head.removeChild(oldLink);
					}

					document.head.appendChild(link);
				},
		},
		data(){
			const jsonld =  {
        "@context": "https://schema.org",
        "@type": "IndividualProduct",
        "name": "Ecommerce",
        "description": "Ecommerce"
      }
			return {
			jsonld,
			slickOptions: {
            prevArrow: '<div class="prev-arrow"><img src="/img/images-db/Arrow.svg" alt="arrow" class="arrow" width="26" height="11"/><img src="/img/images-db/Arrow-hover.svg" alt="arrow" class="arrow-hover" width="26" height="11"/></div>',
            nextArrow: '<div class="next-arrow"><img src="/img/images-db/Arrow.svg" alt="arrow" class="arrow" width="26" height="11"/><img src="/img/images-db/Arrow-hover.svg" alt="arrow" class="arrow-hover" width="26" height="11"/></div>',
					slidesToShow: 3,
					slidesToScroll: 1,
					// centerMode: true,
					responsive: [
						{
							breakpoint: 769,
							settings: {
								slidesToShow: 1,
								centerMode: false,
								prevArrow: '',
								nextArrow: '',
								dots: true,
								customPaging: function(slider, i) {
									return '<div class="slick-mobile-dot"></div>';
								}
							}
						}
					]
							// Any other options that can be got from plugin documentation
					},
			}
		},
	}
</script>
