<template>
  <!-- SECTION BLOG -->

  <main class="blog-page">
    <div class="page-bg-overlay"></div>
    <div class="blog-bg"></div>
    <!-- <img src="/img/images-db/images/bg_img/img v8-1_0000.webp" alt="photo" class="blog-bg" height="1545" width="869"> -->
    <div class="pagination">
      <div class="container-db">
        <div class="bread-crumbs">
          <router-link to="/" class="router-link-active">Home</router-link>
          <span class="bread-separator">/</span>
          <span class="bread-current">Blog</span>
        </div>
      </div>
    </div>

    <div class="container">
      <h1 data-v-59cab048="" class="page-title blog-title">Web Development Blog</h1>
      <section class="main-content blog">

        <ul class="list-posts">
          <li class="post" v-for="article in articles.slice().reverse()">
            <router-link class="post-link" :to="'/blog/' + article.slug"></router-link>
            <div class="post-content">
              <h2 class="post-title">{{ article.title }}</h2>
              <p class="excerpt">{{ article.excerpt }}</p>
              <p class="post-meta">
                <span class="category">{{ article.category ? article.category.name : 'None' }}</span>
                <span class="dot"></span>
                <time :datetime="article.created_at">{{ article.created_at | createDate }}</time>
              </p>
            </div>
            <div class="post-img">
              <img v-lazyload :data-url="'/' + article.image" alt="post image" height="240" width="150" />
            </div>
          </li>
        </ul>

      </section>
      <aside class="aside-content">
        <section class="widget-news">
          <h2 class="widget-news-title">Popular News</h2>

          <div class="widget-news-content">
            <ul class="widget-news-list" v-for="otherPost in otherPosts">
              <li class="widget-news-item">
                <router-link :to="'/blog/' + otherPost.slug" class="post-link"> </router-link>
                <h3 class="widget-news-item-title">
                  {{ otherPost.title }}
                </h3>
                <p class="post-meta">
                  <span class="category">{{ otherPost.category ? otherPost.category.name : 'None' }}</span>
                  <span class="dot"></span>
                  <time :datetime="otherPost.created_at">{{ otherPost.created_at | createDate }}</time>
                </p>
              </li>
            </ul>
          </div>
        </section>
      </aside>

    </div>

    <script v-html="jsonld" type="application/ld+json"></script>
  </main>

</template>

<script>
import { requestGet, requestPost } from "../../services/axios";

let vm = {};

export default {
  name: "blog",
  metaInfo: {
    title: 'Blog by DevBrother - Author Articles from Professionals in Building Software Development Teams',
    meta: [
      {
        name: 'description',
        content: 'Useful articles from experts on building remote teams, outsourcing, outstaffing in software development.'
      }
    ],
  },
  data() {
    const jsonld = {
      "@context": "https://schema.org",
      "@type": "Blog",
      "name": "Blog",
      "description": "Blog"
    }
    return {
      jsonld,
      articles: [],
      otherPosts: [],
      articlesInDevelopment: 0
    };
  },
  beforeCreate() {
    vm = this;
  },
  mounted: () => {
    vm.getPosts();
    vm.getOtherPosts();
    vm.addCanonicalLink();
  },
  filters: {
    createDate: function (value) {
      const created_at = new Date(value.replace(/ /g, "T"));

      const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
      ];
      const month = monthNames[created_at.getMonth()];
      const day = created_at.getDate();
      const year = created_at.getFullYear();

      const date = month + '. ' + day + ', ' + year;

      return date;
    }
  },
  methods: {
    addCanonicalLink: () => {
        const link = document.createElement('link');
        link.setAttribute('rel', 'canonical');
        link.setAttribute('href', 'https://devbrother.com/blog');

        const oldLink = document.querySelector('link[rel="canonical"]');
        if (oldLink) {
            document.head.removeChild(oldLink);
        }

        document.head.appendChild(link);
    },
    getPosts: () => {
      requestGet("/avalablePosts", {}).then(response => {
        if (response.status) {
          vm.articles = response.data;
          const width = window.innerWidth;
          if (width > 1014) {
            vm.articlesInDevelopment = vm.articles.length % 3;
          } else if (width > 690) {
            if (vm.articles.length % 2 === 1) {
              vm.articlesInDevelopment = 3;
            } else {
              vm.articlesInDevelopment = 0;
            }
          } else {
            vm.articlesInDevelopment = 0;
          }
        }
      });
    },
    getOtherPosts: () => {
      requestGet("/related/" + vm.post, {}).then(response => {
        if (response.status) {
          vm.otherPosts = response.data;
        }
      });
    },
  }
};
</script>
<style scoped>
.blog-title {
  font-size: 45px;
  font-family: Roboto Condensed, sans-serif;
}

@media (max-width: 576px) {
  .blog-title {
    font-size: 32px;
  }

  h2.post-title {
    line-height: 1.4;
    margin-bottom: 18px;
  }
}
</style>
