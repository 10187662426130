
<template>    <!--SECTION INDUSTRIES-->
<main>
			<div class="promotaion event">
				<div class="container-db bread-container">
					<div class="bread-crumbs">
						<router-link to="/">Home</router-link>
						<span class="bread-separator">/</span>
						<span class="bread-current">Online Tickets</span>
					</div>
				</div>
				<div class="container-db">
					<h1>Express Event Printing for PurplePass</h1>
				</div>
			</div>
			<div class="case-container challenge">
				<h2>Overview</h2>
				<p>PurplePass is a cloud-based ticketing solution suitable for small to midsize event organizers. Key features include online pre-sale, marketing, printed tickets and wristbands, social media integration, day-of sales and admission management.</p>
				<p>PurplePass decided to scale their services by giving opportunity for their clients to make the design of tickets, booklets, flyers and other event attributes online. Clients had to be able to order this and receive by one of several delivery services.</p>
			</div>
			<div class="descrip-result-wrapper">
				<div class="descrip-result-text">
					<div class="container-db">
						<div class="description">
							<h2>Description</h2>
							<p>We’ve created online designer for customers to be able to create their own tickets, flyers and booklets design. Also templates library had been developed for giving opportunity to choose predefined templates. After client will choose the type, style, quantity and other parameters of needed product he is redirected to checkout and delivery parameter page. </p>
							<p>Infrastructure was set up on AWS with docker to deliver the highest security and stability level</p>
						</div>
						<div class="result">
							<h2>Result</h2>
							<p>Express Event Printing platform already launched in Beta version. Online designing, Templates library, flexible and custom product creation, ordering and products delivering - everything is currently available on the platform.</p>
							<p>PurplePass services became more advanced and their clients now receive much more opportunities and features during their collaboration.</p>
						</div>
					</div>
				</div>
				<img v-lazyload data-url="/img/images-db/case-studies/event-solutions.webp" alt="event-solutions.webp" width="945" height="665">
			</div>
			<div class="tech-features-section">
				<div class="container-db">
					<div class="tech-features-wrapper">
						<div class="technologies-wrapper">
							<h2>Technologies</h2>
							<ul>
								<li class="marked-item">Laravel, Voyager</li>
								<li class="marked-item">HTML5, CSS3, Bootstrap, VueJS, JQuery</li>
								<li class="marked-item">MySQL</li>
								<li class="marked-item">AWS, docker</li>
							</ul>
						</div>
						<div class="features">
							<h2>Features</h2>
							<ul>
								<li>Online Tickets/Flyers/Booklets Designing</li>
								<li>Advanced admin panel features</li>
								<li>Flexible Ordering and Delivering in USA</li>
								<li>Templates library</li>
							</ul>
						</div>

					</div>
				</div>
			</div>
			<div class="relevant-case-studies">
				<div class="container-db">
					<h2>portfolio</h2>
					<div class="slider-container">
						<div class="relevant-case-studies-slider">
							<slick ref="slick" :options="slickOptions">
								<div class="case-slider-item">
									<router-link to="wordpress-dev">
										<div class="case-studies-item">
										<div class="case-studies-img-wrapper">
											<img src="/img/images-db/case-studies/wp3.webp" alt="wordpress development" width="300" height="334">
										</div>
										<h3>WordPress<br>Websites and Plugins Development</h3>
									</div>
									</router-link>
								</div>
								<div class="case-slider-item">
									<router-link to="cryptocurrency">
										<div class="case-studies-item">
										<div class="case-studies-img-wrapper">
											<img src="/img/images-db/case-studies/crypto.webp" alt="Cryptocurrency" width="300" height="334">
										</div>
										<h3>Cryptocurrency</h3>
									</div>
									</router-link>
								</div>
								<div class="case-slider-item">
									<router-link to="art-galleries">
										<div class="case-studies-item">
										<div class="case-studies-img-wrapper">
											<img src="/img/images-db/case-studies/art.webp" alt="Art Galleries" width="300" height="334">
										</div>
										<h3>Art Galleries</h3>
									</div>
									</router-link>
								</div>
								<div class="case-slider-item">
									<router-link to="e-commerce">
										<div class="case-studies-item">
										<div class="case-studies-img-wrapper">
											<img src="/img/images-db/case-studies/ecommerce.webp" alt="E-commerce" width="300" height="334">
										</div>
										<h3>E-commerce</h3>
									</div>
									</router-link>
								</div>
								<div class="case-slider-item">
									<router-link to="healthcare">
										<div class="case-studies-item">
											<div class="case-studies-img-wrapper">
												<img src="/img/images-db/case-studies/health.webp" alt="healthcare" width="300" height="334">
											</div>
											<h3>HealthCare <br />DoctorMed</h3>
										</div>
									</router-link>
								</div>
							</slick>
						</div>
					</div>
				</div>
			</div>
      <AcquaintedSection />
      <div v-lazyload data-src="/img/images-db/parallax/stone2.webp" class="lazy-bg bottom-stone"></div>
			<script v-html="jsonld" type="application/ld+json"></script>
    </main>
</template>
<script>
	import Slick from 'vue-slick';
	import AcquaintedSection from '../AcquaintedSection';

	export default {
			name: 'OnlineEvent',
			metaInfo: {
			 title: 'Case: Creating an Online Ticket Design Builder in PurplePass',
            meta: [
                {
					vmid: "description",
                    name: 'description',
                    content: 'We created an advanced Express Event Printing platform for PurplePass so they could provide a new convenience for their customers.'
                },
				{
					vmid: "og:image:type",
					name: "og:image:type",
					content: 'image/webp'
				},
				{
					vmid: "og:title",
					name: "og:title",
					content: 'Case: Creating an Online Ticket Design Builder in PurplePass'
				},
				{
					vmid: "og:description",
					name: "og:description",
					content: 'We created an advanced Express Event Printing platform for PurplePass so they could provide a new convenience for their customers.'
				},
				{
				  vmid: "og:image",
				  name: "og:image",
				  content: 'https://devbrother.com/img/images-db/case-studies/event-solutions.webp'
				},
				{
					vmid: "og:type",
					name: "og:type",
					content: "website"
				},
				{
					vmid: "og:url",
					name: "og:url",
					content: 'https://devbrother.com/cases/online-event'
				},
				{
					vmid: "twitter:title",
					name: "twitter:title",
					content: 'Case: Creating an Online Ticket Design Builder in PurplePass'
				},
				{
					vmid: "twitter:description",
					name: "twitter:description",
					content: 'We created an advanced Express Event Printing platform for PurplePass so they could provide a new convenience for their customers.'
				},
				{
					vmid: "twitter:image",
					name: "twitter:image",
					content: 'https://devbrother.com/img/images-db/case-studies/event-solutions.webp'
				}
            ],
      },
			components: {
				Slick,
				AcquaintedSection
				 },
		mounted() {
			this.addCanonicalLink();
		},
		methods: {
				addCanonicalLink: () => {
					const link = document.createElement('link');
					link.setAttribute('rel', 'canonical');
					link.setAttribute('href', 'https://devbrother.com/cases/online-event');

					const oldLink = document.querySelector('link[rel="canonical"]');
					if (oldLink) {
						document.head.removeChild(oldLink);
					}

					document.head.appendChild(link);
				},
		},
		data(){
			const jsonld =  {
					"@context": "https://schema.org",
					"@type": "IndividualProduct",
					"name": "Online Events",
					"description": "Online Events"
			}
			return {
			jsonld,
			slickOptions: {
            prevArrow: '<div class="prev-arrow"><img src="/img/images-db/Arrow.svg" alt="arrow" class="arrow" width="26" height="11"/><img src="/img/images-db/Arrow-hover.svg" alt="arrow" class="arrow-hover" width="26" height="11"/></div>',
            nextArrow: '<div class="next-arrow"><img src="/img/images-db/Arrow.svg" alt="arrow" class="arrow" width="26" height="11"/><img src="/img/images-db/Arrow-hover.svg" alt="arrow" class="arrow-hover" width="26" height="11"/></div>',
					slidesToShow: 3,
					slidesToScroll: 1,
					// centerMode: true,
					responsive: [
						{
							breakpoint: 769,
							settings: {
								slidesToShow: 1,
								centerMode: false,
								prevArrow: '',
								nextArrow: '',
								dots: true,
								customPaging: function(slider, i) {
									return '<div class="slick-mobile-dot"></div>';
								}
							}
						}
					]
							// Any other options that can be got from plugin documentation
					},
			}
		},
	}
</script>
