<template>    <!--SECTION INDUSTRIES-->
	<main>
    <div class="top-stone">
    </div>
    <div class="pagination">
      <div class="container-db">
        <div class="bread-crumbs">
          <router-link to="/">Home</router-link>
          <span class="bread-separator">/</span>
          <span class="bread-current">Remote Administration</span>
        </div>
      </div>
    </div>
    <section class="service-description container-db">
      <div class="service-wrapper">
        <div class="service-text-wrapper">
          <h1>Remote Administration</h1>
          <p>
            When you decide to start a new business or it  is growing up and you need to maintain and setup technical infrastructure inside of your company - DevBrother can help you will all technical related issues that can bring you more time for other important things.
          </p>
        </div>
        <div class="stone-image-wrapper">
            <div class="service-contact">
              <p class="service-contact__title">Free consultation</p>
              <p>We advise choosing the right technology and strategies for developing a new product.</p>
              <p>Let's arrange a short phone or video call? We will listen to your wishes and select a team of
                professionals to implement the product.</p>
              <button @click="showPopup" class="btn form-btn">Contact us</button>
            </div>
          <div class="top-stone mobile">
          </div>
        </div>
      </div>
			<div class="service-text-wrapper">
        <h2>What we do:</h2>
        <ul class="bullet-list two-column-list">
          <li class="marked-item">Set up and monitor Remote Access (VPN)</li>
          <li class="marked-item">Process and solve local issues</li>
          <li class="marked-item">Manage your servers (E-mail, Web, Data)</li>
          <li class="marked-item">Provide Anti-Virus and Anti-Spam Protection</li>
          <li class="marked-item">Provide Security Audit and Consulting for Networks, Servers etc.</li>
          <li class="marked-item">Provide 24×7 Technical Support</li>
        </ul>
				<p>
					You can continue developing your business and leave all IT network and infrastructure to DevBrother - we guarantee it will be well structured, highly protected and stable.				
				</p>
			</div>
    </section>
    <AcquaintedSection />
    <div v-lazyload data-src="/img/images-db/parallax/stone2.webp" class="lazy-bg bottom-stone"></div>

    <div class="popup" :class="{ 'hide-popup': !showModal}">
      <div class="popup-wrapper">
        <div class="popup-inner" @click.self="closePopup()">
          <div class="popup-content" id="service-contact-form">
            <button class="close-popup" @click="closePopup()" type="button" aria-label="close popup"></button>
            <ServicesTopForm />
          </div>
        </div>
      </div>
    </div>

    <script v-html="jsonld" type="application/ld+json"></script>
  </main>
</template>
<script>  
import AcquaintedSection from '../AcquaintedSection';
    import ServicesTopForm from '../ServicesTopForm';
    
    let vm = {};
    export default {
        name: 'RemoteAdministrationRemoteAdministration',
        metaInfo: {
             title: 'Remote Administration Service',
            meta: [
              {
                vmid: "description",
                name: 'description',
                content: 'Remote service administration service. We support the technical infrastructure of your service at the highest level.'
              },
              {
                vmid: "og:image:type",
                name: "og:image:type",
                content: 'image/webp'
              },
              {
                vmid: "og:title",
                name: "og:title",
                content: 'Remote Administration Service'
              },
              {
                vmid: "og:description",
                name: "og:description",
                content: 'Remote service administration service. We support the technical infrastructure of your service at the highest level.' 
              },
              // {
              //   vmid: "og:image",
              //   name: "og:image",
              //   content: 'https://devbrother.com/images/...'
              // },
              {
                vmid: "og:type",
                name: "og:type",
                content: "website"
              },
              {
                vmid: "og:url",
                name: "og:url",
                content: 'https://devbrother.com/services/remote-admin'
              },
              {
                vmid: "twitter:title",
                name: "twitter:title",
                content: 'Remote Administration Service'
              },
              {
                vmid: "twitter:description",
                name: "twitter:description",
                content: 'Remote service administration service. We support the technical infrastructure of your service at the highest level.'
              },
              // {
              //   vmid: "twitter:image",
              //   name: "twitter:image",
              //   content: 'https://devbrother.com/images/...'
              // }
            ],
        },
        components: { 
				  AcquaintedSection,
          ServicesTopForm
				 },
        data() {
          const jsonld =  {
            "@context": "https://schema.org",
            "@type": "Service",
            "name": "Remote administration",
            "description": "Remote administration"
          }

          return {
            jsonld,
            showModal: false,
          }
        },
           beforeCreate(){
            vm = this;
        },
          mounted: () => {
            vm.body = document.querySelector("body");
            vm.addCanonicalLink();
          },
          methods: {
            addCanonicalLink: () => {
              const link = document.createElement('link');
              link.setAttribute('rel', 'canonical');
              link.setAttribute('href', 'https://devbrother.com/services/remote-admin');

              const oldLink = document.querySelector('link[rel="canonical"]');
              if (oldLink) {
                document.head.removeChild(oldLink);
              }

              document.head.appendChild(link);
            },
            showPopup: () => {
              vm.showModal = true;
              vm.body.classList.add('ban-scroll');
            },
            closePopup: () => {
              vm.showModal = false;
              vm.body.classList.remove('ban-scroll');
            },
            scrollFix: hash => {
              setTimeout(() => {
                setTimeout(() => $('html, body')
                .animate({scrollTop: $(hash).offset().top}, 1000), 1)
              });
            },
        }
    }
</script>


