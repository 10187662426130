<template>
  <!--SECTION INDUSTRIES-->
  <main>
    <div class="top-stone">
    </div>
    <div class="pagination">
      <div class="container-db">
        <div class="bread-crumbs">
          <router-link to="/">Home</router-link>
          <span class="bread-separator">/</span>
          <span class="bread-current">UI/UX Design Service</span>
        </div>
      </div>
    </div>
    <section class="service-description container-db">
      <div class="service-wrapper">
        <div class="service-text-wrapper">
          <h1>UI/UX Design Service</h1>
          <p>
            When customers interact with your product, they have a user experience that directly affects your revenue.
          </p>
          <p>
            A good user experience increases loyalty to your business, and you need to design your website from the
            beginning in order to create an experience that leads to expected business results.
          </p>
          <p>
            We provide a full range of UI/UX design services, from design research to high-quality prototyping for your
            product.
          </p>
        </div>
        <div class="stone-image-wrapper">
          <div class="service-contact">
            <p class="service-contact__title">Free consultation</p>
            <p>We advise choosing the right technology and strategies for developing a new product.</p>
            <p>Let's arrange a short phone or video call? We will listen to your wishes and select a team of
              professionals to implement the product.</p>
            <button @click="showPopup" class="btn form-btn">Contact us</button>
          </div>
          <div class="top-stone mobile">
          </div>
        </div>
      </div>
      <div class="service-text-wrapper">
        <h2>Our UX services include</h2>
        <h3>Design audit</h3>
        <p>Design audits are performed by UI/UX experts on a website/app. We will identify flaws in your product
          compared to the ideal perception of this</p>
        <ul class="audit-list">
          <li>Why do you need a design audit?</li>
          <li class="marked-item">A quality audit is aimed to identify flaws in your design and, as a result, correct
            them by professional UI/UX experts.</li>
          <li>User retention</li>
          <li class="marked-item">Understanding customer behavior to identify their wants and expectations.</li>
          <li>Contribution to business</li>
          <li class="marked-item">An expert audit will increase website conversions, engagement, and business ROI.</li>
        </ul>
        <h3>Website Usability Testing Services</h3>
        <p>If you want to learn more about your product, website, service, or application, our team of experts will give
          you a test that will reveal the competitive advantages and help you identify the disadvantages.</p>
      </div>
    </section>

    <section class="faq-section">
      <h2>FAQ</h2>
      <div class="container-db">
        <div id="app" class="">
          <app-accordion @closeAccordions='closeAccordions()' v-for="(accordion, index) in accordions" ref="accordion"
            :key="index" class="app-accordion">
            <template v-slot:title>
              <span class="">{{ accordion.title }}</span>
            </template>
            <template v-slot:content>
              <p>
                {{ accordion.text }}
              </p>
            </template>
          </app-accordion>
        </div>
      </div>
    </section>

    <AcquaintedSection />
    <div v-lazyload data-src="/img/images-db/parallax/stone2.webp" class="lazy-bg bottom-stone"></div>

    <div class="popup" :class="{ 'hide-popup': !showModal }">
      <div class="popup-wrapper">
        <div class="popup-inner" @click.self="closePopup()">
          <div class="popup-content" id="service-contact-form">
            <button class="close-popup" @click="closePopup()" type="button" aria-label="close popup"></button>
            <ServicesTopForm />
          </div>
        </div>
      </div>
    </div>

    <script v-html="jsonld" type="application/ld+json"></script>
  </main>
</template>
<script>
import AcquaintedSection from '../AcquaintedSection';
import ServicesTopForm from '../ServicesTopForm';
import AppAccordion from "./web-development/AppAccordion";

let vm = {};
export default {
  name: 'Design',
  metaInfo: {
    title: 'UI/UX Design Services - Competitive Audit, User Testing',
    meta: [
      {
        vmid: "description",
        name: 'description',
        content: 'Best Company【UI/UX Design Services】. DevBrother offers professional in User Experience & User Interface Agency. Mobile, App, Web and Graphisc Design.'
      },
      {
        vmid: "og:image:type",
        name: "og:image:type",
        content: 'image/webp'
      },
      {
        vmid: "og:title",
        name: "og:title",
        content: 'UI/UX Design Services - Competitive Audit, User Testing'
      },
      {
        vmid: "og:description",
        name: "og:description",
        content: 'Best Company【UI/UX Design Services】. DevBrother offers professional in User Experience & User Interface Agency. Mobile, App, Web and Graphisc Design.'
      },
      // {
      //   vmid: "og:image",
      //   name: "og:image",
      //   content: 'https://devbrother.com/images/...'
      // },
      {
        vmid: "og:type",
        name: "og:type",
        content: "website"
      },
      {
        vmid: "og:url",
        name: "og:url",
        content: 'https://devbrother.com/services/ui-ux-user-testing'
      },
      {
        vmid: "twitter:title",
        name: "twitter:title",
        content: 'UI/UX Design Services - Competitive Audit, User Testing'
      },
      {
        vmid: "twitter:description",
        name: "twitter:description",
        content: 'Best Company【UI/UX Design Services】. DevBrother offers professional in User Experience & User Interface Agency. Mobile, App, Web and Graphisc Design.'
      },
        // {
        //   vmid: "twitter:image",
        //   name: "twitter:image",
        //   content: 'https://devbrother.com/images/...'
        // }
    ]
  },
  components: {
    AcquaintedSection,
    ServicesTopForm,
    AppAccordion
  },
  data() {
    const jsonld = {
      "@context": "https://schema.org",
      "@type": "Service",
      "name": "UI/UX Design Services - Competitive Audit, User Testing",
      "description": "Best Company【UI/UX Design Services】. DevBrother offers professional in User Experience & User Interface Agency. Mobile, App, Web and Graphisc Design."
    }

    return {
      jsonld,
      showModal: false,
      accordions: [
        {
          title: 'When should a design audit be performed?',
          text: "A design audit should be conducted before making major changes to a product, site, or application. An audit can be conducted every 1-2 years, as user behavior and expectations of a digital product change over time, which means you need to follow, match and fit this new experience.",

        },
        {
          title: 'How long does a standard design audit take?',
          text: 'The duration of the audit depends on its scale, the work can take up to 7 days, but some projects need to be analyzed for a month.',
        },
        {
          title: 'Why is user testing an important part of the design process?',
          text: "Usability testing, or user testing, is a stage in the design process that allows you to evaluate your product or service with help of real users and enables you to create human-centered products."
        }
      ],
    }
  },
  beforeCreate() {
    vm = this;
  },
  mounted: () => {
    vm.body = document.querySelector("body");
    vm.addCanonicalLink();
  },
  methods: {
    addCanonicalLink: () => {
      const link = document.createElement('link');
      link.setAttribute('rel', 'canonical');
      link.setAttribute('href', 'https://devbrother.com/services/ui-ux-user-testing');

      const oldLink = document.querySelector('link[rel="canonical"]');
      if (oldLink) {
        document.head.removeChild(oldLink);
      }

      document.head.appendChild(link);
    },
    showPopup: () => {
      vm.showModal = true;
      vm.body.classList.add('ban-scroll');
    },
    closePopup: () => {
      vm.showModal = false;
      vm.body.classList.remove('ban-scroll');
    },
    scrollFix: hash => {
      setTimeout(() => {
        setTimeout(() => $('html, body')
          .animate({ scrollTop: $(hash).offset().top }, 1000), 1)
      });
    },
    closeAccordions() {
      this.$refs.accordion.forEach(el => el.closeAccordion());
    },
  }
}
</script>
<style scoped>
.faq-section {
  position: relative;
  z-index: 11;
  margin-bottom: 150px;
}

.faq-section h2 {
  letter-spacing: 3px;
}

.audit-list{
  margin-bottom: 40px;
}

li:not(.marked-item){
  margin-bottom: 15px;
  font-weight: bold;
}

@media (max-width: 1220px) {
  .faq-section {
    margin-bottom: 100px;
  }
}
</style>
