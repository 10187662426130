<template>
  <!--SECTION INDUSTRIES-->
  <main class="wordpress-section">
    <div class="top-stone">
    </div>
    <div class="pagination">
      <div class="container-db">
        <div class="bread-crumbs">
          <router-link to="/" class="router-link-active">Home</router-link>
          <span class="bread-separator">/</span>
          <router-link to="/services/web-development">Web Development</router-link>
          <span class="bread-separator">/</span>
          <span class="bread-current">WordPress</span>
        </div>
      </div>
    </div>

    <section class="service-description">
      <div class="container-db">
        <div class="service-wrapper">
          <div class="service-text-wrapper">
            <h1>WordPress Development Company in the USA</h1>
            <p>
              We are a team of experienced WordPress developers, offering not only websites but practical tools designed to achieve your business goals. As a leading <router-link to="/services/wordpress-development-company">WordPress development company</router-link> in the USA, our customized solutions will help you attract new customers, boost sales, and strengthen your brand. Contact us today for a free consultation!</p>
          </div>

          <div class="stone-image-wrapper">
            <div class="service-contact">
              <p class="service-contact__title">Free consultation</p>
              <p>We advise choosing the right technology and strategies for developing a new product.</p>
              <p>Let's arrange a short phone or video call? We will listen to your wishes and select a team of
                professionals to implement the product.</p>
              <button @click="showPopup" class="btn form-btn">Contact us</button>
            </div>
            <div class="top-stone mobile">
            </div>
          </div>
        </div>
        <div class="service-text-wrapper">
          <h2>What is a WordPress Development Agency?</h2>
          <p>
            A WordPress development agency in the USA specializes in building, customizing, and maintaining WordPress websites. We offer a wide range of WordPress development services in the USA, tailored to meet the needs of businesses of all sizes. Whether it's creating visually stunning themes or developing complex plugins and e-commerce stores, our outsourcing WordPress agency in the USA provides solutions that match your specific goals.
          </p>
        </div>
        <section class="integration-section service-description">
          <div class="service-wrapper custom-service-wrapper">
            <div class="service-text-wrapper">
              <h2 class="custom-development-header">WordPress Development Services in the USA</h2>
            </div>
          </div>
          <div class="benefits">
            <div class="inner-container">
              <ul class="benefits-list">
                <li class="benefits-list-item">
                  <div class="inner-benefits-item">
                    <h3>Custom WordPress Website Development.</h3>
                    <p>
                      We build unique and fully functional WordPress websites that align with your brand's identity and business objectives. Our skilled developers ensure optimal performance and user experience.
                    </p>
                  </div>
                </li>
                <li class="benefits-list-item">
                  <div class="inner-benefits-item">
                    <h3>Theme Development & Customization.</h3>
                    <p>Our design services provide beautiful, customizable WordPress themes that reflect your brand’s aesthetic. We also offer theme modification services, ensuring your website meets your requirements.</p>
                  </div>
                </li>
                <li class="benefits-list-item">
                  <div class="inner-benefits-item">
                    <h3>WooCommerce Development.</h3>
                    <p>As a WooCommerce development company in the USA, we specialize in building scalable WooCommerce online stores. From setting up stores and integrating payment gateways to optimizing conversions, we've got you covered.</p>
                  </div>
                </li>
                <li class="benefits-list-item">
                  <div class="inner-benefits-item">
                    <h3>WordPress Plugin Development Services.</h3>
                    <p>Extend WordPress functionality with custom plugins. Our developers create tailored plugins to enhance user experience and meet your business needs.</p>
                  </div>
                </li>
                <li class="benefits-list-item">
                  <div class="inner-benefits-item">
                    <h3>Blog Development.</h3>
                    <p>We help create engaging blogs that attract and retain your target audience, with services including content creation, SEO optimization, and design integration.</p>
                  </div>
                </li>
                <li class="benefits-list-item">
                  <div class="inner-benefits-item">
                    <h3>API Integration & Customization.</h3>
                    <p>Our developers seamlessly integrate third-party APIs, such as payment gateways and CRM systems, into your WordPress website to expand its functionality.</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>

        </section>
        <section>
          <div class="container-db">
            <div class="service-wrapper">
              <div class="service-text-wrapper">
                <h2>Additional Custom WordPress Development Services</h2>
                <h3>Mobile-first Website Design</h3>
                <p>Our WordPress website development company in the USA ensures mobile-friendly design, providing an optimal user experience across devices.</p>
                <h3>Conversion Rate Optimization (CRO)</h3>
                <p>As a custom WordPress development company in the USA, we analyze your website’s performance and implement strategies to drive conversions. Our experts optimize your site to boost leads and sales.</p>
                <h3>Search Engine Optimization (SEO)</h3>
                <p>We optimize your website for search engines, helping improve visibility and drive organic traffic.</p>
              </div>
            </div>
          </div>
        </section>
        <div class="service-text-wrapper our-web-related-technologies">
          <h2>Our Technologies for WordPress Development</h2>
          <p>
            We use cutting-edge tools and technologies to build exceptional WordPress websites, including:
          </p>
          <div class="three-list-wrapper">
            <div class="list-wrapper">
              <ul class="bullet-list">
                <li class="marked-item">Visual Studio Code (VSCode)</li>
                <li class="marked-item">Git and GitHub</li>
              </ul>
            </div>

            <div class="list-wrapper">
              <ul class="bullet-list">
                <li class="marked-item">WooCommerce</li>
                <li class="marked-item">WP-CLI</li>
                <li class="marked-item">Webpack</li>
              </ul>
            </div>

            <div class="list-wrapper">
              <ul class="bullet-list">
                <li class="marked-item">Composer</li>
                <li class="marked-item">PHP</li>
                <li class="marked-item">JavaScript (jQuery, React)</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <section class="industries-section service-description service-process">
          <div class="container-db">
            <div class="service-wrapper">
              <div class="service-text-wrapper service-steps">
                <h2>WordPress Website Development Company Process</h2>
                <p>Our development process is structured to deliver the best results:</p>
                <ol>
                  <li><span class="marked">Planning and Discovery</span> We collaborate with you to understand your goals, target audience, and project requirements.</li>
                  <li><span class="marked">Information Architecture and User Experience Design</span> We create user-friendly interfaces and an intuitive site structure.</li>
                  <li><span class="marked">Website Development and Customization</span> Our development team builds and customizes your WordPress site according to your specifications.</li>
                  <li><span class="marked">Testing and Quality Assurance</span> We ensure functionality and performance across all devices.</li>
                </ol>
              </div>
            </div>
          </div>
        </section>

      <div class="popular-request">
        <div class="popular-request-wrapper container-db">
          <div class="service-text-wrapper requests">
            <h2>Why Choose Our WordPress Development Services in the USA?</h2>
            <ul class="bullet-list">
              <li class="marked-item">
                <span>Specialized Expertise in WordPress.</span> <router-link to="/about">Our team</router-link> stays updated on the latest WordPress trends.
              </li>
              <li class="marked-item">
                <span>Innovative Custom Solutions.</span> We create solutions that address your unique business needs.
              </li>
              <li class="marked-item">
                <span>Thorough Quality Control.</span> We adhere to strict quality standards to ensure error-free, secure, and high-performing websites.
              </li>
              <li class="marked-item">
                <span>Customer-first Communication.</span> We maintain open communication throughout the development process.
              </li>
              <li class="marked-item">
                <span>Fast and Reliable Delivery.</span> Projects are completed on time and within budget.
              </li>
              <li class="marked-item">
                <span>Comprehensive Post-launch Support.</span> We provide ongoing support and maintenance for your site.
              </li>
            </ul>
          </div>
        </div>
        <div class="popular-request-img">
          <img v-lazyload data-url="/img/images-db/services/wordpress.jpg" alt="Wordpress Website Development" width="715" height="500">
        </div>
      </div>
    </section>
    <section class="faq-section">
      <h2>Frequently Asked Questions</h2>
        <div class="container-db">
          <div v-lazyload data-src="/img/images-db/services/lite-coin.png" class="lazy-bg bg-icon">
          </div>
          <div id="app">
            <app-accordion @closeAccordions='closeAccordions()' v-for="(accordion, index) in accordions"
                        ref="accordion" :key="index" class="app-accordion">
              <template v-slot:title>
                <span>{{ accordion.title }}</span>
              </template>
              <template v-slot:content>
                <p>
                  {{ accordion.text }}
                </p>
              </template>
            </app-accordion>
          </div>
        </div>
    </section>

    <h2 id="questions" class="more-questions">If you have any more questions, write to us</h2>
    <AcquaintedSection />
    <div v-lazyload data-src="/img/images-db/parallax/stone2.webp" class="lazy-bg bottom-stone"></div>

    <div class="popup" :class="{ 'hide-popup': !showModal }">
      <div class="popup-wrapper">
        <div class="popup-inner" @click.self="closePopup()">
          <div class="popup-content" id="service-contact-form">
            <button class="close-popup" @click="closePopup()" type="button" aria-label="close popup"></button>
            <ServicesTopForm />
          </div>
        </div>
      </div>
    </div>

    <div>
      <div class="container-db">
        <div class="service-wrapper">
          <div class="service-text-wrapper service-description final-text">
            For reliable WordPress development services in the USA, contact our team today! Whether you’re looking for custom WordPress development or assistance from a WordPress agency in the USA, we’re here to help.
          </div>
        </div>
      </div>
    </div>

    <script v-html="jsonld" type="application/ld+json"></script>
  </main>
</template>
<script>
import AcquaintedSection from '../../AcquaintedSection';
import ServicesTopForm from '../../ServicesTopForm';
import AppAccordion from "./AppAccordion";

let vm = {};
export default {
  name: 'WordpressUsa',
  metaInfo: {
    title: 'Top WordPress Development Company in the USA | Custom WordPress Solutions',
    meta: [
      {
        vmid: "description",
        name: 'description',
        content: 'Looking for a reliable WordPress development company in the USA? Our expert team offers custom WordPress development to help grow your business. Get a free consultation today!'
      },
      {
        vmid: "og:image:type",
        name: "og:image:type",
        content: 'image/webp'
      },
      {
        vmid: "og:title",
        name: "og:title",
        content: 'Top WordPress Development Company in the USA | Custom WordPress Solutions'
      },
      {
        vmid: "og:description",
        name: "og:description",
        content: 'Looking for a reliable WordPress development company in the USA? Our expert team offers custom WordPress development to help grow your business. Get a free consultation today!'
      },
      // {
      //   vmid: "og:image",
      //   name: "og:image",
      //   content: 'https://devbrother.com/img/images-db/images/e-commerce.webp'
      // },
      {
        vmid: "og:type",
        name: "og:type",
        content: "website"
      },
      // {
      //   vmid: "og:url",
      //   name: "og:url",
      //   content: 'https://devbrother.com/services/web-development'
      // },
      {
        vmid: "twitter:title",
        name: "twitter:title",
        content: 'Top WordPress Development Company in the USA | Custom WordPress Solutions'
      },
      {
        vmid: "twitter:description",
        name: "twitter:description",
        content: 'Looking for a reliable WordPress development company in the USA? Our expert team offers custom WordPress development to help grow your business. Get a free consultation today!'
      },
      // {
      //   vmid: "twitter:image",
      //   name: "twitter:image",
      //   content: 'https://devbrother.com/img/images-db/images/e-commerce.webp'
      // }
    ]
  },
  components: {
    AcquaintedSection,
    ServicesTopForm,
    AppAccordion,
  },
  data() {
    const jsonld = {
      "@context": "https://schema.org",
      "@type": "Service",
      "name": "Top WordPress Development Company in the USA | Custom WordPress Solutions",
      "description": "Looking for a reliable WordPress development company in the USA? Our expert team offers custom WordPress development to help grow your business. Get a free consultation today!"
    }

    return {
      jsonld,
      showModal: false,
      accordions: [
        {
          title: 'Is coding required to manage a WordPress website?',
          text: "No, WordPress can be managed without coding. The platform provides a user-friendly interface for content updates. For advanced customization, coding may be needed."
        },
        {
          title: 'How long does it take to build a customized WordPress website?',
          text: "It takes 2-12 weeks, depending on complexity."
        },
        {
          title: 'What benefits can PWA integration bring to mobile efficiency?',
          text: "PWA improves speed, offline access, and app-like performance, enhancing mobile user experience."
        },
        {
          title: 'Which platform is best for startups: WooCommerce, Shopify, or Magento?',
          text: "WooCommerce is best for flexibility; Shopify is simpler, while Magento suits larger businesses."
        },
        {
          title: 'Can we integrate ERP or CRM tools with WordPress?',
          text: "Yes, ERPs and CRMs can be integrated with WordPress using plugins or APIs."
        },
      ],
    }
  },
  beforeCreate() {
    vm = this;
  },
    mounted: () => {
            vm.body = document.querySelector("body");
            vm.addCanonicalLink();
    },
    methods: {
            addCanonicalLink: () => {
              const link = document.createElement('link');
              link.setAttribute('rel', 'canonical');
              link.setAttribute('href', 'https://devbrother.com/services/wordpress-development-company/united-states');

              const oldLink = document.querySelector('link[rel="canonical"]');
              if (oldLink) {
                document.head.removeChild(oldLink);
              }

              document.head.appendChild(link);
            },
            showPopup: () => {
              vm.showModal = true;
              vm.body.classList.add('ban-scroll');
            },
            closePopup: () => {
              vm.showModal = false;
              vm.body.classList.remove('ban-scroll');
            },
            scrollFix: hash => {
              setTimeout(() => {
                setTimeout(() => $('html, body')
                  .animate({ scrollTop: $(hash).offset().top }, 1000), 1)
              });
            },
            closeAccordions() {
              this.$refs.accordion.forEach(el => el.closeAccordion());
            },
  }
}
</script>
<style lang="scss" scoped>
.wordpress-section {
  .three-list-wrapper {
    justify-content: flex-start;

    .list-wrapper {
      margin-bottom: 15px;
    }
  }

  .faq-section {
    margin-bottom: 100px;
    z-index: 11;
    position: relative;
  }

  .more-questions {
    text-align: center;
    margin-bottom: 100px;
    position: relative;
    z-index: 11;
  }

  .inner-benefits-item {
    color: #fff;
    font-family: Roboto;
    font-size: 17px;
    letter-spacing: .57px;
    line-height: 19px;
    padding: 14px 23px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    height: 100%;
    box-sizing: border-box;
    border: 1px solid #1e242a;
    background-color: #2b506e;
    border-radius: 2px;
  }

  .benefits-list {
    flex-wrap: wrap;
    justify-content: space-around;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    width: 100%;
  }

  .benefits-list-item {
    width: 48%;
    margin-bottom: 25px;
    min-height: 180px;
    border: 1px solid #06c6ff;
    border-radius: 2px;
    padding: 4px 3px;
    min-width: 280px;
  }

  .custom-service-wrapper {
    display: flex;
    justify-content: center;
    text-align: center;

    .service-text-wrapper {
      margin-bottom: 50px;
    }
  }

  .service-process {
    margin-top: -50px;
    padding-bottom: 0px;
  }
}

.final-text {
  max-width: 1200px;
}
</style>
