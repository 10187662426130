<template>    <!--SECTION INDUSTRIES-->
<main>
			<div class="promotaion health">
				<div class="container-db bread-container">
					<div class="bread-crumbs">
						<router-link to="/">Home</router-link>
						<span class="bread-separator">/</span>
						<span class="bread-current">HealthCare</span>
					</div>
				</div>
				<div class="container-db">
					<h1>HealthCare <br />DoctorMed
					</h1>
				</div>
			</div>
			<div class="case-container challenge">
				<h2>Overview</h2>
				<p>DoctorMed is one of the biggest online booking and searching for doctors platform for European market. DoctorMed founders decided to build analog of famous USA platform - ZocDoc but to make it even more advanced, interesting and with some special features, like online video chats with doctors.</p>
				<p>DoctorMed contacted DevBrother from the very beginning of planning their idea. As we’ve already built 2 similar projects - our experience was very helpful and we already had several modules that we could easily integrate into new platform.</p>
				<p>*All names and business details of the project were changed according to NDA rules</p>
			</div>
			<div class="descrip-result-wrapper">
				<div class="descrip-result-text">
					<div class="container-db">
						<div class="description">
							<h2>Description</h2>
							<p>
								We had to build the big online platform with several user roles (admin, clinic, doctor, user, manager). The project had to be scalable with lots of modules like: searching module, ratings-reviews  system, online booking, online video chats, health calendar, catalogs system, several admin panels, flexible payment system with different subscriptions plans. Everything was built from scratch, with design, markup, DB architecture, backend and frontend structure
							</p>
							<p>
								Also we’ve had to built mobile application for Android and iOS. To reduce development and maintenance cost for mobile applications we’ve decided to implement this part of project with help of Cordova framework - to implement application for both mobile platforms with minimum cost.
							</p>
						</div>
						<div class="result">
							<h2>Result</h2>
							<p>
								The web portal and mobile applications were successfully launched on the market in 2018. Nowadays it has hundreds of active users and DoctorMed team pushing marketing campaigns for involving new users/doctors and clients to the platform. Server side structure was implemented on AWS with Load Balancer and other features that will be working with highest security and stable standards in production mode.
							</p>
						</div>
					</div>
				</div>
				<img v-lazyload data-url="/img/images-db/case-studies/solutions.webp" alt="solutions.webp" width="945" height="665">
			</div>
			<div class="tech-features-section">
				<div class="container-db">
					<div class="tech-features-wrapper">
						<div class="technologies-wrapper">
							<h2>Technologies</h2>
							<ul>
								<li class="marked-item">PHP/Laravel</li>
								<li class="marked-item">HTML5, CSS3, Bootstrap, VueJS</li>
								<li class="marked-item">MySQL</li>
								<li class="marked-item">AWS, DropBox, PCloud, LastPass</li>
							</ul>
						</div>
						<div class="features">
							<h2>Features</h2>
							<ul>
								<li>Online  platform for European market with search, ratings, booking, online video chats, health calendar and other necessary modules for doctors, clinics and patients</li>
								<li>Web platform + Mobile applications Andoid/iOS</li>
								<li>Best implementation solutions for reducing development costs</li>
							</ul>
						</div>

					</div>
				</div>
			</div>
			<div class="relevant-case-studies">
				<div class="container-db">
					<h2>portfolio</h2>
					<div class="slider-container">
						<div class="relevant-case-studies-slider">
							<slick ref="slick" :options="slickOptions">
								<div class="case-slider-item">
									<router-link to="e-commerce">
										<div class="case-studies-item">
										<div class="case-studies-img-wrapper">
											<img src="/img/images-db/case-studies/ecommerce.webp" alt="E-commerce" width="300" height="334">
										</div>
										<h3>E-commerce</h3>
									</div>
									</router-link>
								</div>
								<div class="case-slider-item">
									<router-link to="wordpress-dev">
										<div class="case-studies-item">
										<div class="case-studies-img-wrapper">
											<img src="/img/images-db/case-studies/wp3.webp" alt="wordpress development" width="300" height="334">
										</div>
										<h3>WordPress<br>Websites and Plugins Development</h3>
									</div>
									</router-link>
								</div>
								<div class="case-slider-item">
									<router-link to="online-event">
										<div class="case-studies-item">
										<div class="case-studies-img-wrapper">
											<img src="/img/images-db/case-studies/event.webp" alt="Online Tickets  Printing" width="300" height="334">
										</div>
										<h3>Online Tickets  Printing</h3>
									</div>
									</router-link>
								</div>
								<div class="case-slider-item">
									<router-link to="cryptocurrency">
										<div class="case-studies-item">
										<div class="case-studies-img-wrapper">
											<img src="/img/images-db/case-studies/crypto.webp" alt="Cryptocurrency" width="300" height="334">
										</div>
										<h3>Cryptocurrency</h3>
									</div>
									</router-link>
								</div>
								<div class="case-slider-item">
									<router-link to="art-galleries">
										<div class="case-studies-item">
										<div class="case-studies-img-wrapper">
											<img src="/img/images-db/case-studies/art.webp" alt="Art Galleries" width="300" height="334">
										</div>
										<h3>Art Galleries</h3>
									</div>
									</router-link>
								</div>
							</slick>
						</div>
					</div>
				</div>
			</div>
      <AcquaintedSection />
      <div v-lazyload data-src="/img/images-db/parallax/stone2.webp" class="lazy-bg bottom-stone"></div>
			<script v-html="jsonld" type="application/ld+json"></script>
    </main>
</template>
<script>

	import Slick from 'vue-slick';
	import AcquaintedSection from '../AcquaintedSection'

	export default {
			name: 'Healthcare',
			metaInfo: {
			    title: 'Case: Creation of a major European service for finding doctors and making appointments with them',
            	meta: [
                	{
					vmid: "description",
                    name: 'description',
                    content: 'Successful case of creating a large service (software) for doctors and patients to find and make an appointment with a doctor.'
                	},
				{
					vmid: "og:image:type",
					name: "og:image:type",
					content: 'image/webp'
				},
				{
					vmid: "og:title",
					name: "og:title",
					content: 'Case: Creation of a major European service for finding doctors and making appointments with them'
				},
				{
					vmid: "og:description",
					name: "og:description",
					content: 'Successful case of creating a large service (software) for doctors and patients to find and make an appointment with a doctor.'
				},
				{
				  vmid: "og:image",
				  name: "og:image",
				  content: 'https://devbrother.com/img/images-db/case-studies/solutions.webp'
				},
				{
					vmid: "og:type",
					name: "og:type",
					content: "website"
				},
				{
					vmid: "og:url",
					name: "og:url",
					content: 'https://devbrother.com/cases/healthcare'
				},
				{
					vmid: "twitter:title",
					name: "twitter:title",
					content: 'Case: Creation of a major European service for finding doctors and making appointments with them'
				},
				{
					vmid: "twitter:description",
					name: "twitter:description",
					content: 'Successful case of creating a large service (software) for doctors and patients to find and make an appointment with a doctor.'
				},
				{
					vmid: "twitter:image",
					name: "twitter:image",
					content: 'https://devbrother.com/img/images-db/case-studies/solutions.webp'
				}
            	],
      },
			components: {
				Slick,
				AcquaintedSection
				 },
			mounted() {
				this.addCanonicalLink();
			},
			methods: {
					addCanonicalLink: () => {
						const link = document.createElement('link');
						link.setAttribute('rel', 'canonical');
						link.setAttribute('href', 'https://devbrother.com/cases/healthcare');

						const oldLink = document.querySelector('link[rel="canonical"]');
						if (oldLink) {
							document.head.removeChild(oldLink);
						}

						document.head.appendChild(link);
					},
			},
			data(){
				const jsonld =  {
					"@context": "https://schema.org",
					"@type": "IndividualProduct",
					"name": "HealthCare",
					"description": "HealthCare"
				}
				return {
				jsonld,
				slickOptions: {
            prevArrow: '<div class="prev-arrow"><img src="/img/images-db/Arrow.svg" alt="arrow" class="arrow" width="26" height="11"/><img src="/img/images-db/Arrow-hover.svg" alt="arrow" class="arrow-hover" width="26" height="11"/></div>',
            nextArrow: '<div class="next-arrow"><img src="/img/images-db/Arrow.svg" alt="arrow" class="arrow" width="26" height="11"/><img src="/img/images-db/Arrow-hover.svg" alt="arrow" class="arrow-hover" width="26" height="11"/></div>',
						slidesToShow: 3,
						slidesToScroll: 1,
						// centerMode: true,
						responsive: [
							{
								breakpoint: 769,
								settings: {
									slidesToShow: 1,
									centerMode: false,
									prevArrow: '',
									nextArrow: '',
									dots: true,
									customPaging: function(slider, i) {
										return '<div class="slick-mobile-dot"></div>';
									}
								}
							}
						]
                // Any other options that can be got from plugin documentation
            },
			 }
			},
	}
</script>
