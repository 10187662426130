  <template>
    <main class="web-reactjs">
        <div class="top-stone">
        </div>
        <div class="pagination">
            <div class="container-db">
                <div class="bread-crumbs">
                    <router-link to="/" class="router-link-active">Home</router-link>
                    <span class="bread-separator">/</span>
                    <router-link to="/services/web-development">Web Development</router-link>
                    <span class="bread-separator">/</span>
                    <span class="bread-current">ReactJS</span>
                </div>
            </div>
        </div>

        <section class="top-form-section service-description">
            <div class="container-db">
                <div class="service-wrapper">
                    <div class="service-text-wrapper">
                        <h1>React JS Development Services</h1>
                        <p>Companies like Facebook, Uber, Airbnb, Netflix, Amazon, Twitter, Udemy, and nearly 9,000 others
                          worldwide use JavaScript for web, desktop, and mobile applications. And all draw from the same pool
                          of talent, which is not exactly limitless. So yes - your problem is quite real.</p>
                        <p>Ordering React JS Development Company from DevBrother, you get:</p>
                        <ol>
                            <li>Virtual DOM can improve the performance of heavily loaded applications, which can reduce
                              the likelihood of possible inconvenience and enhance the user experience;</li>
                            <li>Using an isomorphic approach helps render pages faster, making users feel more comfortable
                              using your application. Search engines index such pages better. Since the same code can be
                              used in both the client and server side of the application front end, there is no need to
                              duplicate the same functionality;</li>
                            <li>Code reuse makes it much easier to create mobile applications. The code written during
                              the website's creation can be used again to create a mobile app.</li>
                        </ol>
                        <p>Development company DevBrother will solve problems with the following:</p>
                        <ul>
                            <li class="marked-item">Improving the user experience of your sites and apps.</li>
                            <li class="marked-item">Increasing development company speed.</li>
                            <li class="marked-item">Using the most trending ReactJS services and technologies.</li>
                            <li class="marked-item">Work with the site's server part or develop the interfaces of mobile
                              applications. React is used with tools that adapt web technologies for other purposes in such cases.</li>
                            <li class="marked-item">Refinement of new interface functionality with any of the original
                              technology stacks: the framework is independent of the rest of the toolkit. It will work well,
                              no matter what the code is written on.</li>
                            <li class="marked-item">Easy development of complex program structures - you can describe
                              them without effort if you use the React approach.</li>
                            <li class="marked-item">Fast implementation of individual components and entire pages - elements
                              in React are simple to reuse.</li>
                            <li class="marked-item">To create functional and interactive web interfaces that do not constantly
                              require you to refresh the page.</li>
                        </ul>
                    </div>
                    <div id="contact-service">
                        <form v-on:submit.prevent="sendMail()" action="#" novalidate="novalidate"
                            class="acquainted-form">
                            <div>
                                <p class="form-title">Get Consultation Now</p>
                                <p class="form-title">Let's Discuss to Build Something Great Together</p>
                                <div class="name-email-area">
                                    <p class="name-label">
                                        <label for="name">Name</label>
                                        <input v-model="mailData.name" type="text" required name="name" id="name"
                                            @focus="raiseLabelUp('name')"
                                            @blur="checkForEmptiness('name')" />
                                        <span class="error-message" :style="styles.name.span">Please enter the
                                            name</span>
                                    </p>
                                    <p class="email-label">
                                        <label for="email">Email</label>
                                        <input v-model="mailData.email" type="email" required name="email" id="email"
                                            @click="raiseLabelUp('email')"
                                            @blur="checkForEmptiness('email')" />
                                        <span class="error-message" :style="styles.email.span">Please enter the email in
                                            valid format</span>
                                    </p>
                                </div>

                                <p>
                                    <label for="text" id="text">Message</label>
                                    <textarea v-model="mailData.comment" name="comment" id="comment" cols="30" rows="3"
                                        aria-label="project description" required @focus="raiseLabelUp('comment')"
                                        @blur="checkForEmptiness('comment')"></textarea>
                                    <span :style="styles.comment.span">Please enter your message</span>
                                </p>
                                <button type="submit" class="btn form-btn" aria-label="submit form">Contact us</button>
                            </div>
                        </form>
                        <img v-lazyload class="top-icon" data-url="/images/react-icon.webp" alt="reactjs icon"
                            width="300" height="300">
                    </div>
                </div>
            </div>
        </section>

        <section class="dedicated-section service-description service-dedicated">
            <div v-lazyload data-src="/img/images-db/images/bg_img/img v8-4_0000.webp" class="lazy-bg team-bg_services">
            </div>
            <div class="container-db">
                <div class="service-wrapper">
                    <div class="service-text-wrapper">
                        <h2>Hire Dedicated ReactJS Developers</h2>
                        <p>
                          Today, if you want to find the right programmer for your needs, it's not enough to choose developers
                          with a long list of technologies on their resumes. The programmer should have a portfolio with
                          finished works and some examples. One must be able to do what the customer wants.
                        </p>
                        <p>
                          Before you hire someone to work on your project, you need to understand the basics of React js
                          web development services. This will ensure that you have the right expectations of what the job
                          entails and what kind of experience the professional should have to achieve the overall goal
                          of your project management.
                        </p>
                        <p>
                          Why is it better to hire a whole team rather than one freelancer? If a freelancer realizes she
                          they are doing a job poorly, they will simply leave, most likely without feedback. The freelancer
                          needs help or support from the team. However, the pros of hiring a company are much higher.
                        </p>
                        <p>
                          They will work cohesively. If something needs to be clarified, you can always ask for help.
                          The outsourced React JS <router-link to="/" class="router-link-active">
                          <span class="marked">development company</span>
                          </router-link> always provides support and feedback. Even at the end of the project, you can
                          always get feedback and solve sudden problems.
                        </p>
                    </div>
                    <div class="service-img-wrapper"><img v-lazyload data-url="/img/images-db/services/office-team.webp"
                            alt="developers" height="307" width="460">
                    </div>
                </div>
            </div>
        </section>

        <section class="engagement-section service-description">
            <div v-lazyload data-src="/img/images-db/images/bg_img/img v8-3_0000.webp" class="lazy-bg team-bg_services">
            </div>
            <div class="container-db">
                <div class="service-wrapper">
                    <div class="service-text-wrapper">
                        <h2>Engagement Model to Hire ReactJS Developers</h2>
                        <p>Work comes in a variety of time patterns hire React developer. There are three choices of
                            work and their particularities. Some work longer hours and some work shorter hours.
                        </p>
                    </div>
                </div>
                <ul class="benefits-list">
                    <li class="benefits-list-item">
                        <div class="inner-benefits-item">
                            <div class="img-wrapper">
                                <img v-lazyload data-url="/img/images-db/services/hourly-team.webp" alt="icon"
                                    height="55" width="55">
                            </div>
                            <h3>Hourly Hiring:</h3>
                            <ul>
                                <li class="marked-item">prefer better half for your business</li>
                                <li class="marked-item">duration: 8 hrs/day - 5 days a week (160 hrs per the month)</li>
                                <li class="marked-item">billing: weekly/daily/monthly</li>
                                <li class="marked-item">timeline: based on the project</li>
                            </ul>
                        </div>
                    </li>

                    <li class="benefits-list-item">
                        <div class="inner-benefits-item">
                            <div class="img-wrapper">
                                <img v-lazyload data-url="/img/images-db/services/team-out-of-place.svg" alt="icon"
                                    height="45" width="45">
                            </div>
                            <h3>Full-Time Hiring:</h3>
                            <ul>
                                <li class="marked-item">only works for you</li>
                                <li class="marked-item">duration: 8 hrs/day - 5 days a week</li>
                                <li class="marked-item">billing: monthly</li>
                                <li class="marked-item">timeline: based on the project</li>
                            </ul>
                        </div>
                    </li>
                    <li class="benefits-list-item">
                        <div class="inner-benefits-item">
                            <div class="img-wrapper">
                                <img v-lazyload data-url="/img/images-db/services/full-time.webp" alt="icon" height="45"
                                    width="45">
                            </div>
                            <h3>Part-Time Hiring:</h3>
                            <ul>
                                <li class="marked-item">need a few hours of attention to the work</li>
                                <li class="marked-item">duration: 4 hrs/day - 5 days a week (80 hrs per the month)</li>
                                <li class="marked-item">billing: monthly</li>
                                <li class="marked-item">timeline: based on the project</li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </div>
            <a href="#questions" class="btn hoverItem" v-smooth-scroll>Let’s talk</a>
        </section>

        <section class="integration-section service-description">
            <div class="container-db">
                <div class="service-wrapper">
                    <div class="service-text-wrapper">
                        <h2>React JS Software Integration</h2>
                    </div>
                </div>
                <div class="benefits">
                    <div class="inner-container">
                        <ul class="benefits-list">
                            <li class="benefits-list-item">
                                <div class="inner-benefits-item">
                                    <h3>ReactJS + Typescript</h3>
                                    <p>The backed React+Typescript bundle is currently the most
                                        popular for creating client-side applications.</p>
                                    <p>This combination has a huge
                                        potential and allows to write applications of absolutely any complexity.</p>
                                </div>
                            </li>
                            <li class="benefits-list-item">
                                <div class="inner-benefits-item">
                                    <h3>ReactJS + Django</h3>
                                    <p>Add React to your INSTALLED_APPS and specify some
                                        configuration. The ability to deploy to the product and staging with minimal
                                        configuration changes from the development environment.</p>
                                    <p>The development environment should not imply complex configuration. Ideally,
                                        everything should work through a single port which is created through the
                                        runserver.</p>
                                </div>
                            </li>
                            <li class="benefits-list-item">
                                <div class="inner-benefits-item">
                                    <h3>ReactJS + Node.JS</h3>
                                    <p>NodeJS is a Server-side technology that helps generate HTML
                                        returns to the user's browser, it is similar to other technologies like PHP,
                                        Java Servlet/JSP.</p>
                                    <p>The difference here is that NodeJS uses Javascript to write
                                        code, so you only need to know Javascript and you can program applications from
                                        2 sides Client & Server.</p>
                                </div>
                            </li>
                            <li class="benefits-list-item">
                                <div class="inner-benefits-item">
                                    <h3>ReactJS + Spring Boot</h3>
                                    <p>All the files you need are in the same project, so you end up with a monolithic
                                        application. Easy and convenient infrastructure. In essence, we only need one
                                        application server. Build a project back through one utility (e.g., maven),
                                        which in turn will cause the front to be built through NPM. In total, only one
                                        utility is run.</p>
                                    <p>No problems with calls to endpoints. (Sometimes, when the front
                                        end is spinning separately and accessing an open API, you may need an additional
                                        CORS setup).</p>
                                </div>
                            </li>
                            <li class="benefits-list-item">
                                <div class="inner-benefits-item">
                                    <h3>ReactJS + Bootstrap</h3>
                                    <p>React-Bootstrap is a complete reimplementation of Bootstrap components using
                                        React. It doesn't depend on either bootstrap.js or jQuery. If you have React
                                        setup and React-Bootstrap installed, you have everything you need.</p>
                                    <p>Thus,
                                        React-Bootstrap provides a more robust solution by incorporating Bootstrap
                                        functionality into the React virtual DOM.</p>
                                </div>
                            </li>
                            <li class="benefits-list-item">
                                <div class="inner-benefits-item">
                                    <h3>ReactJS + Firebase</h3>
                                    <p>In conclusion, Firebase has already revolutionized the way developers test and
                                        use hosting as a service.</p>
                                    <p>It is a much simpler and easier way to efficiently
                                        host applications without having to go into the details and intricacies of the
                                        hosting setup.</p>
                                </div>
                            </li>
                            <li class="benefits-list-item">
                                <div class="inner-benefits-item">
                                    <h3>ReactJS + PHP</h3>
                                    <p>Since PHP is a server-side scripting language, integration
                                        with React can take several forms:</p>
                                    <ul>
                                        <li class="marked-item">Using React-PHP-v8js</li>
                                        <li class="marked-item">Server-side request/response processing (with a router
                                            like Alto)</li>
                                        <li class="marked-item">JSON output via json_encode()</li>
                                    </ul>
                                    <p>The power of combining React with any server-side scripting language is the
                                        ability to feed React with data and apply your business logic on both the server
                                        and client sides. Updating an old application into a Reactive application has
                                        never been easier!</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>

        <section class="industries-section service-description">
            <div class="container-db">
                <div class="service-wrapper">
                    <div class="service-text-wrapper">
                        <h2>Industries for which we make ReactJS Services</h2>
                        <p>We have delivered ReactJS solutions for clients hailing from diverse industry verticals</p>
                    </div>
                </div>
                <ul class="cards-wrapper">
                    <li>
                        <p class="image-wrapper">
                            <img v-lazyload data-url="/img/images-db/services/arts-icon.webp" alt="Art Galleries"
                                height="70" width="70">
                        </p>
                        Art Galleries
                    </li>
                    <li>
                        <router-link to="/industries/healthcare-software-development">
                        <p class="image-wrapper">
                            <img v-lazyload data-url="/img/images-db/services/healthcare-icon.webp" alt="HealthCare"
                                height="70" width="70">
                        </p>
                        HealthCare
                    </router-link>
                    </li>
                    <li>
                        <p class="image-wrapper">
                            <img v-lazyload data-url="/img/images-db/services/wordpress-icon.webp" alt="WP Development"
                                height="70" width="70">
                        </p>
                        WP Development
                    </li>
                    <li>
                        <p class="image-wrapper">
                            <img v-lazyload data-url="/img/images-db/services/ticket-icon.webp" alt="Eventing/Ticketing"
                                height="70" width="70">
                        </p>
                        Eventing/Ticketing
                    </li>
                    <li>
                        <p class="image-wrapper">
                            <img v-lazyload data-url="/img/images-db/services/bitcoin-icon.webp" alt="Cryptocurrency"
                                height="70" width="70">
                        </p>
                        Cryptocurrency
                    </li>
                    <li>
                        <p class="image-wrapper">
                            <img v-lazyload data-url="/img/images-db/services/e-commerce-icon.webp" alt="E-Commerce"
                                height="70" width="70">
                        </p>
                        E-Commerce
                    </li>
                    <li>
                        <p class="image-wrapper">
                            <img v-lazyload data-url="/img/images-db/services/finance-icon.webp" alt="Finance"
                                height="70" width="70">
                        </p>
                        Finance
                    </li>
                    <li>
                        <p class="image-wrapper">
                            <img v-lazyload data-url="/img/images-db/services/fedex3-icon.webp" alt="Logistics"
                                height="70" width="70">
                        </p>
                        Logistics
                    </li>
                </ul>
            </div>
        </section>

        <section class="technologies-section service-description">
            <div v-lazyload data-src="/img/images-db/images/bg_img/img v8-3_0000.webp" class="lazy-bg team-bg_services">
            </div>
            <div class="container-db">
                <div class="service-wrapper">
                    <div class="service-text-wrapper">
                        <h2>Technologies & tools that we use</h2>
                        <p>DevBrother has a huge inventory which consists of several latest and cutting-edge tools &
                            technologies. Our ReactJS developers are highly experienced to use these technologies to
                            their optimum best.</p>
                    </div>
                </div>
                <div class="three-list-wrapper">
                    <div class="list-wrapper">
                        <ul class="bullet-list">
                            <li class="marked-item">ReactJS</li>
                            <li class="marked-item">Redux</li>
                            <li class="marked-item">HOC</li>
                            <li class="marked-item">Hooks</li>
                            <li class="marked-item">JavaScript</li>
                            <li class="marked-item">TypeScript</li>
                            <li class="marked-item">Jest</li>
                            <li class="marked-item">Chai</li>
                            <li class="marked-item">Cypress IO</li>
                            <li class="marked-item">React Testing Library</li>
                            <li class="marked-item">NextJS</li>
                            <li class="marked-item">Thunk</li>
                            <li class="marked-item">Saga</li>
                        </ul>
                    </div>
                    <div class="list-wrapper">
                        <ul class="bullet-list">
                            <li class="marked-item">RxJS</li>
                            <li class="marked-item">HTML5/CSS3</li>
                            <li class="marked-item">SASS/LESS</li>
                            <li class="marked-item">GraphQL</li>
                            <li class="marked-item">Socket.io</li>
                            <li class="marked-item">Gulp</li>
                            <li class="marked-item">WebPack</li>
                            <li class="marked-item">NPM</li>
                            <li class="marked-item">JSON</li>
                            <li class="marked-item">Ant Design</li>
                            <li class="marked-item">MaterialUI</li>
                            <li class="marked-item">Bootstrap</li>
                            <li class="marked-item">Framer Motion</li>
                        </ul>
                    </div>
                    <div class="list-wrapper">
                        <ul class="bullet-list">
                            <li class="marked-item">PostgreSQL</li>
                            <li class="marked-item">MySQL</li>
                            <li class="marked-item">MongoDB</li>
                            <li class="marked-item">Redis</li>
                            <li class="marked-item">DynamoDB</li>
                            <li class="marked-item">Firebase</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="best-tools service-wrapper">
                <div class="container-db">
                    <div class="service-text-wrapper">
                        <h2>Best Tools for ReactJS Development</h2>
                    </div>
                    <div class="industries-section">
                        <ul class="cards-wrapper">
                            <li>
                                <p class="image-wrapper">
                                    <img v-lazyload data-url="/img/images-db/services/redux-icon.svg" height="70"
                                        width="70" alt="redux">
                                </p>
                                Redux
                            </li>
                            <li>
                                <p class="image-wrapper">
                                    <img v-lazyload data-url="/img/images-db/services/npm-icon.svg" height="70"
                                        width="70" alt="npm">
                                </p>
                                Npm
                            </li>
                            <li>
                                <p class="image-wrapper">
                                    <img v-lazyload data-url="/img/images-db/services/storybook-icon.svg" height="70"
                                        width="70" alt="storybook">
                                </p>
                                Storybook
                            </li>
                            <li>
                                <p class="image-wrapper">
                                    <img v-lazyload data-url="/img/images-db/services/bit-icon.svg" height="70"
                                        width="70" alt="bit">
                                </p>
                                Bit
                            </li>
                            <li>
                                <p class="image-wrapper">
                                    <img v-lazyload data-url="/img/images-db/services/codesandbox-icon.svg" height="70"
                                        width="70" alt="codesandbox">
                                </p>
                                Codesandbox
                            </li>

                            <li>
                                <p class="image-wrapper">
                                    <img v-lazyload data-url="/img/images-db/services/jest-icon.svg" height="70"
                                        width="70" alt="jest">
                                </p>
                                Jest
                            </li>
                            <li>
                                <p class="image-wrapper">
                                    <img v-lazyload data-url="/img/images-db/services/razzle-icon.svg" height="70"
                                        width="70" alt="razzle">
                                </p>
                                Razzle
                            </li>
                            <li>
                                <p class="image-wrapper">
                                    <img v-lazyload data-url="/img/images-db/services/gatsby-icon.svg" height="70"
                                        width="70" alt="gatsby">
                                </p>
                                Gatsby
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>

        <section class="companiesUse-section service-description">
            <div class="container-db">
                <div class="service-wrapper">
                    <div class="service-text-wrapper">
                        <h2 class="tac">What Companies Use ReactJS</h2>
                        <p class="tac">React is used to create interfaces that are fast and versatile. It is one of the
                            most widely used JavaScript frameworks. Many top websites in a variety of industries are
                            built with React. The most famous companies that use the framework include Meta, Netflix,
                            Uber, Yahoo!, Airbnb, Instagram, and others.
                        </p>
                    </div>
                </div>

                <ul class="cards-wrapper">
                    <li>
                        <p class="image-wrapper">
                            <img v-lazyload data-url="/img/images-db/services/meta-icon.svg" alt="Meta" width="64"
                                height="64">
                        </p>
                    </li>
                    <li>
                        <p class="image-wrapper">
                            <img v-lazyload data-url="/img/images-db/services/netflix-icon.svg" alt="Netflix" width="64"
                                height="64">
                        </p>
                    </li>
                    <li>
                        <p class="image-wrapper">
                            <img v-lazyload data-url="/img/images-db/services/uber-icon-3.svg" alt="Uber" width="64"
                                height="64">
                        </p>
                    </li>
                    <li>
                        <p class="image-wrapper">
                            <img v-lazyload data-url="/img/images-db/services/yahoo-icon-1.svg" alt="yahoo-icon-1"
                                width="64" height="64">
                        </p>
                    </li>
                    <li>
                        <p class="image-wrapper">
                            <img v-lazyload data-url="/img/images-db/services/airbnb-icon.svg" alt="airbnb" width="64"
                                height="64">
                        </p>
                    </li>
                    <li>
                        <p class="image-wrapper">
                            <img v-lazyload data-url="/img/images-db/services/instagram-icon-1.svg" alt="instagram"
                                width="64" height="64">
                        </p>
                    </li>
                    <li>
                        <p class="image-wrapper">
                            <img v-lazyload data-url="/img/images-db/services/dropbox-icon.svg" alt="dropbox" width="64"
                                height="64">
                        </p>
                    </li>
                    <li>
                        <p class="image-wrapper">
                            <img v-lazyload data-url="/img/images-db/services/atlassian-icon-1.svg" alt="atlassian"
                                width="64" height="64">
                        </p>
                    </li>
                    <li>
                        <p class="image-wrapper">
                            <img v-lazyload data-url="/img/images-db/services/tesla-icon.svg" alt="tesla" width="64"
                                height="64">
                        </p>
                    </li>
                </ul>
            </div>
        </section>

        <section class="industries-section service-description">
          <div class="container-db">
            <div class="service-wrapper">
              <div class="service-text-wrapper">
                <h2>React Development Services Benefits</h2>
                <p>Here are five benefits of hiring a React company:</p>
                <ol>
                  <li class="marked-item"><span class="marked">Expertise and experience:</span> Development companies have
                    teams of skilled developers with extensive experience building complex React applications.
                    This ensures that your project uses best practices, efficient coding standards, and a
                    robust architecture.</li>
                  <li class="marked-item"><span class="marked">Efficiency and speed:</span> These companies can deliver
                    projects faster with a dedicated team of developers. They use efficient workflows,
                    reusable components, and optimized processes that reduce development time without sacrificing quality.</li>
                  <li class="marked-item"><span class="marked">Scalability and flexibility:</span> React developers can
                    scale their resources to meet your project needs. If you need to add more features,
                    handle increased user load, or make significant changes, they'll provide the flexibility
                    to adapt quickly.</li>
                  <li class="marked-item"><span class="marked">Focus on quality and testing:</span> Professional React
                    companies pay special attention to quality assurance and thorough testing. They implement automated
                    testing, code reviews, and debugging processes to ensure your application is robust, reliable,
                    and free of critical bugs.</li>
                  <li class="marked-item"><span class="marked">Ongoing support and maintenance:</span> After the initial
                    development phase, these companies offer ongoing support and maintenance. They provide updates,
                    security patches, performance optimizations, and enhancements to keep your app running smoothly
                    and efficiently.</li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <section class="industries-section service-description service-process">
          <div class="container-db">
            <div class="service-wrapper">
              <div class="service-text-wrapper service-steps">
                <h2>Our React JS development services process</h2>
                <p>Here's an outline of the typical process involved in providing React JS software:</p>
                <ol>
                  <li><span class="marked">Initial consultation and requirements gathering</span> Hold meetings to understand
                    the client's business goals, target audience, and specific project requirements.
                    Analyze the criteria to determine the scope, functionality, and features needed for the application.</li>
                  <li><span class="marked">Planning and proposal</span> Define the project roadmap, including timelines,
                    milestones, and deliverables. Develop a detailed proposal describing the scope of work, cost estimates,
                    and project schedule.</li>
                  <li><span class="marked">Design and prototyping</span> Create wireframes, mockups,
                    and prototypes to visualize the application's user interface and user experience. Present the design
                    to the client for feedback and make the necessary changes to meet their vision.</li>
                  <li><span class="marked">Development</span> Set up the development environment, including the necessary
                    tools, libraries, and frameworks. Develop reusable and modular components with React JS, ensuring
                    the code base's scalability and maintainability. Integrate backend services and APIs for seamless
                    data flow and functionality. Implement state management solutions such as Redux or Context APIs to
                    manage application state effectively.</li>
                  <li><span class="marked">Testing and quality assurance</span> Write and execute unit tests to ensure that
                    individual components function correctly. Conduct integration tests to ensure that all components work
                    together as expected. Conduct user testing to gather feedback and identify any usability issues.
                    Resolve any bugs or issues found during testing to ensure a smooth user experience.</li>
                  <li><span class="marked">Deployment</span> Optimize the application for production, including code
                    minification and separation. Deploy the application to the production environment, ensuring proper
                    configuration and setup. Conduct final tests in the production environment to confirm that everything
                    works as expected.</li>
                  <li><span class="marked">Support and maintenance</span> Provide ongoing support to resolve issues,
                    implement updates, and add new features as needed. Monitor application performance and make necessary
                    adjustments to optimize speed and reliability. Ensure the app stays updated with the latest security
                    patches and feature enhancements.</li>
                  <li><span class="marked">Training and documentation for customers</span> Conduct training sessions for
                    the client's team to ensure they can effectively use and manage the application. Provide detailed
                    documentation covering the architecture, components, and instructions for using the application.</li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <section class="faq-section">
            <h2>Frequently Asked Questions</h2>
            <div class="container-db">
                <div id="app" class="">
                    <app-accordion @closeAccordions='closeAccordions()' v-for="(accordion, index) in accordions"
                        ref="accordion" :key="index" class="app-accordion">
                        <template v-slot:title>
                            <span class="">{{ accordion.title }}</span>
                        </template>
                        <template v-slot:content>
                            <p>
                                {{ accordion.text }}
                            </p>
                        </template>
                    </app-accordion>
                </div>

            </div>
        </section>

        <h2 id="questions" class="more-questions">If you have any more questions, write to us</h2>

        <section class="acquainted main-contact" id="acquainted">
            <div class="container-db">
                <div class="acquainted-form-wrapper">
                    <div v-lazyload data-src="/img/images-db/parallax/stone2.webp" class="lazy-bg bottom-stone"></div>
                    <div class="cooperation-offer">
                        <h3>What's your challenge?</h3>
                        <div class="cooperation-offer-text">
                            Let us deliver the talent and expertise to help you solve it
                        </div>
                    </div>
                    <AcquaintedForm />
                </div>
            </div>
        </section>
        <script v-html="jsonld" type="application/ld+json"></script>
    </main>
</template>
<script>
import AcquaintedForm from "../../partials/AcquaintedForm";
import { requestGet, requestPost } from "../../../services/axios.js";
import AppAccordion from "./AppAccordion";

let vm = {};
export default {
    name: 'React',
    metaInfo: {
        title: 'React JS Development Company and Outsource Services',
        meta: [
            {
                vmid: "description",
                name: 'description',
                content: 'React JS Development Services in the USA ▲ Hire Dedicated Developers and Staff your projects in a matter of days. ➥ Schedule Your Free Consultation Today.'
            },
            {
                vmid: "og:image:type",
                property: "og:image:type",
                content: 'image/webp'
            },
            {
                vmid: "og:title",
                property: "og:title",
                content: 'React JS Development Company and Outsource Services'
            },
            {
                vmid: "og:description",
                property: "og:description",
                content: 'React JS Development Services in the USA ▲ Hire Dedicated Developers and Staff your projects in a matter of days. ➥ Schedule Your Free Consultation Today.'
            },
            {
                vmid: "og:image",
                property: "og:image",
                content: 'https://devbrother.com/img/images-db/services/office-team.webp'
            },
            {
                vmid: "og:type",
                property: "og:type",
                content: "website"
            },
            {
                vmid: "og:url",
                property: "og:url",
                content: 'https://devbrother.com/services/web-development/react'
            },
            {
                vmid: "twitter:title",
                name: "twitter:title",
                content: 'React JS Development Company and Outsource Services'
            },
            {
                vmid: "twitter:description",
                name: "twitter:description",
                content: 'React JS Development Services in the USA ▲ Hire Dedicated Developers and Staff your projects in a matter of days. ➥ Schedule Your Free Consultation Today.'
            },
            {
                vmid: "twitter:image",
                name: "twitter:image",
                content: 'http://devbrother.com/img/images-db/services/office-team.webp'
            }
        ]
    },
    components: { AcquaintedForm, AppAccordion },
    data() {
        const jsonld = {
            "@context": "https://schema.org",
            "@graph": [{
                "@type": "Organization",
                "@id": "https://devbrother.com/#organization",
                "name": "DevBrother",
                "url": "https://devbrother.com/",
                "sameAs": ["https://www.linkedin.com/company/devbrother/",
                    "https://www.instagram.com/devbrother_company/",
                    "https://clutch.co/profile/devbrother"],
                "logo": {
                    "@type": "ImageObject", "inLanguage": "en-US",
                    "@id": "https://devbrother.com/#/schema/logo/image/",
                    "url": "https://devbrother.com/img/images-db/Logo.svg",
                    "contentUrl": "https://devbrother.com/img/images-db/Logo.svg",
                    "width": 70,
                    "height": 40,
                    "caption": "DevBrother"
                },
                "image":
                    { "@id": "https://devbrother.com/#/schema/logo/image/" }
            },
            {
                "@type": "WebSite", "@id": "https://devbrother.com/#website",
                "url": "https://devbrother.com/",
                "name": "DevBrother",
                "description": "DevOps &amp; Software Development",
                "publisher": { "@id": "https://devbrother.com/#organization" },
                "potentialAction": [{
                    "@type": "SearchAction",
                    "target": {
                        "@type": "EntryPoint",
                        "urlTemplate": "https://devbrother.com/?s={search_term_string}"
                    },
                    "query-input": "required name=search_term_string"
                }],
                "inLanguage": "en-US"
            },
            {
                "@type": "ImageObject",
                "inLanguage": "en-US",
                "@id": "https://devbrother.com/services/web-development/react#primaryimage",
                "url": "https://devbrother.com/images/react-icon.webp",
                "contentUrl": "https://devbrother.com/images/react-icon.webp",
                "width": 512,
                "height": 512
            },
            {
                "@type": "WebPage",
                "@id": "https://devbrother.com/services/web-development/react",
                "url": "https://devbrother.com/services/web-development/react",
                "name": "React JS Development Services & Outsource Company - Hire Dedicated Experts",
                "isPartOf": { "@id": "https://devbrother.com/#website" },
                "primaryImageOfPage": { "@id": "https://devbrother.com/services/web-development/react#primaryimage" },
                "datePublished": "2022-03-10T17:26:55+00:00",
                "dateModified": "2022-03-10T17:26:55+00:00",
                "description": "React JS Development Services in the USA ▲ Hire Dedicated Developers and Staff your projects in a matter of days. ➥ Schedule Your Free Consultation Today.",
                "breadcrumb": { "@id": "https://devbrother.com/services/web-development/react#breadcrumb" },
                "inLanguage": "en-US",
                "potentialAction": [{
                    "@type": "ReadAction",
                    "target": ["https://devbrother.com/services/web-development/react"]
                }]
            },
            {
                "@type": "BreadcrumbList",
                "@id": "https://devbrother.com/services/web-development/react#breadcrumb",
                "itemListElement": [{
                    "@type": "ListItem",
                    "position": 1,
                    "name": "DevBrother",
                    "item": "https://devbrother.com/"
                },
                {
                    "@type": "ListItem",
                    "position": 2,
                    "name": "Web Development",
                    "item": "https://devbrother.com/services/web-development"
                },
                {
                    "@type": "ListItem",
                    "position": 3,
                    "name": "ReactJS",
                    "item": "https://devbrother.com/services/web-development/react"
                }
                ]
            },
            {
                "@type": "FAQPage",
                "mainEntity": [{
                    "@type": "Question",
                    "name": "How do I hire ReactJS development company?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "The company must have the specific programming expertise to be able to handle any complexity of the job. The company can provide some of its own completed projects. You need to look mainly at their learnings. Try filling out a form with your contacts and then talk to the client support."
                    }
                }, {
                    "@type": "Question",
                    "name": "How much do your React JS development services cost?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "Mostly depends on experience, knowledge, responsibilities, building the project architecture, or-just responsive mark-up. The salary varies greatly, in any case, it will be a big project and the salary will be decent. Whether it will be a big project and how much time will be spent on it. Also, knowledge of languages is important. On average, it costs offer $3,500. Salary rates are different in countries and cities. In the big cities, the salary is the highest"
                    }
                }, {
                    "@type": "Question",
                    "name": "Can ReactJS build a mobile app?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "Of course, it is. It is worth a good understanding of it and applying all the knowledge in the application, then everything will work out. Very often there are different integrations that are used together with React. It can do a lot and is very popular among developers in today's world."
                    }
                }, {
                    "@type": "Question",
                    "name": "Why Should You Outsource React.js?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "The best way to experience the benefits of the framework is to outsource React.js development to an offshore vendor."
                    }
                }]
            }
            ]
        }


        return {
            jsonld,
            accordions: [
                {
                    title: 'How do I hire ReactJS development company?',
                    text: "The company must have the specific programming expertise to be able to handle any complexity of the job. The company can provide some of its own completed projects. You need to look mainly at their learnings. Try filling out a form with your contacts and then talk to the client support.",

                },
                {
                    title: 'How much do your React JS development services cost?',
                    text: 'Mostly depends on experience, knowledge, responsibilities, building the project architecture, or-just responsive mark-up. The salary varies greatly, in any case, it will be a big project and the salary will be decent. Whether it will be a big project and how much time will be spent on it. Also, knowledge of languages is important. On average, it costs offer $3,500. Salary rates are different in countries and cities. In the big cities, the salary is the highest',
                },
                {
                    title: 'Can ReactJS build a mobile app?',
                    text: "Of course, it is. It is worth a good understanding of it and applying all the knowledge in the application, then everything will work out. Very often there are different integrations that are used together with React. It can do a lot and is very popular among developers in today's world."
                },
                {
                    title: 'Why Should You Outsource React.js?',
                    text: 'The best way to experience the benefits of the framework is to outsource React.js development to an offshore vendor.',
                }
            ],
            isMailSend: false,
            mailData: {
                name: "",
                email: "",
                comment: ""
            },
            errors: [],
            styles: {
                name: {
                    input: "",
                    label: "",
                    span: "visibility: hidden",
                },
                email: {
                    input: "",
                    label: "",
                    span: "visibility: hidden",
                },
                comment: {
                    input: "",
                    label: "",
                    span: "visibility: hidden;",
                }
            }
        };
    },
    beforeCreate() {
        vm = this;
    },
    mounted: () => {
        vm.addCanonicalLink();
    },
    methods: {
        addCanonicalLink: () => {
            const link = document.createElement('link');
            link.setAttribute('rel', 'canonical');
            link.setAttribute('href', 'https://devbrother.com/services/web-development/react');

            const oldLink = document.querySelector('link[rel="canonical"]');
            if (oldLink) {
                document.head.removeChild(oldLink);
            }

            document.head.appendChild(link);
        },
        closeAccordions() {
            this.$refs.accordion.forEach(el => el.closeAccordion());
        },
        checkForEmptiness: (field) => {
            if (vm.mailData[field] === "") {
                vm.addWarning(field)
            } else {
                vm.styles[field]["span"] = "visibility: hidden";
            }
        },
        addWarning: (field) => {
            vm.styles[field]["input"] = "border: 1px solid red;"
            vm.styles[field]["span"] = "visibility: visible";
        },
        raiseLabelUp: field => {
            vm.styles[field]["input"] = "";
            vm.styles[field]["span"] = "visibility: hidden";
        },
        validEmail: email => {
            const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },
        hasErrors: () => {
            let gotError = false;
            Object.keys(vm.mailData).forEach(item => {
                if (vm.mailData[item] == "") {
                    gotError = true;
                    vm.checkForEmptiness(item);
                } else if ((item == "email" && !vm.validEmail(vm.mailData[item]))) {
                    gotError = true;
                    vm.checkForEmptiness(item);
                    vm.addWarning('email');
                }
            });
            return gotError;
        },
        sendMail: (event) => {
            if (!vm.hasErrors()) {
                requestPost("/contact-about-us", vm.mailData)
                    .then(res => {
                        if (res.status) {
                            vm.isMailSend = true;
                            vm.mailData.name = "";
                            vm.mailData.email = "";
                            vm.mailData.comment = "";
                        } else {
                            console.log("Email send failed, error", res.error);
                        }
                    })
                    .catch(error => {
                        return { status: false, error };
                    });
            }
        }
    }
}
</script>
<style scoped lang="scss">

.marked {
  color: #06c6ff;
}

.service-steps {
  ol {
    margin-left: 20px;
    list-style-type: auto;

    li {
      padding-left: 0;

      &::before {
        display: none;
      }

      &::marker {
        color: #06c6ff;
      }
    }
  }
}

.service-process, .service-dedicated {
  margin-top: -20px;
}

</style>
