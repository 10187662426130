<template>    <!--SECTION INDUSTRIES-->
	<main>
    <div class="top-stone">
    </div>
    <div class="pagination">
      <div class="container-db">
        <div class="bread-crumbs">
          <router-link to="/">Home</router-link>
          <span class="bread-separator">/</span>
          <span class="bread-current">Team Extension</span>
        </div>
      </div>
    </div>
    <section class="service-description container-db">
      <div class="service-wrapper">
        <div class="service-text-wrapper">
          <h1>Outsourcing Team Extension Services</h1>
          <p>
            DevBrother is uniquely positioned to deliver our customers cost-optimized, high-quality outstaffing service solutions. We can achieve this because DevBrother owns CoWorkingClub, a coworking facility that accommodates many upstarts with unique, high-impact solutions to bring to market.
          </p>
          <h2>
            Outstaffing Benefits:
          </h2>
          <ul>
              <li class="marked-item">Opportunity to reach a database of thousands of coworking clients-freelancers that can be involved in projects by need</li>
              <li class="marked-item">Easy search/hiring/taking on board people for remote work</li>
              <li class="marked-item">Access to the highest standards of office spaces with the best conditions for remote work (meeting rooms, private rooms, conference rooms, etc, comfortable workspace)</li>
              <li class="marked-item">Hundreds of meters of office space are intended for easy teams and project scaling.</li>
              <li class="marked-item">Fast remote team building and outsourcing team extension by using CoWorking human, admin, and office resources</li>
              <li class="marked-item">Admin, Technical, Social and Management support</li>
            </ul>
          <p>
            That’s why we can offer our clients the best conditions when outsourcing staff extension.
          </p>
        </div>
      </div>
      <div class="service-text-wrapper">
				<h2>With DevBrother Outstaffing Services, You Can:</h2>
        <ul class="bullet-list">
          <li class="marked-item">
            Quickly scale the size of your team
          </li>
          <li class="marked-item">
            Get new team members with the highest personal and professional skills
          </li>
          <li class="marked-item">
            Reduce and control operating costs
          </li>
					<li class="marked-item">Avoid local constraints in terms of hiring/contracting/scaling</li>
					<li class="marked-item">Have direct access to all team members</li>
					<li class="marked-item">Pay a fixed all-inclusive fee without any additional charges</li>
        </ul>
      </div>
      <div class="service-text-wrapper">
        <h2>STAGES:</h2>
				<h3>STAGE 1 - Recruiting</h3>
        <p>
          We find the perfect candidate according to your requirements by interviewing and assigning test tasks to tens candidates. After your approval, we make an offer.
        </p>
        <p>
          Having a vast network of software engineering communities and engineers, we can find the best candidate for your specific needs in minimal terms. We are also collaborating with several <a href="https://talentbankai.com" target="_blank">recruiting agencies</a> to have more candidates. Candidates pass interviews and complete test tasks. We always take care of our professional skills and our personalities. That’s why we usually take one person from 20-30 candidates that came to us from different sources. So you won’t waste your time on candidates who do not meet your requirements. We introduce you to the candidate, and you make all the necessary decisions (interview, test task, or whatever you need) from your side. With our regular clients - CV and introduction usually are enough. After this we are making an offer, approved by you.
        </p>
				<h3>STAGE 2 - Onboarding</h3>
        <p>
          After we take candidates on board, we set up the environments and configure essential settings/processes (daily stand-ups, reporting, time tracking, etc.).
        </p>
        <h3>STAGE 3 - Training and Development</h3>
        <ul>
          <li class="marked-item"><b>Onboarding</b>: Onboarding training for new extended team members to get familiar with client project requirements, tools, and processes.</li>
          <li class="marked-item"><b>Continuous Learning</b>: Online courses, workshops, and training programs to keep the development team extension updated with the latest technologies and industry trends.</li>
          <li class="marked-item"><b>Professional Development</b>: Encouraging participation in conferences, webinars, and certifications.</li>
          <li class="marked-item"><b>Technical Skill Enhancement</b>: Technical skill enhancement sessions to improve the team’s proficiency in relevant technologies and methodologies.</li>
          <li class="marked-item"><b>Soft Skills Training</b>: Training in communication, teamwork, and problem-solving skills for effective collaboration and project management.</li>
          <li class="marked-item"><b>Performance Feedback</b>: Feedback loop where team members get constructive feedback and guidance for continuous improvement.</li>
          <li class="marked-item"><b>Mentorship Programs</b>: Pairing junior team members with senior mentors for knowledge transfer and career growth.</li>
          <li class="marked-item"><b>Learning Resources</b>: Library of learning resources, including books, tutorials, and research papers.</li>
        </ul>
				<h3>STAGE 4 - Ongoing Work</h3>
				  We are highly interested in providing a working process that guarantees both sides - clients and developers - will achieve the best results, ensuring all contractual obligations are met, and both sides can easily communicate with each other on any needs.
      </div>
    </section>
    <AcquaintedSection />
    <div v-lazyload data-src="/img/images-db/parallax/stone2.webp" class="lazy-bg bottom-stone"></div>

    <div class="popup" :class="{ 'hide-popup': !showModal}">
      <div class="popup-wrapper">
        <div class="popup-inner" @click.self="closePopup()">
          <div class="popup-content" id="service-contact-form">
            <button class="close-popup" @click="closePopup()" type="button" aria-label="close popup"></button>
            <ServicesTopForm />
          </div>
        </div>
      </div>
    </div>

    <script v-html="jsonld" type="application/ld+json"></script>
  </main>
</template>
<script>
import AcquaintedSection from '../AcquaintedSection';
    import ServicesTopForm from '../ServicesTopForm';

    let vm = {};
    export default {
        name: 'QAservice',
        metaInfo: {
            title: 'Team Extension - New Alternative To Outsourcing',
            meta: [
              {
                vmid: "description",
                name: 'description',
                content: 'Team Extension with DevBrother will allow you to complete the project on time. We offer a convenient and modern model of outsourcing - extended team.'
              },
              {
                vmid: "og:image:type",
                name: "og:image:type",
                content: 'image/webp'
              },
              {
                vmid: "og:title",
                name: "og:title",
                content: 'Team Extension - New Alternative To Outsourcing'
              },
              {
                vmid: "og:description",
                name: "og:description",
                content: 'Team Extension with DevBrother will allow you to complete the project on time. We offer a convenient and modern model of outsourcing - extended team.'
              },
              // {
              //   vmid: "og:image",
              //   name: "og:image",
              //   content: 'https://devbrother.com/images/...'
              // },
              {
                vmid: "og:type",
                name: "og:type",
                content: "website"
              },
              {
                vmid: "og:url",
                name: "og:url",
                content: 'https://devbrother.com/services/remote-team-extension'
              },
              {
                vmid: "twitter:title",
                name: "twitter:title",
                content: 'Team Extension - New Alternative To Outsourcing'
              },
              {
                vmid: "twitter:description",
                name: "twitter:description",
                content: 'Team Extension with DevBrother will allow you to complete the project on time. We offer a convenient and modern model of outsourcing - extended team.'
              },
              // {
              //   vmid: "twitter:image",
              //   name: "twitter:image",
              //   content: 'https://devbrother.com/images/...'
              // }
            ]
        },
        components: {
				  AcquaintedSection,
          ServicesTopForm
				 },
        data() {
          const jsonld =  {
            "@context": "https://schema.org",
            "@type": "Service",
            "name": "Team Extension - New Alternative To Outsourcing",
            "description": "Team Extension with DevBrother will allow you to complete the project on time. We offer a convenient and modern model of outsourcing - extended team."
          }

          return {
            jsonld,
            showModal: false,
          }
        },
           beforeCreate(){
            vm = this;
        },
          mounted: () => {
            vm.body = document.querySelector("body");
            vm.addCanonicalLink();
          },
          methods: {
            addCanonicalLink: () => {
              const link = document.createElement('link');
              link.setAttribute('rel', 'canonical');
              link.setAttribute('href', 'https://devbrother.com/services/remote-team-extension');

              const oldLink = document.querySelector('link[rel="canonical"]');
              if (oldLink) {
                document.head.removeChild(oldLink);
              }

              document.head.appendChild(link);
            },
            showPopup: () => {
              vm.showModal = true;
              vm.body.classList.add('ban-scroll');
            },
            closePopup: () => {
              vm.showModal = false;
              vm.body.classList.remove('ban-scroll');
            },
            scrollFix: hash => {
              setTimeout(() => {
                setTimeout(() => $('html, body')
                .animate({scrollTop: $(hash).offset().top}, 1000), 1)
              });
            },
        }
    }
</script>
