<template>    <!--SECTION INDUSTRIES-->
<main>
			<div class="promotaion art">
				<div class="container-db bread-container">
					<div class="bread-crumbs">
						<router-link to="/">Home</router-link>
						<span class="bread-separator">/</span>
						<span class="bread-current">Art Galleries</span>
					</div>
				</div>
				<div class="container-db">
					<h1>Art Galleries</h1>
				</div>
			</div>
			<div class="case-container challenge">
				<h2>Overview</h2>
				<p>VitavieGallery Art Gallery in Toronto opted for a complete rebranding. A crucial part of this effort was the development of fast, beautiful and reliable website. The website was built as a Single Page Application with the administration area that allowed the client to conveniently manage the content, analyze website statistics, communicate with clients and create mailing campaigns.
				</p>
				<p>DevBrother was asked to deliver such kind of product to VitavieGallery Art Gallery.</p>
			</div>
			<div class="descrip-result-wrapper">
				<div class="descrip-result-text">
					<div class="container-db">
						<div class="description">
							<h2>Description</h2>
							<p>
								We’ve made deep investigation of Art Galleries world to create the new branding strategy for VitavieGallery Art Gallery. After new brand was created and confirmed, we’ve developed custom CMS based on Laravel+VueJS and implemented all features that were described in technical description. Development for Art Galleries requires special approach because of the quality of Artworks images, the way they should be shown on the website and many other things that we were taking into account during website development.
							</p>
							<p>
								DevBrother was asked to deliver such kind of product to VitavieGallery Art Gallery.
							</p>
						</div>
						<div class="result">
							<h2>Result</h2>
							<p>
								We’ve created new brand for VitavieGallery, developed custom CMS based on Laravel+VueJS and implemented statistics, mailchimp integration, clients capturing system and mailing system, notification system, opportunity to manage artworks, artists and events from admin panel.
							</p>
						</div>
					</div>
				</div>
				<img v-lazyload data-url="/img/images-db/case-studies/art-solutions.webp" alt="art-solutions.webp" width="945" height="665">
			</div>
			<div class="tech-features-section">
				<div class="container-db">
					<div class="tech-features-wrapper">
						<div class="technologies-wrapper">
							<h2>Technologies</h2>
							<ul>
								<li class="marked-item">Laravel</li>
								<li class="marked-item">HTML5, CSS3, Bootstrap, VueJS</li>
								<li class="marked-item">MySQL</li>
								<li class="marked-item">DigitalOcean</li>
							</ul>
						</div>
						<div class="features">
							<h2>Features</h2>
							<ul>
								<li>Custom CMS for Art Gallery</li>
								<li>Single Page Application</li>
								<li>Special approach for Art Gallery website images</li>
							</ul>
						</div>

					</div>
				</div>
			</div>
			<div class="relevant-case-studies">
				<div class="container-db">
					<h2>portfolio</h2>
					<div class="slider-container">
						<div class="relevant-case-studies-slider">
							<slick ref="slick" :options="slickOptions">
								<div class="case-slider-item">
									<router-link to="cryptocurrency">
										<div class="case-studies-item">
										<div class="case-studies-img-wrapper">
											<img src="/img/images-db/case-studies/crypto.webp" alt="Cryptocurrency" width="300" height="334">
										</div>
										<h3>Cryptocurrency</h3>
									</div>
									</router-link>
								</div>
								<div class="case-slider-item">
									<router-link to="e-commerce">
										<div class="case-studies-item">
										<div class="case-studies-img-wrapper">
											<img src="/img/images-db/case-studies/ecommerce.webp" alt="E-commerce" width="300" height="334">
										</div>
										<h3>E-commerce</h3>
									</div>
									</router-link>
								</div>
							<div class="case-slider-item">
									<router-link to="healthcare">
										<div class="case-studies-item">
											<div class="case-studies-img-wrapper">
												<img src="/img/images-db/case-studies/health.webp" alt="healthcare" width="300" height="334">
											</div>
											<h3>HealthCare <br />DoctorMed</h3>
										</div>
									</router-link>
								</div>
								<div class="case-slider-item">
									<router-link to="wordpress-dev">
										<div class="case-studies-item">
										<div class="case-studies-img-wrapper">
											<img src="/img/images-db/case-studies/wp3.webp" alt="wordpress development" width="300" height="334">
										</div>
										<h3>WordPress<br>Websites and Plugins Development</h3>
									</div>
									</router-link>
								</div>
								<div class="case-slider-item">
									<router-link to="online-event">
										<div class="case-studies-item">
										<div class="case-studies-img-wrapper">
											<img src="/img/images-db/case-studies/event.webp" alt="Online Tickets  Printing" width="300" height="334">
										</div>
										<h3>Online Tickets  Printing</h3>
									</div>
									</router-link>
								</div>
							</slick>
						</div>
					</div>
				</div>
			</div>
      <AcquaintedSection />
      <div v-lazyload data-src="/img/images-db/parallax/stone2.webp" class="lazy-bg bottom-stone"></div>
			<script v-html="jsonld" type="application/ld+json"></script>
    </main>
</template>
<script>
	import Slick from 'vue-slick';
	import AcquaintedSection from '../AcquaintedSection';
	let vm = {};
	export default {
			name: 'Artgalleries',
			metaInfo: {
			 title: 'Case: Creating a CMS for an art gallery in Toronto VitavieGallery',
            meta: [
				{
					vmid: "description",
					name: 'description',
					content: 'We at DevBrother have created a comfortable and modern art gallery site from Canada. A small dedicated team was involved to create it.'
				},
				{
					vmid: "og:image:type",
					name: "og:image:type",
					content: 'image/webp'
				},
				{
					vmid: "og:title",
					name: "og:title",
					content: 'Case: Creating a CMS for an art gallery in Toronto VitavieGallery'
				},
				{
					vmid: "og:description",
					name: "og:description",
					content: 'We at DevBrother have created a comfortable and modern art gallery site from Canada. A small dedicated team was involved to create it.'
				},
				{
				  vmid: "og:image",
				  name: "og:image",
				  content: 'https://devbrother.com/img/images-db/case-studies/art-solutions.webp'
				},
				{
					vmid: "og:type",
					name: "og:type",
					content: "website"
				},
				{
					vmid: "og:url",
					name: "og:url",
					content: 'https://devbrother.com/cases/art-galleries'
				},
				{
					vmid: "twitter:title",
					name: "twitter:title",
					content: 'Case: Creating a CMS for an art gallery in Toronto VitavieGallery'
				},
				{
					vmid: "twitter:description",
					name: "twitter:description",
					content: 'We at DevBrother have created a comfortable and modern art gallery site from Canada. A small dedicated team was involved to create it.'
				},
				{
					vmid: "twitter:image",
					name: "twitter:image",
					content: 'https://devbrother.com/img/images-db/case-studies/art-solutions.webp'
				}
            ],
      },
			components: { Slick, AcquaintedSection },
		mounted() {
			this.addCanonicalLink();
    },
		methods: {
			addCanonicalLink: () => {
				const link = document.createElement('link');
				link.setAttribute('rel', 'canonical');
				link.setAttribute('href', 'https://devbrother.com/cases/art-galleries');

				const oldLink = document.querySelector('link[rel="canonical"]');
				if (oldLink) {
					document.head.removeChild(oldLink);
				}

				document.head.appendChild(link);
			},
		},
		data(){
			const jsonld =  {
        "@context": "https://schema.org",
        "@type": "IndividualProduct",
        "name": "Art Gallery",
        "description": "Art Gallery"
      }
			return {
			jsonld,
			slickOptions: {
            prevArrow: '<div class="prev-arrow"><img src="/img/images-db/Arrow.svg" alt="arrow" class="arrow" width="26" height="11"/><img src="/img/images-db/Arrow-hover.svg" alt="arrow" class="arrow-hover" width="26" height="11"/></div>',
            nextArrow: '<div class="next-arrow"><img src="/img/images-db/Arrow.svg" alt="arrow" class="arrow" width="26" height="11"/><img src="/img/images-db/Arrow-hover.svg" alt="arrow" class="arrow-hover" width="26" height="11"/></div>',
					slidesToShow: 3,
					slidesToScroll: 1,
					// centerMode: true,
					responsive: [
						{
							breakpoint: 769,
							settings: {
								slidesToShow: 1,
								centerMode: false,
								prevArrow: '',
								nextArrow: '',
								dots: true,
								customPaging: function(slider, i) {
									return '<div class="slick-mobile-dot"></div>';
								}
							}
						}
					]
							// Any other options that can be got from plugin documentation
					},
			}
		},
	}
</script>
