<template>
    <main class="web-vuejs web-reactjs">
        <div class="top-stone"><img src="/img/images-db/parallax/stone1.png" alt="stone"></div>
        <div class="pagination">
            <div class="container-db">
                <div class="bread-crumbs">
                    <router-link to="/" class="router-link-active">Home</router-link>
                    <span class="bread-separator">/</span>
                    <router-link to="/services/web-development">Web Development</router-link>
                    <span class="bread-separator">/</span>
                    <span class="bread-current">VueJS</span>
                </div>
            </div>
        </div>

        <section class="top-form-section service-description">
            <div class="container-db">
                <div class="service-wrapper">
                    <div class="service-text-wrapper">
                        <h1>Vue JS Development Company</h1>
                        <p>
                            DevBrother is a team of experts in Software Engineering, specializing in the full cycle of
                            product development and support. We’re a unique company because we are bringing a new
                            generation of Remote Team Extension on the market — we’ve created one of the biggest
                            European Software Engineers Database and set up all necessary support for remote work
                            with our clients — office spaces, recruiting, hiring, management, administrative,
                            infrastructure, etc., based on partnership with CoWorking Spaces.
                            <br><br>
                            We create a new quality of the connection between people in the IT industry based on the
                            principles of pro-activity, humanity, and awareness.
                            <br><br>
                            The company goal is to give client more than they expect, focusing on achieving the client's
                            goals and results.
                            <br><br>
                        </p>
                    </div>
                    <div id="contact-service">
                        <form v-on:submit.prevent="sendMail()" action="#" novalidate="novalidate"
                            class="acquainted-form">
                            <div>
                                <p class="form-title">Get React Consultation Now</p>
                                <p class="form-title">Let's Discuss to Build Something Great Together</p>
                                <div class="name-email-area">
                                    <p class="name-label">
                                        <label for="name">Name</label>
                                        <input v-model="mailData.name" type="text" required name="name" id="name"
                                            placeholder="" @focus="raiseLabelUp('name')"
                                            @blur="checkForEmptiness('name')" />
                                        <span class="error-message" :style="styles.name.span">Please enter the
                                            name</span>
                                    </p>
                                    <p class="email-label">
                                        <label for="email">Email</label>
                                        <input v-model="mailData.email" type="email" required name="email" id="email"
                                            placeholder="" @click="raiseLabelUp('email')"
                                            @blur="checkForEmptiness('email')" />
                                        <span class="error-message" :style="styles.email.span">Please enter the email in
                                            valid format</span>
                                    </p>
                                </div>

                                <p>
                                    <label for="text" id="text">Message</label>
                                    <textarea v-model="mailData.comment" name="" id="comment" cols="30" rows="3"
                                        placeholder="" required @focus="raiseLabelUp('comment')"
                                        @blur="checkForEmptiness('comment')"></textarea>
                                    <span :style="styles.comment.span">Please enter your message</span>
                                </p>
                                <button type="submit" class="btn form-btn">Contact us</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>

        <section class="choose-section service-description">
            <img src="/img/images-db/images/bg_img/img v8-3_0000.webp" alt="stone" class="team-bg_services">
            <div class="container-db">
                <div class="service-wrapper">
                    <div class="service-text-wrapper">
                        <!-- <h2 class="tac">Why Should You Choose VueJS?</h2> -->
                        <h2 class="tac">Why Should Business Choose VueJS for Custom Application Development?</h2>
                        <div class="two-list-wrap">
                            <ul>
                                <li class="two-list-item">Simple integration</li>
                                <li class="two-list-item">High perfomance</li>
                                <li class="two-list-item">Two-way data binding</li>
                            </ul>
                            <ul>
                                <li class="two-list-item">Simplicity</li>
                                <li class="two-list-item">Flexibility & security</li>
                                <li class="two-list-item">SEO friendly apps</li>
                            </ul>
                        </div>

                    </div>
                </div>

                <div class="service-wrapper">
                    <div class="service-text-wrapper">
                        <!-- <h2 class="tac">Why Should Business Choose VueJS for Custom Application Development?</h2> -->
                        <!-- <div class=""> -->
                        <p>The Vue.js framework has dozens of features that benefit businesses of all sizes and
                            types. The main advantage of Vue.js is its ease of use. For clients like you this means
                            that the new product or service can be developed faster than if we used another
                            JavaScript framework</p>
                        <p>Please consider the following Vue.js features that allow us to create high-quality
                            software products:</p>
                        <ul>
                            <li class="list-item marked-item">
                                <span>Excellent performance.</span>
                                Vue.js provides developers with effective state management mechanisms that enable us
                                to build lag-free, high-performance systems.
                            </li>
                            <li class="list-item marked-item">
                                <span>Simple customization and optimization.</span>
                                The component-based flow of Vue.js allows developers to customize existing projects.
                            </li>
                            <li class="list-item marked-item">
                                <span>High security.</span>
                                Its two-way data binding feature automatically updates all changes on both the
                                client and the server side. This mitigates the risk of disclosing confidential
                                information.
                            </li>
                            <li class="list-item marked-item">
                                <span>Fast development and implementation.</span>
                                Vue.js is a lightweight and easy-to-use JavaScript framework that enables fast
                                coding.
                            </li>

                        </ul>

                        <!-- </div> -->

                    </div>
                </div>



            </div>
        </section>

        <section class="integration-section service-description">
            <!-- <img src="/img/images-db/images/bg_img/img v8-3_0000.webp" alt="stone" class="team-bg_services"> -->
            <div class="container-db">
                <div class="service-wrapper">
                    <div class="service-text-wrapper">
                        <h2>OUR VUEJS DEVELOPMENT SERVICES</h2>
                        <!-- <p>text</p> -->
                    </div>
                </div>
                <div class="benefits">
                    <div class="inner-container">
                        <ul class="benefits-list">
                            <li data-aos="flip-left" data-aos-duration="3000"
                                class="benefits-list-item aos-init aos-animate">
                                <div class="inner-benefits-item">
                                    <h6>Portal development solution</h6>
                                    <p>=== Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                                        Eius, dolorem dicta maiores molestiae itaque cumque animi eaque autem facere
                                        neque aut commodi praesentium eligendi, numquam similique reprehenderit est fuga
                                        maxime. Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                                    </p>
                                </div>
                            </li>
                            <li data-aos="flip-left" data-aos-duration="3000"
                                class="benefits-list-item aos-init aos-animate">
                                <div class="inner-benefits-item">
                                    <h6>Data-analysis tools</h6>
                                    <p>=== Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                                        Eius, dolorem dicta maiores molestiae itaque cumque animi eaque autem facere
                                        neque aut commodi praesentium eligendi, numquam similique reprehenderit est fuga
                                        maxime. Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                                    </p>
                                </div>
                            </li>
                            <li data-aos="flip-left" data-aos-duration="3000"
                                class="benefits-list-item aos-init aos-animate">
                                <div class="inner-benefits-item">
                                    <h6>Maintenance and upgradation</h6>
                                    <p>=== Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                                        Eius, dolorem dicta maiores molestiae itaque cumque animi eaque autem facere
                                        neque aut commodi praesentium eligendi, numquam similique reprehenderit est fuga
                                        maxime. Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                                    </p>
                                </div>
                            </li>
                            <li data-aos="flip-left" data-aos-duration="3000"
                                class="benefits-list-item aos-init aos-animate">
                                <div class="inner-benefits-item">
                                    <h6>Real-time application development</h6>
                                    <p>=== Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                                        Eius, dolorem dicta maiores molestiae itaque cumque animi eaque autem facere
                                        neque aut commodi praesentium eligendi, numquam similique reprehenderit est fuga
                                        maxime. Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                                    </p>
                                </div>
                            </li>
                            <li data-aos="flip-left" data-aos-duration="3000"
                                class="benefits-list-item aos-init aos-animate">
                                <div class="inner-benefits-item">
                                    <h6>Single-page application development</h6>
                                    <p>=== Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                                        Eius, dolorem dicta maiores molestiae itaque cumque animi eaque autem facere
                                        neque aut commodi praesentium eligendi, numquam similique reprehenderit est fuga
                                        maxime. Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                                    </p>
                                </div>
                            </li>
                            <li data-aos="flip-left" data-aos-duration="3000"
                                class="benefits-list-item aos-init aos-animate">
                                <div class="inner-benefits-item">
                                    <h6>Custom application development</h6>
                                    <p>=== Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                                        Eius, dolorem dicta maiores molestiae itaque cumque animi eaque autem facere
                                        neque aut commodi praesentium eligendi, numquam similique reprehenderit est fuga
                                        maxime. Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>

        <section class="technologies-section service-description">
            <img src="/img/images-db/images/bg_img/img v8-3_0000.webp" alt="stone" class="team-bg_services">
            <div class="container-db">
                <div class="service-wrapper">
                    <div class="service-text-wrapper">
                        <h2>VueJS Tech Stack</h2>
                        <p>DevBrother has a huge inventory which consists of several latest and cutting-edge tools &
                            technologies. Our VueJS developers are highly experienced to use these technologies to
                            their optimum best.</p>
                    </div>
                </div>
                <div class="three-list-wrapper">
                    <div class="list-wrapper">
                        <ul class="bullet-list">
                            <li class="marked-item">ReactJS</li>
                            <li class="marked-item">Redux</li>
                            <li class="marked-item">HOC</li>
                            <li class="marked-item">Hooks</li>
                            <li class="marked-item">JavaScript</li>
                            <li class="marked-item">TypeScript</li>
                            <li class="marked-item">Jest</li>
                            <li class="marked-item">Chai</li>
                            <li class="marked-item">Cypress IO</li>
                            <li class="marked-item">React Testing Library</li>
                            <li class="marked-item">NextJS</li>
                            <li class="marked-item">Thunk</li>
                            <li class="marked-item">Saga</li>
                        </ul>
                    </div>
                    <div class="list-wrapper">
                        <ul class="bullet-list">
                            <li class="marked-item">RxJS</li>
                            <li class="marked-item">HTML5/CSS3</li>
                            <li class="marked-item">SASS/LESS</li>
                            <li class="marked-item">GraphQL</li>
                            <li class="marked-item">Socket.io</li>
                            <li class="marked-item">Gulp</li>
                            <li class="marked-item">WebPack</li>
                            <li class="marked-item">NPM</li>
                            <li class="marked-item">JSON</li>
                            <li class="marked-item">Ant Design</li>
                            <li class="marked-item">MaterialUI</li>
                            <li class="marked-item">Bootstrap</li>
                            <li class="marked-item">Framer Motion</li>
                        </ul>
                    </div>
                    <div class="list-wrapper">
                        <ul class="bullet-list">
                            <li class="marked-item">PostgreSQL</li>
                            <li class="marked-item">MySQL</li>
                            <li class="marked-item">MongoDB</li>
                            <li class="marked-item">Redis</li>
                            <li class="marked-item">DynamoDB</li>
                            <li class="marked-item">Firebase</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>

        <section class="choose-section service-description">
            <div class="container-db">
                <div class="service-wrapper">
                    <div class="service-text-wrapper">
                        <h2 class="tac">Hire Outsource VueJS Developers Team</h2>
                        <p>Simple & Transparent Pricing &nbsp;|&nbsp;Fully Signed NDA &nbsp;|&nbsp; Code Security &nbsp;|&nbsp;Easy Exit Policy</p>
                        <p>Naturaily is a company with over 5 years of experience in web development, including both
                            good old technologies like Ruby on Rails and innovative JavaScript frameworks like Node.js,
                            Vue.js, React.js, and others.</p>
                        <p>We help businesses grow at each stage of product development - from strategy consulting,
                            prototyping, design and development, to scaling and support</p>
                        <div class="two-list-wrap">
                            <ul>
                                <li class="two-list-item">100% Customer Satisfaction</li>
                                <li class="two-list-item">10+Dedicated VueJS developers Teams</li>
                                <li class="two-list-item">Integrity and Transparency</li>
                                <li class="two-list-item">Core agile believer</li>
                            </ul>
                            <ul>
                                <li class="two-list-item">5+ Years of Experience</li>
                                <li class="two-list-item">Flexible Engagement Models</li>
                                <li class="two-list-item">Competitive Pricing</li>
                                <li class="two-list-item">Working under NDA</li>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>
        </section>

        <section class="engagement-section service-description">
            <img src="/img/images-db/images/bg_img/img v8-3_0000.webp" alt="stone" class="team-bg_services">
            <div class="container-db">
                <div class="service-wrapper">
                    <div class="service-text-wrapper">
                        <h2>Engagement Model to Hire VueJS Developers</h2>
                        <p>Being in Ukraine and Poland, DevBrother offers flexible hiring models from which you can hire
                            the best suitable individual remote developer, managed developers, or a dedicated team of
                            React JS Experts on a full-time, part-time, or hourly basis.
                        </p>
                    </div>
                </div>
                <ul class="benefits-list">
                    <li class="benefits-list-item">
                        <div class="inner-benefits-item">
                            <div class="img-wrapper">
                                <img src="/img/images-db/services/hourly-team.png" alt="icon">
                            </div>
                            <h6>Hourly Basis</h6>
                            <ul>
                                <li class="marked-item">Prefer better half for you business</li>
                                <li class="marked-item">Duration: 8 Hrs/Day-5 Days/Week</li>
                                <li class="marked-item">Billing: Weekly/Daily/Monthly</li>
                                <li class="marked-item">Timeline: Based on Project</li>
                            </ul>
                        </div>
                    </li>
                    <li class="benefits-list-item">
                        <div class="inner-benefits-item">
                            <div class="img-wrapper">
                                <img src="/img/images-db/services/full-time.png" alt="icon">
                            </div>
                            <h6>Full-Time Basis</h6>
                            <ul>
                                <li class="marked-item">Only Works For You</li>
                                <li class="marked-item">Duration: 8 Hrs/Day-5 Days/<br>Week(160 Hrs/Month)</li>
                                <li class="marked-item">Billing: Monthly</li>
                                <li class="marked-item">Timeline: Based on Project</li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </div>
            <a href="#acquainted" class="btn hoverItem" v-smooth-scroll>Let’s talk</a>
        </section>

        <section class="industries-section service-description">
            <div class="container-db">
                <div class="service-wrapper">
                    <div class="service-text-wrapper">
                        <h2>Vue.js Development for Different Business Verticals</h2>
                        <p>We have delivered VueJS solutions for clients hailing from diverse industry verticals</p>
                    </div>
                </div>
                <ul class="cards-wrapper">
                    <li>
                        <p class="image-wrapper">
                            <img src="/img/images-db/services/arts-icon.png" alt="Art Galleries">
                        </p>
                        Art Galleries
                    </li>
                    <li>
                        <p class="image-wrapper">
                            <img src="/img/images-db/services/healthcare-icon.png" alt="HealthCare">
                        </p>
                        HealthCare
                    </li>
                    <li>
                        <p class="image-wrapper">
                            <img src="/img/images-db/services/wordpress-icon.png" alt="WP Development">
                        </p>
                        WP Development
                    </li>
                    <li>
                        <p class="image-wrapper">
                            <img src="/img/images-db/services/ticket-icon.png" alt="Eventing/Ticketing">
                        </p>
                        Eventing/Ticketing
                    </li>
                    <li>
                        <p class="image-wrapper">
                            <img src="/img/images-db/services/bitcoin-icon.png" alt="Cryptocurrency">
                        </p>
                        Cryptocurrency
                    </li>
                    <li>
                        <p class="image-wrapper">
                            <img src="/img/images-db/services/e-commerce-icon.png" alt="E-Commerce">
                        </p>
                        E-Commerce
                    </li>
                    <li>
                        <p class="image-wrapper">
                            <img src="/img/images-db/services/finance-icon.png" alt="Finance">
                        </p>
                        Finance
                    </li>
                    <li>
                        <p class="image-wrapper">
                            <img src="/img/images-db/services/fedex3-icon.png" alt="Logistics">
                        </p>
                        Logistics
                    </li>
                </ul>
            </div>
        </section>

        <section class="companiesUse-section service-description">
            <div class="container-db">
                <div class="service-wrapper">
                    <div class="service-text-wrapper">
                        <h2 class="tac">What Companies Use VueJS</h2>
                        <p class="tac">
                            Because of the advantages of Vue.js, many companies and businesses use the framework to
                            enhance their software development process. Many largest companies dicides that VueJS right
                            choice for their business.
                            Among them are such giants as Facebook, Netflix, Xiaomi, Apple, Google, BMW, Gitlab,
                            Nintendo, Adobe.
                        </p>
                    </div>
                </div>

                <ul class="cards-wrapper">
                    <li>
                        <p class="image-wrapper">
                            <img src="/img/images-db/services/facebook-icon.png" alt="facebook-icon.png">
                        </p>
                    </li>

                    <li>
                        <p class="image-wrapper">
                            <img src="/img/images-db/services/netflix-icon.png" alt="netflix-icon.png">
                        </p>
                    </li>
                    <li>
                        <p class="image-wrapper">
                            <img src="/img/images-db/services/xiaomi-icon.png" alt="xiaomi-icon.png">
                        </p>
                    </li>

                    <li>
                        <p class="image-wrapper">
                            <img src="/img/images-db/services/apple-icon.png" alt="apple-icon.png">
                        </p>
                    </li>
                    <li>
                        <p class="image-wrapper">
                            <img src="/img/images-db/services/google-icon.png" alt="google-icon.png">
                        </p>
                    </li>
                    <li>
                        <p class="image-wrapper">
                            <img src="/img/images-db/services/bmw-icon.png" alt="bmw-icon.png">
                        </p>
                    </li>
                    <li>
                        <p class="image-wrapper">
                            <img src="/img/images-db/services/gitlab-icon.png" alt="gitlab-icon.png">
                        </p>
                    </li>
                    <li>
                        <p class="image-wrapper">
                            <img src="/img/images-db/services/nintendo-icon.png" alt="nintendo-icon.png">
                        </p>
                    </li>
                    <li>
                        <p class="image-wrapper">
                            <img src="/img/images-db/services/adobe-icon.png" alt="adobe-icon.png">
                        </p>
                    </li>
                </ul>
            </div>
        </section>

        <section class="faq-section">
            <img src="/img/images-db/images/bg_img/img v8-3_0000.webp" alt="stone" class="team-bg_services">
            <div class="container-db">
                <div id="app" class="">
                    <app-accordion @closeAccordions='closeAccordions()' v-for="(accordion, index) in accordions"
                        ref="accordion" :key="index" class="app-accordion">
                        <template v-slot:title>
                            <span class="">{{ accordion.title }}</span>
                        </template>
                        <template v-slot:content>
                            <p>
                                {{ accordion.text }}
                            </p>
                        </template>
                    </app-accordion>
                </div>

            </div>
        </section>


        <section class="acquainted main-contact" id="acquainted">
            <div class="container-db">
                <div class="acquainted-form-wrapper">
                    <div class="bottom-stone"><img src="/img/images-db/parallax/stone2.webp" alt=""></div>
                    <div class="cooperation-offer">
                        <h3>What's your challenge?</h3>
                        <h4 class="cooperation-offer-text">
                            Let us deliver the talent and expertise to help you solve it.
                        </h4>
                    </div>
                    <AcquaintedForm />
                </div>
            </div>
        </section>


    </main>
</template>
<script>
import AcquaintedForm from "../../partials/AcquaintedForm";
import { requestGet, requestPost } from "../../../services/axios.js";
import AppAccordion from "./AppAccordion";

let vm = {};
export default {
    name: 'Vue',
    metaInfo: {
    },
    components: { AcquaintedForm, AppAccordion },
    data() {
        return {
            accordions: [
                {
                    title: 'How much does it cost to Hire a dedicated Vue.js developer?',
                    text: 'Lorem ipsum',
                },
                {
                    title: 'How do I hire Vue.JS developers or team?',
                    text: 'Lorem ipsum',
                },
                {
                    title: 'Can I outsource Vue.JS developer to work with my existing team?',
                    text: 'Lorem ipsum',
                },
                {
                    title: 'What our VueJS developers can do for you?',
                    text: 'Lorem ipsum',
                },
                {
                    title: 'Can SMEs start with Vue app development?',
                    text: 'Lorem ipsum',
                },
                {
                    title: 'Why should you choose Vue.js for your upcoming project?',
                    text: 'Lorem ipsum',
                },
            ],
            isMailSend: false,
            mailData: {
                name: "",
                email: "",
                comment: ""
            },
            errors: [],
            styles: {
                name: {
                    input: "",
                    label: "",
                    span: "visibility: hidden",
                },
                email: {
                    input: "",
                    label: "",
                    span: "visibility: hidden",
                },
                comment: {
                    input: "",
                    label: "",
                    span: "visibility: hidden;",
                }
            }
        };
    },
    beforeCreate() {
        vm = this;
    },
    mounted: () => {
        vm.addCanonicalLink();
    },
    methods: {
        addCanonicalLink: () => {
            const link = document.createElement('link');
            link.setAttribute('rel', 'canonical');
            link.setAttribute('href', 'https://devbrother.com/services/web-development/vue');

            const oldLink = document.querySelector('link[rel="canonical"]');
            if (oldLink) {
                document.head.removeChild(oldLink);
            }

            document.head.appendChild(link);
        },
        closeAccordions() {
            this.$refs.accordion.forEach(el => el.closeAccordion());
        },
        checkForEmptiness: (field) => {
            if (vm.mailData[field] === "") {
                vm.addWarning(field)
            } else {
                vm.styles[field]["span"] = "visibility: hidden";
            }
        },
        addWarning: (field) => {
            vm.styles[field]["input"] = "border: 1px solid red;"
            vm.styles[field]["span"] = "visibility: visible";
        },
        raiseLabelUp: field => {
            vm.styles[field]["input"] = "";
            vm.styles[field]["span"] = "visibility: hidden";
        },
        validEmail: email => {
            const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },
        hasErrors: () => {
            let gotError = false;
            Object.keys(vm.mailData).forEach(item => {
                if (vm.mailData[item] == "") {
                    gotError = true;
                    vm.checkForEmptiness(item);
                } else if ((item == "email" && !vm.validEmail(vm.mailData[item]))) {
                    gotError = true;
                    vm.checkForEmptiness(item);
                    vm.addWarning('email');
                }
            });
            return gotError;
        },
        sendMail: (event) => {
            if (!vm.hasErrors()) {
                requestPost("/contact-about-us", vm.mailData)
                    .then(res => {
                        if (res.status) {
                            vm.isMailSend = true;
                            vm.mailData.name = "";
                            vm.mailData.email = "";
                            vm.mailData.comment = "";
                        } else {
                            console.log("Email send failed, error", res.error);
                        }
                    })
                    .catch(error => {
                        return { status: false, error };
                    });
            }
        }
    }
}
</script>
<style lang="scss" scoped>
@media (max-width: 1220px){
    .choose-section {
        .service-text-wrapper{
            max-width: 650px !important;
        }
    }
}
</style>
