<template>
  <!--SECTION INDUSTRIES-->
  <main class="laravel-section">
    <div class="top-stone">
    </div>
    <div class="pagination">
      <div class="container-db">
        <div class="bread-crumbs">
          <router-link to="/" class="router-link-active">Home</router-link>
          <span class="bread-separator">/</span>
          <router-link to="/services/web-development">Web Development</router-link>
          <span class="bread-separator">/</span>
          <span class="bread-current">Laravel</span>
        </div>
      </div>
    </div>

    <section class="service-description">
      <div class="container-db">
        <div class="service-wrapper">
          <div class="service-text-wrapper">
            <h1>Laravel Development Company</h1>
            <p>
              A Laravel development company specializes in creating web applications using the Laravel framework. We offer services, from custom Laravel development to maintenance and support, to help businesses of all sizes build robust, scalable, and secure web solutions.</p>
          </div>

          <div class="stone-image-wrapper">
            <div class="service-contact">
              <p class="service-contact__title">Free consultation</p>
              <p>We advise choosing the right technology and strategies for developing a new product.</p>
              <p>Let's arrange a short phone or video call? We will listen to your wishes and select a team of
                professionals to implement the product.</p>
              <button @click="showPopup" class="btn form-btn">Contact us</button>
            </div>
            <div class="top-stone mobile">
            </div>
          </div>
        </div>
      </div>
    </section>
        <section class="life-cycle dedicated-section service-description">
            <div class="container-db">
                <div v-lazyload data-src="/img/images-db/icons-industry/ethereum.png" class="lazy-bg bg-icon">
                </div>
                <div class="service-wrapper">
                    <div class="service-text-wrapper advantages">
                        <h2>
                            Advantages of Laravel Web Development Company
                        </h2>
                        <p>
                            Laravel is a robust PHP framework that offers numerous benefits for Laravel web development company.
                        </p>
                        <ol class="text-img-list">
                            <li>
                                <div class="text-img-wrap">
                                    <div class="card-text">
                                <h3 class="marked">Rapid Application Development:</h3>
                                        <p>
                                            Laravel's elegant syntax and pre-built components accelerate development, allowing faster time-to-market.
                                        </p>
                                    </div>
                                    <div class="img-wrap service-img-wrapper">
                                        <img v-lazyload data-url="/img/images-db/services/laravel web development.webp"
                                            alt="laravel web development" height="307" width="460">
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="text-img-wrap inverted">
                                    <div class="img-wrap service-img-wrapper">
                                        <img v-lazyload data-url="/img/images-db/services/secure laravel web development.webp" alt="secure with laravel"
                                            height="307" width="460">
                                    </div>
                                    <div class="card-text">
                                        <h3 class="marked">Highly Secure:</h3>
                                        <p>
                                            With built-in security features and protection against common vulnerabilities, Laravel ensures the safety of your application and user data.
                                        </p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="text-img-wrap">
                                    <div class="card-text">
                                        <h3 class="marked">Robust Performance:</h3>
                                        <p>
                                            Optimized for speed and efficiency, Laravel delivers high-performance web applications that can handle heavy traffic.
                                        </p>
                                    </div>
                                    <div class="img-wrap service-img-wrapper">
                                        <img v-lazyload data-url="/img/images-db/services/speed with laravel development.webp"
                                            alt="speed with laravel" height="307" width="460">
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="text-img-wrap inverted">
                                    <div class="img-wrap service-img-wrapper">
                                        <img v-lazyload data-url="/img/images-db/services/integration with laravel.webp"
                                            alt="integration with laravel" height="307" width="460">
                                    </div>
                                    <div class="card-text">
                                        <h3 class="marked">Easy Integration:</h3>
                                        <p>
                                            Seamlessly integrate various third-party services and APIs to expand your application's functionality.Seamlessly integrate various third-party services and APIs to expand your application's functionality.
                                        </p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="text-img-wrap">
                                    <div class="card-text">
                                        <h3 class="marked">Built-in Testing:</h3>
                                        <p>
                                            Laravel's testing features help ensure code quality and reliability through automated testing.
                                        </p>
                                    </div>
                                    <div class="img-wrap service-img-wrapper">
                                        <img v-lazyload data-url="/img/images-db/services/laravel tessting.webp"
                                            alt="testing process" height="307" width="460">
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="text-img-wrap inverted">
                                    <div class="img-wrap service-img-wrapper">
                                        <img v-lazyload data-url="/img/images-db/services/cost savings laravel.webp"
                                            alt="cost savings laravel" height="307" width="460">
                                    </div>
                                    <div class="card-text">
                                        <h3 class="marked">Cost-Effective:</h3>
                                        <p>
                                          You can often reduce development costs by leveraging Laravel's efficiency and open-source nature.
                                        </p>
                                    </div>
                                </div>
                            </li>
                        </ol>
                        <a href="#questions" class="btn" v-smooth-scroll>Let’s talk</a>
                    </div>
                </div>
            </div>
        </section>
        <div class="service-text-wrapper our-web-related-technologies laravel-services">
          <h2>Our Laravel Website Development Services</h2>
          <p>
            Our company offers a comprehensive range of Laravel development services tailored to your specific needs:
          </p>
          <div class="three-list-wrapper">
            <div class="list-wrapper">
              <ul class="bullet-list">
                <li class="marked-item router-link-active">Custom Laravel application development</li>
                <li class="marked-item">Laravel e-commerce solutions</li>
                <li class="marked-item">Laravel CMS development</li>
              </ul>
            </div>

            <div class="list-wrapper">
              <ul class="bullet-list">
                <li class="marked-item router-link-active">Laravel API development</li>
                <li class="marked-item">Laravel migration and upgrade</li>
                <li class="marked-item">Laravel maintenance and support</li>
              </ul>
            </div>
          </div>
        </div>
        <section class="software-benefits dedicated-section service-description laravel-process">
            <div class="container-db">
                <div v-lazyload data-src="/img/images-db/icons-industry/monero-coin.png" class="lazy-bg bg-icon">
                </div>
                <div class="service-wrapper">
                    <div class="service-text-wrapper key-benefits">
                        <h2>Our Laravel Development Services Process</h2>
                        <p>
                            We follow a structured development process to deliver high-quality projects:
                        </p>
                        <div class="wrap">
                            <ol>
                                <li>
                                    <span class="marked">Requirements gathering and analysis.</span> Clearly define project goals and user needs and document functional and non-functional requirements.
                                </li>
                                <li>
                                    <span class="marked">Design and Planning.</span> Create wireframes, mockups, and detailed project plans that describe the structure and functionality of the application.
                                </li>
                                <li>
                                    <span class="marked">Development and Coding.</span> Implement application functionality based on an approved design, following coding standards and best practices.
                                </li>
                                <li>
                                    <span class="marked"><router-link to="/services/qa-automation">Testing and Quality Assurance.</router-link></span> Conducting thorough testing to identify and fix bugs, ensuring the application meets quality standards and user expectations.
                                </li>
                                <li>
                                    <span class="marked">Implementation and launch.</span> Release the application to the target audience through the appropriate channels and platforms.
                                </li>
                                <li>
                                    <span class="marked">Support and Maintenance.</span> Providing continuous support, updates, and bug fixes to ensure the efficient operation of the application and user satisfaction.
                                </li>
                            </ol>
                            <div class="img-wrap service-img-wrapper">
                              <img class="key-benefits-image" v-lazyload data-url="/img/images-db/services/DB-team.webp"
                                        alt="Devbrother team" height="350" width="525">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div class="service-text-wrapper laravel-services laravel-choose">
          <h2>Why do People Choose us?</h2>
          <ul>
            <li class="marked-item"><b>Proven track record of successful Laravel projects</b>: Our <router-link to="/services/web-development">web development</router-link> portfolio showcases a wide range of complex applications we've built using Laravel. We understand the intricacies of the framework and leverage its potential to deliver exceptional results.</li>
            <li class="marked-item"><b>Team of skilled and experienced Laravel developers</b>: Our developers possess in-depth knowledge of Laravel's ecosystem. They stay updated with the latest trends and best practices to ensure your project is in capable hands.</li>
            <li class="marked-item"><b>Client-centric approach and focus on satisfaction</b>: We believe in building long-term partnerships with our clients. Your success is our priority, and we strive to exceed your expectations at every project stage.</li>
            <li class="marked-item"><b>Commitment to delivering high-quality solutions</b>: We adhere to rigorous quality standards throughout the development process. Our attention to detail and dedication to excellence guarantee a top-notch product.</li>
            <li class="marked-item"><b>Competitive pricing and flexible engagement models</b>: We offer transparent pricing and flexible engagement options to fit your budget and project requirements. We can accommodate your needs whether you need a fixed-price or hourly-based model.</li>
          </ul>
        </div>

    <section class="laravel-portfolio">
      <div class="container-db">
        <div class="service-wrapper">
          <h2>Portfolio</h2>
          <p class="laravel-portfolio-description">Explore our portfolio to see examples of our Laravel development work.</p>
          <CaseStudies />
        </div>
      </div>
    </section>
    <section class="faq-section">
      <h2>Frequently Asked Questions</h2>
        <div class="container-db">
          <div v-lazyload data-src="/img/images-db/services/lite-coin.png" class="lazy-bg bg-icon">
          </div>
          <div id="app">
            <app-accordion @closeAccordions='closeAccordions()' v-for="(accordion, index) in accordions"
                        ref="accordion" :key="index" class="app-accordion">
              <template v-slot:title>
                <span>{{ accordion.title }}</span>
              </template>
              <template v-slot:content>
                <p>
                  {{ accordion.text }}
                </p>
              </template>
            </app-accordion>
          </div>
        </div>
    </section>

    <h2 id="questions" class="more-questions">If you have any more questions, write to us</h2>
    <AcquaintedSection />
    <div v-lazyload data-src="/img/images-db/parallax/stone2.webp" class="lazy-bg bottom-stone"></div>

    <div class="popup" :class="{ 'hide-popup': !showModal }">
      <div class="popup-wrapper">
        <div class="popup-inner" @click.self="closePopup()">
          <div class="popup-content" id="service-contact-form">
            <button class="close-popup" @click="closePopup()" type="button" aria-label="close popup"></button>
            <ServicesTopForm />
          </div>
        </div>
      </div>
    </div>

    <script v-html="jsonld" type="application/ld+json"></script>
  </main>
</template>
<script>
import AcquaintedSection from '../../AcquaintedSection';
import ServicesTopForm from '../../ServicesTopForm';
import AppAccordion from "./AppAccordion";
const CaseStudies = () => import("../../Home/CaseStudies");

let vm = {};
export default {
  name: 'Laravel',
  metaInfo: {
    title: 'Laravel Development Company in USA | DevBrother',
    meta: [
      {
        vmid: "description",
        name: 'description',
        content: 'Custom Laravel development company help in delivering fast, feature-rich websites including customization, CRM, eCommerce, Marketplace, and API integration'
      },
      {
        vmid: "og:image:type",
        name: "og:image:type",
        content: 'image/webp'
      },
      {
        vmid: "og:title",
        name: "og:title",
        content: 'Laravel Development Company in USA | DevBrother'
      },
      {
        vmid: "og:description",
        name: "og:description",
        content: 'Custom Laravel development company help in delivering fast, feature-rich websites including customization, CRM, eCommerce, Marketplace, and API integration'
      },
      // {
      //   vmid: "og:image",
      //   name: "og:image",
      //   content: 'https://devbrother.com/img/images-db/images/e-commerce.webp'
      // },
      {
        vmid: "og:type",
        name: "og:type",
        content: "website"
      },
      // {
      //   vmid: "og:url",
      //   name: "og:url",
      //   content: 'https://devbrother.com/services/web-development'
      // },
      {
        vmid: "twitter:title",
        name: "twitter:title",
        content: 'Laravel Development Company in USA | DevBrother'
      },
      {
        vmid: "twitter:description",
        name: "twitter:description",
        content: 'Custom Laravel development company help in delivering fast, feature-rich websites including customization, CRM, eCommerce, Marketplace, and API integration'
      },
      // {
      //   vmid: "twitter:image",
      //   name: "twitter:image",
      //   content: 'https://devbrother.com/img/images-db/images/e-commerce.webp'
      // }
    ]
  },
  components: {
    AcquaintedSection,
    ServicesTopForm,
    AppAccordion,
    CaseStudies
  },
  data() {
    const jsonld = {
      "@context": "https://schema.org",
      "@type": "Service",
      "name": "Laravel Development Company in USA | DevBrother",
      "description": "Custom Laravel development company help in delivering fast, feature-rich websites including customization, CRM, eCommerce, Marketplace, and API integration"
    }

    return {
      jsonld,
      showModal: false,
      accordions: [
        {
          title: 'How long does it take to build a Laravel website?',
          text: "The development time depends on the project's complexity. Typically, simple websites can be built within weeks, while larger applications may take several months."
        },
        {
          title: 'Can Laravel be used for scalable application development?',
          text: "Yes, Laravel is highly scalable. It can handle increasing traffic and data volumes through caching, load balancing, and horizontal scaling."
        },
        {
          title: 'What is the estimated cost of a standard Laravel web application?',
          text: "The cost of a Laravel application varies based on factors such as features, complexity, and design. After understanding your project requirements, we provide customized quotes."
        },
      ],
    }
  },
  beforeCreate() {
    vm = this;
  },
    mounted: () => {
            vm.body = document.querySelector("body");
            vm.addCanonicalLink();
    },
    methods: {
            addCanonicalLink: () => {
              const link = document.createElement('link');
              link.setAttribute('rel', 'canonical');
              link.setAttribute('href', 'https://devbrother.com/services/laravel-development-company');

              const oldLink = document.querySelector('link[rel="canonical"]');
              if (oldLink) {
                document.head.removeChild(oldLink);
              }

              document.head.appendChild(link);
            },
            showPopup: () => {
              vm.showModal = true;
              vm.body.classList.add('ban-scroll');
            },
            closePopup: () => {
              vm.showModal = false;
              vm.body.classList.remove('ban-scroll');
            },
            scrollFix: hash => {
              setTimeout(() => {
                setTimeout(() => $('html, body')
                  .animate({ scrollTop: $(hash).offset().top }, 1000), 1)
              });
            },
            closeAccordions() {
              this.$refs.accordion.forEach(el => el.closeAccordion());
            },
  }
}
</script>
<style lang="scss" scoped>
.laravel-choose  {
  h2 {
    text-align: center;
  }
}

.laravel-portfolio {
  h2 {
    margin-top: 100px;
    text-align: center;
  }

  ::v-deep .case-studies .inner-container h2 {
    display: none;
  }

  .laravel-portfolio-description {
    margin-bottom: -50px;
    text-align: center;
  }
}

.laravel-section {
  .faq-section {
    margin-top: 150px;
  }

  .more-questions {
    margin-top: 150px;
    text-align: center;
  }
}

.laravel-process {
  margin-top: 150px;
}

.laravel-services {
  margin: 0 auto;
}

.service-contact {
    max-height: 430px;
}

ol {
    padding: 0;
    list-style-type: none;

    li {
        position: relative;
        margin-bottom: 15px;
        padding-left: 35px;

        &::before {
            content: "";
            position: absolute;
            width: 20px;
            height: 20px;
            top: 3px;
            left: 0;
            background: url(/img/images-db/services/check_circle_icon.svg) no-repeat;
            background-size: contain;
        }
    }
}

//.service-description .service-img-wrapper 

.service-description {

    a {
        color: #06c6ff;
        text-decoration: underline;

        &:hover {
            opacity: 0.85;
        }
    }

    .service-img-wrapper {
        max-width: 550px;
        height: auto;

        img {
            filter: grayscale(0.6);
            border-radius: 20px;
        }

        .key-benefits-image  {
            min-height: 525px;
        }
    }

    .advantages {
        max-width: 90%;
        margin-left: auto;
        margin-right: auto;

        h2 {
            text-align: center;
        }

        .bottom-info {
            margin-top: 30px;
        }
    }

    .key-benefits {
        max-width: 90%;
        margin-left: auto;
        margin-right: auto;

        h2 {
            text-align: center;
        }

        ol {
            width: 60%;
        }

        .img-wrap {
            width: 37%;
            margin-top: 0;
        }
    }
}

.marked {
    color: #06c6ff;
}

.service-text-wrapper {
    padding: 30px;
    border-radius: 40px;
    background: linear-gradient(342.18deg, #1b222a 0%, #2b506e 100%);
}

.text-img-list {
    max-width: 900px;
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;

    h3 {
        text-align: center;
        width: 100%;
    }

    .text-img-wrap {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    li {
        padding-left: 0;

        &:not(:last-child) {
            margin-bottom: 50px;
        }

        &::before {
            display: none;
        }
    }

    .card-text {
        width: 50%;
        p{
            max-width: 425px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .img-wrap {
        width: 40%;
    }
}

.list-inner-wrap {
    display: flex;
    align-items: center;
}

.advantages {
    .btn {
        display: block;
        width: 200px;
        margin-top: 30px;
        margin-left: auto;
        margin-right: auto;
        color: #1f2427;
        text-decoration: none;

        &:hover {
            color: #ffffff;
        }
    }
}

.life-cycle{
    img{
        min-height: 240px;
        max-height: 240px;
        object-fit: cover;
    }
}

.wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .img-wrap {
        max-width: 350px;
    }
}

@media (max-width: 1220px) {
    .top-form-section .service-text-wrapper {
        max-height: none;
    }

    .industry-top-form {
        margin-top: 30px;
    }

    .advantages {
        .img-wrap {
            margin-top: 0;
        }
    }
}

@media (max-width: 990px) {

    .health-technologies {
        li {
            width: 48%;
        }
    }

}

@media (max-width: 900px) {

    .text-img-list {

        h3 {
            text-align: center;
            width: 100%;
        }

        .text-img-wrap {
            flex-direction: column-reverse;
            align-items: center;

            &.inverted {
                flex-direction: column;
            }
        }

        .card-text {
            width: 100%;
        }

        .img-wrap {
            width: 100%;
        }
    }

    .img-wrap.service-img-wrapper {
        max-width: 400px;
        margin-top: 0;
        margin-bottom: 25px;
    }

    .inverted {
        flex-direction: column-reverse;
    }

    .service-description {
        .key-benefits {
            .wrap {
                flex-direction: column-reverse;
            }

            ol {
                width: 100%;
            }

            .img-wrap {
                max-width: 400px;
                width: 100%;
                margin-bottom: 25px;
            }
        }
    }

    .software-benefits{
        .img-wrap{
            width: auto!important;
            img{
                max-height: 400px;
            }
        }
    }
}

@media (max-width: 768px) {
    .bg-icon {
        display: none;
    }
}

@media (max-width: 767px) {
    .service-text-wrapper {
        padding: 30px 15px;
    }

    .health-technologies {
        h3 {
            font-size: 20px;
            letter-spacing: 0.45px;
            line-height: 30px;
        }

    }
}

@media (max-width: 550px) {

    h1 {
        font-size: 30px;
        line-height: 37px;
    }

    h2 {
        font-size: 26px;
    }

    .health-technologies {
        li {
            width: 100%;
        }

    }
}
</style>