<template>    <!--SECTION INDUSTRIES-->
	<main>
    <div class="top-stone">
    </div>
    <div class="pagination">
      <div class="container-db">
        <div class="bread-crumbs">
          <router-link to="/">Home</router-link>
          <span class="bread-separator">/</span>
          <span class="bread-current">IT Consulting</span>
        </div>
      </div>
    </div>
    <section class="service-description container-db">
      <div class="service-wrapper">
        <div class="service-text-wrapper">
          <h1>IT Consulting Services</h1>
          <p>
            DevBrother has very deep expertise in some technical areas. We are happy to share our expertise with you. We have rockstar software engineers with tens years of experience on millions users projects. Their experience can be helpful for lots of companies that didn’t face loads or issues like this on real projects before.
          </p>
        </div>
        <div class="stone-image-wrapper">
            <div class="service-contact">
              <p class="service-contact__title">Free consultation</p>
              <p>We advise choosing the right technology and strategies for developing a new product.</p>
              <p>Let's arrange a short phone or video call? We will listen to your wishes and select a team of
                professionals to implement the product.</p>
              <button @click="showPopup" class="btn form-btn">Contact us</button>
            </div>
          <div class="top-stone mobile">
          </div>
        </div>
      </div>
			<div class="service-text-wrapper">
        <h2>Here is the list of major things we’ll be happy to help you with</h2>
        <ul class="bullet-list two-column-list">
          <li class="marked-item">Hosting Infrastructure</li>
          <li class="marked-item">Project architecture</li>
          <li class="marked-item">Slow Project speed</li>
          <li class="marked-item">Database issues</li>
          <li class="marked-item">High load issues</li>
          <li class="marked-item">Project estimation</li>
          <li class="marked-item">Security issues</li>
          <li class="marked-item">Backup and stable project work</li>
          <li class="marked-item">Specifications and technical description</li>
          <li class="marked-item">Low stats on google analytics and other SEO issues </li>
        </ul>
				<p>
					All of this and many other issues we can handle and solve to make your life easier.
				</p>
				<p>
					Just <a href="mailto:contact@devbrother.com" class="contact-us"> contact us </a> and ask whatever questions you have. No worries if you are not sure how to ask something in a proper way - we’ll help you to understand what you need and  usually we  answer earlier than you ask.
				</p>
			</div>
    </section>
    <AcquaintedSection />
    <div v-lazyload data-src="/img/images-db/parallax/stone2.webp" class="lazy-bg bottom-stone"></div>

    <div class="popup" :class="{ 'hide-popup': !showModal}">
      <div class="popup-wrapper">
        <div class="popup-inner" @click.self="closePopup()">
          <div class="popup-content" id="service-contact-form">
            <button class="close-popup" @click="closePopup()" type="button" aria-label="close popup"></button>
            <ServicesTopForm />
          </div>
        </div>
      </div>
    </div>

    <script v-html="jsonld" type="application/ld+json"></script>
  </main>
</template>
<script>
    import AcquaintedSection from '../AcquaintedSection';
    import ServicesTopForm from '../ServicesTopForm';

    let vm = {};
    export default {
        name: 'Itcosulting',
        metaInfo: {
            title: 'IT Consulting Services: Infrastructure, Planning, Hosting, Database',
            meta: [
              {
                vmid: "description",
                name: 'description',
                content: 'Best Company【IT Consulting Services】.DevBrother will help with Hosting, Database, Project Estimation, Architecture, Strategy, Service Management.'
              },
              {
                vmid: "og:image:type",
                name: "og:image:type",
                content: 'image/webp'
              },
              {
                vmid: "og:title",
                name: "og:title",
                content: 'IT Consulting Services: Infrastructure, Planning, Hosting, Database'
              },
              {
                vmid: "og:description",
                name: "og:description",
                content: 'Best Company【IT Consulting Services】.DevBrother will help with Hosting, Database, Project Estimation, Architecture, Strategy, Service Management.'
              },
              // {
              //   vmid: "og:image",
              //   name: "og:image",
              //   content: 'https://devbrother.com/images/...'
              // },
              {
                vmid: "og:type",
                name: "og:type",
                content: "website"
              },
              {
                vmid: "og:url",
                name: "og:url",
                content: 'https://devbrother.com/services/it-consulting'
              },
              {
                vmid: "twitter:title",
                name: "twitter:title",
                content: 'IT Consulting Services: Infrastructure, Planning, Hosting, Database'
              },
              {
                vmid: "twitter:description",
                name: "twitter:description",
                content: 'Best Company【IT Consulting Services】.DevBrother will help with Hosting, Database, Project Estimation, Architecture, Strategy, Service Management.'
              },
              // {
              //   vmid: "twitter:image",
              //   name: "twitter:image",
              //   content: 'https://devbrother.com/images/...'
              // }
            ]
        },
        components: {
				  AcquaintedSection,
          ServicesTopForm
				},
        data() {
          const jsonld =  {
            "@context": "https://schema.org",
            "@type": "Service",
            "name": "IT Consulting Services: Infrastructure, Planning, Hosting, Database",
            "description": "Best Company【IT Consulting Services】.DevBrother will help with Hosting, Database, Project Estimation, Architecture, Strategy, Service Management."
          }

          return {
            jsonld,
            showModal: false,
          }
        },
           beforeCreate(){
            vm = this;
        },
          mounted: () => {
            vm.body = document.querySelector("body");
            vm.addCanonicalLink();
          },
          methods: {
            addCanonicalLink: () => {
              const link = document.createElement('link');
              link.setAttribute('rel', 'canonical');
              link.setAttribute('href', 'https://devbrother.com/services/it-consulting');

              const oldLink = document.querySelector('link[rel="canonical"]');
              if (oldLink) {
                document.head.removeChild(oldLink);
              }

              document.head.appendChild(link);
            },
            showPopup: () => {
              vm.showModal = true;
              vm.body.classList.add('ban-scroll');
            },
            closePopup: () => {
              vm.showModal = false;
              vm.body.classList.remove('ban-scroll');
            },
            scrollFix: hash => {
              setTimeout(() => {
                setTimeout(() => $('html, body')
                .animate({scrollTop: $(hash).offset().top}, 1000), 1)
              });
            },
        }
    }
</script>


