<template>
    <main class="web-reactjs">
        <div class="top-stone">
        </div>
        <div class="pagination">
            <div class="container-db">
                <div class="bread-crumbs">
                    <router-link to="/" class="router-link-active">Home</router-link>
                    <span class="bread-separator">/</span>
                    <span class="bread-current">IT Staff Augmentation</span>
                </div>
            </div>
        </div>

        <section class="top-form-section service-description">
            <div class="container-db">
                <div class="service-wrapper">
                  <div class="service-text-wrapper">
                      <h1>Staff Augmentation Services</h1>
                      <p>Behind every successful project, there is a dedicated team of professionals who are endowed with
                        the necessary skills and experience. IT staff augmentation services can assist in finding the best
                        candidates with the necessary skills and experience to complete the tasks assigned by the client.</p>
                      <p>DevBrother provides a wide range of services all around the globe with development centers in Eastern
                        Europe and mostly focusing on JavaScript,
                        <router-link to="/services/web-development/react">React.js</router-link>,
                        <router-link to="/services/web-development/node">Node.js</router-link> stack.
                      </p>
                      <p>Our Software Engineers Database already has around 30,000 professionals, and we are constantly
                        expanding with new prospective candidates. So, any small enterprise or big corporation will not
                        be left without high-quality employees for any type of project.</p>
                      <p>DevBrother's services use state-of-the-art recruitment methods to improve your productivity.
                        We select an individual approach according to the client's requirements, regardless of the request:
                        the search for one Java developer or the whole team selection.</p>
                  </div>


                    <div class="service-contact">
                        <p class="service-contact__title">Free consultation</p>
                        <p>We advise choosing the right technology and strategies for developing a new product.</p>
                        <p>Let's arrange a short phone or video call? We will listen to your wishes and select a team of
                            professionals to implement the product.</p>
                        <button @click="showPopup" class="btn form-btn">Contact us</button>
                    </div>

                </div>
            </div>
        </section>

        <section class="dedicated-section service-description">
            <div data-src="/img/images-db/images/bg_img/img v8-4_0000.webp" class="lazy-bg team-bg_services"
                style="background-image: url('/img/images-db/images/bg_img/img v8-4_0000.webp')"></div>
            <div class="container-db">
                <div class="service-wrapper">
                    <div class="service-text-wrapper">
                        <h2>IT Staff Augmentation Services We Provide</h2>
                        <p>Services that customers can order and receive qualified assistance:</p>
                        <ul>
                            <li class="marked-item"><span class="marked">Additional personnel to an existing project.</span>
                              If you already have a team but need other testers or consultants, we will be happy to hire
                              the best auxiliary engineers for you.</li>
                            <li class="marked-item"><span class="marked">Changing the development team.</span>
                              If you need to change a team in the shortest possible time, we can manage it. All team members
                              will meet your professional and skill requirements.</li>
                            <li class="marked-item"><span class="marked">Short-Term IT Staff Augmentation.</span>
                              If you need to supplement your existing staff for a shorter period of time, we are here to
                              help you temporarily expand your capacity. Usually, these are short-term agreements from 2
                              weeks to 2 months.</li>
                            <li class="marked-item"><span class="marked">Long-Term Extended Team.</span> The project leading
                              from its development, launch, and further support by one group has many advantages.
                              We can provide professionals for the long term.
                            </li>
                        </ul>
                    </div>
                    <div class="service-img-wrapper"><img v-lazyload data-url="/img/images-db/services/we-provide.webp"
                            alt="developers coworking" title="developers coworking" height="307" width="460">
                    </div>
                </div>
            </div>
        </section>

        <div class="inner-container">
            <section class="clients awards-section">
                <div class="clients-wrapper award-wrapper">
                    <div class="inner-container">
                        <h2 class="our-achievements">Reviews about us</h2>
                        <ul class="award-list">
                            <li class="awards-list-item" data-aos="zoom-in" data-aos-duration="1500">
                                <a target="_blank" href="https://www.goodfirms.co/company/devbrother"
                                    rel="nofollow"><img v-lazyload height="160" width="187"
                                        data-url="https://goodfirms.s3.amazonaws.com/badges/color-badge/top-software-development-companies.svg"
                                        alt="GoodFirms Badge"></a>
                            </li>
                            <li class="awards-list-item" data-aos="zoom-in" data-aos-duration="500">
                                <a href="https://clutch.co/profile/devbrother" target="_blank" rel="nofollow">
                                    <img v-lazyload class="award-logo" data-url="/img/images-db/socials/award2.png"
                                        height="160" width="148" alt="Clutch Top Developers">
                                </a>
                                <!-- <div class="clutch-widget clutch-widget-mobile" data-url="https://widget.clutch.co"
                                    data-widget-type="1" data-height="50" data-darkbg="1"
                                    data-clutchcompany-id="1327434"></div> -->
                            </li>
                            <li class="awards-list-item" data-aos="zoom-in" data-aos-duration="500">
                                <a class="top-soft-award-logo"
                                    href="https://topsoftwarecompanies.co/ukraine/software-development/agencies/kharkiv"
                                    target="_blank" rel="nofollow">
                                    <img v-lazyload height="150" width="150"
                                        data-url="/img/images-db/socials/top-software-developers.webp"
                                        alt="software developers kharkiv" title="Software Developers Kharkiv" />
                                </a>
                            </li>
                        </ul>
                    </div>
                    <!-- <div class="clutch-widget-stars clutch-widget-desktop">
                        <div class="clutch-widget" data-url="https://widget.clutch.co" data-widget-type="1"
                            data-height="50" data-darkbg="1" data-clutchcompany-id="1327434"></div>
                    </div> -->
                </div>
            </section>
        </div>

        <section class="dedicated-section service-description">
            <div class="container-db">
                <div class="service-wrapper">
                    <div class="service-text-wrapper advantages">
                        <h2>Advantages of the IT Staff Augmentation due to Specialists in the Software Development</h2>
                        <ol>
                            <li><span class="marked">Transparency of the workflow.</span> The customer and his team at
                                any time can access the project or service being developed at different stages.</li>
                            <li><span class="marked">All intellectual property rights are yours.</span> Regardless of how
                              many employees are on the development or project launch team, all rights are yours.</li>
                            <li><span class="marked">Increase the level of productivity.</span> Increasing the staff,
                                especially competent experts improves the competitive environment and the level of labor.</li>
                            <li><span class="marked">Remote work format.</span> This cost-effective solution can bring
                                together experienced employees from around the world – Ukraine, Poland, Germany,
                                United States, China, etc.
                            </li>
                            <li><span class="marked">Partial or complete renewal of the team.</span> This approach helps to avoid
                              a dead end and eventually gives a high-quality result by finding extra talent to strengthen the team.
                            </li>
                        </ol>
                    </div>
                    <!-- <div class="service-img-wrapper"><img v-lazyload data-url="/img/images-db/services/advantage.webp"
                            alt="Advantages Outstaffing" height="307" width="460">
                    </div> -->
                </div>
            </div>
        </section>

        <section class="dedicated-section service-description service-augmentation">
          <div class="container-db">
            <div class="service-wrapper">
              <div class="service-text-wrapper augmentation">
                <h2>When Should Companies Choose to Work With a Staff Augmentation Model?</h2>
                <p>Companies should consider using a staff engagement model when they have specific short-term needs or
                  projects that require specialized skills that the existing team does not possess.
                  Here are some situations in which staff engagement may be appropriate:</p>
                <ol>
                  <li><span class="marked">Short-term projects:</span> When a company has a short-term project with a
                    defined scope and timeline, hiring full-time employees may not make sense. Augmenting your staff
                    allows you to bring in skilled professionals for the project's duration quickly.</li>
                  <li><span class="marked">Specialized Skills:</span> If a company lacks certain specialized skills on
                    an existing team but does not need those skills on an ongoing basis, staff augmentation can provide
                    access to experts in those areas without the need to hire full-time employees.</li>
                  <li><span class="marked">Seasonal workloads:</span> Some industries experience seasonal fluctuations
                    in demand, requiring additional labor during peak periods. Staff augmentation allows companies to
                    increase or decrease staffing levels as needed without hiring and firing employees.</li>
                  <li><span class="marked">Cost-effective:</span> Hiring full-time employees involves significant costs
                    such as salary, benefits, and training. Increasing staff can be a more cost-effective solution for short-term or specialized needs because companies only pay for the resources they use.</li>
                  <li><span class="marked">Flexibility:</span> Staff augmentation allows companies to scale staffing to
                    meet changing project requirements. Companies can easily add or remove resources as needed without
                    the long-term commitment of hiring full-time employees.</li>
                  <li><span class="marked">Access to talent:</span> In some cases, companies need help finding the talent
                    they need locally or require specialized expertise that cannot be obtained internally.
                    Staff augmentation allows them to tap into a global talent pool and access specialized skills that
                    may not be available locally.</li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <section class="dedicated-section service-description service-augmentation">
          <div class="container-db">
            <div class="service-wrapper">
              <div class="service-text-wrapper augmentation-steps">
                <h2>How to Choose IT Staff Augmentation Company</h2>
                <p>Choosing the right IT staffing company is critical to the success of your projects. Here are a few
                    steps to help you choose the most appropriate company:</p>
                <ol>
                  <li><span class="marked">Identify your needs:</span> Clearly define what skills and experience you need
                    for your project or ongoing support. This will help you narrow your search and find a staffing company
                    specializing in the required areas.</li>
                  <li><span class="marked">Do your research and make a shortlist:</span> Research various IT staffing
                    companies online, ask for recommendations from colleagues or industry peers, and check reviews and
                    testimonials. Shortlist a few companies that fit your needs and have a good reputation.</li>
                  <li><span class="marked">Assess Experience:</span> Assess the experience and expertise of the staff
                    at the staff augmentation company. Look for companies with a track record of providing qualified
                    people in your industry and technology stack.</li>
                  <li><span class="marked">Check references:</span> Ask the staffing company for references from past
                    clients or projects similar to yours. Contact them to get feedback on their experience with the
                    company and the quality of the talent provided.</li>
                  <li><span class="marked">Assess communication and cultural fit:</span> Communication is essential
                    to successful collaboration, so assess how well the staffing services company communicates with
                    clients and integrates into their teams. Also, consider the cultural fit between your company and
                    the staffing services company to ensure a smooth collaboration.</li>
                  <li><span class="marked">Assess flexibility and scalability:</span> Assess the flexibility and
                    scalability of the company's staffing services. Can they scale up or down quickly, depending on
                    your project requirements? Do they offer different engagement models to suit your needs?</li>
                  <li><span class="marked">Examine contracts and legal aspects:</span> Carefully review the contracts,
                    service level agreements (SLAs), and legal terms offered by the engagement company. Ensure they meet
                    your expectations regarding project deliverables, timelines, confidentiality, and intellectual
                    property rights.</li>
                  <li><span class="marked">Consider location and time zone:</span> If location or time zone is
                    essential to your project, look for staffing companies with offices or resources in the same
                    or comparable time zone as your team.</li>
                  <li><span class="marked">Evaluate pricing and value:</span> Compare the pricing structures and
                    value propositions different staffing companies offer. While cost is significant, prioritize
                    value and quality to ensure you get the right talent and support for your projects.</li>
                  <li><span class="marked">Start with a pilot project:</span> Start with a small pilot project to
                    test the staffing company's capabilities and compatibility before embarking on a long-term
                    partnership.</li>
                </ol>
                <p>By following these steps and conducting a thorough vetting process, you can choose an IT
                  staffing company that will meet your needs and help you achieve your project goals effectively.</p>
              </div>
            </div>
          </div>
        </section>

        <section class="industries-section service-description">
            <div class="container-db">
                <div class="service-wrapper">
                    <div class="service-text-wrapper">
                        <h2>Industries of our expertise</h2>
                        <p>We have delivered solutions for clients hailing from diverse industry verticals</p>
                    </div>
                </div>
                <ul class="cards-wrapper">
                    <li>
                        <router-link to="/cases/banking-app">
                            <p class="image-wrapper">
                                <img v-lazyload data-url="/img/images-db/services/banking_application.webp" alt="app development"
                                     title="app development"
                                    height="70" width="70">
                            </p>
                            Banking Application
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/cases/markets-exchanges">
                            <p class="image-wrapper">
                                <img v-lazyload data-url="/img/images-db/services/markets.webp" alt="Markets And Exchanges"
                                    height="70" width="70">
                            </p>
                            Markets And Exchanges
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/cases/flexiswap-nft-swap-platform">
                            <p class="image-wrapper">
                                <img v-lazyload data-url="/img/images-db/services/nft.webp" alt="NFT Swap Platform"
                                    height="70" width="70">
                            </p>
                            NFT Swap Platform
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/cases/currency-research-analasis-news-platform">
                            <p class="image-wrapper">
                                <img v-lazyload data-url="/img/images-db/services/currency_analysis.webp" alt="WP Development"
                                    height="70" width="70">
                            </p>
                            Currency analysis/news
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/cases/cannabis-platform">
                            <p class="image-wrapper">
                                <img v-lazyload data-url="/img/images-db/services/cannabis.webp" alt="Cannabis development platform" title="Cannabis development platform"
                                    height="70" width="70">
                            </p>
                            Pharmacy platform
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/cases/doctormed">
                            <p class="image-wrapper">
                                <img v-lazyload data-url="/img/images-db/services/doctormed.webp" alt="Healthcare software development" title="Healthcare software development"
                                    height="70" width="70">
                            </p>
                            DoctorMed
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/cases/social-network-for-dog-owners">
                            <p class="image-wrapper">
                                <img v-lazyload data-url="/img/images-db/services/social_network.webp" alt="Social Network"
                                    height="70" width="70">
                            </p>
                            Social Network for Dog Owners
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/cases/real-estate-platform">
                            <p class="image-wrapper">
                                <img v-lazyload data-url="/img/images-db/services/real_estate.webp"
                                     alt="Real estate development platform"
                                     title="Real estate development platform"
                                     height="70" width="70">
                            </p>
                            Real Estate Platform
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/cases/art-galleries-development">
                            <p class="image-wrapper">
                                <img v-lazyload data-url="/img/images-db/services/art-gallery.webp" alt="Art Gallery"
                                    height="70" width="70">
                            </p>
                            Art Gallery
                        </router-link>
                    </li>
                </ul>
            </div>
        </section>

        <section class="dedicated-section service-description">

            <div class="container-db">
                <div class="service-wrapper">
                    <div class="service-text-wrapper outstaffing">
                        <h2>IT Outstaffing Processes</h2>
                        <p>Our collaboration takes place in a simple and understandable environment for everyone and
                            consists of a few simple steps:</p>
                        <ol>
                            <li>Communication regarding the services you want to order, we will find
                                out all the details and highlight your main needs.</li>
                            <li>Recruiting a team according to all the client's needs and requests
                                in a stage. Then, each employee is selected for compliance with the specified qualities
                                and professional experience before hiring.</li>
                            <li>Once the team is fully formed, it gets to work. Project steps are developed, and the
                              client can check the process of each of them.</li>
                            <li>Within the agreed timeframe, the project is completed and handed
                                over to the client with all the necessary supporting documentation, including reports.
                            </li>
                            <li>Suppose you have any questions before, during, or after the delivery
                                of the project. In that case, the support service is ready to provide any assistance and
                                answer to clarify all the necessary details.</li>
                        </ol>
                    </div>
                    <!-- <div class="service-img-wrapper"><img v-lazyload data-url="/img/images-db/services/process.webp"
                            alt="developers" height="307" width="460">
                    </div> -->
                </div>
            </div>
        </section>

        <section class="dedicated-section service-description">
            <div class="container-db">
                <div class="service-wrapper">
                    <div class="service-text-wrapper key-benefits">
                        <h2>Key benefits outstaffing with DevBrother</h2>
                        <ol>
                            <li>KPI-based progress and quality control help evaluate existing progress and adjust other
                                long-term business objectives.</li>
                            <li>Smooth communication & reporting promotes a healthier atmosphere between team members
                                and the primary provider. It sets the stage for more open contact and better task
                                completion.</li>
                            <li>Optimal pricing models can maximize profits because they charge the amount that the
                                market can bear. Strategies can change according to the consumer demand.</li>
                            <li>The fixed price model provides a more predictable scenario for both parties to the
                                agreement over the contract's period, as prices cannot be changed.</li>
                            <li>The T&M model is an advantage for clients as they pay the team for the time and
                                resources spent.</li>
                            <li>T&M with a cap helps to prevent unexpected costs beyond the budget, such as setting a
                                maximum limit for technology development.</li>
                        </ol>
                    </div>
                    <!-- <div class="service-img-wrapper"><img v-lazyload data-url="/img/images-db/services/key_benefits.webp"
                            alt="developers" height="307" width="460">
                    </div> -->
                </div>
            </div>
        </section>

        <section class="faq-section">
            <h2>Frequently Asked Questions</h2>
            <div class="container-db">
                <div id="app" class="">
                    <app-accordion @closeAccordions='closeAccordions()' v-for="(accordion, index) in accordions"
                        ref="accordion" :key="index" class="app-accordion">
                        <template v-slot:title>
                            <span class="">{{ accordion.title }}</span>
                        </template>
                        <template v-slot:content>
                            <p>
                                {{ accordion.text }}
                            </p>
                        </template>
                    </app-accordion>
                </div>

            </div>
        </section>

        <h2 id="questions" class="more-questions">If you have any more questions, write to us</h2>

        <section class="acquainted main-contact" id="acquainted">
            <div class="container-db">
                <div class="acquainted-form-wrapper">
                    <div v-lazyload data-src="/img/images-db/parallax/stone2.webp" class="lazy-bg bottom-stone"></div>
                    <div class="cooperation-offer">
                        <h3>What's your challenge?</h3>
                        <div class="cooperation-offer-text">
                            Let us deliver the talent and expertise to help you solve it
                        </div>
                    </div>
                    <AcquaintedForm />
                </div>
            </div>
        </section>



        <div class="popup" :class="{ 'hide-popup': !showModal }">
            <div class="popup-wrapper">
                <div class="popup-inner" @click.self="closePopup()">
                    <div class="popup-content" id="service-contact-form">
                        <button class="close-popup" @click="closePopup()" type="button" aria-label="close popup"></button>
                        <ServicesTopForm />
                    </div>
                </div>
            </div>
        </div>


        <script v-html="jsonld" type="application/ld+json"></script>
    </main>
</template>
<script>
import AcquaintedForm from "../partials/AcquaintedForm";
import { requestGet, requestPost } from "../../services/axios.js";
import AppAccordion from "./web-development/AppAccordion";
import ServicesTopForm from '../ServicesTopForm';

let vm = {};
export default {
    name: 'StaffAugmentationService',
    metaInfo: {
        title: 'Staff Augmentation Services in the USA | Best Web Company',
        meta: [
            {
                vmid: "description",
                name: 'description',
                content: 'Make IT Staff Augmentation Services from Ukraine for Software Development. Hire Qualified Staff for Enterprises and Businesses Based on Information Technology.'
            },
            {
                vmid: "og:image:type",
                property: "og:image:type",
                content: 'image/webp'
            },
            // {
            //   vmid: "og:title",
            //   name: "og:title",
            //   content: 'React JS Development Services - Hire Dedicated Professionals'
            // },
            {
                vmid: "og:title",
                property: "og:title",
                content: 'Staff Augmentation Services in the USA | Best Web Company'
            },
            {
                vmid: "og:description",
                property: "og:description",
                content: 'Make IT Staff Augmentation Services from Ukraine for Software Development. Hire Qualified Staff for Enterprises and Businesses Based on Information Technology.'
            },
            {
                vmid: "og:image",
                name: "og:image",
                content: 'http://devbrother.com/img/images-db/services/we-provide.webp'
            },
            {
                vmid: "og:type",
                property: "og:type",
                content: "website"
            },
            {
                vmid: "og:url",
                property: "og:url",
                content: 'https://devbrother.com/services/staff-augmentation-service'
            },
            {
                vmid: "twitter:title",
                name: "twitter:title",
                content: 'Staff Augmentation Services in the USA | Best Web Company'
            },
            {
                vmid: "twitter:description",
                name: "twitter:description",
                content: 'Make IT Staff Augmentation Services from Ukraine for Software Development. Hire Qualified Staff for Enterprises and Businesses Based on Information Technology.'
            },
            {
                vmid: "twitter:image",
                name: "twitter:image",
                content: 'http://devbrother.com/img/images-db/services/we-provide.webp'
            }
        ]
    },
    components: { AcquaintedForm, AppAccordion, ServicesTopForm },
    data() {
        const jsonld = {
            "@context": "https://schema.org",
            "@graph": [{
                "@type": "Organization",
                "@id": "https://devbrother.com/#organization",
                "name": "DevBrother",
                "url": "https://devbrother.com/",
                "sameAs": ["https://www.linkedin.com/company/devbrother/",
                    "https://www.instagram.com/devbrother_company/",
                    "https://clutch.co/profile/devbrother"],
                "logo": {
                    "@type": "ImageObject", "inLanguage": "en-US",
                    "@id": "https://devbrother.com/#/schema/logo/image/",
                    "url": "https://devbrother.com/img/images-db/Logo.png",
                    "contentUrl": "https://devbrother.com/img/images-db/Logo.png",
                    "width": 70,
                    "height": 40,
                    "caption": "DevBrother"
                },
                "image":
                    { "@id": "https://devbrother.com/#/schema/logo/image/" }
            },
            {
                "@type": "WebSite", "@id": "https://devbrother.com/#website",
                "url": "https://devbrother.com/",
                "name": "DevBrother",
                "description": "DevOps &amp; Software Development",
                "publisher": { "@id": "https://devbrother.com/#organization" },
                "potentialAction": [{
                    "@type": "SearchAction",
                    "target": {
                        "@type": "EntryPoint",
                        "urlTemplate": "https://devbrother.com/?s={search_term_string}"
                    },
                    "query-input": "required name=search_term_string"
                }],
                "inLanguage": "en-US"
            },
            {
                "@type": "ImageObject",
                "inLanguage": "en-US",
                "@id": "https://devbrother.com/services/staff-augmentation-service#primaryimage",
                "url": "http://devbrother.com/img/images-db/services/we-provide.webp",
                "contentUrl": "http://devbrother.com/img/images-db/services/we-provide.webp",
                "width": 512,
                "height": 512
            },
            {
                "@type": "WebPage",
                "@id": "https://devbrother.com/services/staff-augmentation-service",
                "url": "https://devbrother.com/services/staff-augmentation-service",
                "name": "Staff Augmentation Services - IT Outstaffing Company DevBrother",
                "isPartOf": { "@id": "https://devbrother.com/#website" },
                "primaryImageOfPage": { "@id": "https://devbrother.com/services/web-development/react#primaryimage" },
                "datePublished": "2022-08-25T18:29:55+00:00",
                "dateModified": "2022-08-27T12:26:55+00:00",
                "description": "Make 【IT Staff Augmentation】 Services from Ukraine for Software Development. Hire Qualified Staff for Enterprises and Businesses based on Information Technology.",
                "breadcrumb": { "@id": "https://devbrother.com/services/staff-augmentation-service#breadcrumb" },
                "inLanguage": "en-US",
                "potentialAction": [{
                    "@type": "ReadAction",
                    "target": ["https://devbrother.com/services/staff-augmentation-service"]
                }]
            },
            {
                "@type": "BreadcrumbList",
                "@id": "https://devbrother.com/services/staff-augmentation-service#breadcrumb",
                "itemListElement": [{
                    "@type": "ListItem",
                    "position": 1,
                    "name": "DevBrother",
                    "item": "https://devbrother.com/"
                },
                {
                    "@type": "ListItem",
                    "position": 2,
                    "name": "Web Development",
                    "item": "https://devbrother.com/services/web-development"
                },
                {
                    "@type": "ListItem",
                    "position": 3,
                    "name": "Staff Augmentation Services",
                    "item": "https://devbrother.com/services/staff-augmentation-service"
                }
                ]
            },
            {
                "@type": "FAQPage",
                "mainEntity": [
                    {
                        "@type": "Question",
                        "name": "What Is Project Augmentation?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "<a href='https://devbrother.com/services/staff-augmentation-service'>Project augmentation</a> is one of the most flexible and cost-effective methods used by marketing outsource. Digging involves a special model in the process, according to which there is an increase in resources by hiring professionals on a contract basis for a specific project or group of tasks."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "What Percentage of Companies Use Staff Augmentation?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "According to the latest statistics for 2022, more than 50% of large companies use the augmentation method."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "What Is Staff Augmentation Consulting?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Augmentation consulting has lots of options. For example, hiring one person as a web developer is possible using sites for freelancers, like Upwork. Or a client can also use a software development company as a consultant to build the software."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Can I Hire One Developer, or Do I Need to Hire the Whole Team?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "DevBrother aims to help with any projects and tasks. And if the client's request is the search for only one extra talent, no one will impose the mandatory hiring of an entire team."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Is Europe good for software development?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Those countries show outstanding results, and European developers are considered among the most sought worldwide, especially in offshore software development."
                        }
                    }]
            }
            ]
        }


        return {
            jsonld,
            showModal: false,
            accordions: [
                {
                    title: 'What Is Project Augmentation?',
                    text: "Project augmentation is one of the most flexible and cost-effective methods used by marketing outsource. Digging involves a special model in the process, according to which there is an increase in resources by hiring professionals on a contract basis for a specific project or group of tasks.",

                },
                {
                    title: 'What Percentage of Companies Use Staff Augmentation?',
                    text: 'According to the latest statistics for 2022, more than 50% of large companies use the augmentation method.',
                },
                {
                    title: 'What Is Staff Augmentation Consulting?',
                    text: "Augmentation consulting has lots of options. For example, hiring one person as a web developer is possible using sites for freelancers, like Upwork. Or a client can also use a software development company as a consultant to build the software."
                },
                {
                    title: 'Can I Hire One Developer, or Do I Need to Hire the Whole Team?',
                    text: "DevBrother aims to help with any projects and tasks. And if the client's request is the search for only one extra talent, no one will impose the mandatory hiring of an entire team."
                },
                {
                    title: 'Is Europe good for software development?',
                    text: "Those countries show outstanding results, and European developers are considered among the most sought worldwide, especially in offshore software development."
                }
            ],
            isMailSend: false,
            mailData: {
                name: "",
                email: "",
                comment: ""
            },
            errors: [],
            styles: {
                name: {
                    input: "",
                    label: "",
                    span: "visibility: hidden",
                },
                email: {
                    input: "",
                    label: "",
                    span: "visibility: hidden",
                },
                comment: {
                    input: "",
                    label: "",
                    span: "visibility: hidden;",
                }
            }
        };
    },
    beforeCreate() {
        vm = this;
    },
    mounted: () => {
        vm.body = document.querySelector("body");
        vm.addCanonicalLink();
    },
    methods: {
        addCanonicalLink: () => {
        const link = document.createElement('link');
        link.setAttribute('rel', 'canonical');
        link.setAttribute('href', 'https://devbrother.com/services/staff-augmentation-service');

        const oldLink = document.querySelector('link[rel="canonical"]');
        if (oldLink) {
            document.head.removeChild(oldLink);
        }

        document.head.appendChild(link);
        },
        closeAccordions() {
            this.$refs.accordion.forEach(el => el.closeAccordion());
        },
        checkForEmptiness: (field) => {
            if (vm.mailData[field] === "") {
                vm.addWarning(field)
            } else {
                vm.styles[field]["span"] = "visibility: hidden";
            }
        },
        addWarning: (field) => {
            vm.styles[field]["input"] = "border: 1px solid red;"
            vm.styles[field]["span"] = "visibility: visible";
        },
        raiseLabelUp: field => {
            vm.styles[field]["input"] = "";
            vm.styles[field]["span"] = "visibility: hidden";
        },
        validEmail: email => {
            const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },
        hasErrors: () => {
            let gotError = false;
            Object.keys(vm.mailData).forEach(item => {
                if (vm.mailData[item] == "") {
                    gotError = true;
                    vm.checkForEmptiness(item);
                } else if ((item == "email" && !vm.validEmail(vm.mailData[item]))) {
                    gotError = true;
                    vm.checkForEmptiness(item);
                    vm.addWarning('email');
                }
            });
            return gotError;
        },
        sendMail: (event) => {
            if (!vm.hasErrors()) {
                requestPost("/contact-about-us", vm.mailData)
                    .then(res => {
                        if (res.status) {
                            vm.isMailSend = true;
                            vm.mailData.name = "";
                            vm.mailData.email = "";
                            vm.mailData.comment = "";
                        } else {
                            console.log("Email send failed, error", res.error);
                        }
                    })
                    .catch(error => {
                        return { status: false, error };
                    });
            }
        },
        showPopup: () => {
            vm.showModal = true;
            vm.body.classList.add('ban-scroll');
        },
        closePopup: () => {
            vm.showModal = false;
            vm.body.classList.remove('ban-scroll');
        },
        scrollFix: hash => {
            setTimeout(() => {
                setTimeout(() => $('html, body')
                    .animate({ scrollTop: $(hash).offset().top }, 1000), 1)
            });
        },
    }
}
</script>
<style lang="scss" scoped>
.top-form-section {
    background: none;
}

.service-contact {
    max-height: 430px;
}

.inner-container {
    max-width: 1000px;
}

.awards-section {
    margin-bottom: 0;
}

.our-achievements {
    text-align: center;
}

ol {
    padding: 0;
    list-style-type: none;

    li {
        position: relative;
        margin-bottom: 15px;
        padding-left: 35px;

        &::before {
            content: "";
            position: absolute;
            width: 20px;
            height: 20px;
            top: 3px;
            left: 0;
            background: url(/img/images-db/services/check_circle_icon.svg) no-repeat;
            background-size: contain;
        }
    }
}

.outstaffing, .augmentation-steps {
    ol {
        margin-left: 20px;
        list-style-type: auto;

        li {
            padding-left: 0;

            &::before {
                display: none;
            }

            &::marker {
                color: #06c6ff;
            }
        }
    }
}

.service-description {

    a {
        color: #06c6ff;
        text-decoration: underline;

        &:hover {
            opacity: 0.85;
        }
    }

    .service-text-wrapper {
        max-width: 570px;
    }

    .service-img-wrapper {
        max-width: 550px;

        img {
            filter: contrast(1.1) grayscale(0.5);
        }
    }

    .advantages, .augmentation, .augmentation-steps, .outstaffing, .key-benefits {
        max-width: 85%;
        margin-left: auto;
        margin-right: auto;

        h2 {
            text-align: center;
        }
    }
}

.marked {
    color: #06c6ff;
}

.service-augmentation {
  margin-top: -20px;
}
</style>
