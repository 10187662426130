require('./bootstrap');

import Vue from 'vue';
import VueMeta from 'vue-meta'
import VueRouter from 'vue-router';
import App from './components/MainApp';
import AOS from 'aos';
import 'aos/dist/aos.css';
import ReadMore from 'vue-read-more';
import {store} from './store';
import VueSmoothScroll from 'vue2-smooth-scroll';

import LazyLoadDirective from "../directives/LazyLoadDirective";

import createRouter from './routes';


Vue.use(VueRouter);
Vue.use(ReadMore);
Vue.use(VueMeta);
Vue.use(VueSmoothScroll);

Vue.directive("lazyload", LazyLoadDirective);

const initApp = async () => {
  const router = await createRouter();
  new Vue({
    created() {
      AOS.init()
    },
    el: '#app',
    router,
    store,
    render: h => h(App),
  });
};
initApp();
