<template>
    <div>
        <div :class="{ 'app-wrapper': true, 'white-wrapper': isWhiteTheme()}">
            <Header/>
            <router-view :key="$route.fullPath"></router-view>
            <Footer />
            <!--<QuickMail />-->
        </div>
        <!--
        <div v-if="preloader" class="loader-wrapper">
            <div class="loader">
                <div class="loader-item"></div>
                <div class="loader-item"></div>
                <div class="loader-item"></div>
            </div>
        </div>
        -->
    </div>
</template>

<script>

    import Header from './partials/Header.vue';
    import Footer from './partials/Footer.vue';
    import QuickMail from './partials/QuickMail.vue';

    let vm = {};

    export default {
        name: 'App',
        components: {
            Header,
            Footer,
            QuickMail
        },
        data: () => {
            return {
                preloader: false,
            }
        },
        beforeCreate() {
            vm = this;
            const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
            const format = isSafari ? 'png' : 'webp';
            this.$store.commit('setImageFormat', format);
        },
        mounted() {
            // setTimeout(() => { vm.preloader = false;}, 1500)
        },
        methods: {
            isWhiteTheme: () => {
                return vm.$router.currentRoute.name == 'Post' ? true : false;
            }
        }
    }
</script>