<template>
	<section class="acquainted">
		<div class="container-db">
			<div class="acquainted-form-wrapper">
				<div class="cooperation-offer">
					<h3>What's your challenge?</h3>
					<div class="cooperation-offer-text">
						Let us deliver the talent and expertise to help you solve it
					</div>
				</div>
				<AcquaintedForm />
			</div>
		</div>
	</section>
</template>

<script>
	import AcquaintedForm from "./partials/AcquaintedForm";

  export default {
		name: 'AcquaintedSection',
		components: { AcquaintedForm },
		data: () => {
			return {}
		}
    }
</script>
