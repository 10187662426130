<template>    <!--SECTION INDUSTRIES-->
	<main>
    <div class="top-stone">
    </div>
    <div class="pagination">
      <div class="container-db">
        <div class="bread-crumbs">
          <router-link to="/">Home</router-link>
          <span class="bread-separator">/</span>
          <span class="bread-current">Technical Support</span>
        </div>
      </div>
    </div>
    <section class="service-description">
      <div class="container-db">
        <div class="service-wrapper">
          <div class="service-text-wrapper">
            <h1>Technical Support</h1>
            <p>
              One of the most challenging services we do for our clients is technical support 24/7.  We are gathering team depending on the client’s request. Setting up working process to have needed response time and appropriate level of support. And then everything just works.
            </p>
          </div>
          <div class="stone-image-wrapper">
            <div class="service-contact">
              <p class="service-contact__title">Free consultation</p>
              <p>We advise choosing the right technology and strategies for developing a new product.</p>
              <p>Let's arrange a short phone or video call? We will listen to your wishes and select a team of
                professionals to implement the product.</p>
              <button @click="showPopup" class="btn form-btn">Contact us</button>
            </div>
          <div class="top-stone mobile">
          </div>
        </div>
        </div>
        <div class="service-text-wrapper">
					<h2>Technical support can be divided on 2 main categories:</h2>
            <div class="list-wrapper">
              <ol>
                <li class="support-item"><p>Response time must be less than several hours</p>
									<p>
										In this case we are much more flexible because of the low response requirements. Sometimes we don’t even need to setup a special team for this, which will help to reduce costs for our clients.
									</p>
								</li>
                <li class="support-item">
									<p>Response time must be less than 10 minutes</p>
									<p>
										This case requires specific approach when the response time and solution for the problem should be very fast. We enjoy to organize this way of technical support because it is always brings some new and interesting solutions and unusual people to our team that are not afraid of difficulties or overtime job. 
									</p>
									<p>
										If you need to setup technical support of your product/service/startup/business or whatever - contact us and be sure - we will meet your expectations
									</p>
								</li>
              </ol>
            </div>
         
        </div>
      </div>
    </section>
    <AcquaintedSection />
    <div v-lazyload data-src="/img/images-db/parallax/stone2.webp" class="lazy-bg bottom-stone"></div>

    <div class="popup" :class="{ 'hide-popup': !showModal}">
      <div class="popup-wrapper">
        <div class="popup-inner" @click.self="closePopup()">
          <div class="popup-content" id="service-contact-form">
            <button class="close-popup" @click="closePopup()" type="button" aria-label="close popup"></button>
            <ServicesTopForm />
          </div>
        </div>
      </div>
    </div>

    <script v-html="jsonld" type="application/ld+json"></script>
  </main>
</template>
<script>  
    import AcquaintedSection from '../AcquaintedSection';
    import ServicesTopForm from '../ServicesTopForm';

    let vm = {};    
    export default {
        name: 'TechnicalSupport',
        metaInfo: {
            title: 'Technical support to maintain response time',
            meta: [
              {
                vmid: "description",
                name: 'description',
                content: 'We will monitor your computer systems and, if necessary, tune them 24/7 in order to achieve high uptime. We are available at any time and solve problems quickly.'
              },
              {
                vmid: "og:image:type",
                name: "og:image:type",
                content: 'image/webp'
              },
              {
                vmid: "og:title",
                name: "og:title",
                content: 'Technical support to maintain response time'
              },
              {
                vmid: "og:description",
                name: "og:description",
                content: 'We will monitor your computer systems and, if necessary, tune them 24/7 in order to achieve high uptime. We are available at any time and solve problems quickly.'
              },
              // {
              //   vmid: "og:image",
              //   name: "og:image",
              //   content: 'https://devbrother.com/images/...'
              // },
              {
                vmid: "og:type",
                name: "og:type",
                content: "website"
              },
              {
                vmid: "og:url",
                name: "og:url",
                content: 'https://devbrother.com/services/technical-support'
              },
              {
                vmid: "twitter:title",
                name: "twitter:title",
                content: 'Technical support to maintain response time'
              },
              {
                vmid: "twitter:description",
                name: "twitter:description",
                content: 'We will monitor your computer systems and, if necessary, tune them 24/7 in order to achieve high uptime. We are available at any time and solve problems quickly.'
              },
              // {
              //   vmid: "twitter:image",
              //   name: "twitter:image",
              //   content: 'https://devbrother.com/images/...'
              // }
            ]
        },
        components: { 
				  AcquaintedSection,
          ServicesTopForm
				 },
        data() {
          const jsonld =  {
            "@context": "https://schema.org",
            "@type": "Service",
            "name": "Technical support to maintain response time",
            "description": "We will monitor your computer systems and, if necessary, tune them 24/7 in order to achieve high uptime. We are available at any time and solve problems quickly."
          }

          return {
            jsonld,
            showModal: false,
          }
        },
           beforeCreate(){
            vm = this;
        },
          mounted: () => {
            vm.body = document.querySelector("body");
            vm.addCanonicalLink();
          },
          methods: {
            addCanonicalLink: () => {
              const link = document.createElement('link');
              link.setAttribute('rel', 'canonical');
              link.setAttribute('href', 'https://devbrother.com/services/technical-support');

              const oldLink = document.querySelector('link[rel="canonical"]');
              if (oldLink) {
                document.head.removeChild(oldLink);
              }

              document.head.appendChild(link);
            },
            showPopup: () => {
              vm.showModal = true;
              vm.body.classList.add('ban-scroll');
            },
            closePopup: () => {
              vm.showModal = false;
              vm.body.classList.remove('ban-scroll');
            },
            scrollFix: hash => {
              setTimeout(() => {
                setTimeout(() => $('html, body')
                .animate({scrollTop: $(hash).offset().top}, 1000), 1)
              });
            },
      }

    }
</script>
