<template>
    <main>
        <MainSection />
        <!-- <Difference /> -->
        <Wrapper />
        <!-- <Clients /> -->
        <Technologies />
        <Company />
        <CaseStudies />
<!--        <OurWorks />-->
        <Testimonials />
<!--        <ExclusivePartners />-->
        <Team />
        <Benefits />
        <Contact />
        <script v-html="jsonld" type="application/ld+json"></script>
    </main>
</template>

<script>
    import  MainSection from './MainSection';
    const Difference = () => import("./Difference");
    const Partner = () => import("./Partner");
    const Wrapper = () => import("./Wrapper");
    // import Clients from './ClientsD';
    const Partnership = () => import("./Partnership");
    const CaseStudies = () => import("./CaseStudies");
    const OurWorks = () => import("./OurWorks");
    const Team = () => import("./TeamD");
    const Benefits = () => import("./Benefits");
    const Contact = () => import("./Contact");
    const Company = () => import("./Company");
    const Testimonials = () => import("./Testimonials");
    const Technologies = () => import("./Technologies");
    const ExclusivePartners = () => import("./ExclusivePartners");

    export default {
        name: 'home',
        data: () => {
            const jsonld =  {
                "@context": "https://schema.org",
                "@type": "Organization",
                "legalName": "DevBrother",
                "description": "",
                "url": "https://devbrother.com",
                "address": {
                    "@type": "PostalAddress",
                    "addressLocality": "Gdańsk",
                    "addressCountry": "Poland",
                    "streetAddress": "aleja Grunwaldzka, 80-309"
                },
                "email": "contact@devbrother.com",
                "logo" : "https://devbrother.com/img/images-db/Logo.png",
                "sameAs": [
                    "https://www.linkedin.com/company/devbrother",
                    "https://www.instagram.com/devbrother_company",
                ]
            }
            return {
                jsonld
            }
        },
        components: {
            MainSection,
            Difference,
            Partner,
            Wrapper,
            // Clients,
            Partnership,
            Technologies,
            CaseStudies,
            OurWorks,
            Team,
            Company,
            Benefits,
            Contact,
            Testimonials,
            ExclusivePartners
        }
    }
</script>
