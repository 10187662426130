<template>

    <main class="privacy">
        <section class="service-description">
            <div class="container-db">
                <h1>Cookies policy</h1>

                <ol class="list-wrap">
                    <li>
                        <h2>Introduction</h2>
                        <ol>
                            <li>Our website uses cookies.</li>
                            <li>We will ask you to consent to our use of cookies in accordance with the terms of this
                                policy when you first visit our website.</li>
                        </ol>
                    </li>
                    <li>
                        <h2>About cookies</h2>
                        <ol>
                            <li>A cookie is a file containing an identifier (a string of letters and numbers) that is
                                sent by a web server to a web browser and is stored by the browser. The identifier is
                                then sent back to the server each time the browser requests a page from the server.</li>
                            <li>Cookies may be either "persistent" cookies or "session" cookies: a persistent cookie
                                will be stored by a web browser and will remain valid until its set expiry date, unless
                                deleted by the user before the expiry date; a session cookie, on the other hand, will
                                expire at the end of the user session, when the web browser is closed.</li>
                            <li>Cookies do not typically contain any information that personally identifies a user, but
                                personal information that we store about you may be linked to the information stored in
                                and obtained from cookies.</li>
                            <li>Cookies can be used by web servers to identify and track users as they navigate
                                different pages on a website and identify users returning to a website</li>
                        </ol>
                    </li>
                    <li>
                        <h2>Our cookies</h2>
                        <ol>
                            <li>We use both session and persistent cookies on our website.</li>
                            <li>The names of the cookies that we use on our website, and the purposes for which they are
                                used, are set out below: we use jv_client_id to recognise a computer when a user visits
                                our website, track users as they navigate the website, improve the website's usability.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <h2>Analytics cookies</h2>
                        <ol>
                            <li>We use Google Analytics to analyse the use of our website.</li>
                            <li>Our analytics service provider generates statistical and other information about website
                                use by means of cookies.</li>
                            <li>The analytics cookies used by our website have the following names: _ga.</li>
                            <li>The information generated relating to our website is used to create reports about the
                                use of our website.</li>
                            <li>Our analytics service provider's privacy policy is available at: <a
                                    href="http://www.google.com/policies/privacy/">http://www.google.com/policies/privacy/</a>.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <h2>Blocking cookies</h2>
                        <ol>
                            <li>Most browsers allow you to refuse to accept cookies; for example:<br>
                                <p>(a) in Internet Explorer (version 11) you can block cookies using the cookie handling
                                    override settings available by clicking "Tools", "Internet Options", "Privacy" and
                                    then "Advanced";</p>
                                <p>(b) in Firefox (version 44) you can block all cookies by clicking "Tools", "Options",
                                    "Privacy", selecting "Use custom settings for history" from the drop-down menu, and
                                    unticking "Accept cookies from sites"; and</p>
                                <p>(c) in Chrome (version 48), you can block all cookies by accessing the "Customise and
                                    control" menu, and clicking "Settings", "Show advanced settings" and "Content
                                    settings", and then selecting "Block sites from setting any data" under the
                                    "Cookies" heading</p>
                            </li>
                            <li>We will ask you to consent to our use of cookies in accordance with the terms of this
                                policy when you first visit our website</li>
                            <li>If you block cookies, you will not be able to use all the features on our website.</li>
                        </ol>
                    </li>
                    <li>
                        <h2>Deleting cookies</h2>
                        <ol>
                            <li>You can delete cookies already stored on your computer; for example:<br>
                                <p>(a) in Internet Explorer (version 11), you must manually delete cookie files (you can
                                    find instructions for doing so at
                                    http://windows.microsoft.com/en-gb/internet-explorer/delete-manage-cookies#ie=ie-11);
                                </p>
                                <p>(b) in Firefox (version 44), you can delete cookies by clicking "Tools", "Options"
                                    and "Privacy", then selecting "Use custom settings for history" from the drop-down
                                    menu, clicking "Show Cookies", and then clicking "Remove All Cookies"; and</p>
                                <p>(c) in Chrome (version 48), you can delete all cookies by accessing the "Customise
                                    and control" menu, and clicking "Settings", "Show advanced settings" and "Clear
                                    browsing data", and then selecting "Cookies and other site and plug-in data" before
                                    clicking "Clear browsing data".</p>
                            </li>
                            <li>Deleting cookies will have a negative impact on the usability of many websites.</li>
                        </ol>
                    </li>
                    <li>
                        <h2>Our details</h2>
                        <p>You can contact us:</p>
                        <p>(a) using our website contact form;</p>
                        <p>(b) by telephone, on the contact number published on our website from 10 am to 8 pm.</p>
                        <p>(c) by email, using the email address published on our website</p>
                    </li>
                </ol>
            </div>
        </section>

        <script v-html="jsonld" type="application/ld+json"></script>
    </main>
</template>
<script>

let vm = {};
export default {
    name: 'CookiesPolicy',
    metaInfo: {
        title: 'DevBrother cookies policy — data we use and other details',
        meta: [
            {
                vmid: "description",
                name: 'description',
                content: 'At the DevBrother website, we use cookies and provide visitors with detailed information on our policy, data analysis tools, and ways to block or delete cookies.'
            },
            // {
            //     vmid: "og:image:type",
            //     name: "og:image:type",
            //     content: 'image/webp'
            // },
            {
                vmid: "og:title",
                name: "og:title",
                content: 'DevBrother cookies policy — data we use and other details'
            },
            {
                vmid: "og:description",
                name: "og:description",
                content: 'At the DevBrother website, we use cookies and provide visitors with detailed information on our policy, data analysis tools, and ways to block or delete cookies.'
            },
            // {
            //     vmid: "og:image",
            //     name: "og:image",
            //     content: 'https://devbrother.com/img/images-db/...'
            // },
            {
                vmid: "og:type",
                name: "og:type",
                content: "website"
            },
            {
                vmid: "og:url",
                name: "og:url",
                content: 'https://devbrother.com/cookies-policy'
            },
            {
                vmid: "twitter:title",
                name: "twitter:title",
                content: 'DevBrother cookies policy — data we use and other details'
            },
            {
                vmid: "twitter:description",
                name: "twitter:description",
                content: 'At the DevBrother website, we use cookies and provide visitors with detailed information on our policy, data analysis tools, and ways to block or delete cookies.'
            },
            // {
            //     vmid: "twitter:image",
            //     name: "twitter:image",
            //     content: 'https://devbrother.com/img/images-db/...'
            // }
        ]
    },
    beforeCreate() {
        vm = this;
    },
    mounted: () => {
        vm.addCanonicalLink();
    },
    methods: {
        addCanonicalLink: () => {
            const link = document.createElement('link');
            link.setAttribute('rel', 'canonical');
            link.setAttribute('href', 'https://devbrother.com/cookies-policy');

            const oldLink = document.querySelector('link[rel="canonical"]');
            if (oldLink) {
                document.head.removeChild(oldLink);
            }

            document.head.appendChild(link);
        },
    },
    data() {
        const jsonld = {
            "@context": "https://schema.org",
            "@type": "Privacy",
            // ?
            // "name": "Web Development Agency - Custom Outsourced Services ➜ DevBrother",
            "name": "Cookies Policy ➜ DevBrother",
            "description": "At the DevBrother website, we use cookies and provide visitors with detailed information on our policy, data analysis tools, and ways to block or delete cookies."
        }

        return {
            jsonld,
        }
    },
}
</script>
<style lang="scss" scoped>
.privacy {
    .container-db {
        max-width: 960px;
        margin-top: 150px;
        line-height: 1.5 !important;
    }

    h1 {
        font-size: 26px;
        line-height: 30px;
    }

    h2 {
        margin-top: 30px;
        font-size: 20px;
        line-height: 24px;
    }

    h3 {
        margin-top: 30px;
        margin-bottom: 15px;
        font-size: 18px;
    }

    .top-message {
        margin-bottom: 50px;
    }

    p {
        margin-bottom: 10px;
        letter-spacing: 0.36px;
    }

    a:not(.router-link-active) {
        color: #06c6ff;

        &:hover {
            opacity: 0.8;
            transition: opacity 0.15s;
        }
    }

    .short-block {
        max-width: 790px;
    }

    .offices:not(:last-child) {
        margin-bottom: 25px;
    }

    ol:not(.list-wrap) {
        padding-left: 0;
    }

    ol {
        counter-reset: section;

        li {
            margin-bottom: 5px;
            list-style-type: none;
        }

        li::before {
            counter-increment: section;
            content: counters(section, ".") " ";
            list-style-type: none;
        }
    }

    .list-wrap {
        padding-left: 25px;
    }

    .list-wrap>li {
        list-style-type: auto;

        &::before {
            counter-increment: section;
            content: "";
            list-style-type: none;
        }

        &::marker {
            font-size: 20px;
        }
    }

    @media (max-width: 1220px) {
        .container-db {
            line-height: 1.4 !important;
        }

        .service-description p {
            line-height: 1.4 !important;
        }
    }

    @media (max-width: 767px) {
        .list-wrap>li ol {
            margin-left: -20px;
        }
    }
}
</style>
