<template>    <!--SECTION INDUSTRIES-->
<main>
			<div class="promotaion cryptocurrency">
				<div class="container-db bread-container">
					<div class="bread-crumbs">
						<router-link to="/">Home</router-link>
						<span class="bread-separator">/</span>
						<span class="bread-current">Cryptocurrency</span>
					</div>
				</div>
				<div class="container-db">
					<h1>Case: Creating a cryptocurrency analysis platform</h1>
				</div>
			</div>
			<div class="case-container challenge">
				<h2>Overview</h2>
				<p>DevBrother was reached out to implement the idea of creating Online Cryptocurrency Analysis Market Platform. Client wanted to build something special in Crypto world - AIO service for people interested in Crypto, that will include tracking price, volume and market capitalization, crypto market analysis and forecast, graphics, chart and many other things gathered in one place.</p>
			</div>
			<div class="descrip-result-wrapper">
				<div class="descrip-result-text">
					<div class="container-db">
						<div class="description">
							<h2>Description</h2>
							<p>
								We’ve created big platform for users interested in cryptocurrency that included all  features, described by client. For Graphics, Charts and other data visualization we used Highcharts library. Best UX/UI solutions were implemented to provide maximum comfortable communication with platform.  Also we’ve implemented subscription system where users can see additional analytics and forecast after they take one of the subscription plans: silver or gold.
							</p>
							<p>
								To implement high-loaded platform we’ve configured Load Balancer on AWS. We also payed special attention for security and stability of the platform by using appropriate AWS tools. All of these things we had to complete during 2 months.
							</p>
						</div>
						<div class="result">
							<h2>Result</h2>
							<p>
								We have done the platform for 2 months. Actually it was the most challenging two months in our lives but we did it
								</p>
								<p>
									This system gave the client the opportunity to stay one of the biggest players in the cryptocurrency analysis market.
								</p>
						</div>
					</div>
				</div>
				<img v-lazyload data-url="/img/images-db/case-studies/bit.webp" alt="bit.webp" width="945" height="665">
			</div>
			<div class="tech-features-section">
				<div class="container-db">
					<div class="tech-features-wrapper">
						<div class="technologies-wrapper">
							<h2>Technologies</h2>
							<ul>
								<li class="marked-item">HTML, SCSS</li>
								<li class="marked-item"><a style="text-decoration: underline;" href="/services/web-development/react" >ReactJS</a></li>
								<li class="marked-item">NodeJS, MongoDB</li>
								<li class="marked-item">AWS, docker</li>
							</ul>
						</div>
						<div class="features">
							<h2>Features</h2>
							<ul>
								<li>Awesome highchart UX/UI solution for visual data representation</li>
								<li>Analytics of the cryptocurrency rates by the inner formulas;</li>
								<li>Graphics, analytics, forecast, charts - AIO platform for crypto interested people</li>
								<li>Predictions in crypto trends for subscribed users</li>
							</ul>
						</div>

					</div>
				</div>
			</div>
			<div class="relevant-case-studies">
				<div class="container-db">
					<h2>portfolio</h2>
					<div class="slider-container">
						<div class="relevant-case-studies-slider">
							<slick ref="slick" :options="slickOptions">
								<div class="case-slider-item">
									<router-link to="online-event">
										<div class="case-studies-item">
										<div class="case-studies-img-wrapper">
											<img src="/img/images-db/case-studies/event.webp" alt="Online Tickets  Printing" width="300" height="334">
										</div>
										<h3>Online Tickets  Printing</h3>
									</div>
									</router-link>
								</div>
								<div class="case-slider-item">
									<router-link to="art-galleries">
										<div class="case-studies-item">
										<div class="case-studies-img-wrapper">
											<img src="/img/images-db/case-studies/art.webp" alt="Art Galleries" width="300" height="334">
										</div>
										<h3>Art Galleries</h3>
									</div>
									</router-link>
								</div>
								<div class="case-slider-item">
									<router-link to="e-commerce">
										<div class="case-studies-item">
										<div class="case-studies-img-wrapper">
											<img src="/img/images-db/case-studies/ecommerce.webp" alt="E-commerce" width="300" height="334">
										</div>
										<h3>E-commerce</h3>
									</div>
									</router-link>
								</div>
								<div class="case-slider-item">
									<router-link to="healthcare">
										<div class="case-studies-item">
											<div class="case-studies-img-wrapper">
												<img src="/img/images-db/case-studies/health.webp" alt="healthcare" width="300" height="334">
											</div>
											<h3>HealthCare <br />DoctorMed</h3>
										</div>
									</router-link>
								</div>
								<div class="case-slider-item">
									<router-link to="wordpress-dev">
										<div class="case-studies-item">
										<div class="case-studies-img-wrapper">
											<img src="/img/images-db/case-studies/wp3.webp" alt="wordpress development" width="300" height="334">
										</div>
										<h3>WordPress<br>Websites and Plugins Development</h3>
									</div>
									</router-link>
								</div>
							</slick>
						</div>
					</div>
				</div>
			</div>
      <AcquaintedSection />
      <div v-lazyload data-src="/img/images-db/parallax/stone2.webp" class="lazy-bg bottom-stone"></div>
			<script v-html="jsonld" type="application/ld+json"></script>
    </main>
</template>
<script>
	import Slick from 'vue-slick';
	import AcquaintedSection from '../AcquaintedSection';

	export default {
			name: 'Cryptocurrency',
			metaInfo: {
			 title: 'Case: Creation of Analytical Cryptocurrency Platform, Analogue of Coinmarketcat.com',
            meta: [
                {
					vmid: "description",
                    name: 'description',
                    content: 'We at DevBrother have created a Coinmarketcap analogue with unique functions of cryptocurrency rate analysis and forecasts, charts and analytics.'
                },
				{
					vmid: "og:image:type",
					name: "og:image:type",
					content: 'image/webp'
				},
				{
					vmid: "og:title",
					name: "og:title",
					content: 'Case: Creation of Analytical Cryptocurrency Platform, Analogue of Coinmarketcat.com'
				},
				{
					vmid: "og:description",
					name: "og:description",
					content: 'We at DevBrother have created a Coinmarketcap analogue with unique functions of cryptocurrency rate analysis and forecasts, charts and analytics.'
				},
				{
				  vmid: "og:image",
				  name: "og:image",
				  content: 'https://devbrother.com/img/images-db/case-studies/bit.webp'
				},
				{
					vmid: "og:type",
					name: "og:type",
					content: "website"
				},
				{
					vmid: "og:url",
					name: "og:url",
					content: 'https://devbrother.com/cases/cryptocurrency'
				},
				{
					vmid: "twitter:title",
					name: "twitter:title",
					content: 'Case: Creation of Analytical Cryptocurrency Platform, Analogue of Coinmarketcat.com'
				},
				{
					vmid: "twitter:description",
					name: "twitter:description",
					content: 'We at DevBrother have created a Coinmarketcap analogue with unique functions of cryptocurrency rate analysis and forecasts, charts and analytics.'
				},
				{
					vmid: "twitter:image",
					name: "twitter:image",
					content: 'https://devbrother.com/img/images-db/case-studies/bit.webp'
				}
            ],
      },
			components: { Slick, AcquaintedSection},
			mounted() {
					this.addCanonicalLink();
			},
			methods: {
					addCanonicalLink: () => {
						const link = document.createElement('link');
						link.setAttribute('rel', 'canonical');
						link.setAttribute('href', 'https://devbrother.com/cases/cryptocurrency');

						const oldLink = document.querySelector('link[rel="canonical"]');
						if (oldLink) {
							document.head.removeChild(oldLink);
						}

						document.head.appendChild(link);
					},
			},
		data(){
			const jsonld =  {
        "@context": "https://schema.org",
        "@type": "IndividualProduct",
        "name": "Cryptocurrency",
        "description": "Cryptocurrency"
      }
			return {
			jsonld,
			slickOptions: {
            prevArrow: '<div class="prev-arrow"><img src="/img/images-db/Arrow.svg" alt="arrow" class="arrow" width="26" height="11"/><img src="/img/images-db/Arrow-hover.svg" alt="arrow" class="arrow-hover" width="26" height="11"/></div>',
            nextArrow: '<div class="next-arrow"><img src="/img/images-db/Arrow.svg" alt="arrow" class="arrow" width="26" height="11"/><img src="/img/images-db/Arrow-hover.svg" alt="arrow" class="arrow-hover" width="26" height="11"/></div>',
					slidesToShow: 3,
					slidesToScroll: 1,
					// centerMode: true,
					responsive: [
						{
							breakpoint: 769,
							settings: {
								slidesToShow: 1,
								centerMode: false,
								prevArrow: '',
								nextArrow: '',
								dots: true,
								customPaging: function(slider, i) {
									return '<div class="slick-mobile-dot"></div>';
								}
							}
						}
					]
							// Any other options that can be got from plugin documentation
					},
			}
		},
	}
</script>
<style lang="scss" scoped>

.descrip-result-wrapper img {
  margin: 0 auto;
  display: block;
}

</style>
