<template>
  <!--SECTION INDUSTRIES-->
  <main class="wordpress-section">
    <div class="top-stone">
    </div>
    <div class="pagination">
      <div class="container-db">
        <div class="bread-crumbs">
          <router-link to="/" class="router-link-active">Home</router-link>
          <span class="bread-separator">/</span>
          <router-link to="/services/web-development">Web Development</router-link>
          <span class="bread-separator">/</span>
          <span class="bread-current">WordPress</span>
        </div>
      </div>
    </div>

    <section class="service-description">
      <div class="container-db">
        <div class="service-wrapper">
          <div class="service-text-wrapper">
            <h1>WordPress Development Company</h1>
            <p>
              We are a team of experienced WordPress developers who create not just websites but practical tools to achieve your business goals. Our customized solutions will help you attract new customers, increase sales, and strengthen your brand. Contact us today for a free consultation!</p>
          </div>

          <div class="stone-image-wrapper">
            <div class="service-contact">
              <p class="service-contact__title">Free consultation</p>
              <p>We advise choosing the right technology and strategies for developing a new product.</p>
              <p>Let's arrange a short phone or video call? We will listen to your wishes and select a team of
                professionals to implement the product.</p>
              <button @click="showPopup" class="btn form-btn">Contact us</button>
            </div>
            <div class="top-stone mobile">
            </div>
          </div>
        </div>
        <div class="service-text-wrapper">
          <h2>What is a WordPress Development Agency?</h2>
          <p>
            A WordPress development agency specializes in creating, customizing, and maintaining websites using the WordPress platform. We offer a wide range of services to help businesses of all sizes establish a solid online presence. From designing visually appealing themes to developing complex plugins and e-commerce stores, an outsourced WordPress development company in the USA offers solutions that meet your specific needs.
          </p>
        </div>
        <section class="integration-section service-description">
          <div class="service-wrapper custom-service-wrapper">
            <div class="service-text-wrapper">
              <h2 class="custom-development-header">WordPress Development Services</h2>
            </div>
          </div>
          <div class="benefits">
            <div class="inner-container">
              <ul class="benefits-list">
                <li class="benefits-list-item">
                  <div class="inner-benefits-item">
                    <h3>Custom WordPress Website Development.</h3>
                    <p>
                      Create unique and functional WordPress websites that align with your brand identity and business objectives. Skilled developers build websites from scratch, ensuring optimal performance and user experience.
                    </p>
                  </div>
                </li>
                <li class="benefits-list-item">
                  <div class="inner-benefits-item">
                    <h3>Theme Development & Customization.</h3>
                    <p>Our <router-link to="/services/ui-ux-user-testing">design</router-link> services craft stunning WordPress themes that are fully customizable to match your brand aesthetic. An outsourced WordPress development company in the USA can modify existing themes to meet your requirements.</p>
                  </div>
                </li>
                <li class="benefits-list-item">
                  <div class="inner-benefits-item">
                    <h3>WooCommerce Development.</h3>
                    <p>We specialize in developing robust and scalable WooCommerce online stores. Experts can help you set up your store, integrate payment gateways, manage inventory, and optimize conversions.</p>
                  </div>
                </li>
                <li class="benefits-list-item">
                  <div class="inner-benefits-item">
                    <h3>WordPress Plugin Development Services.</h3>
                    <p>Our developers can create plugins to extend the platform's possibilities and improve user experience.</p>
                  </div>
                </li>
                <li class="benefits-list-item">
                  <div class="inner-benefits-item">
                    <h3>Blog Development.</h3>
                    <p>Developers help you create exciting and informative blogs to attract and retain your audience. Services include content creation, SEO optimization, and design integration.</p>
                  </div>
                </li>
                <li class="benefits-list-item">
                  <div class="inner-benefits-item">
                    <h3>API Integration & Customization.</h3>
                    <p>WordPress developers seamlessly integrate third-party APIs into your WordPress website to expand its functionality. We handle the integration process efficiently, whether it's payment gateways, marketing tools, or CRM systems.</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>

        </section>
        <section>
          <div class="container-db">
            <div class="service-wrapper">
              <div class="service-text-wrapper">
                <h2>Additional Custom WordPress Development Services</h2>
                <h3>Mobile-first Website Design</h3>
                <p>WordPress website development company prioritizes mobile-friendly design to ensure your website looks and functions flawlessly on all devices. Our responsive designs provide an optimal user experience across different screen sizes.</p>
                <h3>Conversion Rate Optimization (CRO)</h3>
                <p>A custom WordPress development company in the USA analyzes your website's performance and implements strategies to increase conversions. CRO experts identify areas for improvement and optimize your website to drive more leads and sales.</p>
                <h3>Search Engine Optimization (SEO)</h3>
                <p>We optimize your website for search engines to improve visibility and attract organic traffic. SEO experts conduct thorough keyword research and implement on-page and off-page optimization techniques.</p>
              </div>
            </div>
          </div>
        </section>
        <div class="service-text-wrapper our-web-related-technologies">
          <h2>Our Technologies for WordPress Development</h2>
          <p>
            We utilize the latest tools and technologies to build exceptional WordPress websites:
          </p>
          <div class="three-list-wrapper">
            <div class="list-wrapper">
              <ul class="bullet-list">
                <li class="marked-item">Visual Studio Code (VSCode)</li>
                <li class="marked-item">Git and GitHub</li>
                <li class="marked-item">Local by Flywheel</li>
                <li class="marked-item">Advanced Custom Fields (ACF)</li>
              </ul>
            </div>

            <div class="list-wrapper">
              <ul class="bullet-list">
                <li class="marked-item">Elementor</li>
                <li class="marked-item">WooCommerce</li>
                <li class="marked-item">WP-CLI (WordPress Command Line Interface)</li>
                <li class="marked-item">Webpack</li>
              </ul>
            </div>

            <div class="list-wrapper">
              <ul class="bullet-list">
                <li class="marked-item">Composer</li>
                <li class="marked-item">JavaScript</li>
                <li class="marked-item">TypeScript</li>
                <li class="marked-item">NodeJS, and other.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <section class="industries-section service-description service-process">
          <div class="container-db">
            <div class="service-wrapper">
              <div class="service-text-wrapper service-steps">
                <h2>WordPress Website Development Company Process</h2>
                <p>Our <router-link to="/services/web-development">web development</router-link> process follows a structured approach to deliver high-quality results:</p>
                <ol>
                  <li><span class="marked">Planning and Discovery</span> Collaborate with you to understand your business goals, target audience, and project requirements.</li>
                  <li><span class="marked">Information Architecture and User Experience Design</span> We create a clear website structure and develop user-friendly interfaces to improve user experience.</li>
                  <li><span class="marked">Website Development and Customization</span> Our development team builds and customizes your WordPress website based on the approved design and specifications.</li>
                  <li><span class="marked">Testing and Quality Assurance</span> We check your website for functionality, performance, and efficiency on different devices.</li>
                </ol>
              </div>
            </div>
          </div>
        </section>

      <div class="popular-request">
        <div class="popular-request-wrapper container-db">
          <div class="service-text-wrapper requests">
            <h2>Why Choose DevBrother for WordPress Development Services?</h2>
            <ul class="bullet-list">
              <li class="marked-item">
                <span>Specialized Expertise in WordPress.</span> <router-link to="/about">Our team</router-link> has extensive experience in WordPress development and stays up-to-date with the latest trends.
              </li>
              <li class="marked-item">
                <span>Innovative Custom Solutions.</span> We create WordPress solutions that address your unique business needs and stand out.
              </li>
              <li class="marked-item">
                <span>Thorough quality control.</span> Adherence to strict quality standards ensures that your website is error-free, secure, and performs optimally.
              </li>
              <li class="marked-item">
                <span>Customer-first communication.</span> Open and transparent communication throughout the development process to ensure your satisfaction.
              </li>
              <li class="marked-item">
                <span>Fast and reliable delivery.</span> Delivering projects on time and budget without compromising quality.
              </li>
              <li class="marked-item">
                <span>Comprehensive post-launch support.</span> Providing ongoing support and maintenance to keep your website running smoothly.
              </li>
            </ul>
          </div>
        </div>
        <div class="popular-request-img">
          <img v-lazyload data-url="/img/images-db/services/wordpress.jpg" alt="Wordpress Website Development" width="715" height="500">
        </div>
      </div>
    </section>
    <section class="faq-section">
      <h2>Frequently Asked Questions</h2>
        <div class="container-db">
          <div v-lazyload data-src="/img/images-db/services/lite-coin.png" class="lazy-bg bg-icon">
          </div>
          <div id="app">
            <app-accordion @closeAccordions='closeAccordions()' v-for="(accordion, index) in accordions"
                        ref="accordion" :key="index" class="app-accordion">
              <template v-slot:title>
                <span>{{ accordion.title }}</span>
              </template>
              <template v-slot:content>
                <p>
                  {{ accordion.text }}
                </p>
              </template>
            </app-accordion>
          </div>
        </div>
    </section>

    <h2 id="questions" class="more-questions">If you have any more questions, write to us</h2>
    <AcquaintedSection />
    <div v-lazyload data-src="/img/images-db/parallax/stone2.webp" class="lazy-bg bottom-stone"></div>

    <div class="popup" :class="{ 'hide-popup': !showModal }">
      <div class="popup-wrapper">
        <div class="popup-inner" @click.self="closePopup()">
          <div class="popup-content" id="service-contact-form">
            <button class="close-popup" @click="closePopup()" type="button" aria-label="close popup"></button>
            <ServicesTopForm />
          </div>
        </div>
      </div>
    </div>

    <script v-html="jsonld" type="application/ld+json"></script>
  </main>
</template>
<script>
import AcquaintedSection from '../../AcquaintedSection';
import ServicesTopForm from '../../ServicesTopForm';
import AppAccordion from "./AppAccordion";

let vm = {};
export default {
  name: 'Wordpress',
  metaInfo: {
    title: 'Wordpress Development Company in USA | DevBrother',
    meta: [
      {
        vmid: "description",
        name: 'description',
        content: 'Build a custom website with WordPress development services. We offer WordPress design, themes, plugin development, and more. Contact us today!'
      },
      {
        vmid: "og:image:type",
        name: "og:image:type",
        content: 'image/webp'
      },
      {
        vmid: "og:title",
        name: "og:title",
        content: 'Wordpress Development Company in USA | DevBrother'
      },
      {
        vmid: "og:description",
        name: "og:description",
        content: 'Build a custom website with WordPress development services. We offer WordPress design, themes, plugin development, and more. Contact us today!'
      },
      // {
      //   vmid: "og:image",
      //   name: "og:image",
      //   content: 'https://devbrother.com/img/images-db/images/e-commerce.webp'
      // },
      {
        vmid: "og:type",
        name: "og:type",
        content: "website"
      },
      // {
      //   vmid: "og:url",
      //   name: "og:url",
      //   content: 'https://devbrother.com/services/web-development'
      // },
      {
        vmid: "twitter:title",
        name: "twitter:title",
        content: 'Wordpress Development Company in USA | DevBrother'
      },
      {
        vmid: "twitter:description",
        name: "twitter:description",
        content: 'Build a custom website with WordPress development services. We offer WordPress design, themes, plugin development, and more. Contact us today!'
      },
      // {
      //   vmid: "twitter:image",
      //   name: "twitter:image",
      //   content: 'https://devbrother.com/img/images-db/images/e-commerce.webp'
      // }
    ]
  },
  components: {
    AcquaintedSection,
    ServicesTopForm,
    AppAccordion,
  },
  data() {
    const jsonld = {
      "@context": "https://schema.org",
      "@type": "Service",
      "name": "Wordpress Development Company in USA | DevBrother",
      "description": "Build a custom website with WordPress development services. We offer WordPress design, themes, plugin development, and more. Contact us today!"
    }

    return {
      jsonld,
      showModal: false,
      accordions: [
        {
          title: 'Is coding required to manage a WordPress website?',
          text: "No, WordPress is a user-friendly platform that allows you to manage your website without coding knowledge. However, coding expertise is beneficial for complex customizations or plugin development."
        },
        {
          title: 'How long does it take to build a Customized WordPress website?',
          text: "The development time varies depending on the project's complexity and scope. Typically, it can take anywhere from a few weeks to several months."
        },
        {
          title: 'What benefits can PWA integration bring to my business regarding mobile efficiency?',
          text: "PWA integration improves mobile efficiency by providing users with a faster, more reliable, and app-like user experience. It can also improve involvement and conversion rates."
        },
        {
          title: 'Which platform suits our start-up venture: WooCommerce, Shopify, or Magento?',
          text: "The best platform depends on your specific needs and budget. WooCommerce offers flexibility and customization, while Shopify provides a user-friendly experience. Magento is suitable for large-scale e-commerce businesses."
        },
        {
          title: 'Can we integrate an ERP or CRM tool with WordPress?',
          text: "ERP or CRM tools can be integrated with WordPress using APIs or plugins, streamlining business processes and improving data management."
        },
      ],
    }
  },
  beforeCreate() {
    vm = this;
  },
    mounted: () => {
            vm.body = document.querySelector("body");
            vm.addCanonicalLink();
    },
    methods: {
            addCanonicalLink: () => {
              const link = document.createElement('link');
              link.setAttribute('rel', 'canonical');
              link.setAttribute('href', 'https://devbrother.com/services/wordpress-development-company');

              const oldLink = document.querySelector('link[rel="canonical"]');
              if (oldLink) {
                document.head.removeChild(oldLink);
              }

              document.head.appendChild(link);
            },
            showPopup: () => {
              vm.showModal = true;
              vm.body.classList.add('ban-scroll');
            },
            closePopup: () => {
              vm.showModal = false;
              vm.body.classList.remove('ban-scroll');
            },
            scrollFix: hash => {
              setTimeout(() => {
                setTimeout(() => $('html, body')
                  .animate({ scrollTop: $(hash).offset().top }, 1000), 1)
              });
            },
            closeAccordions() {
              this.$refs.accordion.forEach(el => el.closeAccordion());
            },
  }
}
</script>
<style lang="scss" scoped>
.wordpress-section {
  .three-list-wrapper {
    justify-content: flex-start;

    .list-wrapper {
      margin-bottom: 15px;
    }
  }

  .faq-section {
    margin-bottom: 100px;
    z-index: 11;
    position: relative;
  }

  .more-questions {
    text-align: center;
    margin-bottom: 100px;
    position: relative;
    z-index: 11;
  }

  .inner-benefits-item {
    color: #fff;
    font-family: Roboto;
    font-size: 17px;
    letter-spacing: .57px;
    line-height: 19px;
    padding: 14px 23px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    height: 100%;
    box-sizing: border-box;
    border: 1px solid #1e242a;
    background-color: #2b506e;
    border-radius: 2px;
  }

  .benefits-list {
    flex-wrap: wrap;
    justify-content: space-around;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    width: 100%;
  }

  .benefits-list-item {
    width: 48%;
    margin-bottom: 25px;
    min-height: 180px;
    border: 1px solid #06c6ff;
    border-radius: 2px;
    padding: 4px 3px;
    min-width: 280px;
  }

  .custom-service-wrapper {
    display: flex;
    justify-content: center;
    text-align: center;

    .service-text-wrapper {
      margin-bottom: 50px;
    }
  }

  .service-process {
    margin-top: -50px;
    padding-bottom: 0px;
  }
}

</style>
