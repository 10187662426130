<template>
    <main class="web-reactjs dedicated-team">
        <div class="top-stone">
        </div>
        <div class="pagination">
            <div class="container-db">
                <div class="bread-crumbs">
                    <router-link to="/" class="router-link-active">Home</router-link>
                    <span class="bread-separator">/</span>
                    <span class="bread-current">Dedicated Development Team</span>
                </div>
            </div>
        </div>

        <section class="top-form-section service-description">
            <div class="container-db">
                <div class="service-wrapper">
                    <div class="service-text-wrapper">
                        <h1>Dedicated Development Team</h1>
                        <p>Software development is a rather complicated process: creating a software product with fairly
                            well-defined characteristics, performed with acceptable quality, within the allotted budget,
                            and within the required time frame requires constant coordination of many activities between
                            numerous specialists in collaboration.</p>
                        <p>This is where the dedicated team model comes to the rescue. What is at the heart of a
                            dedicated development team? Let's look into it.</p>
                        <p>We explain the dedicated team model's benefits. We will also explain in what scopes its team
                            model works and how the DevBrother uses it to provide the best experience.</p>
                    </div>

                    <div class="service-contact">
                        <p class="service-contact__title">Free consultation</p>
                        <p>We advise choosing the right technology and strategies for developing a new product.</p>
                        <p>Let's arrange a short phone or video call? We will listen to your wishes and select a team of
                            professionals to implement the product.</p>
                        <button @click="showPopup" class="btn form-btn">Contact us</button>
                    </div>

                </div>
            </div>
        </section>

        <section class="dedicated-section service-description">
            <div class="container-db">
                <div class="service-wrapper">
                    <div class="service-text-wrapper">
                        <h2>What is a dedicated team model?</h2>
                        <p>Hiring a dedicated team is a convenient compromise that helps create a quality product. The
                            name of this model speaks for itself. It means that the client hires an assembled experts
                            group to take over their project. The main difference with the extended team model is that
                            it is a separate work group with structures.</p>
                        <p>A dedicated team or development center is a strategy for attracting IT-developers to a
                            project. This is an ideal way to close the need for employees "here and now".</p>
                    </div>
                    <div class="service-img-wrapper"><img v-lazyload
                            data-url="/img/images-db/services/project-team.webp" alt="Team model" title="Team model" height="307"
                            width="460">
                    </div>
                </div>
            </div>
        </section>

        <section class="dedicated-section service-description">
            <div class="container-db">
                <div class="service-wrapper">
                    <div class="service-text-wrapper advantages">
                        <h2>When to choose the dedicated team model?</h2>
                        <p>
                            The DevBrother company has a dedicated team for the project, covering the basic needs and
                            requirements of the customer: analyst, marketer, designer, quality specialist, and
                            copywriter. Each of them works in their own direction. As a result, the customer receives a
                            very high-quality product. This model is typically used for prolonged business endeavors
                            where specifications are unclear and change frequently.
                        </p>
                        <p>
                            Below we will describe in more detail which industries are turning to use dedicated team
                            models.
                        </p>
                        <ol class="text-img-list">
                            <li>
                                <h3 class="marked">Business With Ambiguous Specifications</h3>
                                <div class="text-img-wrap">
                                    <div class="card-text">
                                        <p>
                                            When your idea doesn't fit your usual product and market and needs a
                                            discovery phase, a specialized group of experts is just what you need.
                                            Learning all the nuances and features before moving on to the project's
                                            development can take too much time, leading to a significant loss of the
                                            most valuable time resource.
                                        </p>
                                        <p>
                                            Its model under consideration frees up time and resources for you to pay
                                            attention to the study phase without fear of overspending.
                                        </p>
                                    </div>
                                    <div class="img-wrap service-img-wrapper">
                                        <img v-lazyload data-url="/img/images-db/services/answers.webp" alt="Communication in Devbrother" title="Communication in Devbrother"
                                            height="307" width="460">
                                    </div>
                                </div>
                            </li>
                            <li>
                                <h3 class="marked">Fresh with Undisclosed Potential Startups</h3>
                                <div class="text-img-wrap inverted">
                                    <div class="img-wrap service-img-wrapper">
                                        <img v-lazyload data-url="/img/images-db/services/startup-1.webp" alt="Startup outsourcing" title="Startup outsourcing"
                                            height="307" width="460">
                                    </div>
                                    <div class="card-text">
                                        <p>
                                            Startup outsourcing is an opportunity for young businessmen to overcome
                                            various
                                            difficulties as quickly as possible without distracting them from their main
                                            business tasks. If your new business idea has the potential to succeed, then
                                            this is the best option for you.
                                        </p>
                                        <p>
                                            In this case, finding a dedicated team can be a great option. For a startup
                                            product, speed is also crucial: with such experts, you can be sure that the
                                            work
                                            will be done efficiently and in the shortest possible time.
                                        </p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <h3 class="marked">Business for a Long Period</h3>
                                <div class="text-img-wrap">
                                    <div class="card-text">
                                        <p>
                                            If you have a long-term project, it will be more beneficial (at least
                                            financially) to hire a dedicated team to work on it.
                                        </p>
                                        <p>
                                            Its model under consideration is an ideal option in long-term software
                                            development when the current development plan involves additional resources
                                            and
                                            the use of new technologies. Group members will support you and the project
                                            all
                                            the way.
                                        </p>
                                    </div>
                                    <div class="img-wrap service-img-wrapper">
                                        <img v-lazyload data-url="/img/images-db/services/long.webp"
                                            alt="Term project" title="Term project" height="307" width="460">
                                    </div>
                                </div>
                            </li>

                        </ol>
                    </div>

                </div>
            </div>
        </section>

        <section class="dedicated-section service-description">
            <div class="container-db">
                <div class="service-wrapper">
                    <div class="service-text-wrapper outstaffing">
                        <h2>Benefits of Dedicated Development Teams</h2>
                        <p>The main advantage of dedicated development teams: is an easy start with high scalability.
                            The ability to manage its human capital more intelligently, to direct specialists to
                            essential and strategic tasks, such as market research, development of new projects and
                            products, analysis, expertise, and technical research, mainly aimed at corporate
                            decision-making are the main features of the dedicated team model implementation in a
                            company.</p>
                        <p>The enumerated features of its model lead to several competitive advantages:</p>
                        <ol>
                            <li>
                                <p><span class="marked">The best experts selected specifically for you.</span></p>
                                <p>DevBrother recruits only the best experts for its company. This way, you can be sure
                                    that the contract developers have the necessary skills and that they will be able to
                                    fit into your core team.</p>
                            </li>
                            <li>
                                <p><span class="marked">Always in touch.</span></p>
                                <p>There is no "employee-employer" labor relationship between DevBrother members and the
                                    customer, which is typical for traditional standardized communication. However, the
                                    hired specialists will always be in touch with you under your guidance and
                                    management. You communicate directly with the IT-developers.</p>
                            </li>
                            <li>
                                <p><span class="marked">Stable price of services.</span></p>
                                <p>The flexibility of payment methods in this business relationship model is a
                                    significant advantage. When concluding a deal with the group of experts, the client
                                    will negotiate a monthly payment amount. This will allow you to distribute the
                                    client's further budget, as the price (costs) will be fixed.</p>
                            </li>
                            <li>
                                <p><span class="marked">Stability.</span></p>
                                <p>Clear deadlines with no deviations from internal processes are one of the main
                                    benefits of any outsourcing. You can start a complete revolution in your office, but
                                    your project will not be stopped or paused. These guys will continue to move the
                                    client closer to his goal as he restructures his team environment. In addition, you
                                    can let the staff go on vacation, hire new full-time staff, lay off employees, and
                                    be confident that the project will be delivered on time.</p>
                            </li>
                            <li>
                                <p><span class="marked">Complete focus on the project goals.</span></p>
                                <p>Work flexibility is crucial for hired IT experts. The essential advantage of working
                                    with its business model is that a remote specialist is not limited by time and can
                                    do more than a full-time employee.</p>
                            </li>
                            <li>
                                <p><span class="marked">The ability to focus on other important projects and
                                        tasks.</span></p>
                                <p>A dedicated structure allows developers and managers to better and more productively
                                    manage time, starting with development and production. Thus, there is a significant
                                    reduction in workload with the adequate division of responsibilities and
                                    professionalization of processes, which directly affects productivity.</p>
                            </li>
                        </ol>
                        <a href="#questions" class="btn single-btn" v-smooth-scroll>Let’s talk</a>
                    </div>
                </div>
            </div>
        </section>

        <div class="inner-container">
            <section class="clients awards-section">
                <div class="clients-wrapper award-wrapper">
                    <div class="inner-container">
                        <h2 class="our-achievements">Reviews about us</h2>
                        <ul class="award-list">
                            <li class="awards-list-item" data-aos="zoom-in" data-aos-duration="1500">
                                <a target="_blank" href="https://www.goodfirms.co/company/devbrother"
                                    rel="nofollow"><img v-lazyload height="160" width="187"
                                        data-url="https://goodfirms.s3.amazonaws.com/badges/color-badge/top-software-development-companies.svg"
                                        alt="GoodFirms Badge"></a>
                            </li>
                            <li class="awards-list-item" data-aos="zoom-in" data-aos-duration="500">
                                <a href="https://clutch.co/profile/devbrother" target="_blank" rel="nofollow">
                                    <img v-lazyload class="award-logo" data-url="/img/images-db/socials/award2.png"
                                        height="160" width="148" alt="Clutch Top Developers">
                                </a>
                            </li>
                            <li class="awards-list-item" data-aos="zoom-in" data-aos-duration="500">
                                <a class="top-soft-award-logo"
                                    href="https://topsoftwarecompanies.co/ukraine/software-development/agencies/kharkiv"
                                    target="_blank" rel="nofollow">
                                    <img v-lazyload height="150" width="150"
                                        data-url="/img/images-db/socials/top-software-developers.webp"
                                        alt="software developers kharkiv" title="Software Developers Kharkiv" />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        </div>

        <section class="dedicated-section service-description">
            <div class="container-db">
                <div class="service-wrapper">
                    <div class="service-text-wrapper key-benefits">
                        <h2>How does DevBrother set up a dedicated development team?</h2>
                        <p>The dedicated team model consists of a team of multidisciplinary professionals to fully serve
                            the learning and business solutions:</p>
                        <ol class="row-list">
                            <li>Project Manager</li>
                            <li>Pedagogical Designer</li>
                            <li>Reviewer</li>
                            <li>Art Director</li>
                            <li>Illustrator</li>
                            <li>Web Designer</li>
                        </ol>
                        <p>Clients can choose the resources they need or a fully dedicated team. However, it is
                            important to have a vendor who knows how to create the best team for your corporate
                            education strategy, optimizing your dedicated team to solve the problems your company needs.
                            DevBrother is exactly that.</p>
                        <p>At this stage, the successful DevBrother has assembled a group of qualified and motivated
                            professionals to work on your project. Here are the criteria that were used to select:</p>
                        <ol>
                            <li>Profitability - the estimated return on investment for this project;</li>
                            <li>Quality and service - the DevBrother specialists has the skills and experience to
                                deliver high-quality products and services;</li>
                            <li>Skill development - the opportunity for a team member to develop skills on the project;
                            </li>
                            <li>Morale and motivation - the psychological conditions for working on this project.</li>
                        </ol>
                        <p>The process of hiring a team consists of several steps:</p>
                        <ol class="colored-markers">
                            <li>
                                <p>Finding the right candidate.</p>
                            </li>
                            <li>
                                <p>Interviewing with a potential DevBrother team member.</p>
                            </li>
                            <li>
                                <p>Receiving a trial assignment and its completion by the candidate after communication.
                                </p>
                            </li>
                            <li>
                                <p>After the successful completion of the assignment, it's straightforward to get an
                                    agreement to cooperate.</p>
                            </li>
                        </ol>
                        <p>Each one of DevBrother employee goes through this step-by-step necessary selection process.
                        </p>
                        <a href="#questions" class="btn single-btn" v-smooth-scroll>Let’s talk</a>
                    </div>
                </div>
            </div>
        </section>
        <section class="industries-section service-description">
            <div class="container-db">
                <div class="service-wrapper">
                    <div class="service-text-wrapper">
                        <h2>Industries of our expertise</h2>
                        <p>We have delivered solutions for clients hailing from diverse industry verticals</p>
                    </div>
                </div>
                <ul class="cards-wrapper">
                    <li>
                        <p class="image-wrapper">
                            <img v-lazyload data-url="/img/images-db/services/arts-icon.webp" alt="Art Galleries"
                                height="70" width="70">
                        </p>
                        Art Galleries
                    </li>
                    <li>
                        <router-link to="/industries/healthcare-software-development">
                            <p class="image-wrapper">
                                <img v-lazyload data-url="/img/images-db/services/healthcare-icon.webp" alt="HealthCare"
                                    height="70" width="70">
                            </p>
                            HealthCare
                        </router-link>
                    </li>
                    <li>
                        <p class="image-wrapper">
                            <img v-lazyload data-url="/img/images-db/services/wordpress-icon.webp" alt="WP Development"
                                height="70" width="70">
                        </p>
                        WP Development
                    </li>
                    <li>
                        <p class="image-wrapper">
                            <img v-lazyload data-url="/img/images-db/services/ticket-icon.webp" alt="Eventing/Ticketing"
                                height="70" width="70">
                        </p>
                        Eventing/Ticketing
                    </li>
                    <li>
                        <p class="image-wrapper">
                            <img v-lazyload data-url="/img/images-db/services/bitcoin-icon.webp" alt="Cryptocurrency"
                                height="70" width="70">
                        </p>
                        Cryptocurrency
                    </li>
                    <li>
                        <p class="image-wrapper">
                            <img v-lazyload data-url="/img/images-db/services/e-commerce-icon.webp" alt="E-Commerce"
                                height="70" width="70">
                        </p>
                        E-Commerce
                    </li>
                    <li>
                        <p class="image-wrapper">
                            <img v-lazyload data-url="/img/images-db/services/finance-icon.webp" alt="Finance"
                                height="70" width="70">
                        </p>
                        Finance
                    </li>
                    <li>
                        <p class="image-wrapper">
                            <img v-lazyload data-url="/img/images-db/services/fedex3-icon.webp" alt="Logistics"
                                height="70" width="70">
                        </p>
                        Logistics
                    </li>
                </ul>
            </div>
        </section>

        <section class="dedicated-section service-description">
            <div class="container-db">
                <div class="service-wrapper">
                    <div class="service-text-wrapper advantages">
                        <h2>How to hire a dedicated development team?</h2>
                        <p>An outsourcing company needs to consist of the best specialists who successfully perform the
                            assigned tasks. DevBrother takes a careful and responsible approach to them selection. Here
                            are some essential skills that DevBrother looks for when hiring a programmer:</p>
                        <ul>
                            <li class="marked-item">Ability to code in common programming languages such as JavaScript,
                                TypeScript, HTML, CSS, Ruby, Golang, Python, and C#;</li>
                            <li class="marked-item">Basic understanding of data science and data structures;</li>
                            <li class="marked-item">Familiarity with backend development and databases such as SQL and
                                NoSQL;</li>
                            <li class="marked-item">Cloud computing skills for platforms such as Amazon Web Service,
                                Google Cloud Platform, and Microsoft Azure;</li>
                            <li class="marked-item">Mastery of software development tools;</li>
                            <li class="marked-item">Ability to think logically and critically along with problem-solving
                                skills;</li>
                            <li class="marked-item">Ability to communicate and collaborate effectively and productively
                                with colleagues and customers;</li>
                            <li class="marked-item">Acceptance of responsibility for mistakes and ability to learn from
                                them;</li>
                            <li class="marked-item">Ability to review volumes of code and spot minor errors or
                                deficiencies.</li>
                        </ul>
                        <p>We have already described to you the stages of hiring specialists for the DevBrother and so
                            above you can read about some of the criteria that influence the selection of IT specialists
                            for the company.</p>
                        <a href="#questions" class="btn single-btn" v-smooth-scroll>Let’s talk</a>
                    </div>
                </div>
            </div>
        </section>

        <section class="faq-section">
            <h2>Frequently Asked Questions</h2>
            <div class="container-db">
                <div id="app" class="">
                    <app-accordion @closeAccordions='closeAccordions()' v-for="(accordion, index) in accordions"
                        ref="accordion" :key="index" class="app-accordion">
                        <template v-slot:title>
                            <span class="">{{ accordion.title }}</span>
                        </template>
                        <template v-slot:content>
                            <p>
                                {{ accordion.text }}
                            </p>
                        </template>
                    </app-accordion>
                </div>
            </div>
        </section>

        <h2 id="questions" class="more-questions">If you have any more questions, write to us</h2>

        <section class="acquainted main-contact" id="acquainted">
            <div class="container-db">
                <div class="acquainted-form-wrapper">
                    <div v-lazyload data-src="/img/images-db/parallax/stone2.webp" class="lazy-bg bottom-stone"></div>
                    <div class="cooperation-offer">
                        <h3>What's your challenge?</h3>
                        <div class="cooperation-offer-text">
                            Let us deliver the talent and expertise to help you solve it
                        </div>
                    </div>
                    <AcquaintedForm />
                </div>
            </div>
        </section>

        <div class="popup" :class="{ 'hide-popup': !showModal }">
            <div class="popup-wrapper">
                <div class="popup-inner" @click.self="closePopup()">
                    <div class="popup-content" id="service-contact-form">
                        <button class="close-popup" @click="closePopup()" type="button" aria-label="close popup"></button>
                        <ServicesTopForm />
                    </div>
                </div>
            </div>
        </div>

        <script v-html="jsonld" type="application/ld+json"></script>
    </main>
</template>
<script>
import AcquaintedForm from "../partials/AcquaintedForm";
import { requestGet, requestPost } from "../../services/axios.js";
import AppAccordion from "./web-development/AppAccordion";
import ServicesTopForm from '../ServicesTopForm';

let vm = {};
export default {
    name: 'DedicatedDevelopmentTeam',
    metaInfo: {
        link: [
            {
                rel: 'canonical',
                href: 'https://devbrother.com/services/dedicated-development-team'
            }
        ],
        title: 'Dedicated Development Team: Hire Developers for Business, Startups',
        meta: [
            {
                vmid: "description",
                name: 'description',
                content: 'When you hire a dedicated development team, you get 1% of the talent focused on your task. The outsourcing model allows you to find the best software engineers.'
            },
            {
                vmid: "og:image:type",
                property: "og:image:type",
                content: 'image/webp'
            },
            {
                vmid: "og:title",
                property: "og:title",
                content: 'Dedicated Development Team: Hire Developers for Business, Startups'
            },
            {
                vmid: "og:description",
                property: "og:description",
                content: 'When you hire a dedicated development team, you get 1% of the talent focused on your task. The outsourcing model allows you to find the best software engineers.'
            },
            {
                vmid: "og:image",
                name: "og:image",
                content: 'https://devbrother.com/img/images-db/services/project-team.webp'
            },
            {
                vmid: "og:type",
                property: "og:type",
                content: "website"
            },
            {
                vmid: "og:url",
                property: "og:url",
                content: 'https://devbrother.com/services/dedicated-development-team'
            },
            {
                vmid: "twitter:title",
                name: "twitter:title",
                content: 'Dedicated Development Team: Hire Developers for Business, Startups'
            },
            {
                vmid: "twitter:description",
                name: "twitter:description",
                content: 'When you hire a dedicated development team, you get 1% of the talent focused on your task. The outsourcing model allows you to find the best software engineers.'
            },
            {
                vmid: "twitter:image",
                name: "twitter:image",
                content: 'https://devbrother.com/img/images-db/services/project-team.webp'
            }
        ]
    },
    components: { AcquaintedForm, AppAccordion, ServicesTopForm },
    data() {
        const jsonld = {
            "@context": "https://schema.org",
            "@graph": [{
                "@type": "Organization",
                "@id": "https://devbrother.com/#organization",
                "name": "DevBrother",
                "url": "https://devbrother.com/",
                "sameAs": ["https://www.linkedin.com/company/devbrother/",
                    "https://www.instagram.com/devbrother_company/",
                    "https://clutch.co/profile/devbrother"],
                "logo": {
                    "@type": "ImageObject", "inLanguage": "en-US",
                    "@id": "https://devbrother.com/#/schema/logo/image/",
                    "url": "https://devbrother.com/img/images-db/Logo.png",
                    "contentUrl": "https://devbrother.com/img/images-db/Logo.png",
                    "width": 70,
                    "height": 40,
                    "caption": "DevBrother"
                },
                "image":
                    { "@id": "https://devbrother.com/#/schema/logo/image/" }
            },
            {
                "@type": "WebSite", "@id": "https://devbrother.com/#website",
                "url": "https://devbrother.com/",
                "name": "DevBrother",
                "description": "DevOps &amp; Software Development",
                "publisher": { "@id": "https://devbrother.com/#organization" },
                "potentialAction": [{
                    "@type": "SearchAction",
                    "target": {
                        "@type": "EntryPoint",
                        "urlTemplate": "https://devbrother.com/?s={search_term_string}"
                    },
                    "query-input": "required name=search_term_string"
                }],
                "inLanguage": "en-US"
            },
            {
                "@type": "ImageObject",
                "inLanguage": "en-US",
                "@id": "https://devbrother.com/services/dedicated-development-team#primaryimage",
                "url": "https://devbrother.com/img/images-db/services/project-team.webp",
                "contentUrl": "https://devbrother.com/img/images-db/services/project-team.webp",
                "width": 512,
                "height": 512
            },
            {
                "@type": "WebPage",
                "@id": "https://devbrother.com/services/dedicated-development-team",
                "url": "https://devbrother.com/services/dedicated-development-team",
                "name": "Dedicated Development Team: Hire Developers for Business, Startups",
                "isPartOf": { "@id": "https://devbrother.com/#website" },
                "primaryImageOfPage": { "@id": "https://devbrother.com/services/dedicated-development-team#primaryimage" },
                "datePublished": "2022-10-04T18:29:55+00:00",
                "dateModified": "2022-10-05T12:26:55+00:00",
                "description": "When you hire a dedicated development team, you get 1% of the talent focused on your task. The outsourcing model allows you to find the best software engineers.",
                "breadcrumb": { "@id": "https://devbrother.com/services/dedicated-development-team#breadcrumb" },
                "inLanguage": "en-US",
                "potentialAction": [{
                    "@type": "ReadAction",
                    "target": ["https://devbrother.com/services/dedicated-development-team"]
                }]
            },
            {
                "@type": "BreadcrumbList",
                "@id": "https://devbrother.com/services/dedicated-development-team#breadcrumb",
                "itemListElement": [{
                    "@type": "ListItem",
                    "position": 1,
                    "name": "DevBrother",
                    "item": "https://devbrother.com/"
                },
                {
                    "@type": "ListItem",
                    "position": 2,
                    "name": "Services",
                    "item": "https://devbrother.com/services/"
                },
                {
                    "@type": "ListItem",
                    "position": 3,
                    "name": "🚀 Dedicated Developmetn Team",
                    "item": "https://devbrother.com/services/dedicated-development-team"
                }
                ]
            },
            {
                "@type": "FAQPage",
                "mainEntity": [
                    {
                        "@type": "Question",
                        "name": "How much does it cost to hire a dedicated software development team?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "The cost depends on the complexity and scope of the task, the time required, and many other factors. In any case, the price is reasonable and conditional."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Who should be on a development team?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "A typical software development team comprises experts with a wide range of skills and experience: project manager, business analyst, designers, and developers."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Why hire dedicated developers instead of freelancers?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "If you want to ensure the task will be completed on time, in a high-quality and highly professional manner, it is better to hire a special experts group. Freelancers can also give results, but in this case, it is not 100% guaranteed."
                        }
                    }]
            }
            ]
        }


        return {
            jsonld,
            showModal: false,
            accordions: [
                {
                    title: 'How much does it cost to hire a dedicated software development team?',
                    text: 'The cost depends on the complexity and scope of the task, the time required, and many other factors. In any case, the price is reasonable and conditional.',

                },
                {
                    title: 'Who should be on a development team?',
                    text: 'A typical software development team comprises experts with a wide range of skills and experience: project manager, business analyst, designers, and developers.',
                },
                {
                    title: 'Why hire dedicated developers instead of freelancers?',
                    text: 'If you want to ensure the task will be completed on time, in a high-quality and highly professional manner, it is better to hire a special experts group. Freelancers can also give results, but in this case, it is not 100% guaranteed.'
                }
            ],
            isMailSend: false,
            mailData: {
                name: "",
                email: "",
                comment: ""
            },
            errors: [],
            styles: {
                name: {
                    input: "",
                    label: "",
                    span: "visibility: hidden",
                },
                email: {
                    input: "",
                    label: "",
                    span: "visibility: hidden",
                },
                comment: {
                    input: "",
                    label: "",
                    span: "visibility: hidden;",
                }
            }
        };
    },
    beforeCreate() {
        vm = this;
    },
    mounted: () => {
        vm.body = document.querySelector("body");
        vm.addCanonicalLink();
    },
    methods: {
        addCanonicalLink: () => {
            const link = document.createElement('link');
            link.setAttribute('rel', 'canonical');
            link.setAttribute('href', 'https://devbrother.com/services/dedicated-development-team');

            const oldLink = document.querySelector('link[rel="canonical"]');
            if (oldLink) {
                document.head.removeChild(oldLink);
            }

            document.head.appendChild(link);
        },
        closeAccordions() {
            this.$refs.accordion.forEach(el => el.closeAccordion());
        },
        checkForEmptiness: (field) => {
            if (vm.mailData[field] === "") {
                vm.addWarning(field)
            } else {
                vm.styles[field]["span"] = "visibility: hidden";
            }
        },
        addWarning: (field) => {
            vm.styles[field]["input"] = "border: 1px solid red;"
            vm.styles[field]["span"] = "visibility: visible";
        },
        raiseLabelUp: field => {
            vm.styles[field]["input"] = "";
            vm.styles[field]["span"] = "visibility: hidden";
        },
        validEmail: email => {
            const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },
        hasErrors: () => {
            let gotError = false;
            Object.keys(vm.mailData).forEach(item => {
                if (vm.mailData[item] == "") {
                    gotError = true;
                    vm.checkForEmptiness(item);
                } else if ((item == "email" && !vm.validEmail(vm.mailData[item]))) {
                    gotError = true;
                    vm.checkForEmptiness(item);
                    vm.addWarning('email');
                }
            });
            return gotError;
        },
        sendMail: (event) => {
            if (!vm.hasErrors()) {
                requestPost("/contact-about-us", vm.mailData)
                    .then(res => {
                        if (res.status) {
                            vm.isMailSend = true;
                            vm.mailData.name = "";
                            vm.mailData.email = "";
                            vm.mailData.comment = "";
                        } else {
                            console.log("Email send failed, error", res.error);
                        }
                    })
                    .catch(error => {
                        return { status: false, error };
                    });
            }
        },
        showPopup: () => {
            vm.showModal = true;
            vm.body.classList.add('ban-scroll');
        },
        closePopup: () => {
            vm.showModal = false;
            vm.body.classList.remove('ban-scroll');
        },
        scrollFix: hash => {
            setTimeout(() => {
                setTimeout(() => $('html, body')
                    .animate({ scrollTop: $(hash).offset().top }, 1000), 1)
            });
        },
    }
}
</script>
<style lang="scss" scoped>
h2 {
    white-space: nowrap;
}

.top-form-section {
    background: none;
}

.service-contact {
    max-height: 430px;
}

.inner-container {
    max-width: 1000px;
}

.awards-section {
    margin-bottom: 0;
}

.our-achievements {
    text-align: center;
}

ol {
    padding: 0;
    list-style-type: none;

    li {
        position: relative;
        margin-bottom: 15px;
        padding-left: 35px;

        &::before {
            content: "";
            position: absolute;
            width: 20px;
            height: 20px;
            top: 3px;
            left: 0;
            background: url(/img/images-db/services/check_circle_icon.svg) no-repeat;
            background-size: contain;
        }
    }
}

.row-list {
    display: flex;
    justify-content: space-between;
    max-width: 670px;
    flex-wrap: wrap;
    margin: 20px auto;

    li {
        width: 30%;
    }
}

.outstaffing {

    ol {
        margin-left: 20px;
        list-style-type: auto;

        li {
            padding-left: 0;

            &::before {
                display: none;
            }

            &::marker {
                color: #06c6ff;
            }
        }
    }
}

.colored-markers {
    margin-left: 20px;
    list-style-type: auto;

    li {
        padding-left: 0;

        &::before {
            display: none;
        }

        &::marker {
            color: #06c6ff;
        }
    }
}

.service-description {

    a {
        color: #06c6ff;
        text-decoration: underline;

        &:hover {
            opacity: 0.85;
        }
    }

    .service-text-wrapper {
        max-width: 570px;
    }

    .service-img-wrapper {
        max-width: 550px;

        img {
            filter: contrast(1.1) grayscale(0.7);
            box-shadow: 0 0px 10px 0 rgba(219, 231, 254, 0.93);
        }
    }

    .advantages {
        max-width: 85%;
        margin-left: auto;
        margin-right: auto;

        h2 {
            text-align: center;
        }
    }

    .outstaffing {
        max-width: 85%;
        margin-left: auto;
        margin-right: auto;

        h2 {
            text-align: center;
        }
    }

    .key-benefits {
        max-width: 85%;
        margin-left: auto;
        margin-right: auto;

        h2 {
            text-align: center;
        }
    }
}

.marked {
    color: #06c6ff;
}

.dedicated-team {
    .btn {
        display: block;
        color: #1f2427;
        text-decoration: none;

        &:hover {
            color: #ffffff;
        }
    }

    .single-btn {
        width: 200px;
        margin: 30px auto 0;
    }
}



.text-img-list {

    h3 {
        text-align: center;
        width: 100%;
    }

    .text-img-wrap {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;

        .btn {
            width: 150px;
        }

        &.inverted {
            .btn {
                margin-left: auto;
            }
        }
    }

    li {
        padding-left: 0;

        &:not(:last-child) {
            margin-bottom: 50px;
        }

        &::before {
            display: none;
        }
    }

    .card-text {
        width: 60%;
    }

    .img-wrap {
        width: 37%;
    }
}

@media (max-width: 1220px) {
    .img-wrap.service-img-wrapper {
        margin-top: 0;
    }
}

@media (max-width: 990px) {

    h2 {
        white-space: normal;
    }

    .text-img-list {

        h3 {
            text-align: center;
            width: 100%;
        }

        .text-img-wrap {
            flex-direction: column-reverse;

            &.inverted {
                flex-direction: column;
            }
        }

        .card-text {
            width: 100%;
        }

        .img-wrap {
            width: 100%;
        }
    }

    .dedicated-team {
        .service-img-wrapper {
            max-width: 400px;
        }
    }


    .img-wrap.service-img-wrapper {
        margin-top: 0;
        margin-bottom: 25px;
    }

    .inverted {
        flex-direction: column-reverse;
    }

    .btn {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

}

@media (max-width: 767px) {
    .row-list {
        li {
            width: 50%;
            white-space: nowrap;
        }
    }
}

@media (max-width: 410px) {
    .row-list {
        li {
            width: 52%;
            margin-left: auto;
            margin-right: auto;
        }
    }
}
</style>
