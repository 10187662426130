<template>
    <main class="web-nodejs web-reactjs">
        <div class="top-stone"></div>
        <div class="pagination">
            <div class="container-db">
                <div class="bread-crumbs">
                    <router-link to="/" class="router-link-active">Home</router-link>
                    <span class="bread-separator">/</span>
                    <router-link to="/services/web-development">Web Development</router-link>
                    <span class="bread-separator">/</span>
                    <span class="bread-current">NodeJS</span>
                </div>
            </div>
        </div>

        <section class="top-form-section service-description">
            <div class="container-db">
                <div class="service-wrapper">
                    <div class="service-text-wrapper">
                        <h1>Node.js Development Services Company</h1>
                        <p>
                            DevBrother provides Node.js development services to people all over the world. What sets us
                            apart from our competitors is the quality of our employees.
                        </p>
                        <p>
                            Our staff is composed of seasoned software developers, highly skilled UI/UX designers, and
                            reputable <router-link to="/services/qa-automation" class="custom-link">QA professionals.</router-link> Are you
                            thinking of building a website? You should hire a NodeJS
                            developer!
                        </p>
                        <p>
                            The services we provide are listed below:
                        </p>
                        <ul>
                            <li class="marked-item">Web Development</li>
                            <li class="marked-item">API Development</li>
                            <li class="marked-item">Mobile Apps Development</li>
                            <li class="marked-item">Custom Node.js Development</li>
                            <li class="marked-item">Software Solutions.</li>
                        </ul>
                        <p>
                            Real-time processes and app performance are crucial for many businesses. When you choose to
                            work with DB for your next project, we bring only scalable and reliable solutions.
                        </p>
                        <p>
                            We have assembled one of the largest European Software Engineers Database and have all
                            the necessary software tools to help our clients.
                        </p>
                    </div>
                    <div id="contact-service">
                        <form v-on:submit.prevent="sendMail()" action="#" novalidate="novalidate"
                            class="acquainted-form">
                            <div>
                                <p class="form-title">Get Consultation Now</p>
                                <p class="form-title">Let's Discuss to Build Something Great Together</p>
                                <div class="name-email-area">
                                    <p class="name-label">
                                        <label for="name">Name</label>
                                        <input v-model="mailData.name" type="text" required name="name" id="name"
                                            @focus="raiseLabelUp('name')" @blur="checkForEmptiness('name')" />
                                        <span class="error-message" :style="styles.name.span">Please enter the
                                            name</span>
                                    </p>
                                    <p class="email-label">
                                        <label for="email">Email</label>
                                        <input v-model="mailData.email" type="email" required name="email" id="email"
                                            @click="raiseLabelUp('email')" @blur="checkForEmptiness('email')" />
                                        <span class="error-message" :style="styles.email.span">Please enter the email in
                                            valid format</span>
                                    </p>
                                </div>
                                <p>
                                    <label for="text" id="text">Message</label>
                                    <textarea v-model="mailData.comment" name="comment" id="comment" cols="30" rows="3"
                                        aria-label="project description" required @focus="raiseLabelUp('comment')"
                                        @blur="checkForEmptiness('comment')"></textarea>
                                    <span :style="styles.comment.span">Please enter your message</span>
                                </p>
                                <button type="submit" class="btn form-btn" aria-label="submit form">Contact us</button>
                            </div>
                        </form>
                        <img class="top-icon" v-lazyload data-url="/images/node-icon.png" alt="nodejs icon" width="300"
                            height="300">
                    </div>
                </div>
            </div>
        </section>

        <section class="dedicated-section service-description">
            <div class="team-bg_services"></div>
            <div class="container-db">
                <div class="service-wrapper">
                    <div class="service-text-wrapper">
                        <h2>Hire Dedicated NodeJS Developers</h2>
                        <p>
                            In the world of web development with constantly changing requirements, two developers might
                            not be enough. Although employing the services of a single Node.js developer might seem like
                            a no-brainer at the outset, experiences have shown that recruiting a team of reliable
                            developers comes with lots of benefits.
                        </p>
                        <p>
                            The freelancer works in most cases alone, the developer can sometimes transfer projects to
                            other offices. Cooperating with DB, you get a guarantee of the work done and support after
                            completion. We set up all necessary support for remote work with our clients — office
                            spaces, recruiting, hiring, management, administrative, infrastructure, etc.
                        </p>
                        <p>Furthermore, when you employ the <a href="#we-offer" class="custom-link" v-smooth-scroll>services of a
                                development
                                company</a>, you don't have to pay for
                            a physical workspace’s rent, software, and hardware tools. What you only pay for is the
                            service being rendered. Although, Node.js software developers in the USA tend to charge
                            higher than their peers in Europe.
                        </p>
                    </div>
                    <div class="service-img-wrapper"><img v-lazyload data-url="/img/images-db/services/office-team.webp"
                            alt="developers" height="307" width="460">
                    </div>
                </div>
            </div>
        </section>

        <div class="inner-container node-inner-container">
            <section class="clients awards-section">
                <div class="clients-wrapper award-wrapper">
                    <div class="inner-container">
                        <h2 class="our-achievements">Reviews about us</h2>
                        <ul class="award-list">
                            <li class="awards-list-item" data-aos="zoom-in" data-aos-duration="1500">
                                <a target="_blank" href="https://www.goodfirms.co/company/devbrother"
                                    rel="nofollow"><img v-lazyload height="160" width="187"
                                        data-url="https://goodfirms.s3.amazonaws.com/badges/color-badge/top-software-development-companies.svg"
                                        alt="GoodFirms Badge"></a>
                            </li>
                            <li class="awards-list-item" data-aos="zoom-in" data-aos-duration="500">
                                <a href="https://clutch.co/profile/devbrother" target="_blank" rel="nofollow">
                                    <img v-lazyload class="award-logo" data-url="/img/images-db/socials/award2.png"
                                        height="160" width="148" alt="Clutch Top Developers">
                                </a>
                                <!-- <div class="clutch-widget clutch-widget-mobile" data-url="https://widget.clutch.co"
                                    data-widget-type="1" data-height="50" data-darkbg="1"
                                    data-clutchcompany-id="1327434"></div> -->
                            </li>
                            <li class="awards-list-item" data-aos="zoom-in" data-aos-duration="500">
                                <a class="top-soft-award-logo"
                                    href="https://topsoftwarecompanies.co/ukraine/software-development/agencies/kharkiv"
                                    target="_blank" rel="nofollow">
                                    <img v-lazyload height="150" width="150"
                                        data-url="/img/images-db/socials/top-software-developers.webp"
                                        alt="software developers kharkiv" title="Software Developers Kharkiv" />
                                </a>
                            </li>
                        </ul>
                    </div>
                    <!-- <div class="clutch-widget-stars clutch-widget-desktop">
                        <div class="clutch-widget" data-url="https://widget.clutch.co" data-widget-type="1"
                            data-height="50" data-darkbg="1" data-clutchcompany-id="1327434"></div>
                    </div> -->
                </div>
            </section>
        </div>

        <section id="we-offer" class="integration-section service-description">
            <div class="team-bg_services"></div>
            <div class="container-db">
                <div class="service-wrapper">
                    <div class="service-text-wrapper">
                        <h2>Node JS Web Development Services We Offer</h2>
                    </div>
                </div>
                <div class="benefits">
                    <div class="inner-container">
                        <ul class="benefits-list">
                            <li class="benefits-list-item">
                                <div class="inner-benefits-item">
                                    <h3>Real-time Applications</h3>
                                    <p>Node.js web application <router-link to="/">
                                      development services</router-link> offer many benefits not found on
                                      other platforms. It can be used to build fast and efficient real-time
                                      applications that process a large amount of data in seconds and send
                                      the information back to the end user without needing tons of servers.</p>
                                </div>
                            </li>
                            <li class="benefits-list-item">
                                <div class="inner-benefits-item">
                                    <h3>Collaborative tools</h3>
                                    <p>Various content management systems and project management applications use Node.js.
                                      A well-known project management tool, Trello, is a typical example.
                                      This is unsurprising because the runtime environment is well-built for
                                      collaborative applications requiring instant updates.</p>
                                </div>
                            </li>
                            <li class="benefits-list-item">
                                <div class="inner-benefits-item">
                                    <h3>IoT Applications</h3>
                                    <p>Various brands and companies incorporate the Internet of Things into product
                                      developments. Unsurprisingly, most software developers prefer using
                                      Node.js to develop IoT mobile apps. Most IoT apps use the MQTT protocols
                                      and other technologies, which are fully supported by NPM. So, it makes connecting
                                      to third-party services easier, thereby preparing it for integration across
                                      numerous environments.</p>
                                    <p>Node Packaged Modules (NPM) give advanced repositories to IoT requirements.
                                      Any Internet of Things device operates with changing data; however,
                                      Node.js is embedded in Google's V8 engines; this feature gives a high-speed performance.</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>

        <section class="choose-section service-description">
            <div class="container-db">
                <div class="service-wrapper">
                    <div class="service-text-wrapper">
                        <h2 class="tac">Other NodeJS development services we provide</h2>
                        <div class="two-list-wrap">
                            <ul>
                                <li class="two-list-item">API development</li>
                                <!-- <li class="two-list-item">Real-time application development</li> -->
                                <li class="two-list-item">Backend and dashboard development</li>
                                <li class="two-list-item">NodeJS consulting</li>
                                <li class="two-list-item">Web application development</li>
                            </ul>
                            <ul>
                                <li class="two-list-item">Mobile app development</li>
                                <li class="two-list-item">e-Commerce website development</li>
                                <li class="two-list-item">Express.JS development</li>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>
        </section>

        <section class="engagement-section service-description">
            <div class="container-db">
                <div class="service-wrapper">
                    <div class="service-text-wrapper">
                        <h2>Choose Your Cooperation Model</h2>
                        <p>Work comes in a variety of time patterns hire Node.js developer. There are three choices of
                            work and their particularities. Some work longer hours and some work shorter hours.
                        </p>
                    </div>
                </div>
                <ul class="benefits-list">
                    <li class="benefits-list-item">
                        <div class="inner-benefits-item">
                            <div class="img-wrapper">
                                <img v-lazyload data-url="/img/images-db/services/hourly-team.webp" alt="icon"
                                    height="55" width="55">
                            </div>
                            <h3>
                                <router-link to="/blog/staff-augmentation" class="custom-link">Team Augmentation</router-link> with Node.js Developers
                            </h3>
                            <ul>
                                <li class="marked-item"><strong>Suitable for: </strong>filling in the skill gaps with
                                    Node.js developers (from 0,5 FTE) managed on your side directly.</li>
                                <li class="marked-item"><strong>Pricing model: </strong>T&M.</li>
                            </ul>
                        </div>
                    </li>

                    <li class="benefits-list-item">
                        <div class="inner-benefits-item">
                            <div class="img-wrapper">
                                <img v-lazyload data-url="/img/images-db/services/team-out-of-place.svg" alt="icon"
                                    height="45" width="45">
                            </div>
                            <h3>Dedicated Node.js Team</h3>
                            <ul>
                                <li class="marked-item"><strong>Suitable for: </strong>filling in the skill gaps with a
                                    team of 4+ developers and a PM on our side to manage their work and regularly report
                                    to you.</li>
                                <li class="marked-item"><strong>Pricing model: </strong>T&M.</li>
                            </ul>
                        </div>
                    </li>
                    <li class="benefits-list-item">
                        <div class="inner-benefits-item">
                            <div class="img-wrapper">
                                <img v-lazyload data-url="/img/images-db/services/full-time.webp" alt="icon" height="45"
                                    width="45">
                            </div>
                            <h3>Turn-key Project Outsourcing</h3>
                            <ul>
                                <li class="marked-item"><strong>Suitable for: </strong>outsourcing the entire project or
                                    its part to reduce management efforts and development costs and streamline project
                                    delivery.</li>
                                <li class="marked-item"><strong>Pricing model: </strong>fixed price or T&M (in case of
                                    the undefined scope of work and/or long-term projects)</li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </div>
            <a href="#questions" class="btn hoverItem" v-smooth-scroll>Let’s talk</a>
        </section>

        <section class="industries-section service-description">
            <div class="container-db">
                <div class="service-wrapper">
                    <div class="service-text-wrapper">
                        <h2>Our Industry Expertise</h2>
                        <p>We have delivered NodeJS solutions for clients hailing from diverse industry verticals</p>
                    </div>
                </div>
                <ul class="cards-wrapper">
                    <li>
                        <p class="image-wrapper">
                            <img v-lazyload data-url="/img/images-db/services/arts-icon.webp" alt="Art Galleries"
                                height="70" width="70">
                        </p>
                        Art Galleries
                    </li>
                    <li>
                        <router-link to="/industries/healthcare-software-development">
                            <p class="image-wrapper">
                                <img v-lazyload data-url="/img/images-db/services/healthcare-icon.webp" alt="HealthCare"
                                    height="70" width="70">
                            </p>
                            HealthCare
                        </router-link>
                    </li>
                    <li>
                        <p class="image-wrapper">
                            <img v-lazyload data-url="/img/images-db/services/wordpress-icon.webp" alt="WP Development"
                                height="70" width="70">
                        </p>
                        WP Development
                    </li>
                    <li>
                        <p class="image-wrapper">
                            <img v-lazyload data-url="/img/images-db/services/ticket-icon.webp" alt="Eventing/Ticketing"
                                height="70" width="70">
                        </p>
                        Eventing/Ticketing
                    </li>
                    <li>
                        <p class="image-wrapper">
                            <img v-lazyload data-url="/img/images-db/services/bitcoin-icon.webp" alt="Cryptocurrency"
                                height="70" width="70">
                        </p>
                        Cryptocurrency
                    </li>
                    <li>
                        <p class="image-wrapper">
                            <img v-lazyload data-url="/img/images-db/services/e-commerce-icon.webp" alt="E-Commerce"
                                height="70" width="70">
                        </p>
                        E-Commerce
                    </li>
                    <li>
                        <p class="image-wrapper">
                            <img v-lazyload data-url="/img/images-db/services/finance-icon.webp" alt="Finance"
                                height="70" width="70">
                        </p>
                        Finance
                    </li>
                    <li>
                        <p class="image-wrapper">
                            <img v-lazyload data-url="/img/images-db/services/fedex3-icon.webp" alt="Logistics"
                                height="70" width="70">
                        </p>
                        Logistics
                    </li>
                </ul>
            </div>
        </section>

        <section class="technologies-section service-description">
            <div class="team-bg_services"></div>
            <div class="container-db">
                <div class="service-wrapper">
                    <div class="service-text-wrapper">
                        <h2>Our NodeJS-Related Development Skills</h2>
                        <p>DevBrother has a huge inventory which consists of several latest and cutting-edge tools &
                            technologies. Our NodeJS developers are highly experienced to use these technologies to
                            their optimum best.</p>
                    </div>
                </div>
                <div class="three-list-wrapper">
                    <div class="list-wrapper">
                        <ul class="bullet-list">
                            <li class="marked-item">JavaScript</li>
                            <li class="marked-item">TypeScript</li>
                            <li class="marked-item">RxJS</li>
                            <li class="marked-item">NPM/Yarn</li>
                            <li class="marked-item">Node.js</li>
                            <li class="marked-item">Express</li>
                            <li class="marked-item">Koa.js</li>
                            <li class="marked-item">Fastify.js</li>
                            <li class="marked-item">Nest.js</li>
                            <li class="marked-item">Passport.js</li>
                            <li class="marked-item">Nodemailer</li>
                            <li class="marked-item">PM2</li>
                            <li class="marked-item">Swagger</li>
                        </ul>
                    </div>
                    <div class="list-wrapper">
                        <ul class="bullet-list">
                            <li class="marked-item">GraphQL</li>
                            <li class="marked-item">REST</li>
                            <li class="marked-item">WebSockets</li>
                            <li class="marked-item">JWT</li>
                            <li class="marked-item">OAuth</li>
                            <li class="marked-item">Mocha</li>
                            <li class="marked-item">Chai</li>
                            <li class="marked-item">Sinon.js</li>
                            <li class="marked-item">Jest</li>
                            <li class="marked-item">Supertest</li>
                        </ul>
                    </div>
                    <div class="list-wrapper">
                        <ul class="bullet-list">
                            <li class="marked-item">MySQL</li>
                            <li class="marked-item">PostgreSQL</li>
                            <li class="marked-item">MongoDB</li>
                            <li class="marked-item">DynamoDB</li>
                            <li class="marked-item">Redis</li>
                            <li class="marked-item">RabbitMQ</li>
                            <li class="marked-item">Apache Kafka</li>
                            <li class="marked-item">Apache Cassandra</li>

                        </ul>
                    </div>

                </div>

            </div>
            <div class="best-tools service-wrapper">
                <div class="container-db">
                    <div class="service-text-wrapper">
                        <h2>Best Tools for NodeJS Development</h2>
                    </div>
                    <div class="industries-section">
                        <ul class="cards-wrapper">
                            <li>
                                <p class="image-wrapper">
                                    <img v-lazyload data-url="/img/images-db/services/express-icon.png" height="70"
                                        width="70" alt="Express.js">
                                </p>
                                Express.js
                            </li>
                            <li>
                                <p class="image-wrapper">
                                    <img v-lazyload data-url="/img/images-db/services/socetio-icon.png" height="70"
                                        width="70" alt="Socket.io">
                                </p>
                                Socket.io
                            </li>
                            <li>
                                <p class="image-wrapper">
                                    <img v-lazyload data-url="/img/images-db/services/meteor-icon.png" height="70"
                                        width="70" alt="Meteor">
                                </p>
                                Meteor
                            </li>
                            <li>
                                <p class="image-wrapper">
                                    <img v-lazyload data-url="/img/images-db/services/keystone.jpg" height="70"
                                        width="70" alt="Keystone">
                                </p>
                                Keystone
                            </li>
                            <li>
                                <p class="image-wrapper">
                                    <img v-lazyload data-url="/img/images-db/services/broccoli-icon.png" height="70"
                                        width="70" alt="Broccoli">
                                </p>
                                Broccoli
                            </li>
                            <li>
                                <p class="image-wrapper">
                                    <img v-lazyload data-url="/img/images-db/services/koa-icon.png" height="70"
                                        width="70" alt="Koa.js">
                                </p>
                                Koa.js
                            </li>
                            <li>
                                <p class="image-wrapper">
                                    <img v-lazyload data-url="/img/images-db/services/babel-icob.png" height="70"
                                        width="70" alt="Babel">
                                </p>
                                Babel
                            </li>
                            <li>
                                <p class="image-wrapper">
                                    <img v-lazyload data-url="/img/images-db/services/webpack-icob.png" height="70"
                                        width="70" alt="Webpack">
                                </p>
                                Webpack
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>

        <section class="companiesUse-section service-description">
            <div class="container-db">
                <div class="service-wrapper">
                    <div class="service-text-wrapper">
                        <h2 class="tac">What Companies Use NodeJS</h2>
                        <p class="tac">Node.js is the absolute leader in the technology world, with a long list of
                            advantages and 50% of the market. Therefore, it is not surprising that the largest companies
                            use Node.js in their development. Among them are such giants as Twitter, Netflix, LinkedIn,
                            NASA,
                            PayPal, Walmart, Uber, eBay, and Trello.
                        </p>
                    </div>
                </div>

                <ul class="cards-wrapper">
                    <li>
                        <p class="image-wrapper">
                            <img v-lazyload data-url="/img/images-db/services/twitter-icon.svg" alt="Twitter" width="64"
                                height="64">
                        </p>
                    </li>
                    <li>
                        <p class="image-wrapper">
                            <img v-lazyload data-url="/img/images-db/services/netflix-icon.svg" alt="Netflix" width="64"
                                height="64">
                        </p>
                    </li>
                    <li>
                        <p class="image-wrapper">
                            <img v-lazyload data-url="/img/images-db/services/linkedin-icon.svg" alt="LinkedIn"
                                width="64" height="64">
                        </p>
                    </li>
                    <li>
                        <p class="image-wrapper">
                            <img v-lazyload data-url="/img/images-db/services/nasa-icon.svg" alt="NASA" width="64"
                                height="64">
                        </p>
                    </li>
                    <li>
                        <p class="image-wrapper">
                            <img v-lazyload data-url="/img/images-db/services/paypal-icon-2.svg" alt="PayPal" width="64"
                                height="64">
                        </p>
                    </li>
                    <li>
                        <p class="image-wrapper">
                            <img v-lazyload data-url="/img/images-db/services/walmart-icon-1.svg" alt="Walmart"
                                width="64" height="64">
                        </p>
                    </li>
                    <li>
                        <p class="image-wrapper">
                            <img v-lazyload data-url="/img/images-db/services/uber-icon-3.svg" alt="Uber" width="64"
                                height="64">
                        </p>
                    </li>
                    <li>
                        <p class="image-wrapper">
                            <img v-lazyload data-url="/img/images-db/services/ebay-icon-2.svg" alt="eBay" width="64"
                                height="64">
                        </p>
                    </li>
                    <li>
                        <p class="image-wrapper">
                            <img v-lazyload data-url="/img/images-db/services/trello-icon.svg" alt="Trello" width="64"
                                height="64">
                        </p>
                    </li>
                </ul>

            </div>
        </section>

        <section class="industries-section service-description">
          <div class="container-db">
            <div class="service-wrapper">
              <div class="service-text-wrapper">
                <h2>Benefits of Node Development Services</h2>
                <p>Here are five benefits of utilizing Node.js development services:</p>
                <ol>
                  <li class="marked-item"><span class="marked">High Performance and Scalability:</span> Node.js uses Google's
                    V8 engine, which compiles JavaScript into machine code, resulting in high performance. Its event-driven,
                    non-blocking I/O model efficiently handles multiple simultaneous connections, making it highly scalable
                    for applications with heavy traffic and data-intensive operations.</li>
                  <li class="marked-item"><span class="marked">Real-Time Capabilities:</span> NPM excels in building real-time
                    applications like chat applications, gaming servers, and collaborative tools. Its event-driven architecture
                    and WebSocket protocol enable bidirectional communication between the server and clients, ensuring low latency
                    and instant data updates.</li>
                  <li class="marked-item"><span class="marked">Unified Development Stack:</span> Node.js allows developers
                    to use JavaScript for server-side and client-side code, leading to a more streamlined development process.
                    This reduces the learning curve, enables code reuse, and improves collaboration between front-end and
                    back-end developers.</li>
                  <li class="marked-item"><span class="marked">Rich Ecosystem with NPM:</span> It has a vast ecosystem with
                    the Node Package Manager (NPM), which offers various modules and libraries. This extensive repository
                    helps developers easily integrate various functionalities, speeding up the development process and
                    reducing time-to-market.</li>
                  <li class="marked-item"><span class="marked">Strong Community Support:</span> It shared resources. This
                    vibrant community continuously contributes to the improvement of Node.js, ensuring it remains
                    up-to-date with the latest trends and best practices in web development.</li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <section class="industries-section service-description service-process">
          <div class="container-db">
            <div class="service-wrapper">
              <div class="service-text-wrapper service-steps">
                <h2>Our Node.js Development Process</h2>
                <p>Here's an outline of the typical process involved in providing Node JS software:</p>
                <ol>
                  <li><span class="marked">Initial consultation and requirements gathering</span> Hold
                    meetings to understand the client's business goals, target audience, and specific project requirements.
                    Analyze the criteria to determine the scope, functionality, and features needed for the application.</li>
                  <li><span class="marked">Planning and proposal</span> Define the project roadmap,
                    including timelines, milestones, and deliverables. Develop a detailed proposal describing the scope
                    of work, cost estimates, and project schedule.</li>
                  <li><span class="marked">Design and prototyping</span> Create wireframes, mockups,
                    and prototypes to visualize the application's user interface and user experience. Present the design
                    to the client for feedback and make the necessary changes to meet their vision.</li>
                  <li><span class="marked">Development</span> Set up the development environment,
                    including the necessary tools, libraries, and frameworks. Develop reusable and modular components
                    with Node JS, ensuring the code base's scalability and maintainability. Integrate backend services
                    and APIs for seamless data flow and functionality. Implement state management solutions such as
                    Redux or Context APIs to manage application state effectively.</li>
                  <li><span class="marked">Testing and quality assurance</span> Write and execute unit
                    tests to ensure that individual components function correctly. Conduct integration tests to ensure
                    that all components work together as expected. Conduct user testing to gather feedback and identify
                    any usability issues. Resolve any bugs or issues found during testing to ensure a smooth user
                    experience.</li>
                  <li><span class="marked">Deployment</span> Optimize the application for production,
                    including code minification and separation. Deploy the application to the production environment,
                    ensuring proper configuration and setup. Conduct final tests in the production environment to confirm
                    that everything works as expected.</li>
                  <li><span class="marked">Support and maintenance</span> Provide
                    ongoing support to resolve issues, implement updates, and add new features as needed. Monitor application
                    performance and make necessary adjustments to optimize speed and reliability. Ensure the app stays
                    updated with the latest security patches and feature enhancements.</li>
                  <li><span class="marked">Training and documentation for customers</span> Conduct
                    training sessions for the client's team to ensure they can effectively use and manage the application.
                    Provide detailed documentation covering the architecture, components, and instructions
                    for using the application.</li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <section class="faq-section">
            <h2>Frequently Asked Questions</h2>
            <div class="container-db">
                <div id="app" class="">
                    <app-accordion @closeAccordions='closeAccordions()' v-for="(accordion, index) in accordions"
                        ref="accordion" :key="index" class="app-accordion">
                        <template v-slot:title>
                            <span class="">{{ accordion.title }}</span>
                        </template>
                        <template v-slot:content>
                            <p>
                                {{ accordion.text }}
                            </p>
                        </template>
                    </app-accordion>
                </div>

            </div>
        </section>

        <h2 id="questions" class="more-questions">If you have any more questions, write to us</h2>

        <section class="acquainted main-contact" id="acquainted">
            <div class="container-db">
                <div class="acquainted-form-wrapper">
                    <div class="bottom-stone"></div>
                    <div class="cooperation-offer">
                        <h3>What's your challenge?</h3>
                        <h4 class="cooperation-offer-text">
                            Let us deliver the talent and expertise to help you solve it
                        </h4>
                    </div>
                    <AcquaintedForm />
                </div>
            </div>
        </section>
        <script v-html="jsonld" type="application/ld+json"></script>
    </main>
</template>
<script>
import AcquaintedForm from "../../partials/AcquaintedForm";
import { requestGet, requestPost } from "../../../services/axios.js";
import AppAccordion from "./AppAccordion";

// import LazyLoadDirective from "../../../../directives/LazyLoadDirective";

let vm = {};
export default {
    // directives:{
    //    lazyload: LazyLoadDirective
    // },
    name: 'Node',
    metaInfo: {
        title: 'Node JS Development Services - Outsource Node Development',
        meta: [
            {
                vmid: "description",
                name: 'description',
                content: 'We are a Node JS Development Services Company. Efficiency Outsourcing Web Dev services! We guide businesses through every stage of outsource Node development.'
            },
            {
                vmid: "og:image:type",
                property: "og:image:type",
                content: 'image/webp'
            },
            {
                vmid: "og:title",
                property: "og:title",
                content: 'Node JS Development Services - Outsource Node Development'
            },
            {
                vmid: "og:description",
                property: "og:description",
                content: 'We are a Node JS Development Services Company. Efficiency Outsourcing Web Dev services! We guide businesses through every stage of outsource Node development.'
            },
            {
                vmid: "og:image",
                property: "og:image",
                content: 'http://devbrother.com/img/images-db/services/office-team.webp'
            },
            {
                vmid: "og:type",
                property: "og:type",
                content: "website"
            },
            {
                vmid: "og:url",
                property: "og:url",
                content: 'https://devbrother.com/services/web-development/node'
            },
            {
                vmid: "twitter:title",
                name: "twitter:title",
                content: 'Node JS Development Services - Outsource Node Development'
            },
            {
                vmid: "twitter:description",
                name: "twitter:description",
                content: 'We are a Node JS Development Services Company. Efficiency Outsourcing Web Dev services! We guide businesses through every stage of outsource Node development.'
            },
            {
                vmid: "twitter:image",
                name: "twitter:image",
                content: 'http://devbrother.com/img/images-db/services/office-team.webp'
            }
        ]
    },
    components: { AcquaintedForm, AppAccordion },
    data() {
        const jsonld = {
            "@context": "https://schema.org",
            "@graph":
                [{
                    "@type": "Organization",
                    "@id": "https://devbrother.com/#organization",
                    "name": "DevBrother",
                    "url": "https://devbrother.com/",
                    "sameAs": ["https://www.linkedin.com/company/devbrother/",
                        "https://www.instagram.com/devbrother_company/",
                        "https://clutch.co/profile/devbrother"],
                    "logo": {
                        "@type": "ImageObject", "inLanguage": "en-US",
                        "@id": "https://devbrother.com/#/schema/logo/image/",
                        "url": "https://devbrother.com/img/images-db/Logo.svg",
                        "contentUrl": "https://devbrother.com/img/images-db/Logo.svg",
                        "width": 70,
                        "height": 40,
                        "caption": "DevBrother"
                    },
                    "image": { "@id": "https://devbrother.com/#/schema/logo/image/" }
                },
                {
                    "@type": "WebSite", "@id": "https://devbrother.com/#website",
                    "url": "https://devbrother.com/",
                    "name": "DevBrother",
                    "description": "DevOps &amp; Software Development",
                    "publisher": { "@id": "https://devbrother.com/#organization" },
                    "potentialAction": [{
                        "@type": "SearchAction",
                        "target": {
                            "@type": "EntryPoint",
                            "urlTemplate": "https://devbrother.com/?s={search_term_string}"
                        },
                        "query-input": "required name=search_term_string"
                    }],
                    "inLanguage": "en-US"
                },
                {
                    "@type": "ImageObject",
                    "inLanguage": "en-US",
                    "@id": "https://devbrother.com/services/web-development/node#primaryimage",
                    "url": "https://devbrother.com/images/node-icon.png",
                    "contentUrl": "https://devbrother.com/images/node-icon.png",
                    "width": 512,
                    "height": 512
                },
                {
                    "@type": "WebPage",
                    "@id": "https://devbrother.com/services/web-development/node",
                    "url": "https://devbrother.com/services/web-development/node",
                    "name": "Node.js Development Services - Outsource Node.js Company",
                    "isPartOf": { "@id": "https://devbrother.com/#website" },
                    "primaryImageOfPage": { "@id": "https://devbrother.com/services/web-development/node#primaryimage" },
                    "datePublished": "2022-03-10T17:26:55+00:00",

                    "dateModified": "2022-03-10T17:26:55+00:00",
                    "description": "We are Node JS Development Company. Efficiency Outsourcing Web Dev services! We guide businesses through every stage of software development.",
                    "breadcrumb": { "@id": "https://devbrother.com/services/web-development/node#breadcrumb" },
                    "inLanguage": "en-US",
                    "potentialAction": [{
                        "@type": "ReadAction",
                        "target": ["https://devbrother.com/services/web-development/node"]
                    }]
                },
                {
                    "@type": "BreadcrumbList",
                    "@id": "https://devbrother.com/services/web-development/node#breadcrumb",
                    "itemListElement": [{
                        "@type": "ListItem",
                        "position": 1,
                        "name": "DevBrother",
                        "item": "https://devbrother.com/"
                    },
                    {
                        "@type": "ListItem",
                        "position": 2,
                        "name": "Web Development",
                        "item": "https://devbrother.com/services/web-development"
                    },
                    {
                        "@type": "ListItem",
                        "position": 3,
                        "name": "NodeJS",
                        "item": "https://devbrother.com/services/web-development/node"
                    }
                    ]
                },
                {
                    "@type": "FAQPage",
                    "mainEntity": [{
                        "@type": "Question",
                        "name": "Why choose Node.js?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Node.js is known as a server-side proxy because it can process a vast number of concurrent connections in a non-blocking manner. You should choose node js because it is simple and relatively easier to build than its competitors. Furthermore, scalability is one other key reason you should choose node js. It provides excellent real time responses that can easily be scaled. The runtime environment has been tested and trusted by many top companies. Examples include Netflix, Paypal, Linked In, and much more!"
                        }
                    }, {
                        "@type": "Question",
                        "name": "How many developers can I hire?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "If you are a Startup, we recommend you hire an experienced engineer that will help you to align with your goals. The number of developers you hire would also depend on your budget. To make things easy for your business, we recommend hiring a node js development company. They will guide you through the entire process."
                        }
                    }, {
                        "@type": "Question",
                        "name": "Will I be assigned a specific project manager to oversee my project?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Yes. When you employ our services, we immediately assign a specific project manager to oversee your project. Our project managers work closely with our developers to ensure your project turns out to be a successful one. While your project is going on, we carry you along with the entire process."
                        }
                    }, {
                        "@type": "Question",
                        "name": "Do you provide consulting services on Node.js?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Yes, we do. Our company has deep knowledge and expertise in Node.js, and we are ready to share this knowledge with our clients. The consultation service we render will typically include the estimated cost for your project and the estimated time of completion."
                        }
                    }, {
                        "@type": "Question",
                        "name": "What development process do you follow?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "When a client wants to make use of our services, our very first aim is to understand the goals and aspirations of the client. Once we are on the same page with the client, we set the development process fully in motion. We assign a project manager and node js development team specifically for your project. We stay with our clients even after the project is long completed. We also offer routine maintenance services for our clients."
                        }
                    }, {
                        "@type": "Question",
                        "name": "How can Node.js development help my business?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Node.js development can scale up your business because it has a cross-platform ability. In other words, your business can easily target all the main platforms —Windows, Mac OS, and Linux with one single code base."
                        }
                    }, {
                        "@type": "Question",
                        "name": "How much will it cost to create a Node.js application?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "To create a Node.js application, you will need to budget around $10,000 to $40,000. An average Node.js developer would charge around $20 and $40 an hour. Typically, about 500 hours of development time would be needed to develop a Node.js application. Sometimes, around 1000 hours may be needed, depending on your project."
                        }
                    }]
                }
                ]
        }

        return {
            // url: ['/images/node-icon.png'],
            jsonld,
            accordions: [
                {
                    title: 'Why choose Node.js?',
                    text: 'Node.js is known as a server-side proxy because it can process a vast number of concurrent connections in a non-blocking manner. You should choose node js because it is simple and relatively easier to build than its competitors. Furthermore, scalability is one other key reason you should choose node js. It provides excellent real time responses that can easily be scaled. The runtime environment has been tested and trusted by many top companies. Examples include Netflix, Paypal, Linked In, and much more! ',
                },
                {
                    title: 'How many developers can I hire?',
                    text: 'If you are a Startup, we recommend you hire an experienced engineer that will help you to align with your goals. The number of developers you hire would also depend on your budget. To make things easy for your business, we recommend hiring a node js development company. They will guide you through the entire process.',
                },
                {
                    title: 'Will I be assigned a specific project manager to oversee my project?',
                    text: 'Yes. When you employ our services, we immediately assign a specific project manager to oversee your project. Our project managers work closely with our developers to ensure your project turns out to be a successful one. While your project is going on, we carry you along with the entire process.',
                },
                {
                    title: 'Do you provide consulting services on Node.js?',
                    text: 'Yes, we do. Our company has deep knowledge and expertise in Node.js, and we are ready to share this knowledge with our clients. The consultation service we render will typically include the estimated cost for your project and the estimated time of completion.',
                },
                {
                    title: 'What development process do you follow?',
                    text: 'When a client wants to make use of our services, our very first aim is to understand the goals and aspirations of the client. Once we are on the same page with the client, we set the development process fully in motion. We assign a project manager and node js development team specifically for your project. We stay with our clients even after the project is long completed. We also offer routine maintenance services for our clients.',
                },
                {
                    title: 'How can Node.js development help my business?',
                    text: 'Node.js development can scale up your business because it has a cross-platform ability. In other words, your business can easily target all the main platforms —Windows, Mac OS, and Linux with one single code base.',
                },
                {
                    title: 'How much will it cost to create a Node.js application?',
                    text: 'To create a Node.js application, you will need to budget around $10,000 to $40,000. An average Node.js developer would charge around $20 and $40 an hour. Typically, about 500 hours of development time would be needed to develop a Node.js application. Sometimes, around 1000 hours may be needed, depending on your project.',
                },
            ],
            isMailSend: false,
            mailData: {
                name: "",
                email: "",
                comment: ""
            },
            errors: [],
            styles: {
                name: {
                    input: "",
                    label: "",
                    span: "visibility: hidden",
                },
                email: {
                    input: "",
                    label: "",
                    span: "visibility: hidden",
                },
                comment: {
                    input: "",
                    label: "",
                    span: "visibility: hidden;",
                }
            }
        };
    },
    beforeCreate() {
        vm = this;
    },
    mounted: () => {
        vm.addCanonicalLink();
    },
    methods: {
        addCanonicalLink: () => {
            const link = document.createElement('link');
            link.setAttribute('rel', 'canonical');
            link.setAttribute('href', 'https://devbrother.com/services/web-development/node');

            const oldLink = document.querySelector('link[rel="canonical"]');
            if (oldLink) {
                document.head.removeChild(oldLink);
            }

            document.head.appendChild(link);
        },
        closeAccordions() {
            this.$refs.accordion.forEach(el => el.closeAccordion());
        },
        checkForEmptiness: (field) => {
            if (vm.mailData[field] === "") {
                vm.addWarning(field)
            } else {
                vm.styles[field]["span"] = "visibility: hidden";
            }
        },
        addWarning: (field) => {
            vm.styles[field]["input"] = "border: 1px solid red;"
            vm.styles[field]["span"] = "visibility: visible";
        },
        raiseLabelUp: field => {
            vm.styles[field]["input"] = "";
            vm.styles[field]["span"] = "visibility: hidden";
        },
        validEmail: email => {
            const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },
        hasErrors: () => {
            let gotError = false;
            Object.keys(vm.mailData).forEach(item => {
                if (vm.mailData[item] == "") {
                    gotError = true;
                    vm.checkForEmptiness(item);
                } else if ((item == "email" && !vm.validEmail(vm.mailData[item]))) {
                    gotError = true;
                    vm.checkForEmptiness(item);
                    vm.addWarning('email');
                }
            });
            return gotError;
        },
        sendMail: (event) => {
            if (!vm.hasErrors()) {
                requestPost("/contact-about-us", vm.mailData)
                    .then(res => {
                        if (res.status) {
                            vm.isMailSend = true;
                            vm.mailData.name = "";
                            vm.mailData.email = "";
                            vm.mailData.comment = "";
                        } else {
                            console.log("Email send failed, error", res.error);
                        }
                    })
                    .catch(error => {
                        return { status: false, error };
                    });
            }
        }
    }
}

</script>
<style scoped lang="scss">
.custom-link {
    color: #06c6ff;
    text-decoration: underline;

    &:hover {
        opacity: 0.85;
    }
}

.integration-section {
    padding-top: 70px;
    margin-top: -70px;
}

.node-inner-co .node-inner-container {
    max-width: 1000px;
    padding: 0 15px;
}

.awards-section {
    margin-bottom: 0px;
}

.award-wrapper .our-achievements {
    text-align: center;
}

.marked {
  color: #06c6ff;
}

.service-steps {
  ol {
    margin-left: 20px;
    list-style-type: auto;

    li {
      padding-left: 0;

      &::before {
        display: none;
      }

      &::marker {
        color: #06c6ff;
      }
    }
  }
}

.service-process {
  margin-top: -20px;
}
</style>
