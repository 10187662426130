<template>
  <!-- SECTION POST -->
  <div class="post-page container">
    <main>
      <div class="post-header">
        <!-- <p class="category">{{ currentPost.category ? currentPost.category.name : 'None' }}</p> -->
        <h1 id="main-title" class="page-title">
          {{ currentPost.title }}
        </h1>
        <p class="post-meta">
          <!-- <span class="author">by <b>{{ currentPost.author_id ? currentPost.author_id.name : 'None' }}</b></span> -->
          <time :datetime="currentPost.created_at">{{ currentPost.created_at | createDate }}</time>
        </p>
      </div>

      <section class="post-subheader">
        <!-- <p>
          {{ currentPost.title }}
        </p> -->
        <div class="img-content">
          <img :src="'/' + currentPost.image" :alt="currentPost.image_alt" :title="currentPost.image_title" height="500" width="251" />
        </div>
      </section>

      <section class="toc-section">
        <h3>Structure</h3>
        <div class="toc-container"></div>
      </section>

      <article class="main-article-text" v-html="currentPost.body">
      </article>

      <div class="author-info">
        <div class="author-img-wrap">
          <img v-lazyload class="author-info__img" width="150" height="150" :data-url="'../storage/'+currentPost.author_id.avatar"
            alt="author photo">
        </div>
        <div class="author-info__content">
          <span>Written by</span>
          <div class="author-name">
            <a target="_blank" :href="currentPost.author_id.linkedin" rel="nofollow">{{ currentPost.author_id.name }}</a>
          </div>
          <p class="author-position">{{ currentPost.author_id.job_title }}</p>
          <p class="author-description">{{ currentPost.author_id.description }}
          </p>

          <ul class="list-social-founder row">
            <li><a target="_blank" :href="currentPost.author_id.linkedin" rel="nofollow">
                <svg width="24px" height="24px" viewBox="0 0 30 29" version="1.1" xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink">
                  <title>LinkedIn</title>
                  <desc>Created with Sketch.</desc>
                  <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="footer" transform="translate(-810.000000, -204.000000)" fill="#2b506e" fill-rule="nonzero">
                      <g id="Group">
                        <g id="icons" transform="translate(531.000000, 204.000000)">
                          <g id="Filled/16x16/LinkedIn" transform="translate(279.000000, 0.000000)">
                            <path id="linkedin"
                              d="M30,17.779877 L30,29 L23.5700286,29 L23.5700286,18.5313332 C23.5700286,15.9015187 22.6400026,14.1067732 20.3125661,14.1067732 C18.5358635,14.1067732 17.4785465,15.3163002 17.013394,16.4863139 C16.8437658,16.9045893 16.8001032,17.4867738 16.8001032,18.0722746 L16.8001032,29 L10.3679696,29 C10.3679696,29 10.4545972,11.2695145 10.3679696,9.43250398 L16.7994755,9.43250398 L16.7994755,12.2061182 C16.7864325,12.2268626 16.7694837,12.2492299 16.7572079,12.2693393 L16.7994755,12.2693393 L16.7994755,12.2061182 C17.6539639,10.8747354 19.1799906,8.97281035 22.5957123,8.97281035 C26.8275609,8.97273979 30,11.7694975 30,17.779877 Z M3.63968409,0 C1.43925955,0 0,1.46008292 0,3.3801417 C0,5.25835884 1.39761972,6.76275298 3.55431197,6.76275298 L3.59727702,6.76275298 C5.84031787,6.76275298 7.23528714,5.25864108 7.23528714,3.3801417 C7.19301957,1.46008292 5.84031787,0 3.63968409,0 Z M0.382012336,29 L6.81170472,29 L6.81170472,9.43250398 L0.382012336,9.43250398 L0.382012336,29 Z">
                            </path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg></a></li>
            <li><a target="_blank" :href="'mailto:'+currentPost.author_id.email">
                <svg width="24px" height="24px" viewBox="0 0 30 25" version="1.1" xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink">
                  <title>Filled/24x24/mail</title>
                  <desc>Created with Sketch.</desc>
                  <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="footer" transform="translate(-531.000000, -207.000000)" fill="#2b506e">
                      <g id="Group">
                        <g id="icons" transform="translate(531.000000, 204.000000)">
                          <g id="Filled/24x24/mail">
                            <path
                              d="M4.43201031e-13,12.0401786 L15,20.4553571 L30,12.0401786 L30,24.83125 C30,26.1325709 28.8807119,27.1875 27.5,27.1875 L2.5,27.1875 C1.11928813,27.1875 4.43201031e-13,26.1325709 4.43201031e-13,24.83125 L4.43201031e-13,12.0401786 Z M1,3.625 L29,3.625 C29.5522847,3.625 30,4.07271525 30,4.625 C30,6.01785714 30,7.41071429 30,8.80357143 L15,17.21875 L4.43201031e-13,8.80357143 L4.43201031e-13,4.625 C4.43133396e-13,4.07271525 0.44771525,3.625 1,3.625 Z">
                            </path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg></a></li>
            <!-- <li>
              <a class="insta-link" :href="currentPost.author_id.instagram" target="_blank" rel="nofollow">
                <svg fill="#2b506e" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="24px" height="24px">
                  <path class="insta"
                    d="M 16 3 C 8.83 3 3 8.83 3 16 L 3 34 C 3 41.17 8.83 47 16 47 L 34 47 C 41.17 47 47 41.17 47 34 L 47 16 C 47 8.83 41.17 3 34 3 L 16 3 z M 37 11 C 38.1 11 39 11.9 39 13 C 39 14.1 38.1 15 37 15 C 35.9 15 35 14.1 35 13 C 35 11.9 35.9 11 37 11 z M 25 14 C 31.07 14 36 18.93 36 25 C 36 31.07 31.07 36 25 36 C 18.93 36 14 31.07 14 25 C 14 18.93 18.93 14 25 14 z M 25 16 C 20.04 16 16 20.04 16 25 C 16 29.96 20.04 34 25 34 C 29.96 34 34 29.96 34 25 C 34 20.04 29.96 16 25 16 z" />
                </svg>
              </a>
            </li> -->
          </ul>
        </div>
      </div>

      <article class="share-section">
        <p>share this article</p>
        <ul class="social-icon-list">
          <ShareNetwork network="twitter" url="https://devbrother.com" :title="currentPost.title"
            :description="currentPost.meta_description" hashtags="devbrother">
            <li class="social-icon-item twitter">
              <a>
                <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="twitter"
                  class="svg-inline--fa fa-twitter fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512">
                  <path fill="currentColor"
                    d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z">
                  </path>
                </svg>
              </a>
            </li>
          </ShareNetwork>
          <ShareNetwork network="facebook" url="https://devbrother.com" :title="currentPost.title"
            :description="currentPost.meta_description" hashtags="devbrother">
            <li class="social-icon-item facebook">
              <a>
                <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="facebook-f"
                  class="svg-inline--fa fa-facebook-f fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 320 512">
                  <path fill="currentColor"
                    d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z">
                  </path>
                </svg>
              </a>
            </li>
          </ShareNetwork>
          <ShareNetwork network="linkedin" url="https://devbrother.com" :title="currentPost.title"
            :description="currentPost.meta_description" hashtags="devbrother">
            <li class="social-icon-item linkedin">
              <a rel="nofollow">
                <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="linkedin-in"
                  class="svg-inline--fa fa-linkedin-in fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512">
                  <path fill="currentColor"
                    d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z">
                  </path>
                </svg>
              </a>
            </li>
          </ShareNetwork>
        </ul>
      </article>

      <div v-if="[15, 16, 17].includes(currentPost.id)" class="post-acquainted">
        <AcquaintedSection />
      </div>

      <script v-html="jsonld" type="application/ld+json"></script>
    </main>
  </div>
</template>
<script>
import { requestGet, requestPost } from "../../services/axios";
import AcquaintedSection from '../AcquaintedSection';
import Vue from 'vue';
import tocbot from 'tocbot';

let vm = {};

import VueSocialSharing from 'vue-social-sharing'

Vue.use(VueSocialSharing);

export default {
  name: "Post",
  components: {
    VueSocialSharing,
    AcquaintedSection
  },
  metaInfo() {
    return {
      title: vm.currentPost.seo_title,
      meta: [
        {
          vmid: "description",
          name: "description",
          content: vm.currentPost.meta_description
        },
        // {
        //   vmid: "og:image:type",
        //   name: "og:image:type",
        //   content: 'image/jpeg'
        // },
        {
          vmid: "og:image:type",
          property: "og:image:type",
          content: 'image/jpeg'
        },
        // {
        //   vmid: "og:title",
        //   name: "og:title",
        //   content: vm.currentPost.seo_title
        // },
        {
          vmid: "og:title",
          property: "og:title",
          content: vm.currentPost.seo_title
        },
        // {
        //   vmid: "og:description",
        //   name: "og:description",
        //   content: vm.currentPost.meta_description
        // },
        {
          vmid: "og:description",
          property: "og:description",
          content: vm.currentPost.meta_description
        },
        // {
        //   vmid: "og:image",
        //   name: "og:image",
        //   content: 'https://devbrother.com/' + vm.currentPost.image
        // },
        {
          vmid: "og:image",
          property: "og:image",
          content: 'https://devbrother.com/' + vm.currentPost.image
        },
        // {
        //   vmid: "og:type",
        //   name: "og:type",
        //   content: "article"
        // },
        {
          vmid: "og:type",
          property: "og:type",
          content: "article"
        },
        // {
        //   vmid: "og:url",
        //   name: "og:url",
        //   content: 'https://devbrother.com/blog/' + this.$route.params.name
        // },
        {
          vmid: "og:url",
          property: "og:url",
          content: 'https://devbrother.com/blog/' + this.$route.params.name
        },
        {
          vmid: "twitter:title",
          name: "twitter:title",
          content: vm.currentPost.seo_title
        },
        {
          vmid: "twitter:description",
          name: "twitter:description",
          content: vm.currentPost.meta_description
        },
        {
          vmid: "twitter:image",
          name: "twitter:image",
          content: 'https://devbrother.com/' + vm.currentPost.image
        }
      ],
    };
  },
  data() {
    const jsonld = {
      "@context": "http://schema.org",
      "@type": "Article",
      "url": "",
      "name": "",
      "datePublished": "",
      "headline": "",
      "author": {
        "@type": "Person",
        "name": "DevBrother",
        "image": "https://devbrother.com/img/images-db/Logo.svg"
      },
      "image": "",
      // "publisher": {
      //   "@type": "Organization",
      //   "name": "DevBrother",
      //   "logo": {
      //     "@type": "ImageObject",
      //     "width": 32,
      //     "height": 32,
      //     "url": "https://devbrother.com/img/images-db/Logo.svg"
      //   }
      //},
      "mainEntityOfPage": "",
      "@graph": [
        {
          "@type": "BreadcrumbList",
          "@id": "https://devbrother.com/blog/"+ this.$route.params.name +"#breadcrumb",
          "itemListElement": [
            {
              "@type": "ListItem",
              "position": 1,
              "name": "DevBrother",
              "item": "https://devbrother.com/"
            },
            {
              "@type": "ListItem",
              "position": 2,
              "name": "Blog",
              "item": "https://devbrother.com/blog/"
            },
            {
              "@type": "ListItem",
              "position": 3,
              "name": "Cryptocurrency Development Company",
              "item": "https://devbrother.com/blog/"+this.$route.params.name
            }
          ]
        },
      ],
    }

    return {
      jsonld,
      post: this.$route.params.name,
      isShowSocialLink: false,
      currentPost: {},
      overriddenNetworks: {
        "custom": {
          "sharer": "https://mycustomdomain.com",
          "type": "popup"
        },
      }
    };
  },
  beforeCreate() {
    vm = this;
  },
  async mounted() {
    await vm.getPost();
    tocbot.init({
      tocSelector: '.toc-container',
      contentSelector: '.container',
      headingSelector: 'h1, h2',
      orderedList: true,
      collapseDepth: 2,
      asInnerContainers: true,
      scrollSmooth: true,
      headingsOffset: -10,
      throttleTimeout: 50,
    });

    vm.addCanonicalLink();
  },
  beforeDestroy: () => {
    tocbot.destroy();
  },
  filters: {
    createDate: function (value) {
      const created_at = new Date(value.replace(/ /g, "T"));

      const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
      ];
      const month = monthNames[created_at.getMonth()];
      const day = created_at.getDate();
      const year = created_at.getFullYear();
      return month + '. ' + day + ', ' + year;
    }
  },
  methods: {
    addCanonicalLink: () => {
        const link = document.createElement('link');
        link.setAttribute('rel', 'canonical');
        link.setAttribute('href', 'https://devbrother.com/blog/' + vm.$route.params.name);

        const oldLink = document.querySelector('link[rel="canonical"]');
        if (oldLink) {
            document.head.removeChild(oldLink);
        }

        document.head.appendChild(link);
    },
    postWithAddedIds(post) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(post.body, 'text/html');
      const headers = doc.querySelectorAll('h1, h2');
      headers.forEach(header => {
        const textContent = header.textContent.trim().toLowerCase().replace(/[\s]+/g, '-');

        header.id = textContent;
      });

      return {
        ...post,
        body: doc.body.innerHTML
      };
    },
    handleDomParser: (post) => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(post.body, 'text/html');

      const blockquotes = doc.querySelectorAll('blockquote');

      const result = [];

      blockquotes.forEach(blockquote => {
        const h2 = blockquote.querySelector('h2');

        if (h2 && h2.textContent.trim() === 'FAQ') {
          const h3 = blockquote.querySelector('h3');
          const p = blockquote.querySelector('p');

          if (h3 && p) {
            result.push({
              "@type": "Question",
              "name": h3.textContent.trim(),
              "acceptedAnswer": {
                "@type": "Answer",
                "text": p.textContent.trim()
              }
            });
          }
        }
      });

      return result;
    },
    convertPublishedDate: (data) => {
      const date = new Date(data);

      const month = date.toLocaleString('en-US', { month: 'short' });
      const day = date.getDate();
      const weekday = date.toLocaleDateString('en-US', { weekday: 'short' });
      const year = date.getFullYear();
      const time = date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds()

      return weekday + ', ' + day + ' ' + month + ' ' + year + ' ' + time;
    },
    async getPost() {
      await requestGet("/getPost/" + vm.post, {})
        .then(response => {
          if (response.status) {
            vm.updateJsonLd(response.data);

            vm.currentPost = vm.postWithAddedIds(response.data);
          }
        })
    },
    updateJsonLd: (data) => {
      vm.jsonld.url = window.location.href;
      vm.jsonld.mainEntityOfPage = window.location.href;

      vm.jsonld.name = data.title;
      vm.jsonld.headline = data.title;

      vm.jsonld.image = window.location.origin + '/' + data.image;
      vm.jsonld.datePublished = vm.convertPublishedDate(data.updated_at);

      const faq = {
        "@type": "FAQPage",
        "mainEntity": vm.handleDomParser(data)
      }
      vm.jsonld['@graph'].push(faq);
    }
  }
};
</script>
<style lang="scss" scoped>
.page-title {
  font-size: 45px;
  font-family: Roboto Condensed, sans-serif;
}

// .post-page {
//   padding: 0 15px;
// }

h2 {
  font-size: 30px;
}

h3 {
  font-size: 24px;
}

.post-container {
  display: flex;
  justify-content: space-between;
}

.post-meta {
  margin-bottom: 10px;
}

.create-date {
  display: flex;

  .created {
    &+time {
      margin-right: 20px;
    }
  }
}

.post-main-content {
  max-width: 67%;
  width: 100%;
}

// .aside {
//   max-width: 30%;
//   width: 100%;
// }

// .aside-inner {
//   margin-top: 25px;
//   margin-bottom: 25px;
//   padding: 25px 15px;
//   display: flex;
//   flex-direction: column;
//   background: linear-gradient(342.18deg, #1b222a 0%, #2b506e 100%);
//   border-radius: 5px;
//   color: #ffffff;
//   position: sticky;
//   top: 90px;
// }

// .aside-inner__title {
//   margin-bottom: 20px;
// }

// .aside-inner__subtitle {
//   margin-bottom: 20px;
// }

.btn.hoverItem {
  cursor: pointer;
}

// .important-text {
//   position: relative;
//   margin: 2em 0px -0.46em;
//   margin-left: 20px;
//   margin-right: 10px;
//   padding-left: 25px;
//   padding-right: 20px;
//   border-left: 3px solid #06c6ff;
//   border-right: 3px solid #06c6ff;

//   &::before {
//     position: absolute;
//     content: "";
//     width: 40px;
//     height: 40px;
//     left: -23px;
//     z-index: 5;
//     background-position: center;
//     background-color: #ffffff;
//     background-size: 35px 35px;
//     background-repeat: no-repeat;
//     top: 50%;
//     transform: translateY(-50%);
//     transform: translate(1px, -50%);
//     border-top: 5px solid #ffffff;
//     border-bottom: 5px solid #ffffff;
//   }
// }

// .important-high::before {
//   background-image: url("/img/images-db/case-studies/wp3.webp");
//   background-image: url("/img/images-db/blog/attention.svg");
// }

// .important-middle::before {
//   background-image: url("/img/images-db/case-studies/ecommerce.webp");
//   background-image: url("/img/images-db/blog/closer-look.svg");
// }

// .important-low::before {
//   background-image: url("/img/images-db/case-studies/art.webp");
//   background-image: url("/img/images-db/blog/bulb.svg");
// }

// .toc-container {
//   margin: 2em 0px -0.46em;
//   padding: 30px 25px 20px;
//   background: rgba(0, 0, 0, 0.05);
// }

// .toc-list {
//   list-style: none;
//   font-size: 20px;
// }

// .toc-link:hover {
//   color: #06c6ff;
// }

// .toc-title {
//   margin-bottom: 20px;
//   font-size: 28px;
// }

// .toc-list-item {
//   margin-top: 5px;
//   margin-bottom: 5px;
// }
// .toc-container {
//   color: #0977c3;
//   text-decoration: none;
//   border-bottom: 1px solid #eee;
//   transition: all 300ms ease;
// }

// .a.toc-link {
//   color: red;
// }

// .toc-container .toc-link {
//   list-style: none!important;
//   color: red!important;
// }

.inner-link {
  color: #06c6ff;

  &:hover {
    opacity: 0.7;
    transition: opacity 0.2s;
  }
}

.author-info {
  margin-left: auto;
  margin-right: auto;
  // max-width: 680px;
  margin-top: 50px;
  margin-bottom: 50px;
  padding: 45px 35px;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.author-img-wrap {
  width: 150px;
  height: 150px;
  margin-right: 30px;
  border-radius: 50%;
  overflow: hidden;
  object-fit: contain;
  flex-shrink: 0;
  background-color: #eee;
}

.author-info__img {
  object-fit: contain;
}

.author-info__content {
  display: flex;
  flex-direction: column;

  span {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 700;
    opacity: 0.7;
  }

  .author-name {
    margin-bottom: 10px;
    color: #06c6ff;

    a {
      font-size: 20px;
      font-weight: 700;

      &:hover {
        opacity: 0.7;
        transition: opacity 0.2s;
      }
    }
  }

  .author-position {
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 700;
  }

  .author-description {
    font-size: 18px;
    line-height: 26px;
  }

  .list-social-founder {
    align-items: center;

    li {
      list-style: none;
      margin: 0 20px 0 0;
      cursor: pointer;

      &:first-child {
        margin-top: -4px;
      }

      &:not(:last-child) {
        margin-right: 20px;
      }

      a {
        display: flex;
      }
    }

    svg:hover path {
      fill: #06c6ff
    }
  }
}

.post-page .share-section .social-icon-item:hover svg path {
  fill: #fff !important;
}



// @media (max-width: 1220px) {
//   .post-page {
//     margin-top: 60px;
//   }

//   .aside-inner__title {
//     font-size: 24px;
//     letter-spacing: 0.9px;
//     line-height: 30px;
//   }
// }

// @media (max-width: 990px) {
//   .post-main-content {
//     max-width: 100%;
//   }

//   .post-container {
//     flex-wrap: wrap;
//   }

//   .aside {
//     max-width: 100%;
//   }

//   .aside-inner {
//     position: initial;
//     max-width: 300px;
//     margin: 0 auto 70px;
//   }
// }

.toc-container {
  margin-bottom: 20px;
  float: left;
  margin-right: 30px;
}

@media (max-width: 576px) {
  .page-title {
    margin-bottom: 15px;
    font-size: 28px;
  }

  h2 {
    margin-bottom: 20px;
    font-size: 26px;
    line-height: 1.25;
  }

  h3 {
    font-size: 24px;
  }

  h4 {
    font-size: 22px;
  }

  // author
  .create-date {
    flex-direction: column;

    p:first-child {
      margin-bottom: 3px;
    }

    .created {
      &+time {
        margin-right: 0px;
      }
    }
  }

  .author-info {
    flex-direction: column;
    padding: 35px 20px 15px;

  }

  .author-img-wrap {
    margin-right: 0;
  }

  .list-social-founder {
    margin-left: auto;
    margin-right: auto;
  }

}

@media (max-width: 500px) {
  h3 {
    font-size: 24px;
  }
}
</style>
