<template>
  <!--SECTION INDUSTRIES-->
  <main>
    <div class="top-stone">
    </div>
    <div class="pagination">
      <div class="container-db">
        <div class="bread-crumbs">
          <router-link to="/">Home</router-link>
          <span class="bread-separator">/</span>
          <span class="bread-current">QA</span>
        </div>
      </div>
    </div>
    <section class="service-description container-db">
      <div class="service-wrapper">
        <div class="service-text-wrapper">
          <h1>QA Services at Outsource</h1>
          <p>
            The Manual Testing plays a vital role in software application development. While doing manual testing
            activity, the tester needs to be creative & patient. Testers need to play a role of the end user & execute
            all the test cases as per the end user's point of view.
          </p>
          <p>
            The main goal of DevBrother company is to make sure that the application under test is defect free and
            software application is working as per the requirements specification document.
          </p>
          <p>
            QA engineers involved from the start of project. Our engineers create testing plan, gather requirements, do
            requirement and design testing, and create test cases and checklists. The mission is to identify bugs – to
            find the differences between actual and expected results. Every implemented feature has to be carefully
            tested, for the final product to meet client’s requirements.
          </p>
        </div>
        <div class="stone-image-wrapper">
          <div class="service-contact">
            <p class="service-contact__title">Free consultation</p>
            <p>We advise choosing the right technology and strategies for developing a new product.</p>
            <p>Let's arrange a short phone or video call? We will listen to your wishes and select a team of
              professionals to implement the product.</p>
            <button @click="showPopup" class="btn form-btn">Contact us</button>
          </div>
          <div class="top-stone mobile">
          </div>
        </div>
      </div>
      <div class="service-text-wrapper">
        <h2>Performance Testing Services</h2>
        <p>
          Performance testing is a non-functional method of software testing that determines stability, speed,
          scalability, and responsiveness under the workload. Without performance testing, it is impossible to ensure
          software quality.
        </p>
        <p>
          The purpose of such testing is to determine the application's load limits, the maximum number of users, and
          the volume of requests.
        </p>
        <h3>Load Testing Services</h3>
        <p>Load testing mainly applies to multi-user systems built using a web server model. We expose the system as if
          it was used by multiple users and check the robustness of the system against this exposure.</p>
        <h3>Stress Testing Services</h3>
        <p>Stress testing determines the ability of the system to operate under stressful conditions, that is beyond the
          expected limits of its capabilities.</p>
        <h3>Endurance testing</h3>
        <p>Endurance testing is done to see if the system can withstand the maximum load over a long period of time.
          Such tests also determine the amount of memory consumed</p>
      </div>
      <div class="service-text-wrapper">
        <h2>Automated Testing</h2>
        <p>
          Test automation is a fast and a reliable way to shorten testing
          time without quality reduction.
        </p>
        <p>
          Benefits of Automation testing:
        </p>
        <ul class="bullet-list">
          <li class="marked-item">Increase productivity</li>
          <li class="marked-item">Save money</li>
          <li class="marked-item">Increase software quality</li>
          <li class="marked-item">Reduce testing time</li>
          <li class="marked-item">Support for various applications</li>
          <li class="marked-item">Increase testing coverage</li>
          <li class="marked-item">Reduction of repetitive work</li>
          <li class="marked-item">Greater consistency</li>
        </ul>
        <p>
          DevBrother provides test automation to increase efficiency and
          test coverage. We automate cross-browser, cross-platform,
          regression, functional, smoke, sanity and UI tests. Our
          experienced engineers provide different types of automation
          testing including web (user interface and functionality), mobile
          (native and hybrid applications) and API using the latest tools
          and frameworks.
        </p>
      </div>
    </section>

    <section class="faq-section">
      <h2>FAQ</h2>
      <div class="container-db">
        <div id="app" class="">
          <app-accordion @closeAccordions='closeAccordions()' v-for="(accordion, index) in accordions" ref="accordion"
            :key="index" class="app-accordion">
            <template v-slot:title>
              <span class="">{{ accordion.title }}</span>
            </template>
            <template v-slot:content>
              <p>
                {{ accordion.text }}
              </p>
            </template>
          </app-accordion>
        </div>
      </div>
    </section>

    <AcquaintedSection />
    <div v-lazyload data-src="/img/images-db/parallax/stone2.webp" class="lazy-bg bottom-stone"></div>

    <div class="popup" :class="{ 'hide-popup': !showModal }">
      <div class="popup-wrapper">
        <div class="popup-inner" @click.self="closePopup()">
          <div class="popup-content" id="service-contact-form">
            <button class="close-popup" @click="closePopup()" type="button" aria-label="close popup"></button>
            <ServicesTopForm />
          </div>
        </div>
      </div>
    </div>

    <script v-html="jsonld" type="application/ld+json"></script>
  </main>
</template>
<script>
import AcquaintedSection from '../AcquaintedSection';
import ServicesTopForm from '../ServicesTopForm';
import AppAccordion from "./web-development/AppAccordion";

let vm = {};
export default {
  name: 'QAservice',
  metaInfo: {
    title: 'Outsource Software Testing - Automation QA Development',
    meta: [
      {
        vmid: "description",
        name: 'description',
        content: 'Best【Software Testing Outsourcing Company】DevBrother.  Full-range QA services within rigid deadlines at Outsource. Order app or software testing.'
      },
      {
        vmid: "og:image:type",
        name: "og:image:type",
        content: 'image/webp'
      },
      {
        vmid: "og:title",
        name: "og:title",
        content: 'Outsource Software Testing - Automation QA Development'
      },
      {
        vmid: "og:description",
        name: "og:description",
        content: 'Best【Software Testing Outsourcing Company】DevBrother.  Full-range QA services within rigid deadlines at Outsource. Order app or software testing.'
      },
      // {
      //   vmid: "og:image",
      //   name: "og:image",
      //   content: 'https://devbrother.com/images/...'
      // },
      {
        vmid: "og:type",
        name: "og:type",
        content: "website"
      },
      {
        vmid: "og:url",
        name: "og:url",
        content: 'https://devbrother.com/services/qa-automation'
      },
      {
        vmid: "twitter:title",
        name: "twitter:title",
        content: 'Outsource Software Testing - Automation QA Development'
      },
      {
        vmid: "twitter:description",
        name: "twitter:description",
        content: 'Best【Software Testing Outsourcing Company】DevBrother.  Full-range QA services within rigid deadlines at Outsource. Order app or software testing.'
      },
        // {
        //   vmid: "twitter:image",
        //   name: "twitter:image",
        //   content: 'https://devbrother.com/images/...'
        // }
    ]
  },
  components: {
    AcquaintedSection,
    ServicesTopForm,
    AppAccordion
  },
  data() {
    const jsonld = {
      "@context": "https://schema.org",
      "@type": "Service",
      "name": "Outsource Software Testing - Automation QA Development",
      "description": "Best【Software Testing Outsourcing Company】DevBrother.  Full-range QA services within rigid deadlines at Outsource. Order app or software testing."
    }

    return {
      jsonld,
      showModal: false,
      accordions: [
        {
          title: 'What is performance testing?',
          text: "Performance testing is aimed to determine the response speed under the workload.",

        },
        {
          title: 'Why do we use endurance testing?',
          text: 'This type of test is performed last and is needed to determine whether the application can handle a long-term load or not.',
        },
        {
          title: 'Why do we do load testing?',
          text: "Load testing is part of the software life cycle. Through load testing, we assess the application's ability to withstand operational and peak loads."
        }
      ],
    }
  },
  beforeCreate() {
    vm = this;
  },
  mounted: () => {
    vm.body = document.querySelector("body");
    vm.addCanonicalLink();
  },
  methods: {
    addCanonicalLink: () => {
      const link = document.createElement('link');
      link.setAttribute('rel', 'canonical');
      link.setAttribute('href', 'https://devbrother.com/services/qa-automation');

      const oldLink = document.querySelector('link[rel="canonical"]');
      if (oldLink) {
        document.head.removeChild(oldLink);
      }

      document.head.appendChild(link);
    },
    showPopup: () => {
      vm.showModal = true;
      vm.body.classList.add('ban-scroll');
    },
    closePopup: () => {
      vm.showModal = false;
      vm.body.classList.remove('ban-scroll');
    },
    scrollFix: hash => {
      setTimeout(() => {
        setTimeout(() => $('html, body')
          .animate({ scrollTop: $(hash).offset().top }, 1000), 1)
      });
    },
    closeAccordions() {
      this.$refs.accordion.forEach(el => el.closeAccordion());
    },
  }
}
</script>
<style scoped>
.faq-section {
  position: relative;
  z-index: 11;
  margin-bottom: 150px;
}

.faq-section h2{
  letter-spacing: 3px;
}

.bullet-list{
  margin-bottom: 30px;
}

@media (max-width: 1220px) {
  .faq-section {
    margin-bottom: 100px;
  }
}
</style>
