import Vue from "vue";
import Router from "vue-router";

import Home from "./components/Home/Home";

import NotFoundPage from "./components/partials/NotFoundPage";

import Design from "./components/services/Design";
import Devops from "./components/services/Devops";
import DevopsUsa from "./components/services/DevopsUsa";
import QAservice from "./components/services/QAservice";
import Webdev from "./components/services/Webdev";
import Web3 from "./components/services/Web3";
import Wordpress from "./components/services/web-development/Wordpress";
import WordpressUsa from "./components/services/web-development/WordpressUsa";
import Laravel from "./components/services/web-development/Laravel";
import Outsource from "./components/services/web-development/Outsource";
import Mobiledev from "./components/services/Mobiledev";
import StaffAugmentationService from "./components/services/StaffAugmentationService";
import StaffAugmentationServiceUsa from "./components/services/StaffAugmentationServiceUsa";
import Itconsulting from "./components/services/Itconsulting";
import RemoteAdministration from "./components/services/RemoteAdministration";
import RemoteTeamExtension from "./components/services/RemoteTeamExtension";
import TechnicalSupport from "./components/services/TechnicalSupport";
import DedicatedDevelopmentTeam from "./components/services/DedicatedDevelopmentTeam";
import Fintech from "./components/services/Fintech";

import HealthcareSoftwareDevelopment from "./components/industries/HealthcareSoftwareDevelopment";
import CryptocurrencyDevelopment from "./components/industries/CryptocurrencyDevelopment";

import Healthcare from "./components/CaseStudies/Healthcare";
import ArtGalleries from "./components/CaseStudies/ArtGalleries";
import OnlineEvent from "./components/CaseStudies/OnlineEvent";
import Ecommerce from "./components/CaseStudies/Ecommerce";
import Wordpressdev from "./components/CaseStudies/WordpressDev";
import Cryptocurrency from "./components/CaseStudies/Cryptocurrency";
import NFTSwapPlatform from "./components/CaseStudies/NFTSwapPlatform";
import BankingApp from "./components/CaseStudies/BankingApp";

import DoctorMed from "./components/CaseStudies/DoctorMed";
import CannabisPlatform from "./components/CaseStudies/CannabisPlatform";
import RealEstate from "./components/CaseStudies/RealEstate";
import MarketsExchanges from "./components/CaseStudies/MarketsExchanges";
import CurrencyResearch from './components/CaseStudies/CurrencyResearch'
import Dogito from './components/CaseStudies/Dogito'
import ArtGalleriesDevelopment from './components/CaseStudies/ArtGalleriesDevelopment'

import Blog from "./components/Blog/Blog";
import Post from "./components/Blog/Post.vue";
import About from "./components/About/About.vue";
import Career from "./components/Career/Career.vue";

import WebReactjs from "./components/services/web-development/React";
import WebVuejs from "./components/services/web-development/Vue";
import WebNodejs from "./components/services/web-development/Node";

import PrivacyPolicy from "./components/privacy/PrivacyPolicy";
import CookiesPolicy from "./components/privacy/CookiesPolicy";
import TermsAndConditions from "./components/privacy/TermsAndConditions";

import OurTrustedVolunteers from "./components/OurTrustedVolunteers";

Vue.use(Router);

const loadRoutes = async () => {
  const response = await axios.get('/api/routes');
  const routes = response.data.map(route => {
    let component;
    switch (route.name) {
      case 'Home':
        component = Home;
        break;
      case 'Post':
        component = Post;
        break;
      case 'About':
        component = About;
        break;
      case 'Career':
        component = Career;
        break;
      case 'Blog':
        component = Blog;
        break;
      case 'OurTrustedVolunteers':
        component = OurTrustedVolunteers;
        break;
      case 'PrivacyPolicy':
        component = PrivacyPolicy;
        break;
      case 'CookiesPolicy':
        component = CookiesPolicy;
        break;
      case 'TermsAndConditions':
        component = TermsAndConditions;
        break;
      case 'Design':
        component = Design;
        break;
      case 'Devops':
        component = Devops;
        break;
      case 'DevopsUsa':
        component = DevopsUsa;
        break;
      case 'React':
        component = WebReactjs;
        break;
      case 'Vue':
        component = WebVuejs;
        break;
      case 'Node':
        component = WebNodejs;
        break;
      case 'Webdev':
        component = Webdev;
        break;
      case 'Web3':
        component = Web3;
        break;
      case 'QAservice':
        component = QAservice;
        break;
      case 'Itconsulting':
        component = Itconsulting;
        break;
      case 'RemoteAdministration':
        component = RemoteAdministration;
        break;
      case 'TechnicalSupport':
        component = TechnicalSupport;
        break;
      case 'RemoteTeamExtension':
        component = RemoteTeamExtension;
        break;
      case 'Mobiledev':
        component = Mobiledev;
        break;
      case 'Wordpress':
        component = Wordpress;
        break;
      case 'WordpressUsa':
        component = WordpressUsa;
        break;
      case 'Outsource':
        component = Outsource;
        break;
      case 'Laravel':
        component = Laravel;
        break;
      case 'StaffAugmentationService':
        component = StaffAugmentationService;
        break;
      case 'StaffAugmentationServiceUsa':
        component = StaffAugmentationServiceUsa;
        break;
      case 'Fintech':
        component = Fintech;
        break;
      case 'DedicatedDevelopmentTeam':
        component = DedicatedDevelopmentTeam;
        break;
      case 'HealthcareSoftwareDevelopment':
        component = HealthcareSoftwareDevelopment;
        break;
      case 'CryptocurrencyDevelopment':
        component = CryptocurrencyDevelopment;
        break;
      case 'Healthcare':
        component = Healthcare;
        break;
      case 'OnlineEvent':
        component = OnlineEvent;
        break;
      case 'Ecommerce':
        component = Ecommerce;
        break;
      case 'ArtGalleries':
        component = ArtGalleries;
        break;
      case 'Wordpressdev':
        component = Wordpressdev;
        break;
      case 'Cryptocurrency':
        component = Cryptocurrency;
        break;
      case 'NFTSwapPlatform':
        component = NFTSwapPlatform;
        break;
      case 'BankingApp':
        component = BankingApp;
        break;
      case 'DoctorMed':
        component = DoctorMed;
        break;
      case 'CannabisPlatform':
        component = CannabisPlatform;
        break;
      case 'RealEstatePlatform':
        component = RealEstate;
        break;
      case 'MarketsExchanges':
        component = MarketsExchanges;
        break;
      case 'CurrencyResearch':
        component = CurrencyResearch;
        break;
      case 'Dogito':
        component = Dogito;
        break;
      case 'ArtGalleriesDevelopment':
        component = ArtGalleriesDevelopment;
        break;
      default:
        component = NotFoundPage;
    }
    return { path: route.path, name: route.name, component };
  });

  routes.push(
    {
      path: "/not-found",
      name: "not-found",
      component: NotFoundPage,
      meta: { statusCode: 404 }
    },
    {
      path: "*",
      redirect: "/not-found",
      meta: { statusCode: 404 }
    }
  );

  return routes;
};

const createRouter = async () => {
  const routes = await loadRoutes();
  return new Router({
    mode: 'history',
    scrollBehavior(to, from, savedPosition) {
      if (to.hash) {
        return { selector: to.hash };
      } else {
        return { x: 0, y: 0 };
      }
    },
    routes
  });
};

export default createRouter;
