<template>
    <div>
        <button @click="toggleAccordion()" class="accordion-btn" :aria-expanded="isOpen"
            :aria-controls="`collapse${_uid}`">
            <slot name="title" />
            <svg :class="{
                'rotate-180': isOpen,
                'rotate-0': !isOpen,
            }" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 10" aria-hidden="true">
                <path d="M15 1.2l-7 7-7-7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        </button>

        <div v-show="isOpen" :id="`collapse${_uid}`" class="panel">
            <slot name="content" />
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            isOpen: false,
            current: false
        };
    },
    beforeCreate() {
        this.isOpen = false;
    },
    methods: {
        toggleAccordion() {
            this.$emit('closeAccordions');
            this.isOpen = !this.current;
        },
        closeAccordion() {
            this.current = this.isOpen;
            this.isOpen = false;
        }
    },
};
</script>

<style scoped>
.accordion-btn {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
    width: 100%;
    margin-bottom: 15px;
    cursor: pointer;
    padding: 18px;
    text-align: left;
    border: none;
    outline: none;
    transition: 0.4s;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.2;
    color: #ffffff;
    background-color: #203448;
    border-radius: 5px;
}

.accordion-btn[aria-expanded="true"] {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background-color: #2b506e;
    box-shadow: 0 5px 15px 0 rgb(0, 0, 0, 0.4);
}

/* .accordion-btn.active, */
.accordion-btn:hover {
    background-color: #2b506e;
}

.accordion-btn svg {
    height: 10px;
    width: auto;
    margin-left: 10px;
    flex-shrink: 0;
    transition-duration: 0.2s;
}

.panel {
    position: relative;
    padding: 18px;
    top: -15px;
    line-height: 1.4;
    background-color: #2b506e;
    overflow: hidden;
    transition: 0.5s ease-out;
}

.rotate-0 {
    transform: rotate(0deg);
}

.rotate-180 {
    transform: rotate(180deg);
}
</style>