<template>

    <main class="privacy">
        <section class="service-description">
            <div class="container-db">
                <h1>Terms and Conditions</h1>

                <ol class="list-wrap">
                    <li>
                        <h2>Introduction</h2>
                        <ol>
                            <li>These terms and conditions govern your use of this website; by using this website, you
                                accept these terms and conditions in full. If you disagree with these terms and
                                conditions or any part of these terms and conditions, you must not use this website.
                            </li>
                            <li>You must be at least 18 years of age to use this website. By using this website and by
                                agreeing to these terms and conditions, you warrant and represent that you are at least
                                18 years of age.</li>
                            <li>This website uses cookies. By using this website and agreeing to these terms and
                                conditions, you consent to our DevBrother's use of cookies in accordance with the terms
                                of DevBrother's cookies policy.</li>
                        </ol>
                    </li>
                    <li>
                        <h2>License to use website</h2>
                        <ol>
                            <li>Unless otherwise stated, DevBrother and/or its licensors own the intellectual property
                                rights in the website and material on the website. Subject to the license below, all
                                these intellectual property rights are reserved.</li>
                            <li>You may view, download for caching purposes only, and print pages from the website for
                                your own personal use, subject to the restrictions set out below and elsewhere in these
                                terms and conditions.</li>
                            <li>This website uses cookies. By using this website and agreeing to these terms and
                                conditions, you consent to our DevBrother's use of cookies in accordance with the terms
                                of DevBrother's cookies policy.</li>
                            <li>You must not:
                                <p class="marked-item">republish material from this website (including republication on
                                    another website) without active, dofollow link on this web page of DevBrother's
                                    website</p>
                                <p class="marked-item">sell, rent or sub-license material from the website without
                                    active, dofollow link on this web page of DevBrother's website</p>
                                <p class="marked-item">show any material from the website in public without active,
                                    dofollow link on this web page of DevBrother's website</p>
                                <p class="marked-item">reproduce, duplicate, copy or otherwise exploit material on this
                                    website for a commercial purpose without active, dofollow link on this web page of
                                    DevBrother's website</p>
                            </li>
                            <li>Where content is specifically made available for redistribution, it may only be
                                redistributed.</li>
                        </ol>
                    </li>
                    <li>
                        <h2>Acceptable use</h2>
                        <ol>
                            <li>You must not use this website in any way that causes, or may cause, damage to the
                                website or impairment of the availability or accessibility of the website; or in any way
                                which is unlawful, illegal, fraudulent or harmful, or in connection with any unlawful,
                                illegal, fraudulent or harmful purpose or activity.</li>
                            <li>You must not use this website to copy, store, host, transmit, send, use, publish or
                                distribute any material which consists of (or is linked to) any spyware, computer virus,
                                Trojan horse, worm, keystroke logger, rootkit, or other malicious computer software.
                            </li>
                            <li>You must not conduct any systematic or automated data collection activities (including
                                without limitation scraping, data mining, data extraction, and data harvesting) on or in
                                relation to this website without DevBrother's express written consent.</li>
                        </ol>
                    </li>
                    <li>
                        <h2>Restricted access</h2>
                        <ol>
                            <li>Access to certain areas of this website is restricted. DevBrother reserves the right to
                                restrict access to other areas of this website, or indeed this entire website, at
                                DevBrother's discretion.</li>
                            <li>If DevBrother provides you with a user ID and password to enable you to access
                                restricted
                                areas of this website or other content or services, you must ensure that the user ID and
                                password are kept confidential.</li>
                        </ol>
                    </li>
                    <li>
                        <h2>User content</h2>
                        <ol>
                            <li>In these terms and conditions, “your user content” means material (including without
                                limitation text, images, audio material, video material and audio-visual material) that
                                you submit to this website, for whatever purpose.</li>
                            <li>You grant to DevBrother a worldwide, irrevocable, non-exclusive, royalty-free license to
                                use, reproduce, adapt, publish, translate and distribute your user content in any
                                existing or future media. You also grant to DevBrother the right to sub-license these
                                rights, and the right to bring an action for infringement of these rights.</li>
                            <li>Your user content must not be illegal or unlawful, must not infringe any third-party's
                                legal rights, and must not be capable of giving rise to legal action whether against you
                                or DevBrother or a third party(in each case under any applicable law).</li>
                            <li>You must not submit any user content to the website that is or has ever been the subject
                                of any threatened or actual legal proceedings or other similar complaint.</li>
                            <li>DevBrother reserves the right to edit or remove any material submitted to this website,
                                or stored on DevBrother's servers, or hosted or published upon this website.</li>
                        </ol>
                    </li>
                    <li>
                        <h2>No warranties</h2>
                        <ol>
                            <li>This website is provided “as is” without any representations or warranties, express or
                                implied. DevBrother makes no representations or warranties in relation to this website
                                or
                                the information and materials provided on this website.</li>
                            <li>Without prejudice to the generality of the foregoing paragraph, DevBrother does not
                                warrant that:
                                <p class="marked-item">this website will be constantly available, or available at all;
                                    or</p>
                                <p class="marked-item">the information on this website is complete, true, accurate or
                                    non-misleading.</p>
                            </li>
                            <li>Nothing on this website constitutes, or is meant to constitute, advice of any kind.</li>
                        </ol>
                    </li>
                    <li>
                        <h2>Limitations of liability</h2>
                        <ol>
                            <li>DevBrother will not be liable to you (whether under the law of contact, the law of torts
                                or otherwise) in relation to the contents of, or use of, or otherwise in connection
                                with, this website
                                <p class="marked-item">for any indirect, special or consequential loss; or</p>
                                <p class="marked-item">for any business losses, loss of revenue, income, profits or
                                    anticipated savings, loss of contracts or business relationships, loss of reputation
                                    or goodwill, or loss or corruption of information or data.</p>
                            </li>
                            <li>These limitations of liability apply even if DevBrother has been expressly advised of
                                the
                                potential loss.</li>
                        </ol>
                    </li>
                    <li>
                        <h2>Exceptions</h2>
                        <ol>
                            <li>Nothing in this website disclaimer will exclude or limit any warranty implied by law
                                that it would be unlawful to exclude or limit; and nothing in this website disclaimer
                                will exclude or limit DevBrother's liability in respect of any:</li>
                            <li>Without prejudice to the generality of the foregoing paragraph, DevBrother does not
                                warrant that:
                                <p class="marked-item">death or personal injury caused by DevBrother's negligence</p>
                                <p class="marked-item">fraud or fraudulent misrepresentation on the part of DevBrother;
                                    or</p>
                                <p class="marked-item">matter which it would be illegal or unlawful for DevBrother to
                                    exclude or limit, or to attempt or purport to exclude or limit, its liability</p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <h2>Reasonableness</h2>
                        <ol>
                            <li>By using this website, you agree that the exclusions and limitations of liability set
                                out in this website disclaimer are reasonable.</li>
                            <li>If you do not think they are reasonable, you must not use this website.</li>
                        </ol>
                    </li>
                    <li>
                        <h2>Unenforceable provisions</h2>
                        <ol>
                            <li>You hereby indemnify DevBrother and undertake to keep DevBrother indemnified against any
                                losses, damages, costs, liabilities and expenses (including without limitation legal
                                expenses and any amounts paid by DevBrother to a third-party in settlement of a claim or
                                dispute on the advice of DevBrother's legal advisers) incurred or suffered by DevBrother
                                arising out of any breach by you of any provision of these terms and conditions.</li>
                        </ol>
                    </li>
                    <li>
                        <h2>Indemnity</h2>
                        <ol>
                            <li>If any provision of this website disclaimer is, or is found to be, unenforceable under
                                applicable law, that will not affect the enforceability of the other provisions of this
                                website disclaimer.</li>
                        </ol>
                    </li>
                    <li>
                        <h2>Breaches of these terms and conditions</h2>
                        <ol>
                            <li>Without prejudice to DevBrother's other rights under these terms and conditions, if you
                                breach these terms and conditions in any way, DevBrother may take such action as
                                DevBrother deems appropriate to deal with the breach, including suspending your access
                                to
                                the website, prohibiting you from accessing the website, blocking computers using your
                                IP address from accessing the website, contacting your internet service provider to
                                request that they block your access to the website and/ or bringing court proceedings
                                against you.</li>
                        </ol>
                    </li>
                    <li>
                        <h2>Variation</h2>
                        <ol>
                            <li>DevBrother may revise these terms and conditions from time-to-time. Revised terms and
                                conditions will apply to the use of this website from the date of the publication of the
                                revised terms and conditions on this website. Please check this page regularly to ensure
                                you are familiar with the current version.</li>
                        </ol>
                    </li>
                    <li>
                        <h2>Assignment</h2>
                        <ol>
                            <li>DevBrother may transfer, sub-contract, or otherwise deal with DevBrother's rights and/or
                                obligations under these terms and conditions without notifying you or obtaining your
                                consent.</li>
                            <li>You may not transfer, sub-contract, or otherwise deal with your rights and/or
                                obligations under these terms and conditions.</li>
                        </ol>
                    </li>
                    <li>
                        <h2>Severability</h2>
                        <ol>
                            <li>f a provision of these terms and conditions is determined by any court or other
                                competent authority to be unlawful and/or unenforceable, the other provisions will
                                continue in effect.</li>
                            <li>If any unlawful and/or unenforceable provision would be lawful or enforceable if part of
                                it were deleted, that part will be deemed to be deleted, and the rest of the provision
                                will continue in effect.</li>
                        </ol>
                    </li>
                    <li>
                        <h2>Entire agreement</h2>
                        <ol>
                            <li>These terms and conditions constitute the entire agreement between you and DevBrother in
                                relation to your use of this website, and supersede all previous agreements in respect
                                of your use of this website</li>
                        </ol>
                    </li>
                    <li>
                        <h2>Law and jurisdiction</h2>
                        <ol>
                            <li>These terms and conditions will be governed by and construed in accordance with the laws
                                of Ukraine, and any disputes relating to these terms and conditions will be subject to
                                the exclusive jurisdiction of the courts of Ukraine.</li>
                        </ol>
                    </li>
                </ol>
                <h2>DevBrother's details</h2>
                <!-- <p>The full name of DevBrother is DevBrother Inc.</p> -->
                <p>You can contact DevBrother by email to <a
                        href="mailto:contact@devbrother.com">contact@devbrother.com</a>.</p>
            </div>
        </section>

        <script v-html="jsonld" type="application/ld+json"></script>
    </main>
</template>
<script>

let vm = {};
export default {
    name: 'TermsAndConditions',
    metaInfo: {
        title: 'Terms and conditions that govern your use of DevBrother website',
        meta: [
            {
                vmid: "description",
                name: 'description',
                content: 'This page outlines DevBrother Terms and Conditions considering the use of our website, restricted access, content, limitations of liability, laws and jurisdictions, and other essential details.'
            },
            // {
            //     vmid: "og:image:type",
            //     name: "og:image:type",
            //     content: 'image/webp'
            // },
            {
                vmid: "og:title",
                name: "og:title",
                content: 'Terms and conditions that govern your use of DevBrother website'
            },
            {
                vmid: "og:description",
                name: "og:description",
                content: 'This page outlines DevBrother Terms and Conditions considering the use of our website, restricted access, content, limitations of liability, laws and jurisdictions, and other essential details.'
            },
            // {
            //     vmid: "og:image",
            //     name: "og:image",
            //     content: 'https://devbrother.com/img/images-db/...'
            // },
            {
                vmid: "og:type",
                name: "og:type",
                content: "website"
            },
            {
                vmid: "og:url",
                name: "og:url",
                content: 'https://devbrother.com/terms-and-conditions'
            },
            {
                vmid: "twitter:title",
                name: "twitter:title",
                content: 'Terms and conditions that govern your use of DevBrother website'
            },
            {
                vmid: "twitter:description",
                name: "twitter:description",
                content: 'This page outlines DevBrother Terms and Conditions considering the use of our website, restricted access, content, limitations of liability, laws and jurisdictions, and other essential details.'
            },
            // {
            //     vmid: "twitter:image",
            //     name: "twitter:image",
            //     content: 'https://devbrother.com/img/images-db/...'
            // }
        ]
    },
    beforeCreate() {
        vm = this;
    },
    mounted: () => {
        vm.addCanonicalLink();
    },
    methods: {
        addCanonicalLink: () => {
            const link = document.createElement('link');
            link.setAttribute('rel', 'canonical');
            link.setAttribute('href', 'https://devbrother.com/terms-and-conditions');

            const oldLink = document.querySelector('link[rel="canonical"]');
            if (oldLink) {
                document.head.removeChild(oldLink);
            }

            document.head.appendChild(link);
        },
    },
    data() {
        const jsonld = {
            "@context": "https://schema.org",
            "@type": "Privacy",
            // ?
            // "name": "Web Development Agency - Custom Outsourced Services ➜ DevBrother",
            "name": "Terms And Conditions ➜ DevBrother",
            "description": "This page outlines DevBrother Terms and Conditions considering the use of our website, restricted access, content, limitations of liability, laws and jurisdictions, and other essential details."
        }

        return {
            jsonld,
        }
    },
}
</script>
<style lang="scss" scoped>
.privacy {
    .container-db {
        max-width: 960px;
        margin-top: 150px;
        line-height: 1.5 !important;
    }

    h1 {
        font-size: 26px;
        line-height: 30px;
    }

    h2 {
        margin-top: 30px;
        font-size: 20px;
        line-height: 24px;
    }

    h3 {
        margin-top: 30px;
        margin-bottom: 15px;
        font-size: 18px;
    }

    .top-message {
        margin-bottom: 50px;
    }

    p {
        margin-bottom: 10px;
        letter-spacing: 0.36px;
    }

    a:not(.router-link-active) {
        color: #06c6ff;

        &:hover {
            opacity: 0.8;
            transition: opacity 0.15s;
        }
    }

    .short-block {
        max-width: 790px;
    }

    .offices:not(:last-child) {
        margin-bottom: 25px;
    }

    ol:not(.list-wrap) {
        padding-left: 0;
    }

    ol {
        counter-reset: section;

        li {
            margin-bottom: 5px;
            list-style-type: none;
        }

        li::before {
            counter-increment: section;
            content: counters(section, ".") " ";
            list-style-type: none;
        }
    }

    .list-wrap {
        padding-left: 25px;
    }

    .list-wrap>li {
        list-style-type: auto;

        &::before {
            counter-increment: section;
            content: "";
            list-style-type: none;
        }

        &::marker {
            font-size: 20px;
        }
    }

    .marked-item {
        padding-left: 21px;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background-color: #06c6ff;
            left: 0px;
            top: 10px;
        }

        &::after {
            content: "";
            position: absolute;
            width: 8px;
            height: 1px;
            left: 5px;
            top: 12px;
            background-color: #06c6ff;
        }
    }

    @media (max-width: 1220px) {
        .container-db {
            line-height: 1.4 !important;
        }

        .service-description p {
            line-height: 1.4 !important;
        }
    }

    @media (max-width: 767px) {
        .list-wrap>li ol {
            margin-left: -20px;
        }

        .list-wrap>li:nth-child(n+10) {
            margin-left: 10px;
        }

        .list-wrap>li:nth-child(n+10) ol {
            margin-left: -30px;
        }
    }
}
</style>
