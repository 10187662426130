<template>    <!--SECTION INDUSTRIES-->
	<main>
    <div class="top-stone">
    </div>
    <div class="pagination">
      <div class="container-db">
        <div class="bread-crumbs">
          <router-link to="/">Home</router-link>
          <span class="bread-separator">/</span>
          <span class="bread-current">Mobile App Development</span>
        </div>
      </div>
    </div>
    <section class="service-description container-db">
      <div class="service-wrapper">
        <div class="service-text-wrapper">
          <h1>Mobile App Development Services - Team Professional
</h1>
          <p>
						Our mobile department is strong enough to deliver the projects with any kind of difficulty. We use wide range of technologies and approaches to meet your expectations and to provide you with the best solutions that will help you to reach success on the market.
          </p>
        </div>
        <div class="stone-image-wrapper">
            <div class="service-contact">
              <p class="service-contact__title">Free consultation</p>
              <p>We advise choosing the right technology and strategies for developing a new product.</p>
              <p>Let's arrange a short phone or video call? We will listen to your wishes and select a team of
                professionals to implement the product.</p>
              <button @click="showPopup" class="btn form-btn">Contact us</button>
            </div>
          <div class="top-stone mobile">
          </div>
        </div>
      </div>
			<div class="service-text-wrapper">
        <h2>Languages and Technologies:</h2>
				<ul class="bullet-list two-column-list">
					<li class="marked-item">Swift</li>
					<li class="marked-item">Flutter</li>
					<li class="marked-item">React Native</li>
          <li class="marked-item">Kotlin</li>
				</ul>
				<p>
					Our mobile development was set up for the most popular platforms: iOS and Android.
				</p>
				<p>
						Here is the short list of things our mobile developers facing in their daily work:
					</p>
					<ul class="bullet-list two-column-list">
						<li class="marked-item">Twitter SDK</li>
						<li class="marked-item">Facebook SDK</li>
						<li class="marked-item">Google  SDK</li>
						<li class="marked-item">In-App Payments</li>
						<li class="marked-item">Push Notifications</li>
						<li class="marked-item">Face Detection</li>
						<li class="marked-item">3D Touch</li>
						<li class="marked-item">HealthKit</li>
						<li class="marked-item">NFC</li>
						<li class="marked-item">Local Authentication</li>
						<li class="marked-item">Network APIs</li>
						<li class="marked-item">Beacons</li>
						<li class="marked-item">Wi-Fi</li>
						<li class="marked-item">Sensors</li>
						<li class="marked-item">App Extensions</li>
						<li class="marked-item">Android NDK</li>
						<li class="marked-item">Chat/Video Chat</li>
						<li class="marked-item">RxJava</li>
						<li class="marked-item">Cardboards</li>
						<li class="marked-item">Camera</li>
						<li class="marked-item">Data Synchronization</li>
						<li class="marked-item">Geolocation</li>
						<li class="marked-item">Google Play Services</li>
						<li class="marked-item">Image Processing</li>
						<li class="marked-item">In-App Purchasing</li>
						<li class="marked-item">Offline Mode</li>
						<li class="marked-item">Maps</li>
						<li class="marked-item">Multiple Screens</li>
						<li class="marked-item">SM Integrations</li>
						<li class="marked-item">Video Streaming</li>
						<li class="marked-item">App Publishing</li>
						<li class="marked-item">Analytics & Monitoring</li>
						<li class="marked-item">Crash Reporting</li>
						<li class="marked-item">Performance Optimization</li>
					</ul>
					<p>
						We are ready to help you with mobile development  of any kind of specified issues and much more. <a href="mailto:contact@devbrother.com" class="contact-us">Contact us</a> and start your journey with the professional team that will bring your ideas into real life with highest programming and management standards.
					</p>
				</div>
    </section>
    <AcquaintedSection />
    <div v-lazyload data-src="/img/images-db/parallax/stone2.webp" class="lazy-bg bottom-stone"></div>

    <div class="popup" :class="{ 'hide-popup': !showModal}">
      <div class="popup-wrapper">
        <div class="popup-inner" @click.self="closePopup()">
          <div class="popup-content" id="service-contact-form">
            <button class="close-popup" @click="closePopup()" type="button" aria-label="close popup"></button>
            <ServicesTopForm />
          </div>
        </div>
      </div>
    </div>

		<script v-html="jsonld" type="application/ld+json"></script>
  </main>
</template>
<script>
    import AcquaintedSection from '../AcquaintedSection';
    import ServicesTopForm from '../ServicesTopForm';

    let vm = {};
    export default {
        name: 'Mobiledev',
				metaInfo: {
            title: 'Mobile App Development Services - Custom Outsourced Company ➜ DevBrother',
            meta: [
              {
                vmid: "description",
                name: 'description',
                content: 'Custom Mobile App Development Services. Java, Swift, Kotlin, Objective C, Android and IOS - Devbrother uses these languages to build applications.'
              },
              {
                vmid: "og:image:type",
                name: "og:image:type",
                content: 'image/webp'
              },
              {
                vmid: "og:title",
                name: "og:title",
                content: 'Mobile App Development Services - Custom Outsourced Company ➜ DevBrother'
              },
              {
                vmid: "og:description",
                name: "og:description",
                content: 'Custom Mobile App Development Services. Java, Swift, Kotlin, Objective C, Android and IOS - Devbrother uses these languages to build applications.'
              },
              // {
              //   vmid: "og:image",
              //   name: "og:image",
              //   content: 'https://devbrother.com/images/...'
              // },
              {
                vmid: "og:type",
                name: "og:type",
                content: "website"
              },
              {
                vmid: "og:url",
                name: "og:url",
                content: 'https://devbrother.com/services/mobile-development'
              },
              {
                vmid: "twitter:title",
                name: "twitter:title",
                content: 'Mobile App Development Services - Custom Outsourced Company ➜ DevBrother'
              },
              {
                vmid: "twitter:description",
                name: "twitter:description",
                content: 'Custom Mobile App Development Services. Java, Swift, Kotlin, Objective C, Android and IOS - Devbrother uses these languages to build applications.'
              },
              // {
              //   vmid: "twitter:image",
              //   name: "twitter:image",
              //   content: 'https://devbrother.com/images/...'
              // }
            ]
        },
                components: {
				  AcquaintedSection,
          ServicesTopForm
				},
        data() {
          const jsonld =  {
            "@context": "https://schema.org/",
            "@type": "IndividualProduct",
            "name": "Mobile Banking App",
            "description": "A mobile app designed to empower users to manage their finances using advanced technology. It offers banking services and the ability to exchange digital assets, with over 3 million users. Features include buying/selling crypto, receiving cashback in crypto, scheduling payments, and monitoring financial markets in real-time.",
            "brand": {
              "@type": "Brand",
              "name": "DevBrother Banking App"
            },
            "offers": {
              "@type": "Offer",
              "url": "https://devbrother.com/cases/banking-app",
              "availability": "https://schema.org/InStock",
              "priceCurrency": "USD"
            },
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "4.5",
              "reviewCount": "1500"
            },
            "applicationCategory": "FinanceApplication",
            "operatingSystem": "iOS, Android",
            "softwareVersion": "1.0",
            "features": [
              "Buying/selling crypto for FIAT currency",
              "Buying/selling crypto for another crypto",
              "Receiving cash back for your non-crypto transactions in crypto",
              "Scheduling payments",
              "Monitoring price changes in real-time",
              "Checking historical price trends",
              "Creating custom alerts for price tracking"
            ],
            "screenshot": "URL_to_screenshot_image",
            "softwareRequirements": "Requires iOS 12.0 or later, Android 5.0 and up"
          }

          return {
            jsonld,
            showModal: false,
          }
        },
           beforeCreate(){
            vm = this;
        },
          mounted: () => {
            vm.body = document.querySelector("body");
            vm.addCanonicalLink();
          },
          methods: {
            addCanonicalLink: () => {
              const link = document.createElement('link');
              link.setAttribute('rel', 'canonical');
              link.setAttribute('href', 'https://devbrother.com/services/mobile-development');

              const oldLink = document.querySelector('link[rel="canonical"]');
              if (oldLink) {
                document.head.removeChild(oldLink);
              }

              document.head.appendChild(link);
            },
            showPopup: () => {
              vm.showModal = true;
              vm.body.classList.add('ban-scroll');
            },
            closePopup: () => {
              vm.showModal = false;
              vm.body.classList.remove('ban-scroll');
            },
            scrollFix: hash => {
              setTimeout(() => {
                setTimeout(() => $('html, body')
                .animate({scrollTop: $(hash).offset().top}, 1000), 1)
              });
            },
        }
    }
</script>

<style lang="scss" scoped>
h2{
text-transform: none;
}

@media (max-width: 1220px){
  .two-column-list li {
      width: 50%;
  }
}

@media (max-width: 576px) {
.two-column-list li {
      width: 100%;
  }
}
</style>



