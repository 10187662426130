<template>
  <main>
    <div class="volunteers">
      <div class="container-db bread-container">
        <div class="bread-crumbs">
          <router-link to="/">Home</router-link>
          <span class="bread-separator">/</span>
          <span class="bread-current">Our Trusted Volunteers</span>
        </div>
      </div>
      <div class="container-db">
        <h2 class="volunteer-page-title">Our Trusted Volunteers</h2>
      </div>
    </div>

    <section id="team" class="team">
      <div class="inner-container">
        <div class="team-wrapper">
          <div
            class="team-member"
            data-aos="fade-right"
            data-aos-offset="300"
            data-aos-easing="ease-in-sine"
            data-aos-duration="800"
          >
            <div class="member-volunteer">
              <div class="member-name">Andrii Shyika</div>
              <div class="prof">Volunteer</div>
              <div class="team-photo-wrapper">
                <img
                  v-lazyload
                  height="300"
                  width="250"
                  data-url="/img/images-db/volunteers/andrii_1.jpg"
                />
              </div>
            </div>
            <div class="characteristic">
              <p>
                Andriy, a member of the IT community of Kharkiv, since March
                2022 I have been helping parts of the Armed Forces of Ukraine,
                National Guard of Ukraine, Territorial Defense of Kharkiv and
                Zaporizhzhia as part of the Euro Kharkiv volunteer group. We
                specialize in helping air reconnaissance units.
              </p>
              <p>
                In 2022, more than UAH 1.5 million worth of equipment was
                purchased and donated to the Armed Forces of Ukraine - these are
                cars, components for UAVs, communication devices, drones,
                Starlinks, tablets, thermal imagers We are currently collecting
                funds for a car for aerial reconnaissance of the 113 Kharkiv
                Brigade
              </p>
              <h2>Contacts</h2>
              <p>Phone: +380669003420</p>
              <h2>Payment credentials</h2>
              <p>PrivatBank: 4149499650659491</p>
              <p>MonoBank: 5375411420697851</p>
            </div>
          </div>
          <div class="team-member" data-aos="zoom-in" data-aos-duration="800">
            <div class="member-volunteer">
              <div class="member-name">Andrii Shyika</div>
              <div class="prof">Volunteer</div>
              <div class="team-photo-wrapper">
                <img
                  v-lazyload
                  height="300"
                  width="250"
                  data-url="/img/images-db/volunteers/andrii_2.jpg"
                  class="ceo-img"
                />
              </div>
            </div>
            <div class="characteristic">
              <p>
                Andriy, a member of the IT community of Kharkiv, since March
                2022 I have been helping parts of the Armed Forces of Ukraine,
                National Guard of Ukraine, Territorial Defense of Kharkiv and
                Zaporizhzhia as part of the Euro Kharkiv volunteer group. We
                specialize in helping air reconnaissance units.
              </p>
              <p>
                In 2022, more than UAH 1.5 million worth of equipment was
                purchased and donated to the Armed Forces of Ukraine - these are
                cars, components for UAVs, communication devices, drones,
                Starlinks, tablets, thermal imagers We are currently collecting
                funds for a car for aerial reconnaissance of the 113 Kharkiv
                Brigade
              </p>
              <h2>Contacts</h2>
              <p>Phone: +380669003420</p>
              <h2>Payment credentials</h2>
              <p>PrivatBank: 4149499650659491</p>
              <p>MonoBank: 5375411420697851</p>
            </div>
          </div>
          <div
            class="team-member"
            data-aos="fade-left"
            data-aos-offset="300"
            data-aos-easing="ease-in-sine"
            data-aos-duration="800"
          >
            <div class="member-volunteer">
              <div class="member-name">Andrii Shyika</div>
              <div class="prof">Volunteer</div>
              <div class="team-photo-wrapper">
                <img
                  v-lazyload
                  height="300"
                  width="250"
                  data-url="/img/images-db/volunteers/andrii_1.jpg"
                />
              </div>
            </div>
            <div class="characteristic">
              <p>
                Andriy, a member of the IT community of Kharkiv, since March
                2022 I have been helping parts of the Armed Forces of Ukraine,
                National Guard of Ukraine, Territorial Defense of Kharkiv and
                Zaporizhzhia as part of the Euro Kharkiv volunteer group. We
                specialize in helping air reconnaissance units.
              </p>
              <p>
                In 2022, more than UAH 1.5 million worth of equipment was
                purchased and donated to the Armed Forces of Ukraine - these are
                cars, components for UAVs, communication devices, drones,
                Starlinks, tablets, thermal imagers We are currently collecting
                funds for a car for aerial reconnaissance of the 113 Kharkiv
                Brigade
              </p>
              <h2>Contacts</h2>
              <p>Phone: +380669003420</p>
              <h2>Payment credentials</h2>
              <p>PrivatBank: 4149499650659491</p>
              <p>MonoBank: <br/> 5375411420697851</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="team-wrapper mobile">
      <div class="team-member" data-aos="fade-right" data-aos-duration="800">
        <div class="member-volunteer">
          <div class="member-name">Andrii Shyika</div>
          <div class="prof">Volunteer</div>
          <div class="team-photo-wrapper">
            <img
              v-lazyload
              height="325"
              width="250"
              data-url="/img/images-db/volunteers/andrii_1.jpg"
              class="ceo-img"
            />
          </div>
        </div>
        <div class="characteristic">
          <p>
            Andriy, a member of the IT community of Kharkiv, since March 2022 I
            have been helping parts of the Armed Forces of Ukraine, National
            Guard of Ukraine, Territorial Defense of Kharkiv and Zaporizhzhia as
            part of the Euro Kharkiv volunteer group. We specialize in helping
            air reconnaissance units.
          </p>
          <p>
            In 2022, more than UAH 1.5 million worth of equipment was purchased
            and donated to the Armed Forces of Ukraine - these are cars,
            components for UAVs, communication devices, drones, Starlinks,
            tablets, thermal imagers We are currently collecting funds for a car
            for aerial reconnaissance of the 113 Kharkiv Brigade
          </p>
          <h2>Contacts</h2>
          <p>Phone: +380669003420</p>
          <p>PrivatBank:4149 4996 5065 9491</p>
          <p>MonoBank:5375 4114 2069 7851</p>
        </div>
      </div>
      <div
        class="team-member"
        data-aos=" zoom-in"
        data-aos-offset="300"
        data-aos-easing="ease-in-sine"
        data-aos-duration="800"
      >
        <div class="member-volunteer">
          <div class="member-name">Andrii Shyika</div>
          <div class="prof">Volunteer</div>
          <div class="team-photo-wrapper">
            <img
              v-lazyload
              height="325"
              width="250"
              data-url="/img/images-db/volunteers/andrii_2.jpg"
            />
          </div>
        </div>
        <div class="characteristic">
          <p>
            Andriy, a member of the IT community of Kharkiv, since March 2022 I
            have been helping parts of the Armed Forces of Ukraine, National
            Guard of Ukraine, Territorial Defense of Kharkiv and Zaporizhzhia as
            part of the Euro Kharkiv volunteer group. We specialize in helping
            air reconnaissance units.
          </p>
          <p>
            In 2022, more than UAH 1.5 million worth of equipment was purchased
            and donated to the Armed Forces of Ukraine - these are cars,
            components for UAVs, communication devices, drones, Starlinks,
            tablets, thermal imagers We are currently collecting funds for a car
            for aerial reconnaissance of the 113 Kharkiv Brigade
          </p>
          <h2>Contacts</h2>
          <p>Phone: +380669003420</p>
          <p>PrivatBank:4149 4996 5065 9491</p>
          <p>MonoBank:5375 4114 2069 7851</p>
        </div>
      </div>
      <div
        class="team-member"
        data-aos="fade-left"
        data-aos-offset="300"
        data-aos-easing="ease-in-sine"
        data-aos-duration="500"
      >
        <div class="member-volunteer">
          <div class="member-name">Andrii Shyika</div>
          <div class="prof"></div>
          <div class="team-photo-wrapper">
            <img
              v-lazyload
              height="325"
              width="250"
              data-url="/img/images-db/volunteers/andrii_1.jpg"
            />
          </div>
        </div>
        <div class="characteristic">
          <p>
            Andriy, a member of the IT community of Kharkiv, since March 2022 I
            have been helping parts of theArmed Forces of Ukraine, National
            Guard of Ukraine, Territorial Defense of Kharkiv and Zaporizhzhia as
            part of the Euro Kharkiv volunteer group. We specialize in helping
            air reconnaissance units.
          </p>
          <p>
            In 2022, more than UAH 1.5 million worth of equipment was purchased
            and donated to the Armed Forces of Ukraine - these are cars,
            components for UAVs, communication devices, drones, Starlinks,
            tablets, thermal imagers We are currently collecting funds for a car
            for aerial reconnaissance of the 113 Kharkiv Brigade
          </p>
          <h2>Contacts</h2>
          <p>Phone: +380669003420</p>
          <p>PrivatBank:4149 4996 5065 9491</p>
          <p>MonoBank:5375 4114 2069 7851</p>
        </div>
      </div>
    </div>

    <h2 class="volunteer-page-title">Photo Report</h2>

    <div class="relevant-case-studies">
      <div class="container-db">
        <div class="slider-container">
          <div class="relevant-case-studies-slider">
            <slick ref="slick" :options="slickOptions">
              <div class="case-slider-item">
                <div class="volunteer-item">
                  <div class="case-studies-img-wrapper">
                    <img
                      src="/img/images-db/volunteers/andrii_4.jpg"
                      width="300"
                      height="334"
                    />
                  </div>
                </div>
              </div>
              <div class="case-slider-item">
                <div class="volunteer-item">
                  <div class="case-studies-img-wrapper">
                    <img
                      src="/img/images-db/volunteers/andrii_1.jpg"
                      width="300"
                      height="334"
                    />
                  </div>
                </div>
              </div>
              <div class="case-slider-item">
                <div class="volunteer-item">
                  <div class="case-studies-img-wrapper">
                    <img
                      src="/img/images-db/volunteers/podyaka_1.jpg"
                      width="300"
                      height="334"
                    />
                  </div>
                </div>
              </div>
              <div class="case-slider-item">
                <div class="volunteer-item">
                  <div class="case-studies-img-wrapper">
                    <img
                      src="/img/images-db/volunteers/andrii_2.jpg"
                      width="300"
                      height="334"
                    />
                  </div>
                </div>
              </div>
              <div class="case-slider-item">
                <div class="volunteer-item">
                  <div class="case-studies-img-wrapper">
                    <img
                      src="/img/images-db/volunteers/podyaka_3.jpg"
                      width="300"
                      height="334"
                    />
                  </div>
                </div>
              </div>
            </slick>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Slick from "vue-slick";
let vm = {};
export default {
  name: "OurTrustedVolunteers",
  metaInfo: {
    title: "Our Trusted Volunteers",
    link: [
      {
        rel: "canonical",
        href: "https://devbrother.com/our-trusted-volunteers",
      },
    ],
  },
  components: { Slick },
  mounted() {},
  data() {
    const jsonld = {
      "@context": "https://schema.org",
      "@type": "IndividualProduct",
      name: "OurTrustedVolunteers",
      description: "Our Trusted Volunteers",
    };
    return {
      jsonld,
      slickOptions: {
        prevArrow:
          '<div class="prev-arrow"><img src="/img/images-db/Arrow.svg" alt="arrow" class="arrow" width="26" height="11"/><img src="/img/images-db/Arrow-hover.svg" alt="arrow" class="arrow-hover" width="26" height="11"/></div>',
        nextArrow:
          '<div class="next-arrow"><img src="/img/images-db/Arrow.svg" alt="arrow" class="arrow" width="26" height="11"/><img src="/img/images-db/Arrow-hover.svg" alt="arrow" class="arrow-hover" width="26" height="11"/></div>',
        slidesToShow: 3,
        slidesToScroll: 1,
        // centerMode: true,
        responsive: [
          {
            breakpoint: 769,
            settings: {
              slidesToShow: 1,
              centerMode: false,
              prevArrow: "",
              nextArrow: "",
              dots: true,
              customPaging: function (slider, i) {
                return '<div class="slick-mobile-dot"></div>';
              },
            },
          },
        ],
        // Any other options that can be got from plugin documentation
      },
    };
  },
};
</script>
