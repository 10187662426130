<template>    <!--SECTION INDUSTRIES-->
<main>
			<div class="promotaion wp">
				<div class="container-db bread-container">
					<div class="bread-crumbs">
						<router-link to="/">Home</router-link>
						<span class="bread-separator">/</span>
						<span class="bread-current">WordPress</span>
					</div>
				</div>
				<div class="container-db">
					<h1>WordPress<br>plugin development for PurplePass</h1>
				</div>
			</div>
			<div class="case-container challenge">
				<h2>Overview</h2>
				<p>
					PurplePass is a cloud-based ticketing solution suitable for small to midsize event organizers. Key features include online pre-sale, marketing, printed tickets and wristbands, social media integration, day-of sales and admission management.
				</p>
				<p>
					PurplePass contacted DevBrother to develop WP plugin for their online service. This plugin had to be synced to their online service in real-time mode and should have lots of functionality that will be available to use from WP admin panel
				</p>
			</div>
			<div class="descrip-result-wrapper">
				<div class="descrip-result-text">
					<div class="container-db">
						<div class="description">
							<h2>Description</h2>
							<p>
								We had to create WP plugin for PurplePass with opportunity to create events from WP admin panel, customize this events, predefine assigned seats for the events and many other specific features connected to syncing WP website with PurplePass online service user account. All data is synchronized and solid. Also we had to develop the widget for showing all events with necessary data on user WP website.
							</p>
						</div>
						<div class="result">
							<h2>Result</h2>
							<p>
								We’ve created WP plugin that syncs WP website with specific user account on PurplePass. All data synced properly, all changes that user will provide on his PurplePass account automatically  applies on the WP website and vice versa. Also customizable widget can be added to the WP website where website visitors can look through all necessary information.
							</p>
						</div>
					</div>
				</div>
				<img v-lazyload data-url="/img/images-db/case-studies/wp-solutions.webp" alt="wp-solutions.webp" width="945" height="665">
			</div>
			<div class="tech-features-section">
				<div class="container-db">
					<div class="tech-features-wrapper">
						<div class="technologies-wrapper">
							<h2>Technologies</h2>
							<ul>
								<li class="marked-item">Wordpress</li>
								<li class="marked-item">HTML5, CSS3, Bootstrap</li>
								<li class="marked-item">MySQL</li>
								<li class="marked-item">AWS, ECP</li>
							</ul>
						</div>
						<div class="features">
							<h2>Features</h2>
							<ul>
								<li>WordPress plugin for online service with syncing and customization</li>
								<li>WordPress Plugin integration with other plugins</li>
								<li>Widget for using with WordPress websites</li>
							</ul>
						</div>

					</div>
				</div>
			</div>
			<div class="relevant-case-studies">
				<div class="container-db">
					<h2>portfolio</h2>
					<div class="slider-container">
						<div class="relevant-case-studies-slider">
							<slick ref="slick" :options="slickOptions">
								<div class="case-slider-item">
									<router-link to="healthcare">
										<div class="case-studies-item">
											<div class="case-studies-img-wrapper">
												<img src="/img/images-db/case-studies/health.webp" alt="healthcare" width="300" height="334">
											</div>
											<h3>HealthCare <br />DoctorMed</h3>
										</div>
									</router-link>
								</div>
								<div class="case-slider-item">
									<router-link to="online-event">
										<div class="case-studies-item">
										<div class="case-studies-img-wrapper">
											<img src="/img/images-db/case-studies/event.webp" alt="Online Tickets  Printing" width="300" height="334">
										</div>
										<h3>Online Tickets Printing</h3>
									</div>
									</router-link>
								</div>
								<div class="case-slider-item">
									<router-link to="cryptocurrency">
										<div class="case-studies-item">
										<div class="case-studies-img-wrapper">
											<img src="/img/images-db/case-studies/crypto.webp" alt="Cryptocurrency" width="300" height="334">
										</div>
										<h3>Cryptocurrency</h3>
									</div>
									</router-link>
								</div>
								<div class="case-slider-item">
									<router-link to="art-galleries">
										<div class="case-studies-item">
										<div class="case-studies-img-wrapper">
											<img src="/img/images-db/case-studies/art.webp" alt="Art Galleries" width="300" height="334">
										</div>
										<h3>Art Galleries</h3>
									</div>
									</router-link>
								</div>
								<div class="case-slider-item">
									<router-link to="e-commerce">
										<div class="case-studies-item">
										<div class="case-studies-img-wrapper">
											<img src="/img/images-db/case-studies/ecommerce.webp" alt="E-commerce" width="300" height="334">
										</div>
										<h3>E-commerce</h3>
									</div>
									</router-link>
								</div>
							</slick>
						</div>
					</div>
				</div>
			</div>
      <AcquaintedSection />
      <div v-lazyload data-src="/img/images-db/parallax/stone2.webp" class="lazy-bg bottom-stone"></div>
			<script v-html="jsonld" type="application/ld+json"></script>
    </main>
</template>
<script>
	import Slick from 'vue-slick';
	import AcquaintedSection from '../AcquaintedSection';

	export default {
			name: 'Wordpressdev',
			metaInfo: {
			title: 'Case: Developing a Plugin for PurplePass for Easy Event Creation from the Site Admin Panel',
            meta: [
                {
					vmid: "description",
                    name: 'description',
                    content: 'We have successfully created a plugin to synchronize the online service with the PurplePass site. Now it is convenient for the user to add and view information.'
                },
				{
					vmid: "og:image:type",
					name: "og:image:type",
					content: 'image/webp'
				},
				{
					vmid: "og:title",
					name: "og:title",
					content: 'Case: Developing a Plugin for PurplePass for Easy Event Creation from the Site Admin Panel'
				},
				{
					vmid: "og:description",
					name: "og:description",
					content: 'We have successfully created a plugin to synchronize the online service with the PurplePass site. Now it is convenient for the user to add and view information.'
				},
				{
				  vmid: "og:image",
				  name: "og:image",
				  content: 'https://devbrother.com/img/images-db/case-studies/wp-solutions.webp'
				},
				{
					vmid: "og:type",
					name: "og:type",
					content: "website"
				},
				{
					vmid: "og:url",
					name: "og:url",
					content: 'https://devbrother.com/cases/wordpress-dev'
				},
				{
					vmid: "twitter:title",
					name: "twitter:title",
					content: 'Case: Developing a Plugin for PurplePass for Easy Event Creation from the Site Admin Panel'
				},
				{
					vmid: "twitter:description",
					name: "twitter:description",
					content: 'We have successfully created a plugin to synchronize the online service with the PurplePass site. Now it is convenient for the user to add and view information.'
				},
				{
					vmid: "twitter:image",
					name: "twitter:image",
					content: 'https://devbrother.com/img/images-db/case-studies/wp-solutions.webp'
				}
            ],
      },
			components: {
				Slick,
				AcquaintedSection
				 },
		mounted() {
			this.addCanonicalLink();
		},
		methods: {
				addCanonicalLink: () => {
					const link = document.createElement('link');
					link.setAttribute('rel', 'canonical');
					link.setAttribute('href', 'https://devbrother.com/cases/wordpress-dev');

					const oldLink = document.querySelector('link[rel="canonical"]');
					if (oldLink) {
						document.head.removeChild(oldLink);
					}

					document.head.appendChild(link);
				},
		},
		data(){
			const jsonld =  {
					"@context": "https://schema.org",
					"@type": "IndividualProduct",
					"name": "Wordpress",
					"description": "Wordpress"
			}
			return {
			jsonld,
			slickOptions: {
		prevArrow: '<div class="prev-arrow"><img src="/img/images-db/Arrow.svg" alt="arrow" class="arrow" width="26" height="11"/><img src="/img/images-db/Arrow-hover.svg" alt="arrow" class="arrow-hover" width="26" height="11"/></div>',
        nextArrow: '<div class="next-arrow"><img src="/img/images-db/Arrow.svg" alt="arrow" class="arrow" width="26" height="11"/><img src="/img/images-db/Arrow-hover.svg" alt="arrow" class="arrow-hover" width="26" height="11"/></div>',
					slidesToShow: 3,
					slidesToScroll: 1,
					// centerMode: true,
					responsive: [
						{
							breakpoint: 769,
							settings: {
								slidesToShow: 1,
								centerMode: false,
								prevArrow: '',
								nextArrow: '',
								dots: true,
								customPaging: function(slider, i) {
									return '<div class="slick-mobile-dot"></div>';
								}
							}
						}
					]
							// Any other options that can be got from plugin documentation
					},
			}
		},
	}
</script>
