<template>
	<div id="help-army">
		<form action="#" novalidate="novalidate" class="acquainted-form">
			<div>
				<div class="form-title">Company details</div>
				<div class="requisites">
					<div>
						DevBrother<br>
						<span>(Company name)</span>
					</div>
					<div>
						UA373515330000026006052127341<br>
						<span>(IBAN Code)</span>
					</div>
					<div>
						JSC CB "PRIVATBANK", 1D HRUSHEVSKOHO STR. KYIV, 011001, UKRAINE<br>
						<span>(Name of the bank)</span>
					</div>
					<div>
						PBANUA2X<br>
						<span>(Bank Swift code)</span>
					</div>
					<div>
						UA64703 Kharkiv, Danilevskoho street 6,b<br>
						<span>(Company address)</span>
					</div>
				</div>
			</div>
		</form>
  </div>
</template>

<script>
		let vm = {}
    export default {
			name: 'HelpArmyModal',
			props: {
			},
      data(){
        return {
				}
			}
    }
</script>
<style scoped>
	.requisites div{
		margin-top: 18px;
		font-family: "Roboto Condensed";
    font-size: 18px;
    letter-spacing: 0.41px;
	}

	.requisites span {
		color: gray;
		font-size: 15px;
	}
</style>
