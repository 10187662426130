<template>
    <main class="healthcare web-reactjs">
        <div class="top-stone">
        </div>
        <div class="pagination">
            <div class="container-db">
                <div class="bread-crumbs">
                    <router-link to="/" class="router-link-active">Home</router-link>
                    <span class="bread-separator">/</span>
                    <span class="bread-current">Cryptocurrency Development</span>
                </div>
            </div>
        </div>

        <section class="top-form-section service-description">
            <div class="container-db">
                <div class="service-wrapper">
                    <div class="service-text-wrapper">
                        <h1>Cryptocurrency Development Company</h1>
                        <p>
                            DevBrother is a cryptocurrency <router-link to="/">development company</router-link> with an excellent reputation. It is considered a leader in creating platforms based on blockchain technology for various types of crypto operations. It uses high-security measures to speed digital currency transactions via P2P networks.
                        </p>
                        <p>
                            The company provides supreme tools according to your needs and preferences.
                        </p>
                        <p>
                            Professional cryptocurrency development company engineers create software for your business and digital currency operations. We guarantee your business's skillful cryptocurrency development services and decentralized blockchain solutions.
                        </p>
                        <p>
                            DevBrother's team provides multiple benefits, from digital apps to staking platforms. Enjoy effective service at a reasonable price.
                        </p>
                    </div>
                    <div class="industry-top-form">
                        <ServicesTopForm />
                    </div>
                </div>
            </div>
        </section>

        <div class="inner-container">
            <section class="clients awards-section">
                <div class="clients-wrapper award-wrapper">
                    <div class="inner-container">
                        <h2 class="our-achievements">Reviews about us</h2>
                        <ul class="award-list">
                            <li class="awards-list-item" data-aos="zoom-in" data-aos-duration="1500">
                                <a target="_blank" href="https://www.goodfirms.co/company/devbrother"
                                    rel="nofollow"><img v-lazyload height="160" width="187"
                                        data-url="https://goodfirms.s3.amazonaws.com/badges/color-badge/top-software-development-companies.svg"
                                        alt="GoodFirms Badge"></a>
                            </li>
                            <li class="awards-list-item" data-aos="zoom-in" data-aos-duration="500">
                                <a href="https://clutch.co/profile/devbrother" target="_blank" rel="nofollow">
                                    <img v-lazyload class="award-logo" data-url="/img/images-db/socials/award2.png"
                                        height="160" width="148" alt="Clutch Top Developers">
                                </a>
                            </li>
                            <li class="awards-list-item" data-aos="zoom-in" data-aos-duration="500">
                                <a class="top-soft-award-logo"
                                    href="https://topsoftwarecompanies.co/ukraine/software-development/agencies/kharkiv"
                                    target="_blank" rel="nofollow">
                                    <img v-lazyload height="150" width="150"
                                        data-url="/img/images-db/socials/top-software-developers.webp"
                                        alt="software developers kharkiv" title="Software Developers Kharkiv" />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        </div>

        <section class="integration-section service-description">
            <div class="container-db">
                <div v-lazyload data-src="/img/images-db/icons-industry/bitcoin.png"
                    class="lazy-bg bg-icon"></div>

                <div class="service-wrapper">
                    <div class="service-text-wrapper advantages">
                        <h2>Cryptocurrency Development Services</h2>
                        <p>
                            Our company provides you with multiple crypto software development services. We create the best product to meet your needs. If you are looking for a cryptocurrency regulation and management company, we can offer the following services to your business:
                        </p>
                    </div>
                </div>
                <div class="health-technologies">
                    <div class="inner-container">
                        <ul class="tech-list">
                            <li>
                                <div class="tech-list_header">
                                    <h3 class="marked">Cryptocurrency Software Exchange</h3>
                                    <i v-lazyload
                                        data-src="/img/images-db/icons-industry/Cryptocurrency Exchange.svg"
                                        class="lazy-bg">
                                    </i>
                                </div>
                                <div class="list-inner-wrap">
                                    <p>
                                        Our experts create cryptocurrency exchange software where you can make different operations with cryptocurrency. We create the best platforms where you can sell, buy, and trade digital currency legally. This software is perfect for exchange operators and brokers. It helps to manage global crypto trading.
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div class="tech-list_header">
                                    <h3 class="marked">DeFi Staking Platforms</h3>
                                    <i v-lazyload data-src="/img/images-db/icons-industry/DeFi Staking Platforms.svg"
                                        class="lazy-bg">
                                    </i>
                                </div>
                                <div class="list-inner-wrap">
                                    <p>
                                        We provide a decentralized finance platform for validators to lock some crypto assets within the Proof-of-Stake (PoS) blockchain networks. You can earn high interest by making your assets available for lending.
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div class="tech-list_header">
                                    <h3 class="marked">Non-Fungible Token (NFT) Marketplace</h3>
                                    <i v-lazyload data-src="/img/images-db/icons-industry/NFT Marketplace.svg"
                                        class="lazy-bg">
                                    </i>
                                </div>
                                <div class="list-inner-wrap">
                                    <p>
                                        We create a cryptocurrency creation platform for simple purchasing and selling NFTs. Our experts construct a marketplace to store and trade your tokens. Get a marketplace platform operating on premium-quality blockchain technology.
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div class="tech-list_header">
                                    <h3 class="marked">P2P Crypto Exchange</h3>
                                    <i v-lazyload data-src="/img/images-db/icons-industry/P2P Crypto Exchange.svg"
                                        class="lazy-bg">
                                    </i>
                                </div>
                                <div class="list-inner-wrap">
                                    <p>
                                        Our company provides a peer-to-peer (P2P) software platform that allows traders to make operations online without intermediaries or a central server. Decentralization is the main concept of this platform for currency exchange that works on blockchain technology. Our experts create top-class decentralized applications that work on P2P blockchain technology.
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div class="tech-list_header">
                                    <h3 class="marked">Crypto Portal Development</h3>
                                    <i v-lazyload data-src="/img/images-db/icons-industry/Crypto Portal Development.svg"
                                        class="lazy-bg">
                                    </i>
                                </div>
                                <div class="list-inner-wrap">
                                    <p>
                                        Our top-class cryptocurrency software development company experts create innovative portals for high-speed digital currency transactions. We build decentralized financial platforms for various crypto operations. Enjoy unlimited, secured transfers of your preferred cryptocurrency.
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div class="tech-list_header">
                                    <h3 class="marked">NFT Launchpads</h3>
                                    <i v-lazyload data-src="/img/images-db/icons-industry/NFT Launchpads.svg"
                                        class="lazy-bg">
                                    </i>
                                </div>
                                <div class="list-inner-wrap">
                                    <p>
                                        Our crypto development team offers well-designed platforms for artists and organizations to help present their effective products to the audience. Brand creators can use this service to provide presales or release their projects. <router-link to="/cases/flexiswap-nft-swap-platform">NFT launchpads</router-link> help creators raise digital coins to deploy their business ideas.
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div class="tech-list_header">
                                    <h3 class="marked">Smart Contract for Cryptocoin Development</h3>
                                    <i v-lazyload
                                        data-src="/img/images-db/icons-industry/Smart Contract Development.svg"
                                        class="lazy-bg">
                                    </i>
                                </div>
                                <div class="list-inner-wrap">
                                    <p>
                                        Smart contracts automate the workflow. Our team creates smart contracts to help your clients meet agreements quickly and prevent time losses.
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="service-text-wrapper advantages">
                    <p>
                        Define the service you are interested in and connect to our team. Our customer service agents are ready to answer your questions and provide detailed information online 24/7. Experts offer advice on any issues and help you solve problems with cryptocurrency management.
                    </p>
                </div>
            </div>
        </section>

        <section class="life-cycle dedicated-section service-description">
            <div class="container-db">
                <div v-lazyload data-src="/img/images-db/icons-industry/ethereum.png" class="lazy-bg bg-icon">
                </div>
                <div class="service-wrapper">
                    <div class="service-text-wrapper advantages">
                        <h2>
                            Benefits Of Crypto Development For Small, Medium, And Large Businesses
                        </h2>
                        <p>
                            Multiple companies use blockchain technology to improve various aspects of their business. Decentralized platforms have affected all kinds of businesses nowadays. Modern companies use all the advantages that decentralized platforms hold. Cryptocurrency software development has significantly changed big, small, and large companies.
                        </p>
                        <ol class="text-img-list">
                            <li>
                                <div class="text-img-wrap">
                                    <div class="card-text">
                                <h3 class="marked">Decentralized System</h3>
                                        <p>
                                            Digital currency transfers are performed using blockchain technology from peer to peer. There are no intermediaries, which helps to save time and money and simplifies money transactions.
                                        </p>
                                        <p>
                                            Multiple companies use blockchain technology for data sharing in their business ecosystem.
                                        </p>

                                    </div>
                                    <div class="img-wrap service-img-wrapper">
                                        <img v-lazyload data-url="/img/images-db/services/blockchain.webp"
                                            alt="blockchain" height="307" width="460">
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="text-img-wrap inverted">
                                    <div class="img-wrap service-img-wrapper">
                                        <img v-lazyload data-url="/img/images-db/services/crypto-security.webp" alt="crypto security"
                                            height="307" width="460">
                                    </div>
                                    <div class="card-text">
                                        <h3 class="marked">Improved Security</h3>
                                        <p>
                                            Blockchain technology has brought significant changes in protecting sensitive data from the intervention of third parties.
                                        </p>
                                        <p>
                                            Companies benefit from instant and safe transactions that are impossible to hack. Transactions are encrypted and secure.
                                        </p>

                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="text-img-wrap">
                                    <div class="card-text">
                                        <h3 class="marked">Low Fees</h3>
                                        <p>
                                            Business organizations reduce costs for money transactions.
                                        </p>
                                        <p>
                                            Blockchain helps businesses eliminate extra spending by avoiding the involvement of intermediaries in financial operations.
                                        </p>
                                    </div>
                                    <div class="img-wrap service-img-wrapper">
                                        <img v-lazyload data-url="/img/images-db/services/low-fees.webp"
                                            alt="low fees" height="307" width="460">
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="text-img-wrap inverted">
                                    <div class="img-wrap service-img-wrapper">
                                        <img v-lazyload data-url="/img/images-db/services/new-payment-solution.webp"
                                            alt="new payment solution" height="307" width="460">
                                    </div>
                                    <div class="card-text">
                                        <h3 class="marked">New Payment Solution</h3>
                                        <p>
                                            More and more companies keep up with the times and accept payments or money transfers in digital currency.
                                        </p>
                                        <p>
                                            The appearance of a digital wallet simplified the process of money transfers and made international payments faster.
                                        </p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="text-img-wrap">
                                    <div class="card-text">
                                        <h3 class="marked">Faster Deals</h3>
                                        <p>
                                            Smart contracts allow customers to meet terms and conditions without intermediaries in one click.
                                        </p>
                                        <p>
                                            It reduces the need to negotiate and makes all details clear.
                                        </p>
                                    </div>
                                    <div class="img-wrap service-img-wrapper">
                                        <img v-lazyload data-url="/img/images-db/services/faster-deals.webp"
                                            alt="faster deals" height="307" width="460">
                                    </div>
                                </div>
                            </li>
                        </ol>
                        <p class="bottom-info marked">Cryptocurrency software enables individuals and organizations to conduct digital currency transactions quickly. It has transformed businesses at all levels and opened up new possibilities. Multiple organizations are interested in digital asset transactional, operational, and investment aspects.</p>
                        <a href="#questions" class="btn" v-smooth-scroll>Let’s talk</a>
                    </div>
                </div>
            </div>
        </section>

        <section class="software-benefits dedicated-section service-description">
            <div class="container-db">
                <div v-lazyload data-src="/img/images-db/icons-industry/monero-coin.png" class="lazy-bg bg-icon">
                </div>
                <div class="service-wrapper">
                    <div class="service-text-wrapper key-benefits">
                        <h2>Software Development Life Cycle For Cryptocurrency projects In DevBrother</h2>
                        <p>
                            DevBrother has a professional team specializing in the entire product development cycle. Our remote engineers are ready to create a top-quality project that complies with the highest industry standards. We provide support for remote work and make everything possible to exceed our client's expectations.
                        </p>
                        <p>
                            DevBrother's main goal is to offer top-quality services and build a long-term partnership. Our database includes the best European developers.
                        </p>
                        <p>
                            Blockchain has changed the digital world, and its development differs from usual apps or platforms.
                        </p>
                        <p class="marked">
                            The blockchain development lifecycle consists of several steps:
                        </p>
                        <div class="wrap">
                            <ol>
                                <li>
                                    <span class="marked">Defining the objective.</span> Our team works on specifying the problem that can be successfully resolved by blockchain technology.
                                </li>
                                <li>
                                    <span class="marked">Selecting the required mechanism.</span> We define if it is necessary to use PoW or PoS mechanism. Or search for other cryptocurrency development solutions that are suitable for the project.
                                </li>
                                <li>
                                    <span class="marked">Programming language selection.</span> Our experts determine whether HTML, Javascript, or another solution is necessary for app creation.
                                </li>
                                <li>
                                    <span class="marked">Design and interface.</span> Our engineers create a high-quality, easy-to-navigate platform interface. This step is required to determine whether you want to create a public or private blockchain network.
                                </li>
                                <li>
                                    <span class="marked">Configurations.</span> This critical step includes making decisions according to address formats, key management, permissions, etc.
                                </li>
                                <li>
                                    <span class="marked">APIs building.</span> All blockchain products require APIs to perform crypto development services related to auditing. It is necessary for data storage and authentication.
                                </li>
                                <li>
                                    <span class="marked">MVP model.</span> MVP is a GUI programming pattern. It is better to start with this model type before adding multiple features. The main focus is on functionality. This step aims to identify and resolve problems that can appear and expertise judgment of the product.
                                </li>

                            </ol>
                            <div class="img-wrap service-img-wrapper">
                                <img v-lazyload data-url="/img/images-db/services/DB-team.webp"
                                    alt="Devbrother team" height="350" width="525">
                            </div>
                        </div>
                        <p class="marked">
                            Our professional team is ready to design the dApp or other software, complying with all your needs and preferences. We develop exchange software with additional features for selling token currency. The DevBrother team ensures the best quality of their products on the market and is entirely ready for any cryptocurrency maintenance.
                        </p>
                    </div>
                </div>
            </div>
        </section>

        <section class="faq-section">
            <h2>Frequently Asked Questions</h2>
            <div class="container-db">
                <div v-lazyload data-src="/img/images-db/icons-industry/lite-coin.png" class="lazy-bg bg-icon">
                </div>
                <div id="app">
                    <app-accordion @closeAccordions='closeAccordions()' v-for="(accordion, index) in accordions"
                        ref="accordion" :key="index" class="app-accordion">
                        <template v-slot:title>
                            <span>{{ accordion.title }}</span>
                        </template>
                        <template v-slot:content>
                            <p>
                                {{ accordion.text }}
                            </p>
                        </template>
                    </app-accordion>
                </div>

            </div>
        </section>

        <h2 id="questions" class="more-questions">If you have any more questions, write to us</h2>

        <section class="acquainted main-contact" id="acquainted">
            <div class="container-db">
                <div class="acquainted-form-wrapper">
                    <div v-lazyload data-src="/img/images-db/parallax/stone2.webp" class="lazy-bg bottom-stone"></div>
                    <div class="cooperation-offer">
                        <h3>What's your challenge?</h3>
                        <div class="cooperation-offer-text">
                            Let us deliver the talent and expertise to help you solve it
                        </div>
                    </div>
                    <AcquaintedForm />
                </div>
            </div>
        </section>



        <script v-html="jsonld" type="application/ld+json"></script>
    </main>
</template>
<script>
import AcquaintedForm from "../partials/AcquaintedForm";
import { requestGet, requestPost } from "../../services/axios.js";
import AppAccordion from "../services/web-development/AppAccordion.vue";
import ServicesTopForm from '../ServicesTopForm';

let vm = {};
export default {
    name: 'CryptocurrencyDevelopment',
    metaInfo: {
        title: 'Cryptocurrency Development Company - Development Services',
        meta: [
            {
                vmid: "description",
                name: 'description',
                content: '【Devbrother】is an innovative cryptocurrency development company. We provide services for technological software development. ➜ Wallet ➜ Exchange, etc.'
            },
            {
                vmid: "og:image:type",
                property: "og:image:type",
                content: 'image/webp'
            },
            {
                vmid: "og:title",
                property: "og:title",
                content: 'Cryptocurrency Development Company - Development Services'
            },
            {
                vmid: "og:description",
                property: "og:description",
                content: '【Devbrother】is an innovative cryptocurrency development company. We provide services for technological software development. ➜ Wallet ➜ Exchange, etc.'
            },
            {
                vmid: "og:image",
                name: "og:image",
                content: 'http://devbrother.com/img/images-db/services/healthcare-benefits.webp !!!'
            },
            {
                vmid: "og:type",
                property: "og:type",
                content: "website"
            },
            {
                vmid: "og:url",
                property: "og:url",
                content: 'http://devbrother.com/industries/cryptocurrency-development'
            },
            {
                vmid: "twitter:title",
                name: "twitter:title",
                content: 'Cryptocurrency Development Company - Development Services'
            },
            {
                vmid: "twitter:description",
                name: "twitter:description",
                content: '【Devbrother】is an innovative cryptocurrency development company. We provide services for technological software development. ➜ Wallet ➜ Exchange, etc.'
            },
            {
                vmid: "twitter:image",
                name: "twitter:image",
                content: 'http://devbrother.com/img/images-db/services/healthcare-benefits.webp !!!'
            }
        ]
    },
    components: { AcquaintedForm, AppAccordion, ServicesTopForm },
    data() {
        const jsonld = {
            "@context": "https://schema.org",
            "@graph": [{
                "@type": "Organization",
                "@id": "https://devbrother.com/#organization",
                "name": "DevBrother",
                "url": "https://devbrother.com/",
                "sameAs": ["https://www.linkedin.com/company/devbrother/",
                    "https://www.instagram.com/devbrother_company/",
                    "https://clutch.co/profile/devbrother"],
                "logo": {
                    "@type": "ImageObject",
                    "inLanguage": "en-US",
                    "@id": "https://devbrother.com/#/schema/logo/image/",
                    "url": "https://devbrother.com/img/images-db/Logo.png",
                    "contentUrl": "https://devbrother.com/img/images-db/Logo.png",
                    "width": 70,
                    "height": 40,
                    "caption": "DevBrother"
                },
                "image":
                    { "@id": "https://devbrother.com/#/schema/logo/image/" }
            },
            {
                "@type": "WebSite", "@id": "https://devbrother.com/#website",
                "url": "https://devbrother.com/",
                "name": "DevBrother",
                "description": "DevOps &amp; Software Development",
                "publisher": { "@id": "https://devbrother.com/#organization" },
                "potentialAction": [{
                    "@type": "SearchAction",
                    "target": {
                        "@type": "EntryPoint",
                        "urlTemplate": "https://devbrother.com/?s={search_term_string}"
                    },
                    "query-input": "required name=search_term_string"
                }],
                "inLanguage": "en-US"
            },
            {
                "@type": "ImageObject",
                "inLanguage": "en-US",
                "@id": "http://devbrother.com/industries/cryptocurrency-development#primaryimage",
                "url": "http://devbrother.com/img/images-db/services/healthcare-benefits.webp !!!",
                "contentUrl": "http://devbrother.com/img/images-db/services/healthcare-benefits.webp !!!",
                "width": 512,
                "height": 512
            },
            {
                "@type": "WebPage",
                "@id": "https://devbrother.com/industries/cryptocurrency-development",
                "url": "https://devbrother.com/industries/cryptocurrency-development",
                "name": "Cryptocurrency Development Company",
                "isPartOf": { "@id": "https://devbrother.com/#website" },
                "primaryImageOfPage": { "@id": "https://devbrother.com/industries/cryptocurrency-development#primaryimage" },
                "datePublished": "2022-11-11T18:29:55+00:00",
                "dateModified": "2022-11-11T12:26:55+00:00",
                "description": "【Devbrother】is an innovative cryptocurrency development company. We provide services for technological software development. ➜ Wallet ➜ Exchange, etc.",
                "breadcrumb": { "@id": "https://devbrother.com/industries/cryptocurrency-development#breadcrumb" },
                "inLanguage": "en-US",
                "potentialAction": [{
                    "@type": "ReadAction",
                    "target": ["https://devbrother.com/industries/cryptocurrency-development"]
                }]
            },
            {
                "@type": "BreadcrumbList",
                "@id": "https://devbrother.com/industries/cryptocurrency-development#breadcrumb",
                "itemListElement": [{
                    "@type": "ListItem",
                    "position": 1,
                    "name": "DevBrother",
                    "item": "https://devbrother.com/"
                },
                {
                    "@type": "ListItem",
                    "position": 2,
                    "name": "Industry",
                    "item": "https://devbrother.com/industries/"
                },
                {
                    "@type": "ListItem",
                    "position": 3,
                    "name": "Cryptocurrency Development Company",
                    "item": "https://devbrother.com/industries/cryptocurrency-development"
                }
                ]
            },
            {
                "@type": "FAQPage",
                "mainEntity": [
                    {
                        "@type": "Question",
                        "name": "How does cryptocurrency software affect the financial sector?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "One of the main advantages is instant money transfers and inflation control. Financial service institutions benefit from high security, automated contacts."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Do you make custom software for cryptocurrencies?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Our engineers create high-quality custom software for cryptocurrency. We are experienced in creating platforms working on P2P networks using blockchain technology."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "How long does it take to develop a typical cryptocurrency software product?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Time depends on the type of service you prefer. Generally, developing a cryptocurrency software platform takes up to several months. Our company's experts comply with users' demands and develop top-quality products in the shortest time possible."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "How much will custom cryptocurrency software development cost?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "The price of custom cryptocurrency software development depends on the type of product you want to make and the features you would like to add. There is a base cost of software, but it rises with additional functions you want to build in."
                        }
                    }]
            }
            ]
        }


        return {
            jsonld,
            showModal: false,
            accordions: [
                {
                    title: 'How does cryptocurrency software affect the financial sector?',
                    text: "One of the main advantages is instant money transfers and inflation control. Financial service institutions benefit from high security, automated contacts."
                },
                {
                    title: 'Do you make custom software for cryptocurrencies?',
                    text: "Our engineers create high-quality custom software for cryptocurrency. We are experienced in creating platforms working on P2P networks using blockchain technology."
                },
                {
                    title: 'How long does it take to develop a typical cryptocurrency software product?',
                    text: "Time depends on the type of service you prefer. Generally, developing a cryptocurrency software platform takes up to several months. Our company's experts comply with users' demands and develop top-quality products in the shortest time possible."
                },
                {
                    title: 'How much will custom cryptocurrency software development cost?',
                    text: "The price of custom cryptocurrency software development depends on the type of product you want to make and the features you would like to add. There is a base cost of software, but it rises with additional functions you want to build in."
                }
            ],
            isMailSend: false,
            mailData: {
                name: "",
                email: "",
                comment: ""
            },
            errors: [],
            styles: {
                name: {
                    input: "",
                    label: "",
                    span: "visibility: hidden",
                },
                email: {
                    input: "",
                    label: "",
                    span: "visibility: hidden",
                },
                comment: {
                    input: "",
                    label: "",
                    span: "visibility: hidden;",
                }
            }
        };
    },
    beforeCreate() {
        vm = this;
    },
    mounted: () => {
        vm.body = document.querySelector("body");
        vm.addCanonicalLink();
    },
    methods: {
        addCanonicalLink: () => {
            const link = document.createElement('link');
            link.setAttribute('rel', 'canonical');
            link.setAttribute('href', 'https://devbrother.com/industries/cryptocurrency-development');

            const oldLink = document.querySelector('link[rel="canonical"]');
            if (oldLink) {
                document.head.removeChild(oldLink);
            }

            document.head.appendChild(link);
        },
        closeAccordions() {
            this.$refs.accordion.forEach(el => el.closeAccordion());
        },
        checkForEmptiness: (field) => {
            if (vm.mailData[field] === "") {
                vm.addWarning(field)
            } else {
                vm.styles[field]["span"] = "visibility: hidden";
            }
        },
        addWarning: (field) => {
            vm.styles[field]["input"] = "border: 1px solid red;"
            vm.styles[field]["span"] = "visibility: visible";
        },
        raiseLabelUp: field => {
            vm.styles[field]["input"] = "";
            vm.styles[field]["span"] = "visibility: hidden";
        },
        validEmail: email => {
            const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },
        hasErrors: () => {
            let gotError = false;
            Object.keys(vm.mailData).forEach(item => {
                if (vm.mailData[item] == "") {
                    gotError = true;
                    vm.checkForEmptiness(item);
                } else if ((item == "email" && !vm.validEmail(vm.mailData[item]))) {
                    gotError = true;
                    vm.checkForEmptiness(item);
                    vm.addWarning('email');
                }
            });
            return gotError;
        },
        sendMail: (event) => {
            if (!vm.hasErrors()) {
                requestPost("/contact-about-us", vm.mailData)
                    .then(res => {
                        if (res.status) {
                            vm.isMailSend = true;
                            vm.mailData.name = "";
                            vm.mailData.email = "";
                            vm.mailData.comment = "";
                        } else {
                            console.log("Email send failed, error", res.error);
                        }
                    })
                    .catch(error => {
                        return { status: false, error };
                    });
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.top-form-section {
    background: none;

    .service-wrapper {
        align-items: center;
    }

    .service-text-wrapper {
        max-width: 725px;
    }
}

.service-contact {
    max-height: 430px;
}

.inner-container {
    max-width: 1000px;
}

.awards-section {
    margin-bottom: 0;
}

.our-achievements {
    text-align: center;
}

ol {
    padding: 0;
    list-style-type: none;

    li {
        position: relative;
        margin-bottom: 15px;
        padding-left: 35px;

        &::before {
            content: "";
            position: absolute;
            width: 20px;
            height: 20px;
            top: 3px;
            left: 0;
            background: url(/img/images-db/services/check_circle_icon.svg) no-repeat;
            background-size: contain;
        }
    }
}

.outstaffing {

    ol {
        margin-left: 20px;
        list-style-type: auto;

        li {
            padding-left: 0;

            &::before {
                display: none;
            }

            &::marker {
                color: #06c6ff;
            }
        }
    }
}

.service-description {

    a {
        color: #06c6ff;
        text-decoration: underline;

        &:hover {
            opacity: 0.85;
        }
    }

    .service-img-wrapper {
        max-width: 550px;

        img {
            filter: grayscale(0.6);
            border-radius: 20px;
        }
    }

    .advantages {
        max-width: 90%;
        margin-left: auto;
        margin-right: auto;

        h2 {
            text-align: center;
        }

        .bottom-info {
            margin-top: 30px;
        }
    }

    .outstaffing {
        max-width: 90%;
        margin-left: auto;
        margin-right: auto;

        h2 {
            text-align: center;
        }
    }

    .key-benefits {
        max-width: 90%;
        margin-left: auto;
        margin-right: auto;

        h2 {
            text-align: center;
        }

        ol {
            width: 60%;
        }

        .img-wrap {
            width: 37%;
            margin-top: 0;
        }
    }
}

.marked {
    color: #06c6ff;
}

.industry-top-form .service-form .form-title {
    font-size: 28px !important;
}

.service-text-wrapper {
    padding: 30px;
    border-radius: 40px;
    background: linear-gradient(342.18deg, #1b222a 0%, #2b506e 100%);
}

.text-img-list {
    max-width: 900px;
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;

    h3 {
        text-align: center;
        width: 100%;
    }

    .text-img-wrap {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    li {
        padding-left: 0;

        &:not(:last-child) {
            margin-bottom: 50px;
        }

        &::before {
            display: none;
        }
    }

    .card-text {
        width: 50%;
        p{
            max-width: 425px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .img-wrap {
        width: 40%;
    }
}

.industry-top-form {
    border-radius: 30px;
    overflow: hidden;
}


.wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .img-wrap {
        max-width: 350px;
    }
}

.health-technologies {
    .inner-container {
        max-width: 1050px;
    }

    .tech-list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        border-radius: 15px;

        li {
            &:last-child {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

    .tech-list_header {
        margin-bottom: 10px;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: flex-end;
    }

    li {
        width: 31%;
        padding: 15px;
        margin-bottom: 30px;
        border-radius: 15px;
        border: 1px solid #06c6ff;
        background: radial-gradient(circle at -20% -5%, #11DFFF 0%, rgba(0, 49, 255, 0) 34%), #1f2427;
    }

    h3 {
        margin-bottom: 0px;
        font-size: 23px;
    }

    i {
        width: 100%;
        max-width: 50px;
        height: 50px;
        margin-right: 15px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
}

.list-inner-wrap {
    display: flex;
    align-items: center;
}

.advantages {
    .btn {
        display: block;
        width: 200px;
        margin-top: 30px;
        margin-left: auto;
        margin-right: auto;
        color: #1f2427;
        text-decoration: none;

        &:hover {
            color: #ffffff;
        }
    }
}

.bg-icon {
    position: absolute;
    height: 250px;
    width: 250px;
    z-index: -1;
    opacity: 0.6;
    top: -85px;
    right: -100px;
    background-size: contain;
    background-repeat: no-repeat;
}

.integration-section,
.healthcare-services,
.software-benefits,
.faq-section,
.life-cycle {
    .container-db {
        position: relative;
    }
}

.life-cycle{
    img{
        min-height: 240px;
        max-height: 240px;
        object-fit: cover;
    }
}

.faq-section {
    .bg-icon {
        top: -165px;
    }
}

.integration-section {
    h2 {
        margin-bottom: 20px;
    }
}

@media (max-width: 1220px) {
    .top-form-section .service-text-wrapper {
        max-height: none;
    }

    .industry-top-form {
        margin-top: 30px;
    }

    .advantages {
        .img-wrap {
            margin-top: 0;
        }
    }
}

@media (max-width: 990px) {

    .health-technologies {
        li {
            width: 48%;
        }
    }

}

@media (max-width: 900px) {

    .text-img-list {

        h3 {
            text-align: center;
            width: 100%;
        }

        .text-img-wrap {
            flex-direction: column-reverse;
            align-items: center;

            &.inverted {
                flex-direction: column;
            }
        }

        .card-text {
            width: 100%;
        }

        .img-wrap {
            width: 100%;
        }
    }

    .img-wrap.service-img-wrapper {
        max-width: 400px;
        margin-top: 0;
        margin-bottom: 25px;
    }

    .inverted {
        flex-direction: column-reverse;
    }

    .service-description {
        .key-benefits {
            .wrap {
                flex-direction: column-reverse;
            }

            ol {
                width: 100%;
            }

            .img-wrap {
                max-width: 400px;
                width: 100%;
                margin-bottom: 25px;
            }
        }
    }

    .software-benefits{
        .img-wrap{
            max-height: 400px;
            width: auto!important;
            img{
                max-height: 400px;
            }
        }
    }
}

@media (max-width: 768px) {
    .bg-icon {
        display: none;
    }
}

@media (max-width: 767px) {
    .service-text-wrapper {
        padding: 30px 15px;
    }

    .health-technologies {
        h3 {
            font-size: 20px;
            letter-spacing: 0.45px;
            line-height: 30px;
        }

    }
}

@media (max-width: 550px) {

    h1 {
        font-size: 30px;
        line-height: 37px;
    }

    h2 {
        font-size: 26px;
    }

    .health-technologies {
        li {
            width: 100%;
        }

    }
}
</style>
