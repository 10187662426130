<template>
	<div id="service-form">
    <form v-on:submit.prevent="sendMail()" action="#" class="service-form acquainted-form"  novalidate="novalidate">
      <div v-if="isMailSend" class="success-send-title"><div>Your message was successfully sent.<br />We will respond to you as soon as possible.</div></div>
      <div v-else-if="!isMailSend">

        <div class="form-title">Let’s get acquainted</div>
        <div class="name-email-area">
          <p class="name-label">
            <label :style="styles.name.label" for="name">Name</label>
            <input v-model="mailData.name" type="text" required name="name" id="name"
              @focus="raiseLabelUp('name')"
              @blur="checkForEmptiness('name')"/>
            <span class="error-message" :style="styles.name.span">Please enter the name</span>
          </p>
          <p>
            <label :style="styles.email.label" for="email">Email</label>
            <input v-model="mailData.email" type="email" required name="email" id="email"
              @click="raiseLabelUp('email')"
              @blur="checkForEmptiness('email')"/>
            <span class="error-message" :style="styles.email.span">Please enter the email in valid format</span>
          </p>
        </div>
        <p>
        <label for="comment" id="text">Message</label>
        <textarea v-model="mailData.comment" name="comment" id="comment" cols="30" rows="3" aria-label="project description"
             required @focus="raiseLabelUp('comment')"
            @blur="checkForEmptiness('comment')"></textarea>
        <span :style="styles.comment.span">Please enter your message</span>
    	</p>
          <button type="submit" class="btn form-btn" aria-label="submit form">Contact us</button>
      </div>
    </form>
  </div>
</template>

<script>
	import { requestGet, requestPost } from "../services/axios.js";

    let vm = {};

	export default {
				name: 'ServicesTopForm',
		data() {
     	  return {
            isMailSend: false,
            mailData: {
                name: "",
                email: "",
                comment: ""
            },
            errors: [],
            styles: {
                name: {
                    input: "",
                    label: "",
                    span: "visibility: hidden",
                },
                email: {
                    input: "",
                    label: "",
                    span: "visibility: hidden",
                },
                comment: {
                    input: "",
                    label: "",
                    span: "visibility: hidden;",
                }
            }
          }
        },
		    beforeCreate(){
            vm = this;
        },
        methods:{
        checkForEmptiness: (field) => {
            if (vm.mailData[field] === "") {
                vm.addWarning(field)
            } else {
                vm.styles[field]["span"] = "visibility: hidden";
            }
        },
        addWarning: (field) => {
            vm.styles[field]["input"] = "border: 1px solid red;"
            vm.styles[field]["span"] = "visibility: visible";
        },
        raiseLabelUp: field => {
            vm.styles[field]["input"] = "";
            vm.styles[field]["span"] = "visibility: hidden";
        },
        validEmail: email => {
            const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },
        hasErrors: () => {
            let gotError = false;
            Object.keys(vm.mailData).forEach(item => {
                if (vm.mailData[item] == "") {
                    gotError = true;
                    vm.checkForEmptiness(item);
                } else if ((item == "email" && !vm.validEmail(vm.mailData[item]))) {
                    gotError = true;
                    vm.checkForEmptiness(item);
                    vm.addWarning('email');
                }
            });
            return gotError;
        },
        sendMail: (event) => {
            if (!vm.hasErrors()) {
                requestPost("/contact-about-us", vm.mailData)
                    .then(res => {
                        if (res.status) {
                            vm.isMailSend = true;
                            vm.mailData.name = "";
                            vm.mailData.email = "";
                            vm.mailData.comment = "";
                        } else {
                            console.log("Email send failed, error", res.error);
							vm.sendMail();
                        }
                    })
                    .catch(error => {
                        return { status: false, error };
                    });
            }
        }
      },
	}

</script>
<style scoped>
.service-form .form-title{
  font-size: 28px !important;
  margin-bottom: 30px !important;
  text-transform: capitalize;
}
.service-form input{
  width: 100%!important;
  height: 44px!important;
}
.service-form textarea{
  width: 100%!important;
}

.service-form.acquainted-form{
  padding: 30px!important;
}
.success-send-title{
	font-size: 24px;
}
@media (max-width: 470px){
  .service-form .form-title{
    font-size: 22px !important;
    margin-bottom: 20px !important;
  }
}
</style>
