<template>
  <!--SECTION INDUSTRIES-->
  <main>
    <div class="bank">
      <div class="container-db bread-container">
        <div class="bread-crumbs">
          <router-link to="/">Home</router-link>
          <span class="bread-separator">/</span>
          <span class="bread-current">Cannabis Pharmacy Platform </span>
        </div>
      </div>
      <div class="container-db">
        <h1 class="bank-title">Cannabis Pharmacy Platform</h1>
      </div>
    </div>
    <div class="descrip-result-wrapper">
      <div class="descrip-result-text">
        <div class="container-db">
          <div class="decription-container">
            <div class="description portfolio-container">
              <div class="portfolio-container-top">
                <div>
                  <h2>Description</h2>
                  <p>
                    System for managing, ordering, and prescribing medicines, aiming
                    to get rid of paperwork and automate complex processes in
                    HealthCare for several actors. The system covers sophisticated
                    flow from medicine manufacturers to end consumers including
                    generating standardised prescriptions and product ordering by
                    government-approved pharmacies.
                  </p>

                  <div class="tech-features-section">
                    <div class="container-db bank-tech">
                      <div class="tech-features-wrapper">
                        <div class="features">
                          <h2 class="tech-features-title">Features</h2>
                          <ul>
                            <li class="marked-item">
                              Implementing a complex event-driven notification
                              system
                            </li>
                            <li class="marked-item">
                              Implementing dynamic prescription generation based on
                              medicines physical properties and user input
                            </li>
                          </ul>
                        </div>
                        <ul class="features second">
                          <li class="marked-item">
                            Integration with a government-based authentication
                            provider
                          </li>
                          <li class="marked-item">
                            Developing flexible medicines' configurator
                          </li>
                        </ul>
                      </div>
                      <div class="tech-featuchers-wrapper">
                        <div class="technologies-wrapper">
                          <h2 class="tech-features-title">Technologies</h2>
                          <ul class="features">
                            <li class="marked-item">
                              JavaScript, ReactJS, NodeJS/NestJS, PostgreSQL,
                              RESTful API, AWS
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <img
                  class="portfolio-img"
                  v-lazyload
                  data-url="/img/images-db/case-studies/Cannabis.webp"
                  alt="Cannabis development platform"
                  title="Cannabis development platform"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="relevant-case-studies">
      <div class="container-db">
        <h2>portfolio</h2>
        <div class="slider-container">
          <div class="relevant-case-studies-slider">
            <slick ref="slick" :options="slickOptions">
              <div class="case-slider-item">
                <router-link to="doctormed">
                  <div class="case-studies-item">
                    <h3>DoctorMed</h3>
                  </div>
                </router-link>
              </div>
              <div class="case-slider-item">
                <router-link to="social-network-for-dog-owners">
                  <div class="case-studies-item">
                    <h3>Social Network for Dog Owners</h3>
                  </div>
                </router-link>
              </div>
              <div class="case-slider-item">
                <router-link to="real-estate-platform">
                  <div class="case-studies-item">
                    <h3>Real Estate</h3>
                  </div>
                </router-link>
              </div>
              <div class="case-slider-item">
                <router-link to="art-galleries-development">
                  <div class="case-studies-item">
                    <h3>ArtGallery</h3>
                  </div>
                </router-link>
              </div>
              <div class="case-slider-item">
                <router-link to="banking-app">
                  <div class="case-studies-item">
                    <h3>Banking Application</h3>
                  </div>
                </router-link>
              </div>
              <div class="case-slider-item">
                <router-link to="crypto-markets-exchange">
                  <div class="case-studies-item">
                    <h3>Markets and Exchanges</h3>
                  </div>
                </router-link>
              </div>
              <div class="case-slider-item">
                <router-link to="flexiswap-nft-swap-platform">
                  <div class="case-studies-item">
                    <h3>NFT Market and Swapping</h3>
                  </div>
                </router-link>
              </div>
              <div class="case-slider-item">
                <router-link to="currency-research-analasis-news-platform">
                  <div class="case-studies-item">
                    <h3>Currency Analysis and News</h3>
                  </div>
                </router-link>
              </div>
            </slick>
          </div>
        </div>
      </div>
    </div>
    <AcquaintedSection />
    <div
      v-lazyload
      data-src="/img/images-db/parallax/stone2.webp"
      class="lazy-bg bottom-stone"
    ></div>
    <script v-html="jsonld" type="application/ld+json"></script>
  </main>
</template>
<script>
import Slick from "vue-slick";
import AcquaintedSection from "../AcquaintedSection";
let vm = {};
export default {
  name: "CannabisPlatform",
  metaInfo: {
    title: "Cannabis Platform",
    meta: [
      {
        vmid: "description",
        name: "description",
        content:
          "We at DevBrother have created a comfortable and modern art gallery site from Canada. A small dedicated team was involved to create it.",
      },
      {
        vmid: "og:image:type",
        name: "og:image:type",
        content: "image/webp",
      },
      {
        vmid: "og:title",
        name: "og:title",
        content:
          "Case: Creating a CMS for an art gallery in Toronto VitavieGallery",
      },
      {
        vmid: "og:description",
        name: "og:description",
        content:
          "We at DevBrother have created a comfortable and modern art gallery site from Canada. A small dedicated team was involved to create it.",
      },
      {
        vmid: "og:image",
        name: "og:image",
        content:
          "https://devbrother.com/img/images-db/case-studies/art-solutions.webp",
      },
      {
        vmid: "og:type",
        name: "og:type",
        content: "website",
      },
      {
        vmid: "og:url",
        name: "og:url",
        content: "https://devbrother.com/cases/art-galleries",
      },
      {
        vmid: "twitter:title",
        name: "twitter:title",
        content:
          "Case: Creating a CMS for an art gallery in Toronto VitavieGallery",
      },
      {
        vmid: "twitter:description",
        name: "twitter:description",
        content:
          "We at DevBrother have created a comfortable and modern art gallery site from Canada. A small dedicated team was involved to create it.",
      },
      {
        vmid: "twitter:image",
        name: "twitter:image",
        content:
          "https://devbrother.com/img/images-db/case-studies/art-solutions.webp",
      },
    ],
  },
  components: { Slick, AcquaintedSection },
  mounted() {
			this.addCanonicalLink();
  },
	methods: {
			addCanonicalLink: () => {
				const link = document.createElement('link');
				link.setAttribute('rel', 'canonical');
				link.setAttribute('href', 'https://devbrother.com/cases/cannabis-platform');

				const oldLink = document.querySelector('link[rel="canonical"]');
				if (oldLink) {
					document.head.removeChild(oldLink);
				}

				document.head.appendChild(link);
			},
	},
  data() {
    const jsonld = {
      "@context": "https://schema.org",
      "@type": "IndividualProduct",
      name: "Art Gallery",
      description: "Art Gallery",
    };
    return {
      jsonld,
      slickOptions: {
        prevArrow:
          '<div class="prev-arrow"><img src="/img/images-db/Arrow.svg" alt="arrow" class="arrow" width="26" height="11"/><img src="/img/images-db/Arrow-hover.svg" alt="arrow" class="arrow-hover" width="26" height="11"/></div>',
        nextArrow:
          '<div class="next-arrow"><img src="/img/images-db/Arrow.svg" alt="arrow" class="arrow" width="26" height="11"/><img src="/img/images-db/Arrow-hover.svg" alt="arrow" class="arrow-hover" width="26" height="11"/></div>',
        slidesToShow: 4,
        slidesToScroll: 1,
        // centerMode: true,
        responsive: [
          {
            breakpoint: 769,
            settings: {
              slidesToShow: 1,
              centerMode: false,
              prevArrow: "",
              nextArrow: "",
              dots: true,
              customPaging: function (slider, i) {
                return '<div class="slick-mobile-dot"></div>';
              },
            },
          },
        ],
        // Any other options that can be got from plugin documentation
      },
    };
  },
};
</script>
