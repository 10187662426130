<template>
  <!--SECTION ABOUT-->
  <section class="our-works" data-aos="zoom-in" data-aos-duration="1000">
    <div class="inner-container">
      <!-- <h2 class="our-works-title">Our teams home</h2> -->
      <div class="our-works-content">
        <div class="our-works-slider slider-list">
          <slick ref="slick" :options="slickOptions">

            <div class="slider-item">
              <div class="content-item">
                <img height="265" width="450" src="images/Office-106.webp" alt="office photo" />
              </div>
            </div>

            <div class="slider-item">
              <div class="content-item">
                <img height="265" width="450" src="images/Office-108.webp" alt="office photo" />
              </div>
            </div>
            
            
            <div class="slider-item">
              <div class="content-item">
                <img height="265" width="450" src="images/Office-7761.webp" alt="office photo" />
              </div>
            </div>

            <div class="slider-item">
              <div class="content-item">
                <img height="265" width="450" src="images/Office-7763.webp" alt="office photo" />
              </div>
            </div>

            <div class="slider-item">
              <div class="content-item">
                <img height="265" width="450" src="images/Office-7762.webp" style="object-fit: contain;" alt="office photo" />
              </div>
            </div>

            <div class="slider-item">
              <div class="content-item">
                <img height="265" width="450" src="images/Office-107.webp" alt="office photo" />
              </div>
            </div>

            <div class="slider-item">
              <div class="content-item">
                <img height="265" width="450" src="images/Office-03.webp" alt="office photo" />
              </div>
            </div>

            <div class="slider-item">
              <div class="content-item">
                <img height="265" width="450" src="images/Office-109.webp" alt="office photo" />
              </div>
            </div>

            <div class="slider-item">
              <div class="content-item">
                <img height="265" width="450" src="images/Office-05.webp" alt="office photo" />
              </div>
            </div>

            <div class="slider-item">
              <div class="content-item">
                <img height="265" width="450" src="images/Office-110.webp" alt="office photo" />
              </div>
            </div>

            <div class="slider-item">
              <div class="content-item">
                <img height="265" width="450" src="images/Office-111.webp" alt="office photo" />
              </div>
            </div>

          </slick>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Slick from "vue-slick";
let vm = {};

export default {
  name: "OurTeamsHome",
  components: {
    Slick
  },
  beforeCreate() {
    vm = this;
  },
  mounted() {
  },
  data() {
    return {
      slickOptions: {
        prevArrow:
          '<div class="prev-arrow"><img height="11" width="26" src="/img/images-db/Arrow.svg" alt="" class="arrow-testimonials"/><img height="11" width="26" src="/img/images-db/Arrow-hover.svg" alt="" class="arrow-hover-testimonials"/></div>',
        nextArrow:
          '<div class="next-arrow"><img height="11" width="26" src="/img/images-db/Arrow.svg" alt="" class="arrow-testimonials" /><img height="11" width="26" src="/img/images-db/Arrow-hover.svg" alt="" class="arrow-hover-testimonials" /></div>',
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 2,
              centerMode: false,
              prevArrow: "",
              nextArrow: "",
              dots: true,
              customPaging: function (slider, i) {
                return '<div class="slick-mobile-dot"></div>';
              }
            }
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              centerMode: false,
              prevArrow: "",
              nextArrow: "",
              dots: true,
              customPaging: function (slider, i) {
                return '<div class="slick-mobile-dot"></div>';
              }
            }
          }
        ]
        // Any other options that can be got from plugin documentation
      }
    };
  }
};
</script>

<style lang="scss" scoped>

.our-works {
  padding-top: 20px;
  padding-bottom: 120px;
  
  .content-item {
    transition: 0.3s;
    img {
      height: 265px;
      @media (max-width: 767px){
      &{
        max-width: 340px;
        margin-left: auto;
        margin-right: auto;
      }
    }
    }
  }
}
</style>
