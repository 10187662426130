<template>
    <section class="clients awards-section">
        <div class="clients-wrapper award-wrapper">
            <div class="inner-container">
                <div class="popup" :class="{ 'hide-popup': !showModal}">
                <div class="popup-wrapper">
                    <div class="popup-inner" @click.self="closePopup()">
                        <div class="popup-content" id="main-contact-form">
                            <button class="close-popup" @click="closePopup()" type="button" aria-label="close popup"></button>
                            <HelpArmyModal/>
                        </div>
                    </div>
                </div>
                </div>
                <!--<a v-if="armyButton" @click="showPopup" class="btn help-army">Help to Ukrainian army</a>-->
                <!-- <h5 class="our-achievements">Our achievements</h5> -->
                <h2 class="our-achievements">Our achievements</h2>
                <ul class="award-list">
                    <li class="awards-list-item" data-aos="zoom-in" data-aos-duration="1500">
                        <a target="_blank" href="https://www.goodfirms.co/company/devbrother" rel="nofollow">
                            <img v-lazyload height="160" width="187" data-url="https://goodfirms.s3.amazonaws.com/badges/color-badge/top-software-development-companies.svg" alt="GoodFirms Badge">
                        </a>
                    </li>
                    <li class="awards-list-item" data-aos="zoom-in" data-aos-duration="500">
                        <a href="https://clutch.co/profile/devbrother" target="_blank" rel="nofollow">
                            <img v-lazyload class="award-logo" data-url="/img/images-db/socials/award2.png" height="160" width="148" alt="Clutch Top Developers" >
                        </a>
                        <!-- <div class="clutch-widget clutch-widget-mobile" data-url="https://widget.clutch.co" data-widget-type="1" data-height="50" data-darkbg="1" data-clutchcompany-id="1327434"></div> -->
                    </li>
                    <li class="awards-list-item" data-aos="zoom-in" data-aos-duration="500">
                        <a class="top-soft-award-logo" href="https://topsoftwarecompanies.co/ukraine/software-development/agencies/kharkiv" target="_blank" rel="nofollow">
                            <img v-lazyload height="150" width="150" data-url="/img/images-db/socials/top-software-developers.webp" alt="software developers kharkiv" title="Software Developers Kharkiv" />
                        </a>
                    </li>
                </ul>
            </div>
            <!-- <div class="clutch-widget-stars clutch-widget-desktop">
                <div class="clutch-widget" data-url="https://widget.clutch.co" data-widget-type="1" data-height="50" data-darkbg="1" data-clutchcompany-id="1327434"></div>
            </div> -->
        </div>
    </section>
</template>
<script>
import HelpArmyModal from '../partials/HelpArmyModal';

let vm = {};

export default {
  name: "app-header",
  components: { HelpArmyModal },

  data() {
    return {
      showModal: false,
      armyButton: false
    };
  },
  mounted() {
    if (this.$route.name === 'Home') {
        this.armyButton = true;
    }
  },
  beforeCreate() {
    vm = this;
  },

  methods: {
    showPopup: () => {
      vm.showModal = true;
    },
    closePopup: () => {
      vm.showModal = false;
    }
  }
};
</script>
<style scoped>
    .award-wrapper .our-achievements {
        text-align: center;
    }

    .help-army {
        position: absolute;
        top: -140px;
        left: 50%;
        margin-left: -220px;
        font-family: "Roboto Condensed";
        letter-spacing: 1.5px;
        padding: 30px 20px;
        font-size: 40px;
        border-radius: 0px;
        box-shadow: none;
        cursor: pointer;
    }

    @media(max-width: 1024px) {
        .help-army {
            padding: 20px 15px;
            font-size: 23px;
            margin-left: -140px;
            top: -70px;
        }
    }

    .help-army:hover {
        color: #06c6ff;
        background: white;
    }
</style>